import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Typography, Form, Input, DatePicker, Select, Button, AutoComplete, Flex, message, Space, Card } from 'antd';
import { IoIosAdd, IoIosArrowRoundForward, IoIosPersonAdd, IoIosRemove } from "react-icons/io";
import { debounce } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import confetti from 'canvas-confetti';
import dayjs from 'dayjs';
const { Title, Paragraph } = Typography;
const { Option } = Select;

const Billing = ({ userData, sessionData, storeData }) => {
    const storeDetail = storeData?.filter(store => store?.store_id.toString() === localStorage.getItem('storeId'))?.[0];
    const [phoneNo, setPhoneNo] = useState('');
    const [fetchCustomer, setFetchCustomer] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [customerDatas, setCustomerDatas] = useState([]);
    const [showCustomer, setShowCustomer] = useState(false);
    const [addMoreDetail, setAddMoreDetail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [billingAmount, setBillingAmount] = useState('');
    const [billingNumber, setBillingNumber] = useState('');
    const [birthDate, setBirthDate] = useState(null);
    const [anniversary, setAnniversary] = useState(null);
    const [gender, setGender] = useState('');
    const [customerId, setCustomerId] = useState(0);
    const [tags, setTags] = useState([]);
    const [redeemPoints, setRedeemPoints] = useState('');

    const [customerList, setCustomerList] = useState([{ customer_number: '', customer_name: '', customer_points: 0, customer_tags: [] }]);

    const handleCustomerChange = (index, field, value) => {
        const updatedList = [...customerList];
        updatedList[index][field] = value;
        setCustomerList(updatedList);
    };

    const addCustomerRow = () => {
        setCustomerList([...customerList, { customer_number: '', customer_name: '', customer_points: 0, customer_tags: [] }]);
    };

    const handleRedeem = () => {

    };

    const currencyToCountryMap = {
        AED: 'ae',
        AFN: 'af',
        ALL: 'al',
        AMD: 'am',
        ANG: 'cw',
        AOA: 'ao',
        ARS: 'ar',
        AUD: 'au',
        AWG: 'aw',
        AZN: 'az',
        BAM: 'ba',
        BBD: 'bb',
        BDT: 'bd',
        BGN: 'bg',
        BHD: 'bh',
        BIF: 'bi',
        BMD: 'bm',
        BND: 'bn',
        BOB: 'bo',
        BRL: 'br',
        BSD: 'bs',
        BTN: 'bt',
        BWP: 'bw',
        BYN: 'by',
        BZD: 'bz',
        CAD: 'ca',
        CDF: 'cd',
        CHF: 'ch',
        CLP: 'cl',
        CNY: 'cn',
        COP: 'co',
        CRC: 'cr',
        CUP: 'cu',
        CVE: 'cv',
        CZK: 'cz',
        DJF: 'dj',
        DKK: 'dk',
        DOP: 'do',
        DZD: 'dz',
        EGP: 'eg',
        ERN: 'er',
        ETB: 'et',
        EUR: 'eu',
        FJD: 'fj',
        FKP: 'fk',
        FOK: 'fo',
        GBP: 'gb',
        GEL: 'ge',
        GHS: 'gh',
        GIP: 'gi',
        GMD: 'gm',
        GNF: 'gn',
        GTQ: 'gt',
        GYD: 'gy',
        HKD: 'hk',
        HNL: 'hn',
        HRK: 'hr',
        HTG: 'ht',
        HUF: 'hu',
        IDR: 'id',
        ILS: 'il',
        INR: 'in',
        IQD: 'iq',
        IRR: 'ir',
        ISK: 'is',
        JMD: 'jm',
        JOD: 'jo',
        JPY: 'jp',
        KES: 'ke',
        KGS: 'kg',
        KHR: 'kh',
        KMF: 'km',
        KPW: 'kp',
        KRW: 'kr',
        KWD: 'kw',
        KYD: 'ky',
        KZT: 'kz',
        LAK: 'la',
        LBP: 'lb',
        LKR: 'lk',
        LRD: 'lr',
        LSL: 'ls',
        LYD: 'ly',
        MAD: 'ma',
        MDL: 'md',
        MGA: 'mg',
        MKD: 'mk',
        MMK: 'mm',
        MNT: 'mn',
        MOP: 'mo',
        MRU: 'mr',
        MUR: 'mu',
        MVR: 'mv',
        MWK: 'mw',
        MXN: 'mx',
        MYR: 'my',
        MZN: 'mz',
        NAD: 'na',
        NGN: 'ng',
        NIO: 'ni',
        NOK: 'no',
        NPR: 'np',
        NZD: 'nz',
        OMR: 'om',
        PAB: 'pa',
        PEN: 'pe',
        PGK: 'pg',
        PHP: 'ph',
        PKR: 'pk',
        PLN: 'pl',
        PYG: 'py',
        QAR: 'qa',
        RON: 'ro',
        RSD: 'rs',
        RUB: 'ru',
        RWF: 'rw',
        SAR: 'sa',
        SBD: 'sb',
        SCR: 'sc',
        SDG: 'sd',
        SEK: 'se',
        SGD: 'sg',
        SHP: 'sh',
        SLL: 'sl',
        SOS: 'so',
        SRD: 'sr',
        SSP: 'ss',
        STN: 'st',
        SYP: 'sy',
        SZL: 'sz',
        THB: 'th',
        TJS: 'tj',
        TMT: 'tm',
        TND: 'tn',
        TOP: 'to',
        TRY: 'tr',
        TTD: 'tt',
        TWD: 'tw',
        TZS: 'tz',
        UAH: 'ua',
        UGX: 'ug',
        USD: 'us',
        UYU: 'uy',
        UZS: 'uz',
        VES: 've',
        VND: 'vn',
        VUV: 'vu',
        WST: 'ws',
        XAF: 'cm',
        XCD: 'ag',
        XOF: 'sn',
        XPF: 'pf',
        YER: 'ye',
        ZAR: 'za',
        ZMW: 'zm',
        ZWL: 'zw',
    };

    const removeCustomerRow = (index) => {
        const updatedList = customerList.filter((_, i) => i !== index);
        setCustomerList(updatedList);
    };

    const fetchCustomerData = debounce(async (phone) => {
        if (phone === '') {
            setCustomerData([]);
            return;
        }
        if (fetchCustomer) {
            return;
        }
        setFetchCustomer(true);
        try {
            const response = await fetch(`${window.API_BASE_URL}/users/search-customers?search=${phone}&storeId=${storeDetail?.store_id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            console.log(data);
            if (data?.user?.length) {
                setCustomerData(data.user);
            } else {
                setCustomerData([]);
            }
        } catch (error) {
            console.error('Error fetching customer:', error);
        }
        setFetchCustomer(false);
    }, 500);

    const handlePhoneChange = (e) => {
        const phone = e.target.value;
        setPhoneNo(phone);
        fetchCustomerData(phone);
    };

    const handlePhoneChangeOne = (value) => {
        const phone = value;
        setPhoneNo(phone);
    } 

    const handleShowDetails = async (customerData) => {
        try {
            setLoading(true);
            const response = await fetch(`${window.API_BASE_URL}/users/bill-customers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify({
                    'customer_number': customerData?.customer_number ? customerData?.customer_number : phoneNo,
                    'countrycode': '',
                    'customer_name': customerData?.customer_name ? customerData?.customer_name : customerName,
                    'customer_email': customerData?.customer_email ? customerData?.customer_email : customerEmail,
                    'customer_gender': customerData?.customer_gender ? customerData?.customer_gender : gender,
                    'customer_dob': customerData?.customer_dob ? customerData.customer_dob : dayjs(birthDate).format('DD-MM-YYYY'),
                    'customer_anniversary': customerData?.customer_anniversary ? customerData.customer_anniversary : dayjs(anniversary).format('DD-MM-YYYY'),
                    'store_id': storeDetail?.store_id,
                    'billing_amount': billingAmount,
                    'billing_number': billingNumber,
                    'tags': customerData?.tags ? JSON.parse(customerData?.customer_tags) : tags,
                    'customer_id': customerData?.customer_id ? customerData?.customer_id : customerId,
                })
            });
            const data = await response.json();
            if (response.ok) {
                setCustomerDatas(customerData);
                setPhoneNo(customerData?.customer_number);
                setCustomerName(customerData?.customer_name);
                setCustomerEmail(customerData?.customer_email);
                setGender(customerData?.customer_gender);
                setBillingAmount('');
                setBillingNumber('');
                setBirthDate(customerData?.customer_dob ? customerData.customer_dob !== '' ? dayjs(customerData.customer_dob, 'DD-MM-YYYY') : null : null);
                setAnniversary(customerData?.customer_anniversary ? customerData.customer_anniversary !== '' ? dayjs(customerData.customer_anniversary, 'DD-MM-YYYY') : null : null);
                setTags(customerData?.customer_tags ? JSON.parse(customerData?.customer_tags) : []);
                if (customerData?.customer_id) {
                    setCustomerId(customerData?.customer_id);
                } else {
                    setCustomerId(data?.customerId);
                }
                message.success(data.message);
            } else {
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowCustomer(true);
            setLoading(false);
        }
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        handleShowDetails();
        setShowCustomer(false);
        setCustomerName('');
        setCustomerEmail('');
        setBirthDate(null);
        setAnniversary(null);
        setGender('');
        setCustomerId(0);
        setTags([]);
        setLoading(false);
    };

    useEffect(() => {
        return () => {
            fetchCustomerData.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const PropTitle = (props) => (
        <Flex align="center" justify="space-between">{props.title}</Flex>
    );

    const renderItem = (customerData, title, phoneNo) => ({
        value: title,
        label: (
            <Flex align="center" justify="space-between" onClick={() => handleShowDetails(customerData)}><Flex align="center" justify="start" gap={8}><div style={{ fontSize: 16, fontWeight: 'bold' }}>{highlightMatch(title, phoneNo)}</div></Flex> {phoneNo === '' ? <IoIosPersonAdd size={24} /> : <IoIosArrowRoundForward size={24} />}</Flex>
        ),
    });

    const highlightMatch = (number, match) => {
        const index = number.indexOf(match);
        if (index === -1) return number;

        return (
            <>
                {number.slice(0, index)}
                <span style={{ backgroundColor: '#3a1a55', color: 'white' }}>
                    {number.slice(index, index + match.length)}
                </span>
                {number.slice(index + match.length)}
            </>
        );
    };

    const handleConfetti = () => {
        confetti({
            particleCount: 500,
            spread: 100,
            origin: { y: 0.6 },
        });
    };

    const handleSubmitData = async () => {
        setLoading(true);
        const data = {
            customers: customerList,
            file_name: 'multiple add manually',
            store_id: localStorage.getItem('storeId')
        };
        try {
            const url = `${window.API_BASE_URL}/users/add-multiple-customers`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            if (!response.ok) {
                console.error("Error while uploading customers:", response);
            } else {
                handleConfetti();
                message.success('Customr data uploaded successfully.');
                console.log(responseData);
            }
        } catch (error) {
            console.error("Error proceeding payment data:", error);
        } finally {
            setLoading(false);
            setCustomerList([{ customer_number: '', customer_name: '', customer_points: 0, customer_tags: [] }]);
        }
    };

    const options = [
        ...(customerData.length !== 1 && phoneNo !== ''
            ? [{ label: (<PropTitle title={fetchCustomer ? 'Fetching customers' : 'Add customers'} />), options: [renderItem(customerData, phoneNo, '')] }]
            : []
        ),
        ...(customerData.length > 0
            ? [...new Map(
                customerData.map((customer) => [
                    customer.customer_number, // Key for uniqueness
                    {
                        label: (
                            <PropTitle
                                title={fetchCustomer ? 'Fetching customers' : customer.customer_name || 'Add customers'}
                            />
                        ),
                        options: [renderItem(customer, customer.customer_number, phoneNo)]
                    }
                ])
            ).values()] // Only unique values
            : []
        )
    ];

    return (
        <Layout className='add-customers-layout'>
            <Row gutter={16} align={'center'}>
                <Col span={12}>
                    <div className='add-customers-div'>
                        <Title level={3} style={{ display: 'flex', alignItems: 'center', marginTop: 0 }}>
                            <b>Add / Edit Customer</b>
                        </Title>
                        <Form layout="vertical" >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Customer Phone"
                                        rules={[{ required: false, message: 'Please enter customer phone number' }]}
                                    >
                                        <AutoComplete options={options} popupClassName="certain-category-search-dropdown">
                                            <Input.Search type='number' placeholder="Enter customer phone number" value={phoneNo} onChange={handlePhoneChange} />
                                        </AutoComplete>
                                    </Form.Item>
                                </Col>
                                {showCustomer && (
                                    <>
                                        <Form.Item
                                            label="Phone No."
                                            rules={[{ required: false, message: 'Please enter phone no.' }]}
                                        >
                                            <PhoneInput type='number' country={currencyToCountryMap[storeDetail?.currency_code] || 'in'} value={phoneNo} onChange={handlePhoneChangeOne} enableSearch={true} inputStyle={{ width: '100%' }} />
                                        </Form.Item>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Customer Name"
                                                rules={[{ required: false, message: 'Please enter customer name' }]}
                                            >
                                                <Input placeholder="Enter customer name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Billing amount"
                                                rules={[{ required: false, message: 'Please enter blling amount' }]}
                                            >
                                                <Input type='number' placeholder="Enter blling amount" value={billingAmount} onChange={(e) => setBillingAmount(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Billing number (Optional)"
                                                rules={[{ required: false, message: 'Please enter billing number' }]}
                                            >
                                                <Input placeholder="Enter billing number" value={billingNumber} onChange={(e) => setBillingNumber(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Flex justify='space-between' align='center' onClick={() => setAddMoreDetail(!addMoreDetail && true)} style={{ cursor: 'pointer', backgroundColor: '#eee', padding: '8px 16px', borderRadius: 6, width: '100%', margin: '0 8px 24px 8px' }}><Title style={{ margin: 0 }} level={5}>Add more customer details</Title> {addMoreDetail ? <IoIosRemove size={24} /> : <IoIosAdd size={24} />} </Flex>
                                        {addMoreDetail && (
                                            <>

                                                <Col span={24}>
                                                    <Form.Item
                                                        label="Customer Email"
                                                        rules={[{ required: false, message: 'Please enter customer email' }, { type: 'email', message: 'Please enter a valid email' }]}
                                                    >
                                                        <Input placeholder="Enter customer email" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label="Birth Date"
                                                        rules={[{ required: false, message: 'Please select birth date' }]}
                                                    >
                                                        <DatePicker placeholder="Select birth date" style={{ width: '100%' }} value={birthDate}
                                                            onChange={(date) => setBirthDate(date)} format={'DD-MM-YYYY'} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label="Anniversary"
                                                    >
                                                        <DatePicker placeholder="Select anniversary" style={{ width: '100%' }} value={anniversary}
                                                            onChange={(date) => setAnniversary(date)} format={'DD-MM-YYYY'} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label="Gender"
                                                        rules={[{ required: true, message: 'Please select gender' }]}
                                                    >
                                                        <Select placeholder="Select gender" value={gender}
                                                            onChange={(value) => setGender(value)}>
                                                            <Option value="male">Male</Option>
                                                            <Option value="female">Female</Option>
                                                            <Option value="other">Other</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="Tags"
                                                    >
                                                        <Select mode="tags" placeholder="Add tags" style={{ width: '100%' }} value={tags} onChange={(value) => setTags(value)} />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                    </>
                                )}
                            </Row>
                            <Form.Item>
                                <Button type="primary" style={{ width: '100%' }} loading={loading} onClick={handleSubmit}>Submit</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
                <Col span={12}>
                    <div className='add-customers-div'>
                        <Title level={3} style={{ display: 'flex', alignItems: 'center', marginTop: 0 }}>
                            <b>Points and rewards</b>
                        </Title>
                        <Paragraph style={{ margin: 0 }}>Customer earns 10% Cashback on any purchase</Paragraph>
                        {customerDatas?.customer_name && (
                            <Card>
                                <b>{customerDatas?.customer_name} has {customerDatas?.customer_points} cash in total</b>
                                {parseInt(customerDatas?.customer_points) === 0 ? (
                                    <Paragraph>No reward available to redeem</Paragraph>
                                ) : (
                                    <div style={{ marginTop: 16 }}>
                                        <Paragraph>Redeem partially</Paragraph>
                                        <Input type='number' placeholder='Eg. 10' min={1} max={parseInt(customerDatas?.customer_points)} value={redeemPoints} onChange={(e) => setRedeemPoints(e.target.value)} />
                                        <Button type='primary' style={{ marginTop: 16 }} onClick={handleRedeem}>Redeem</Button>
                                    </div>
                                )}
                            </Card>
                        )}
                    </div>
                </Col>
            </Row>
        </Layout>
    );
};

export default Billing;
