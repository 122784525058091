import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Layout, Row, Col, Typography, Button, Spin, Table, Tag, Tooltip, Modal, Flex, Input, Select, DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PiArrowLeftDuotone, PiEyeDuotone } from 'react-icons/pi';
import { LoadingOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import moment from 'moment';
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const RefillHistory = ({ userData, sessionData }) => {
    const navigate = useNavigate();
    const [transactionData, setTransactionData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchRef = useRef(null);
    const [dateFilter, setDateFilter] = useState('last12months');
    const [customRange, setCustomRange] = useState([
        moment().subtract(12, 'months').startOf('month'),
        moment().endOf('month')
    ]);

    const checkPendingTransactions = useCallback(async (transactinData) => {
        const pendingTransactions = transactinData.filter(
            (transaction) => transaction.payment_status === 'pending' && transaction.status === 'Initiated'
        );
        pendingTransactions.forEach(async (transaction) => {
            const url = `${window.API_BASE_URL}/payment/status/${transaction.payment_id}`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                });
                const data = await response.json();
                console.log(data);

                if (response.ok) {
                    setTransactionData((prevData) =>
                        prevData.map((item) =>
                            item.payment_id === transaction.payment_id ? { ...item, ...data.transactionData } : item
                        )
                    );
                } else {
                    if (data.message === 'Invalid token') {
                        localStorage.removeItem('loginToken');
                        localStorage.removeItem('deviceId');
                        localStorage.removeItem('storeId');
                        // navigate('/login');
                    }
                    console.error('Error fetching payment status:', data.message);
                }
            } catch (error) {
                console.error('Error during fetching payment status:', error);
            }
        });
    }, [sessionData?.token]);

    const fetchTransactionData = useCallback(async (searchTerm = '', startDate = '', endDate = '', page = 1, pageSize = 10) => {
        console.log(startDate);
        console.log(endDate);
        setTableLoading(true);
        // const url = `${window.API_BASE_URL}/channels/refill-transactions?search=${searchTerm}`;
        const url = new URL(`${window.API_BASE_URL}/channels/refill-transactions`);
        const params = new URLSearchParams();
        if (searchTerm) params.append('search', searchTerm);
        if (startDate) params.append('dateRange', `${startDate},${endDate}`);
        params.append('page', page);
        params.append('pageSize', pageSize);
        url.search = params.toString();
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setLoading(false);
                setTransactionData(data?.transactionData);
                checkPendingTransactions(data?.transactionData);
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                    // navigate('/login');
                } else {
                    setTransactionData([]);
                }
                console.error('Error fetching user data:', data.message);
            }
        } catch (error) {
            console.error('Error during fetching transactions:', error);
        } finally {
            setTableLoading(false);
        }
    }, [checkPendingTransactions, sessionData?.token]);

    useEffect(() => {
        const fetchData = async () => {
            if (sessionData) {
                await fetchTransactionData(searchTerm, customRange[0].format("YYYY-MM-DD"), customRange[1].format("YYYY-MM-DD"));
            } else {
                setLoading(false);
            }
        };
        fetchData();
    }, [fetchTransactionData, sessionData, searchTerm, customRange]);

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    useEffect(() => {
        if (debouncedSearchRef.current) {
            debouncedSearchRef.current.cancel();
        }
        debouncedSearchRef.current = debounce((value) => {
            handleSearch(value);
        }, 300);
        debouncedSearchRef.current(searchTerm);
        return () => {
            debouncedSearchRef.current.cancel();
        };
    }, [searchTerm]);

    const handleDateFilterChange = (value) => {
        setDateFilter(value);
        const { startDate, endDate } = getDateRange(value);
        const dates = [startDate, endDate];
        setCustomRange(dates);
    };

    const handleCustomRangeChange = (dates) => {
        if (dates && dates.length === 2) {
            setCustomRange(dates);
        }
    };

    const getDateRange = (value) => {
        let startDate, endDate;
        switch (value) {
            case 'today':
                startDate = moment().startOf('day');
                endDate = moment().endOf('day');
                break;
            case 'last7days':
                startDate = moment().subtract(7, 'days').startOf('day');
                endDate = moment().endOf('day');
                break;
            case 'last30days':
                startDate = moment().subtract(30, 'days').startOf('day');
                endDate = moment().endOf('day');
                break;
            case 'thismonth':
                startDate = moment().startOf('month');
                endDate = moment().endOf('month');
                break;
            case 'lastmonth':
                startDate = moment().subtract(1, 'month').startOf('month');
                endDate = moment().subtract(1, 'month').endOf('month');
                break;
            case 'last12months':
                startDate = moment().subtract(12, 'months').startOf('month');
                endDate = moment().endOf('month');
                break;
            default:
                startDate = moment().startOf('day');
                endDate = moment().endOf('day');
        }
        console.log(startDate.format('YYYY-MM-DD HH:mm:ss'), endDate.format('YYYY-MM-DD HH:mm:ss'));
        return { startDate, endDate };
    };


    const filteredData = transactionData.filter(transaction =>
        transaction.payment_id.toString().includes(searchTerm)
    );

    const columns = [
        {
            title: 'Payment ID',
            dataIndex: 'payment_id',
            key: 'payment_id',
        },
        {
            title: 'Payment Status',
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (text) => {
                const colorMap = {
                    pending: 'orange',
                    completed: 'green',
                    failed: 'red',
                    refunded: 'blue',
                };
                return (
                    <Tag color={colorMap[text]} style={{ textTransform: 'capitalize' }}>
                        {text}
                    </Tag>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                const colorMap = {
                    Initiated: 'purple',
                    pending: 'orange',
                    success: 'green',
                    failed: 'red',
                    cancelled: 'gray',
                    refunded: 'blue',
                };
                return (
                    <Tag color={colorMap[text]} style={{ textTransform: 'capitalize' }}>
                        {text}
                    </Tag>
                );
            },
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            key: 'total_price',
            render: (_, record) => (`${record.payment_via === 'paypal' ? '$' : '₹'} ${record.total_price}`),
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => moment(created_at).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Tooltip title="View Refill History">
                    <Button className='previewBtnOne' onClick={() => handleViewRefill(record)}>
                        <PiEyeDuotone />
                    </Button>
                </Tooltip>
            ),
        }
    ];

    // Open modal and set refill data
    const handleViewRefill = (record) => {
        setSelectedTransaction(record);
        setIsModalVisible(true);
    };

    // Close modal
    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedTransaction(null);
    };

    return (
        loading ? (
            <Layout className='contentLayout'>
                <div className='loaderDiv'>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }
                    />
                </div>
            </Layout>
        ) : (
            <Layout className='contentLayout'>
                <Flex gap='middle' style={{ marginBottom: 24 }}>
                    <Button shape="round" onClick={() => navigate(`/channels`)} className='close-btn'>
                        <PiArrowLeftDuotone />
                    </Button>
                    <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Refill History</b></Title>
                </Flex>
                <Row gutter={16} style={{ marginBottom: 16 }}>
                    <Col span={8}>
                        <Search
                            placeholder="Search by Payment ID"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            enterButton
                        />
                    </Col>
                    <Col span={4} offset={dateFilter !== 'custom' ? 7 : 7}>
                        <Select defaultValue={dateFilter} onChange={handleDateFilterChange} style={{ width: '100%' }}>
                            <Option value="today">Today</Option>
                            <Option value="last7days">Last 7 Days</Option>
                            <Option value="last30days">Last 30 Days</Option>
                            <Option value="thismonth">This Month</Option>
                            <Option value="lastmonth">Last Month</Option>
                            <Option value="last12months">Last 12 Months</Option>
                            <Option value="custom">Custom Range</Option>
                        </Select>
                    </Col>
                    {/* {dateFilter === 'custom' && ( */}
                    <Col span={5}>
                        <RangePicker
                            value={customRange}
                            onChange={handleCustomRangeChange}
                            format="YYYY-MM-DD"
                            style={{ width: '100%' }}
                        />
                    </Col>
                    {/* )} */}
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Table
                            className='customerTable'
                            dataSource={filteredData}
                            columns={columns}
                            rowKey="payment_id"
                            loading={tableLoading}
                            pagination={{ pageSize: 10 }}
                        />
                    </Col>
                </Row>
                <Modal
                    title="Refill Details"
                    open={isModalVisible}
                    onCancel={handleCloseModal}
                    footer={false}
                >
                    {selectedTransaction?.refill_data && JSON.parse(selectedTransaction?.refill_data).length > 0 ? (
                        <Table
                            dataSource={JSON.parse(selectedTransaction?.refill_data)}
                            rowKey="refill_id"
                            pagination={false}
                            columns={[
                                {
                                    title: 'Refill ID',
                                    dataIndex: 'refill_id',
                                    key: 'refill_id',
                                },
                                {
                                    title: 'Refill Name',
                                    dataIndex: 'refill_name',
                                    key: 'refill_name',
                                },
                                {
                                    title: 'Credits',
                                    dataIndex: 'credits',
                                    key: 'credits',
                                },
                            ]}
                        />
                    ) : (
                        <p>No refill data available.</p>
                    )}
                </Modal>
            </Layout>
        )
    );
};

export default RefillHistory;