import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import confetti from 'canvas-confetti';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import dayjs from 'dayjs';
import bgone from '../assets/1.svg';
import bgtwo from '../assets/2.svg';
import bgthree from '../assets/3.svg';
import bgfour from '../assets/4.svg';
import bgfive from '../assets/5.svg';
import bgsix from '../assets/6.svg';
import bgseven from '../assets/7.svg';
import bgeight from '../assets/8.svg';
import WhatsApp from '../assets/WhatsApp.svg';
import foodOne from '../assets/food/F4FrNfile71ul8ygxgt013bpkm3gf15526362011698389697834.jpg';
import arrowOne from '../assets/arrow1.svg';
import phonevector2 from '../assets/phonevector2.png';
import phonevector from '../assets/phonevector.svg';
import gmail from '../assets/gmail.svg';
import whatsappMobileMokup from '../assets/zipler-mobile-mock-up.png';
import smsMobileMokup from '../assets/sms.png';
import emailMobileMokup from '../assets/email.png';
import emailBanner from '../assets/food/66d69dd61bc26d1b480560db.png';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import PhoneInput from 'react-phone-input-2';
import waitImage from '../assets/review.gif';
import backOne from '../assets/Background_11zon.webp';
import 'react-phone-input-2/lib/style.css';
import { FcBinoculars } from "react-icons/fc";
import { Layout, Row, Col, Typography, Tabs, Carousel, Image, Flex, Button, Slider, Switch, Card, Dropdown, Drawer, Segmented, Progress, Checkbox, Radio, Input, Tooltip, DatePicker, message, Upload, ColorPicker, Avatar, Select, Spin, Modal } from 'antd';
import { PlusOutlined, GiftOutlined, DollarCircleOutlined, PercentageOutlined, ArrowRightOutlined, DownOutlined, UploadOutlined, InboxOutlined, HolderOutlined, CloseOutlined, LinkOutlined, LoadingOutlined, EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { FaBold, FaItalic, FaStrikethrough } from "react-icons/fa";
import { GrAnnounce, GrMonospace } from "react-icons/gr";
import { PiArrowArcLeft, PiArrowArcRightDuotone, PiArrowLeftDuotone, PiArrowRightDuotone, PiChatCenteredDotsDuotone, PiClockAfternoonDuotone, PiEnvelopeDuotone, PiFileDocDuotone, PiFilePdfDuotone, PiFilePptDuotone, PiFileTextDuotone, PiFileVideoDuotone, PiFileXlsDuotone, PiFloppyDiskDuotone, PiHandPointingDuotone, PiImageDuotone, PiImagesDuotone, PiInfoDuotone, PiLaptopDuotone, PiLightbulbDuotone, PiLinkSimpleDuotone, PiMicrosoftPowerpointLogoDuotone, PiPackageDuotone, PiPencilDuotone, PiPhoneCall, PiPlusBold, PiShoppingCartDuotone, PiSmileyDuotone, PiTextTDuotone, PiUsersDuotone, PiVideoDuotone, PiWhatsappLogo, PiX } from 'react-icons/pi';
import defaultImage from '../assets/dymmy.jpg';
import ImgCrop from 'antd-img-crop';
import CampaignPreformence from './CampaignPreformence';
const { Paragraph, Title } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const twoColors = {
    '0%': '#108ee9',
    '50%': '#3A1A55',
    '100%': '#3A1A55',
};

const currencyToCountryMap = {
    AED: 'ae',
    AFN: 'af',
    ALL: 'al',
    AMD: 'am',
    ANG: 'cw',
    AOA: 'ao',
    ARS: 'ar',
    AUD: 'au',
    AWG: 'aw',
    AZN: 'az',
    BAM: 'ba',
    BBD: 'bb',
    BDT: 'bd',
    BGN: 'bg',
    BHD: 'bh',
    BIF: 'bi',
    BMD: 'bm',
    BND: 'bn',
    BOB: 'bo',
    BRL: 'br',
    BSD: 'bs',
    BTN: 'bt',
    BWP: 'bw',
    BYN: 'by',
    BZD: 'bz',
    CAD: 'ca',
    CDF: 'cd',
    CHF: 'ch',
    CLP: 'cl',
    CNY: 'cn',
    COP: 'co',
    CRC: 'cr',
    CUP: 'cu',
    CVE: 'cv',
    CZK: 'cz',
    DJF: 'dj',
    DKK: 'dk',
    DOP: 'do',
    DZD: 'dz',
    EGP: 'eg',
    ERN: 'er',
    ETB: 'et',
    EUR: 'eu',
    FJD: 'fj',
    FKP: 'fk',
    FOK: 'fo',
    GBP: 'gb',
    GEL: 'ge',
    GHS: 'gh',
    GIP: 'gi',
    GMD: 'gm',
    GNF: 'gn',
    GTQ: 'gt',
    GYD: 'gy',
    HKD: 'hk',
    HNL: 'hn',
    HRK: 'hr',
    HTG: 'ht',
    HUF: 'hu',
    IDR: 'id',
    ILS: 'il',
    INR: 'in',
    IQD: 'iq',
    IRR: 'ir',
    ISK: 'is',
    JMD: 'jm',
    JOD: 'jo',
    JPY: 'jp',
    KES: 'ke',
    KGS: 'kg',
    KHR: 'kh',
    KMF: 'km',
    KPW: 'kp',
    KRW: 'kr',
    KWD: 'kw',
    KYD: 'ky',
    KZT: 'kz',
    LAK: 'la',
    LBP: 'lb',
    LKR: 'lk',
    LRD: 'lr',
    LSL: 'ls',
    LYD: 'ly',
    MAD: 'ma',
    MDL: 'md',
    MGA: 'mg',
    MKD: 'mk',
    MMK: 'mm',
    MNT: 'mn',
    MOP: 'mo',
    MRU: 'mr',
    MUR: 'mu',
    MVR: 'mv',
    MWK: 'mw',
    MXN: 'mx',
    MYR: 'my',
    MZN: 'mz',
    NAD: 'na',
    NGN: 'ng',
    NIO: 'ni',
    NOK: 'no',
    NPR: 'np',
    NZD: 'nz',
    OMR: 'om',
    PAB: 'pa',
    PEN: 'pe',
    PGK: 'pg',
    PHP: 'ph',
    PKR: 'pk',
    PLN: 'pl',
    PYG: 'py',
    QAR: 'qa',
    RON: 'ro',
    RSD: 'rs',
    RUB: 'ru',
    RWF: 'rw',
    SAR: 'sa',
    SBD: 'sb',
    SCR: 'sc',
    SDG: 'sd',
    SEK: 'se',
    SGD: 'sg',
    SHP: 'sh',
    SLL: 'sl',
    SOS: 'so',
    SRD: 'sr',
    SSP: 'ss',
    STN: 'st',
    SYP: 'sy',
    SZL: 'sz',
    THB: 'th',
    TJS: 'tj',
    TMT: 'tm',
    TND: 'tn',
    TOP: 'to',
    TRY: 'tr',
    TTD: 'tt',
    TWD: 'tw',
    TZS: 'tz',
    UAH: 'ua',
    UGX: 'ug',
    USD: 'us',
    UYU: 'uy',
    UZS: 'uz',
    VES: 've',
    VND: 'vn',
    VUV: 'vu',
    WST: 'ws',
    XAF: 'cm',
    XCD: 'ag',
    XOF: 'sn',
    XPF: 'pf',
    YER: 'ye',
    ZAR: 'za',
    ZMW: 'zm',
    ZWL: 'zw',
};

const iconComponents = [
    <PiImagesDuotone size={24} />,
    <PiFileVideoDuotone size={24} />,
    <PiFileTextDuotone size={24} />,
    <PiFilePdfDuotone size={24} />,
    <PiFilePptDuotone size={24} />,
    <PiFileXlsDuotone size={24} />,
];

const handleConfetti = () => {
    confetti({
        particleCount: 500,
        spread: 100,
        origin: { y: 0.6 },
        zIndex:1000
    });
};

const rgbToHex = (r, g, b) => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
};


const itemsPerSlide = 6;

const templateItems = [
    {
        image: '',
        title: "Start From scratch"
    },
    {
        image: bgone,
        title: "Ganesh Chaturthi (7th Sep)"
    },
    {
        image: bgtwo,
        title: "Monsoon Special 🌧️"
    },
    {
        image: bgthree,
        title: "Buy One Get One (BOGO)"
    },
    {
        image: bgfour,
        title: "Celebrate events"
    },
    {
        image: bgfive,
        title: "Exciting Offers"
    },
    {
        image: bgsix,
        title: "Happy Hour Offers!"
    },
    {
        image: bgseven,
        title: "Live Event & Entertainment"
    },
    {
        image: bgeight,
        title: "New Menu Launch"
    },
    {
        image: bgone,
        title: "Drive online orders and takeaway"
    },
    {
        image: bgtwo,
        title: "Promote New Items"
    },
    {
        image: bgthree,
        title: "Promote New Location"
    },
    {
        image: bgfour,
        title: "Weekday Specials"
    }
];

const datePickerItems = [
    {
        key: '1',
        label: 'Highlights for Today'
    },
    {
        key: '2',
        label: 'Last 7 Days'
    },
    {
        key: '3',
        label: 'Last 30 Days'
    },
    {
        key: '4',
        label: 'This Month'
    },
    {
        key: '5',
        label: 'Last Month'
    },
    {
        key: '6',
        label: 'Last 12 Month'
    },
    {
        key: '7',
        label: 'Custom range'
    }
];

const groupedItems = [];
for (let i = 0; i < templateItems.length; i += itemsPerSlide) {
    groupedItems.push(templateItems.slice(i, i + itemsPerSlide));
}

const groups = [
    { key: 'new', label: `New Customers`, title: `These customers have recently visited your business for the first time.` },
    { key: 'attention', label: `Need Attention`, title: `These customers visit very occasionally, spend an average amount, and haven't made a purchase recently.` },
    { key: 'prommissing', label: `Promising Customers`, title: `These customers have visited your business recently, but they haven't spent much.` },
    { key: 'loyal', label: `Loyal Customers`, title: `These are frequent visitors, and they spend the most, but they haven't visited in a while.` },
    { key: 'vip', label: `VIP Customers`, title: `These customers are frequent visitors who have recently been to your business and spend the most.` },
    { key: 'risk', label: `At Risk Customers`, title: `These customers are average spenders, but they haven't visited your business recently.` },
    { key: 'lost', label: `Lost Customers`, title: `These customers have not visited your business in a very long time or very often, and they have spent the least.` },
    { key: 'cantloose', label: `Can't Lose Customers`, title: `These customers visit your business frequently and spend a lot, but it's been a while since their last visit.` },
    { key: 'sleep', label: `About to Sleep Customers`, title: `These customers visited your business sometime back, but they don't usually visit frequently and are low spenders.` },
    { key: 'potential', label: `Potential Loyal Customers`, title: `These customers have visited your business recently, but they aren't frequent visitors and are average spenders.` },
];

const rangePresets = [
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'This Month',
        value: [dayjs().startOf('month'), dayjs().endOf('month')],
    },
    {
        label: 'Last Month',
        value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
        label: 'Last 12 Months',
        value: [dayjs().subtract(12, 'months'), dayjs()],
    },
    {
        label: 'This Year',
        value: [dayjs().startOf('year'), dayjs().endOf('year')],
    },
    {
        label: 'Custom Range',
        value: [null, null],
    },
    {
        label: 'All Time',
        value: [dayjs('0000-00-00'), dayjs()],
    },
];

const CampaignContent = ({ userData, whatsappData, sessionData, storeData, socketData }) => {
    const { tabType } = useParams();
    const storeDetail = storeData?.filter(store => store?.store_id.toString() === localStorage.getItem('storeId'))?.[0];
    const [openDrawer, setOpenDrawer] = useState(false);
    const [expireDate, setExpireDate] = useState(dayjs());
    const [segmentType, setSegmentType] = useState('Creative');
    const [languagesData, setLanguagesData] = useState([]);
    const [createNewTemplateDrawer, setCreateNewTemplateDrawer] = useState(tabType === 'addnew' ? true : false);
    const [selectedChannels, setSelectedChannels] = useState({ whatsapp: true, sms: true, email: true });
    const [rewardType, setRewardType] = useState('');
    const [customerType, setCustomerType] = useState('all');
    const [whenSend, setWhenSend] = useState('now');
    const [isSendToInactive, setIsSendToInactive] = useState('no');
    const [preDisc, setPerDisc] = useState('');
    const [cashDisc, setCashDisc] = useState('');
    const [rewardOn, setRewardOn] = useState('entire');
    const [specificItem, setSpecificItem] = useState('');
    const [activeTemplateItem, setActiveTemplateItem] = useState([]);
    const [campaignData, setCampaignData] = useState([]);
    const [minPurchace, setMinPurchace] = useState('');
    const [minPurchaseAmt, setMinPurchaceAmt] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [freeItem, setFreeItem] = useState('');
    const [redeemTime, setRedeemTime] = useState('justonce');
    const [file, setFile] = useState(null);
    const [showContact, setShowContact] = useState(true);
    const [contactNumber, setContactNumber] = useState(userData?.user_phone);
    const [campaignTitle, setCampaignTitle] = useState('');
    const [campaignDesc, setCampaignDesc] = useState('');
    const [termsText, setTermsText] = useState(`2 offers cannot be clubbed.\nReward cannot be exchanged for cash.`);
    const navigate = useNavigate();
    const [campaignId, setCampaignId] = useState(0);
    const [currentIconIndex, setCurrentIconIndex] = useState(0);
    const [isHeader, setIsHeader] = useState(true);
    const [headerType, setHeaderType] = useState('image');
    const [smsText, setSmsText] = useState('');
    const [whatsappText, setWhatsapptext] = useState('');
    const [staps, setStaps] = useState(1);
    const [themeColor, setThemeColor] = useState('#3a1a55');
    const [textColor, setTextColor] = useState('#FFFFFF');
    const [posterBannerImage, setPosterBannerImage] = useState(null);
    const [posterBannerVideo, setPosterBannerVideo] = useState(null);
    const [posterBannerDocument, setPosterBannerDocument] = useState(null);
    const [headerText, setHeaderText] = useState('');
    const optInMessage = useState(true);
    const [showPicker, setShowPicker] = useState(false);
    const pickerRef = useRef(null);
    const textAreaRef = useRef(null);
    const [templateName, setTemplateName] = useState('');
    const [templateCategory, setTemplateCategory] = useState('MARKETING');
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const headerTextRef = useRef(null);
    const [filter, setFilter] = useState(null);
    const [inputValues, setInputValues] = useState({});
    const [headerVarValue, setHeaderVarValue] = useState('');
    const [whatsappImage, setWhatsappImage] = useState(null);
    const [stepLoader, setStepLoader] = useState(false);
    const [isButtons, setIsButtons] = useState(true);
    const [buttons, setButtons] = useState([]);
    const [buttonFields, setButtonFields] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [fileId, setFileId] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');
    const [templateReview, setTemplateReview] = useState(false);
    const [templateId, setTemplateId] = useState('');
    const [templateStatus, setTemplateStatus] = useState('PENDING');
    const [targatedGroupModal, setTargatedGroupModal] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedCustomerSagment, setSelectedCustomerSagment] = useState([]);
    const areAllSelected = selectedGroups.length === groups.length;
    const [filterCustomerModal, setFilterCustomerModal] = useState(false);
    const [customRange, setCustomRange] = useState([dayjs().add(-30, 'd'), dayjs()]);
    const [importedCustomer, setImportedCustomer] = useState(false);
    const [selectedGender, setSelectedGender] = useState([]);
    const [averageSpend, setAverageSpend] = useState([]);
    const [dayPreference, setDayPreference] = useState([]);
    const [timePreference, setTimePreference] = useState([]);
    const [isTotalSpend, setIsTotalSpend] = useState(false);
    const [isAverageSpend, setIsAverageSpend] = useState(false);
    const [isTotalVisits, setIsTotalVisits] = useState(false);
    const [isLoyaltyPointBalance, setIsLoyaltyPointBalance] = useState(false);
    const [isNoOfTimeLoyaltyPointRedeem, setNoOfTimeLoyaltyPointRedeem] = useState(false);
    const [isLoyaltyPointRedeem, setIsLoyaltyPointRedeem] = useState(false);
    const [totalSpentRangeValues, setTotalSpentRangeValues] = useState([1110, 1110]);
    const [averageSpentRangeValues, setAverageSpentRangeValues] = useState([370, 370]);
    const [totalVisitsRangeValues, setTotalVisitsRangeValues] = useState([3, 3]);
    const [loyaltyPointBalanceRangeValues, setLoyaltyPointBalanceRangeValues] = useState([150, 500]);
    const [loyaltyPointRedeemRangeValues, setLoyaltyPointRedeemRangeValues] = useState([0, 1]);
    const [noOfLoyaltyPointBalanceRangeValues, setNoOfLoyaltyPointRedeemRangeValues] = useState([0, 1]);
    const [scheduleTime, setScheduleTime] = useState('');
    const [testMail, setTestMail] = useState(userData?.user_email);
    const [testPhone, setTestPhone] = useState(userData?.user_phone);

    const uploadContainerRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const downloadPosterAsImage = async () => {
        setLoading(true);
        const downloadImages = (containerRef) => {
            if (!containerRef.current) {
                return Promise.resolve();
            }

            const images = containerRef.current.getElementsByTagName('img');
            const imageLoadPromises = Array.from(images).map((img) => {
                return new Promise((resolve) => {
                    img.crossOrigin = "anonymous";
                    if (img.complete) {
                        resolve();
                    } else {
                        img.onload = resolve;
                        img.onerror = resolve;
                    }
                });
            });
            return Promise.all([...imageLoadPromises]);
        };

        const generateAndDownloadCanvas = async (containerRef) => {
            try {
                const canvas = await html2canvas(containerRef.current, { allowTaint: true, useCORS: true, backgroundColor: null });
                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                // link.download = `campaign-poster.png`;
                // link.click();
                await sendMail(link.href);
                if(selectedChannels.whatsapp){
                    await handleTemplateSend();
                }
            } catch (err) {
                console.error("Error generating canvas:", err);
            } finally {
                setLoading(false);
            }
        };

        try {
            await downloadImages(uploadContainerRef);
            await generateAndDownloadCanvas(uploadContainerRef);
        } catch (err) {
            console.error("Image loading error:", err);
        }
    };

    const handleTemplateSend = async () => {
        const template = _.cloneDeep(activeTemplateItem);
        console.log(template);
        const templateName = template.name;
        const templateLanguage = template.language;
        const wanumber = userData?.user_phone;
        const variables = inputValues;
        const imageId = fileId;
        const imageExtension = fileType;
        let datacomponent = [];
        const headerComponent = template?.components?.find(component => component.type === "HEADER");
        if (headerComponent) {
            if (headerComponent.format === 'IMAGE') {
                if (imageId === '') {
                    message.error('Please Upload the image');
                    return;
                }
            }
        }
        template?.components?.forEach(component => {
            if (component.type === "HEADER") {
                const header = {
                    type: "header",
                    parameters: []
                };

                if (component.format === 'TEXT') {
                    let replacedHeader = component.text;
                    component.text.match(/{{\d+}}/g).forEach(element => {
                        const replacement = variables[`HEADER-${element}`];
                        replacedHeader = replacedHeader.replace(element, replacement);
                        header.parameters.push({
                            type: "text",
                            text: variables[`HEADER-${element}`]
                        });
                    });
                    component.text = replacedHeader;
                }

                if (component.format === 'IMAGE') {
                    if (imageExtension === 'image/png') {
                        component.example.header_handle[0] = `https://api.connectby.io/whatsapp_media/${imageId}.png`;
                    }
                    if (imageExtension === 'image/jpeg') {
                        component.example.header_handle[0] = `https://api.connectby.io/whatsapp_media/${imageId}.jpeg`;
                    }
                    header.parameters.push({
                        type: "image",
                        image: {
                            id: imageId
                        }
                    });
                }

                if (header.parameters.length > 0) {
                    datacomponent.push(header);
                }
            }

            if (component.type === "BODY") {
                const body = {
                    type: "body",
                    parameters: []
                };
                let replacedBody = component.text;
                if (component.text.match(/{{\d+}}/g)) {
                    component.text.match(/{{\d+}}/g).forEach(element => {
                        const replacement = variables[`BODY-${element}`];
                        replacedBody = replacedBody.replace(element, replacement);
                        body.parameters.push({
                            type: "text",
                            text: variables[`BODY-${element}`]
                        });
                    });
                }
                component.text = replacedBody;
                if (body.parameters.length > 0) {
                    datacomponent.push(body);
                }
            }
        });
        const body = {
            accessToken: userData?.whatsappAccessToken !== '' ? userData?.whatsappAccessToken : whatsappData?.whatsappAccessToken,
            phoneNoId: userData?.phonenumberid !== '' ? userData?.phonenumberid : whatsappData?.phonenumberid,
            to: wanumber,
            template_name: templateName,
            language: templateLanguage,
            template: template,
            components: datacomponent
        };
        console.log('body', body);
        try {
            const response = await fetch(`${window.API_BASE_URL}/chats/send-template-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify(body),
            });
            const responseData = await response.json();
            if (response.ok) {
                message.success('Template sent to whatsapp successfully.');
            } else {
                message.error(responseData.error || 'Unknown error');
                console.error('Failed to send WhatsApp template:', responseData.error || 'Unknown error');
            }
        } catch (error) {
            console.error('Error sending WhatsApp template:', error);
            message.error('Internal Server Error');
        };
    };

    const sendMail = async (imageDataUrl) => {
        const data = {
            mail: testMail,
            subject: emailSubject,
            image: imageDataUrl
        };

        try {
            const url = `${window.API_BASE_URL}/templates/send-test-mail`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            message.success('Mail sent successfully.');
            handleConfetti();
            console.log(responseData);

        } catch (error) {
            console.error("Error while sending mail:", error);
        }
    };

    const toggleCustomerSagment = (sagment) => {
        setSelectedCustomerSagment((prevSelected) => prevSelected.includes(sagment) ? prevSelected.filter((s) => s !== sagment) : [...prevSelected, sagment]);
    };

    const toggleCustomerGender = (gender) => {
        setSelectedGender((prevSelected) => prevSelected.includes(gender) ? prevSelected.filter((s) => s !== gender) : [...prevSelected, gender]);
    };

    const toggleCustomerAverageSpand = (spand) => {
        setAverageSpend((prevSelected) => prevSelected.includes(spand) ? prevSelected.filter((s) => s !== spand) : [...prevSelected, spand]);
    };

    const toggleCustomerDayPreference = (day) => {
        setDayPreference((prevSelected) => prevSelected.includes(day) ? prevSelected.filter((s) => s !== day) : [...prevSelected, day]);
    };

    const toggleCustomerTimePreference = (time) => {
        setTimePreference((prevSelected) => prevSelected.includes(time) ? prevSelected.filter((s) => s !== time) : [...prevSelected, time]);
    };

    const toggleGroup = (group) => {
        setSelectedGroups((prevSelected) =>
            prevSelected.includes(group)
                ? prevSelected.filter((g) => g !== group)
                : [...prevSelected, group]
        );
    };

    const toggleSelectAll = () => {
        if (areAllSelected) {
            setSelectedGroups([]);
        } else {
            setSelectedGroups(groups.map((g) => g.key));
        }
    };

    const clearSelectAll = () => {
        setSelectedGroups([]);
    };

    const isGroupSelected = (group) => selectedGroups.includes(group);
    const isSagmentSelected = (sagment) => selectedCustomerSagment.includes(sagment);
    const isgenderSelected = (gender) => selectedGender.includes(gender);
    const isAverageSpandSelected = (spand) => averageSpend.includes(spand);
    const isDayPreferenceSelected = (day) => dayPreference.includes(day);
    const isTimePreferenceSelected = (time) => timePreference.includes(time);

    const fetchCustomerData = useCallback(async () => {
        const url = `${window.API_BASE_URL}/users/customer?storeId=${localStorage.getItem('storeId')}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                console.log(data);
                setCustomers(data.user);
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                }
                console.error('Error fetching customer data:', data.message);
            }
        } catch (error) {
            console.error('Error during fetching customer data:', error);
        }
    }, [sessionData]);


    const fetchCampaigns = useCallback(async () => {
        const url = `${window.API_BASE_URL}/templates/campaigns/?store_id=${localStorage.getItem('storeId')}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                console.log('campaigns', data);
                setCampaignData(data.campaigns);
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                }
                console.error('Error fetching customer data:', data.message);
            }
        } catch (error) {
            console.error('Error during fetching customer data:', error);
        }
    }, [sessionData]);

    useEffect(() => {
        if (sessionData) {
            fetchCustomerData();
            fetchCampaigns();
        }
    }, [fetchCustomerData, fetchCampaigns, sessionData]);

    const [sections, setSections] = useState([
        { id: 'weburl', label: 'Add website url' },
        { id: 'phone', label: 'Add phone number' },
        { id: 'quick', label: 'Add Quick Replies' }
    ]);

    const fetchLanguages = async () => {
        try {
            const response = await fetch(`${window.API_BASE_URL}/languages`);
            if (!response.ok) {
                throw new Error('Failed to fetch languages');
            }
            const data = await response.json();
            setLanguagesData(data);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    const handleDeleteCampaign = (campaign) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this campaign?',
            content: 'It won\'t be retrievable once deleted.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                try {
                    const response = await fetch(`${window.API_BASE_URL}/templates/campaign/${campaign?.campaign_id}`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${sessionData?.token}`,
                        }
                    });
                    console.log(response);
                    if (response.ok) {
                        setCampaignData((prevData) => prevData.filter((item) => item.campaign_id !== campaign.campaign_id));
                        message.success('Campaign deleted successfully!');
                    } else {
                        message.error('Error while deleting campaign.');
                    }
                } catch (error) {
                    console.error('Error during deleting campaign:', error);
                    message.error('Network error during campaign deletion.');
                }
            },
        });
    };

    const handleEditCampaign = (campaign) => {
        setCampaignId(campaign?.campaign_id);
        setSelectedChannels(JSON.parse(campaign?.channels));
        setRewardType(campaign?.reward_type);
        setPerDisc(campaign?.pre_disc);
        setCashDisc(campaign?.cash_disc);
        setFreeItem(campaign?.free_item);
        setMinPurchace(campaign?.min_purchase);
        setMinPurchaceAmt(campaign?.min_purchase_amt);
        setSpecificItem(campaign?.specific_item);
        setRewardOn(campaign?.reward_on);
        setCampaignTitle(campaign?.campaign_title);
        setCampaignDesc(campaign?.campaign_desc);
        setExpireDate(dayjs(campaign?.campaign_expire_date));
        setRedeemTime(campaign?.redemption_per_customer);
        setTermsText(campaign?.terms_conditions);
        setShowContact(campaign?.show_contact_number);
        setFileName(campaign?.campaign_banner);
        setContactNumber(campaign?.contact_no);
        setFile(campaign?.campaign_logo);
        setThemeColor(campaign?.theme_color);
        setTextColor(campaign?.text_color);
        setSmsText(campaign?.text_message);
        setEmailSubject(campaign?.email_subject);
        setWhenSend(campaign?.send_type);
        setScheduleTime(campaign?.schedule_datetime);
        setTemplateId(campaign?.whatsapp_template_id);
        setIsSendToInactive(campaign?.is_send_to_inactive);
        setCreateNewTemplateDrawer(true); navigate(`/campaigns/addnew`);
    };

    const handleButtonInputChange = (type, id, key, value) => {
        setButtonFields(prevFields => {
            const fields = prevFields || [];
            return fields.map(field => {
                if (field.id === id) {
                    return {
                        ...field,
                        [key]: value
                    };
                }
                return field;
            });
        });
    };

    useEffect(() => {
        if (storeDetail) {
            fetchLanguages();
        }
    }, [storeDetail]);

    useEffect(() => {
        if (socketData) {
            console.log(socketData?.message_template_id);
            console.log(templateId);
            if (socketData?.message_template_id && socketData?.message_template_id.toString() === templateId) {
                console.log(socketData);
                if (socketData?.event === 'APPROVED') {
                    if (templateStatus === 'PENDING') {
                        setTemplateReview(false);
                        message.success('Congratulations! Your template has been approved successfully.');
                        if (selectedChannels.sms) {
                            setStaps(6);
                        }
                        if (!selectedChannels.sms && selectedChannels.email) {
                            setStaps(7);
                        }
                        if (!selectedChannels.sms && !selectedChannels.email) {
                            setStaps(8);
                        }
                    }
                } else {
                    message.error('Oops! There was an issue approving your template.');
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketData]);

    const fetchTemplateData = useCallback(async () => {
        const url = `${window.API_BASE_URL}/chats/get-template`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify({
                    whatsappaccesstoken: userData?.whatsappAccessToken !== '' ? userData?.whatsappAccessToken : whatsappData?.whatsappAccessToken,
                    wabaid: userData?.wabaid !== '' ? userData?.wabaid : whatsappData?.wabaid
                }),
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data);
                const templates = data?.templates?.data;
            }
        } catch (error) {
            console.error('Error during fetching template data:', error);
        } finally {
        };
    }, [sessionData, userData]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newOrder = reorder(sections, result.source.index, result.destination.index);
        setSections(newOrder);
    };

    const beforeUpload = (file) => {
        const isJPGOrPNG = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJPGOrPNG) {
            message.error('You can only upload JPG/PNG files!');
            return Upload.LIST_IGNORE;
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('Image must be smaller than 5MB!');
            return Upload.LIST_IGNORE;
        }
        return isJPGOrPNG && isLt5M;
    };

    const beforeUploadVideo = (file) => {
        const isMp4Or3gp = file.type === 'video/mp4' || file.type === 'video/3gp';
        if (!isMp4Or3gp) {
            message.error('You can only upload MP4/3GP files!');
            return Upload.LIST_IGNORE;
        }
        const isLt16M = file.size / 1024 / 1024 < 16;
        if (!isLt16M) {
            message.error('Video must be smaller than 16MB!');
            return Upload.LIST_IGNORE;
        }
        return isMp4Or3gp && isLt16M;
    };

    const beforeUploadDoc = (file) => {
        const isDocPdf = file.type === 'text/plain' || file.type === 'application/pdf' || file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/msword' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isDocPdf) {
            message.error('You can only upload TEXT, PDF, PPT/PPTX, DOC/DOCX, XLX/XLXS file!');
            return Upload.LIST_IGNORE;
        }
        const isLt100M = file.size / 1024 / 1024 < 100;
        if (!isLt100M) {
            message.error('Document file must be smaller than 100MB!');
            return Upload.LIST_IGNORE;
        }
        return isDocPdf && isLt100M;
    };

    const handleChangePosterBanner = (info) => {
        if (info.file.status === 'done') {
            const reader = new FileReader();
            reader.onload = () => setPosterBannerImage(reader.result);
            reader.readAsDataURL(info.file.originFileObj);
        }
    };

    const handleRemovePosterBanner = () => {
        setPosterBannerImage(null);
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setStepLoader(true);
            return;
        }
        if (info.file.status === 'done') {
            const fileType = info.file.originFileObj.type;
            getBase64(info.file.originFileObj, (url) => {
                setStepLoader(false);
                if (fileType === 'image/jpeg' || fileType === 'image/png') {
                    setWhatsappImage(url);
                }
                if (fileType === 'video/mp4' || fileType === 'video/3gp') {
                    setPosterBannerVideo(url);
                }
                if (fileType === 'text/plain' || fileType === 'application/pdf' || fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/msword' || fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    setPosterBannerDocument(url);
                }

            });
        }
    };

    const handleRemoveWhatsappBanner = () => {
        setWhatsappImage(null);
    };

    const handleRemoveWhatsappVideo = () => {
        setPosterBannerVideo(null);
    };

    const handleRemoveWhatsappDocument = () => {
        setPosterBannerDocument(null);
    };

    const handleChangeButtonCheckbox = (type) => {
        if (buttons.includes(type)) {
            setButtonFields(buttonFields.filter(field => field.type !== type));
            setButtons(buttons.filter(button => button !== type));
        } else {
            if (buttonFields.length < 10) {
                setButtons([...buttons, type]);
                addField(type);
            } else {
                message.error('Maximum 10 buttons can be added.');
            }
        }
    };

    const addField = (type) => {
        if (buttonFields.length < 10) {
            const countForType = buttonFields.filter(field => field.type === type).length;
            if ((type === 'weburl' && countForType < 3) ||
                (type === 'quick' && countForType < 10) ||
                (type === 'phone' && countForType < 1)) {
                setButtonFields([...buttonFields, { id: buttonFields.length + 1, type }]);
            }
        }
    };

    const removeField = (id) => {
        setButtonFields(buttonFields.filter(field => field.id !== id));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIconIndex((prevIndex) => (prevIndex + 1) % iconComponents.length);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const props = {
        name: 'file',
        multiple: false,
        beforeUpload: (file) => {
            return false;
        },
        onChange(info) {
            const { status } = info.file;

            if (status !== 'uploading') {
                console.log('Selected File:', info.file);
                const file = info.file.originFileObj || info.file;
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const base64String = reader.result;
                    setFile(base64String);
                };
                reader.onerror = (error) => {
                    console.error('Error converting file to Base64:', error);
                };
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        showUploadList: false,
    };

    const handleEmojiSelect = (emoji) => {
        const textareaRef = textAreaRef.current.resizableTextArea.textArea;
        const cursorPosition = textareaRef.selectionStart;
        const updatedMessage =
            whatsappText.slice(0, cursorPosition) +
            emoji.native +
            whatsappText.slice(cursorPosition);
        setWhatsapptext(updatedMessage);
        setShowPicker(false);
        setTimeout(() => {
            if (textAreaRef) {
                const newCursorPosition = cursorPosition + emoji.native.length;
                textareaRef.focus();
                textareaRef.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
    };

    const handleInputChange = (index, value) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [index]: value,
        }));
    };

    const handleTextStyle = (type) => {
        const textareaRef = textAreaRef.current.resizableTextArea.textArea;
        const textareaValue = whatsappText || '';
        if (!textareaRef) return;

        const start = textareaRef.selectionStart;
        const end = textareaRef.selectionEnd;
        const selectedText = textareaValue.substring(start, end);
        let newText
        switch (type) {
            case 'bold':
                newText = textareaValue.slice(0, start) + `*${selectedText}*` + textareaValue.slice(end);
                break;
            case 'italic':
                newText = textareaValue.slice(0, start) + `_${selectedText}_` + textareaValue.slice(end);
                break;
            case 'strike':
                newText = textareaValue.slice(0, start) + `~${selectedText}~` + textareaValue.slice(end);
                break;
            case 'mono':
                newText = textareaValue.slice(0, start) + `\`\`\`${selectedText}\`\`\`` + textareaValue.slice(end);
                break;
            default:
                newText = textareaValue;
                break;
        }
        setWhatsapptext(newText);
        setTimeout(() => {
            if (textAreaRef) {
                const newCursorPosition = type === 'mono' ? start + (selectedText ? selectedText.length + 3 : 3) : start + (selectedText ? selectedText.length + 2 : 1);
                textareaRef.focus();
                textareaRef.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
    }

    const handleAddVariable = () => {
        const textareaRef = textAreaRef.current.resizableTextArea.textArea;
        const cursorPosition = textareaRef.selectionStart;
        const textareaValue = whatsappText;
        const existingVariables = textareaValue.match(/{{\d+}}/g) || [];
        const existingVariablesCount = existingVariables.length;
        const variable = `{{${existingVariablesCount + 1}}}`;
        const updatedMessage =
            whatsappText.slice(0, cursorPosition) +
            variable +
            whatsappText.slice(cursorPosition);
        setWhatsapptext(updatedMessage);
        setTimeout(() => {
            if (textAreaRef) {
                const newCursorPosition = cursorPosition + variable.length;
                textareaRef.focus();
                textareaRef.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
    };

    const handleAddVariableToHeader = () => {
        const textareaRef = headerTextRef.current.resizableTextArea.textArea;
        const cursorPosition = textareaRef.selectionStart;
        const textareaValue = headerText;
        const existingVariables = textareaValue.match(/{{\d+}}/g) || [];
        const existingVariablesCount = existingVariables.length;
        if (existingVariablesCount < 1) {
            const variable = `{{${existingVariablesCount + 1}}}`;
            const updatedMessage =
                headerText.slice(0, cursorPosition) +
                variable +
                headerText.slice(cursorPosition);
            setHeaderText(updatedMessage);
            setTimeout(() => {
                if (textAreaRef) {
                    const newCursorPosition = cursorPosition + variable.length;
                    textareaRef.focus();
                    textareaRef.setSelectionRange(newCursorPosition, newCursorPosition);
                }
            }, 0);
        } else {
            message.error('Only one variable is allowed.');
        }
    };

    const formatTextForPreview = (text) => {
        let formattedText = text
            .replace(/\*(.*?)\*/g, '<b>$1</b>')
            .replace(/_(.*?)_/g, '<i>$1</i>')
            .replace(/~(.*?)~/g, '<s>$1</s>')
            .replace(/```(.*?)```/gs, '<pre><code>$1</code></pre>');
        return formattedText;
    };

    const handleTextChange = (e) => {
        setWhatsapptext(e.target.value);
    };

    const handletemplateNameInputChange = (e) => {
        const value = e.target.value
            .replace(/\s+/g, '_')
            .toLowerCase()
            .replace(/[^a-z0-9_]/g, '');
        setTemplateName(value);
    };

    const items = [
        {
            key: 'template-library',
            label: 'Template Library',
            children: (
                <Row gutter={[16, 32]}>
                    <Col span={24}>
                        <Flex gap="middle" justify="space-between" align='center' style={{ display: 'none' }}>
                            <Flex gap="middle">
                                <Button type='primary'>All</Button>
                                <Button><GrAnnounce /> No Discount</Button>
                                <Button><GiftOutlined /> Free Item</Button>
                                <Button><DollarCircleOutlined /> Discount</Button>
                                <Button><PercentageOutlined /> Discount</Button>
                            </Flex>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Flex gap="middle" justify="space-between" align='center'>
                            <Title level={4}>Drafts | Custom Campaigns</Title>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Carousel arrows infinite={false} className='templateSliderOne' slidesPerRow={4} slidesToScroll={1} dots={false} draggable={true}>
                            <div onClick={() => { setCreateNewTemplateDrawer(true); navigate(`/campaigns/addnew`); }}>
                                <Card hoverable bordered={false}>
                                    <div style={{ height: 340 }}>
                                        <img alt="example" className='mainImg' src={backOne} />
                                        <div className='cardContent cardContentOne' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <PiPlusBold size={48} color='#FFF' />
                                                <Title level={3} style={{ margin: 0 }}>
                                                    Start form scratch
                                                </Title>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            {campaignData && campaignData.map((campaign) => (
                                <div onClick={() => handleEditCampaign(campaign)}>
                                    <Card hoverable bordered={false}>
                                        <div style={{ height: 340 }}>
                                            <img alt="example" className='mainImg' src={campaign.campaign_banner ? `${window.BASE_URL}uploads/template_media/${campaign.campaign_banner}` : defaultImage} />
                                            <div className='cardContent'>
                                                <Flex gap="middle" justify="space-between" align='center'>
                                                    <div className="status-draft">Draft</div>
                                                    <Dropdown className='cardMenuDropdown' menu={{
                                                        items: [{
                                                            key: '1',
                                                            label: (<span className='editMenu' onClick={(e) => { e.stopPropagation(); handleEditCampaign(campaign) }}><EditOutlined style={{ fontSize: 16 }} /> Edit</span>),
                                                        },
                                                        {
                                                            key: '2',
                                                            label: (<span className='deleteMenu' onClick={(e) => { e.stopPropagation(); handleDeleteCampaign(campaign) }}><DeleteOutlined style={{ fontSize: 16, color: 'red' }} /> Delete</span>),
                                                        }]
                                                    }}
                                                        onClick={(e) => e.stopPropagation()}
                                                    ><MoreOutlined rotate={90} style={{ fontWeight: 'bold' }} /></Dropdown>
                                                </Flex>
                                                <Title level={3}>
                                                    {campaign?.campaign_title}
                                                    <Flex gap='middle' className='iconDiv'>
                                                        <div className='icons'>
                                                            <GrAnnounce />
                                                        </div>
                                                        <div className='icons'>
                                                            <Image src={WhatsApp} preview={false} style={{ width: 30 }} />
                                                        </div>
                                                    </Flex>
                                                </Title>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
            ),
        },
        {
            key: 'campaign-performance',
            label: 'Campaign performance',
            children: (
                <CampaignPreformence userData={userData} sessionData={sessionData} storeData={storeData} socketData={socketData} />
            ),
        }
    ];

    const onChange = (tabType) => {
        navigate(`/campaigns/${tabType}`);
    };

    const handleCloseAddNewPopup = () => {
        switch (staps) {
            case 1:
                navigate(`/campaigns`);
                setCreateNewTemplateDrawer(false);
                break;
            case 2:
                setStaps(staps - 1);
                break;
            case 3:
                setStaps(staps - 1);
                break;
            case 4:
                setStaps(staps - 1);
                break;
            case 5:
                setStaps(staps - 1);
                break;
            case 6:
                if (!selectedChannels.whatsapp) {
                    setStaps(staps - 2);
                } else {
                    setStaps(staps - 1);
                }
                break;
            case 7:
                let sevstap = 1;
                if (!selectedChannels.sms && selectedChannels.whatsapp) {
                    sevstap += 1;
                }
                if (!selectedChannels.sms && !selectedChannels.whatsapp) {
                    sevstap += 2;
                }
                setStaps(staps - sevstap);
                break;
            case 8:
                let eitstap = 1;
                if (selectedChannels.email) {
                    eitstap = 1;
                }
                if (!selectedChannels.email && selectedChannels.sms) {
                    eitstap = 2;
                }
                if (!selectedChannels.email && !selectedChannels.sms && selectedChannels.whatsapp) {
                    eitstap = 3;
                }
                setStaps(staps - eitstap);
                break;
            case 9:
                setStaps(staps - 1);
                break;
            case 10:
                setStaps(staps - 1);
                break;
            default:
                break;
        }
    };

    const insertUpdateCampaign = async (status, draft) => {
        console.log('fileName', fileName);
        setStepLoader(true);
        try {
            const formData = new FormData();
            formData.append('store_id', storeDetail?.store_id);
            formData.append('channels', JSON.stringify(selectedChannels));
            formData.append('reward_type', rewardType);
            formData.append('pre_disc', preDisc);
            formData.append('cash_disc', cashDisc);
            formData.append('free_item', freeItem);
            formData.append('reward_on', rewardOn);
            formData.append('min_purchase', minPurchace);
            formData.append('campaign_title', campaignTitle);
            formData.append('campaign_desc', campaignDesc);
            formData.append('specific_item', specificItem);
            formData.append('min_purchase_amt', minPurchaseAmt);
            formData.append('terms_conditions', termsText);
            formData.append('redemption_per_customer', redeemTime);
            formData.append('campaign_expire_date', expireDate);
            formData.append('theme_color', themeColor);
            formData.append('text_color', textColor);
            formData.append('show_contact_number', showContact ? 'yes' : 'no');
            formData.append('contact_no', contactNumber);
            formData.append('text_message', smsText);
            formData.append('email_subject', emailSubject);
            formData.append('send_type', whenSend);
            formData.append('whatsapp_template_id', templateId);
            formData.append('schedule_datetime', scheduleTime);
            formData.append('is_send_to_inactive', isSendToInactive);
            formData.append('campaign_banner', fileName);
            formData.append('filter', JSON.stringify(filter));
            formData.append('status', status);
            formData.append('is_draft', draft);
            if (file !== null) {
                formData.append('campaign_logo', file);
            }
            if (campaignId === 0) {
                const url = `${window.API_BASE_URL}/templates/campaigns/`;
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                    body: formData,
                });

                const result = await response.json();
                if (response.ok) {
                    console.log('QR Inserted Successfully', result);
                    setCampaignId(result?.campaign?.campaign_id);
                    return result;
                } else {
                    console.error('API Error:', result.message);
                    throw new Error(result.message);
                }
            } else {
                const url = `${window.API_BASE_URL}/templates/campaigns/${campaignId}`;
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                    body: formData,
                });

                const result = await response.json();
                console.log(response);
                if (response.ok) {
                    console.log('QR Inserted Successfully', result);
                    return result;
                } else {
                    console.error('API Error:', result.message);
                    throw new Error(result.message);
                }
            }
        } catch (error) {
            console.error('Validation or Fetch Error:', error);
            throw error;
        } finally {
            setStepLoader(false);
        }
    };

    const handleNextAddNewPopup = async () => {
        switch (staps) {
            case 1:
                if (!selectedChannels.whatsapp && !selectedChannels.sms && !selectedChannels.email) {
                    message.error('Please select atleast one channel.');
                    return;
                }
                await insertUpdateCampaign('inactive', 'yes');
                setStaps(staps + 1);
                break;
            case 2:
                if (!rewardType) {
                    message.error('Please select reward type.');
                    return;
                }
                if (rewardType === 'percentage') {
                    const percentage = Number(preDisc);
                    if (!percentage || percentage <= 0 || percentage > 100) {
                        message.error('Percentage off should be greater than 0 and less than or equal to 100.');
                        return;
                    }
                }
                if (rewardOn === 'specific' && !specificItem) {
                    message.error('Item required on specific purchase reward.');
                    return;
                }
                if (!campaignTitle.trim()) {
                    message.error('Campaign title required.');
                    return;
                }
                if (!campaignDesc.trim()) {
                    message.error('Campaign description / subtitle required.');
                    return;
                }
                await insertUpdateCampaign('inactive', 'yes');
                setStaps(staps + 1);
                break;
            case 3:
                if (!expireDate || expireDate.isBefore(dayjs().startOf('day'))) {
                    message.error('Campaign expiry must be a future date.');
                    return;
                }
                if (showContact && contactNumber === '') {
                    message.error('Contact number is required.');
                    return;
                }
                await insertUpdateCampaign('inactive', 'yes');
                setStaps(staps + 1);
                break;
            case 4:
                await insertUpdateCampaign('inactive', 'yes');
                if (selectedChannels.whatsapp) {
                    setStaps(staps + 1);
                }
                if (!selectedChannels.whatsapp && selectedChannels.sms) {
                    setStaps(staps + 2);
                }
                if (!selectedChannels.whatsapp && !selectedChannels.sms && selectedChannels.email) {
                    setStaps(staps + 3);
                }
                break;
            case 5:
                await submitTemplateForApproval();
                // setStaps(staps + 1);
                break;
            case 6:
                if (selectedChannels.email) {
                    setStaps(staps + 1);
                } else {
                    setStaps(staps + 2);
                }
                break;
            case 7:
                setStaps(staps + 1);
                break;
            case 8:
                setStaps(staps + 1);
                break;
            case 9:
                setStaps(staps + 1);
                break;
            case 10:
                setStaps(staps);
                break;
            default:
                break;
        }
    };

    function formatCampaignTitle(title) {
        return title.toLowerCase().replace(/[^a-z0-9\s]/g, '').replace(/\s+/g, '_');
    }

    const customRequest = () => {
        return async ({ action, data, file, filename, headers, onError, onProgress, onSuccess }) => {
            try {
                // Create a FileReader to read the file as a base64 string
                const formData = new FormData();
                formData.append('header_file', file);
                formData.append('is_whatsapp', selectedChannels.whatsapp);
                formData.append('accessToken', userData?.whatsappAccessToken !== '' ? userData?.whatsappAccessToken : whatsappData?.whatsappAccessToken);
                formData.append('wappid', userData?.waappid !== '' ? userData?.waappid : whatsappData?.waappid);
                const response = await fetch(action, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                    body: formData
                });
                // Parse the response
                const responseData = await response.json();
                // Process the response based on file type
                if (selectedChannels.whatsapp) {
                    setFileId(responseData.filePath.h);
                }
                setFileName(responseData.fileName);
                setFileType(file.type);
                // Call onSuccess with the response data and the file
                onSuccess(responseData, file);
            } catch (error) {
                onError(error);
                console.error(error);
            }
        };
    };

    const submitTemplateForApproval = async () => {
        if (templateName === '') {
            message.error('Template name is required.');
            return;
        }
        if (templateCategory === '') {
            message.error('Category is required');
            return;
        }
        if (selectedLanguage === '') {
            message.error('Please select language.');
            return;
        }

        if (whatsappText.match(/{{\d+}}/g) && whatsappText.match(/{{\d+}}/g).length !== 0) {
            let required = 'no';
            whatsappText.match(/{{\d+}}/g).forEach((match, index) => {
                if (!inputValues[index]) {
                    required = 'yes';
                }
                if (inputValues[index] === '') {
                    required = 'yes';
                }
            });
            if (required === 'yes') {
                message.error('Example text required for selected variables.');
                return;
            }
        }
        if (isHeader) {
            let headerrequired = 'no';
            if (headerType === '') {
                message.error('Please Select header or disable toggle switch.');
                headerrequired = 'yes';
            } else {
                switch (headerType) {
                    case 'text':
                        if (headerText === '') {
                            message.error('Header Text is required.');
                            headerrequired = 'yes';
                        } else {
                            if (headerText.match(/{{\d+}}/g) && headerText.match(/{{\d+}}/g).length !== 0) {
                                if (headerVarValue === '') {
                                    message.error('Example text required for selected variables.');
                                    headerrequired = 'yes';
                                }
                            }
                        }
                        break;
                    case 'image':
                        if (fileId === '') {
                            message.error('Please upload the image.');
                            headerrequired = 'yes';
                        }
                        break;
                    case 'video':
                        if (fileId === '') {
                            message.error('Please upload the video.');
                            headerrequired = 'yes';
                        }
                        break;
                    case 'document':
                        if (fileId === '') {
                            message.error('Please upload the document.');
                            headerrequired = 'yes';
                        }
                        break;
                    default:
                        break;
                }
                if (headerrequired === 'yes') {
                    return;
                }
            }
        }
        if (isButtons) {
            let buttonrequired = 'no';
            if (buttonFields.length === 0) {
                message.error('Please add button or disable button toggle switch.');
                buttonrequired = 'yes';
            } else {
                buttonFields.forEach(buttons => {
                    if (buttons.type === 'weburl') {
                        console.log(buttons.type);
                        if (!buttons.text || buttons.text === '') {
                            message.error('Please add Website url button name.');
                            buttonrequired = 'yes';
                        }
                        if (!buttons.url || buttons.url === '') {
                            message.error('Please enter Website url.');
                            buttonrequired = 'yes';
                        }
                    }
                    if (buttons.type === 'phone') {
                        if (!buttons.text || buttons.text === '') {
                            message.error('Please add Phone No. button name.');
                            buttonrequired = 'yes';
                        }
                        if (!buttons.phoneNumber || buttons.phoneNumber === '') {
                            message.error('Please enter Phone No.');
                            buttonrequired = 'yes';
                        }
                    }
                    if (buttons.type === 'quick') {
                        if (!buttons.text || buttons.text === '') {
                            message.error('Please add text in quick reply input field.');
                            buttonrequired = 'yes';
                        }
                    }
                });
            }
            if (buttonrequired === 'yes') {
                return;
            }
        }
        Modal.confirm({
            title: 'Are you sure you want to submit this template for approval',
            content: 'Pleade double-check all details. Once submitted, changes to message content cannot be made. To make changes, you will need to create new template.',
            okText: 'Yes, Submit',
            okButtonProps: { className: 'modalconfirmokbtn' },
            okType: 'primary',
            cancelText: 'No, I want to edit',
            onOk: async () => {
                const formData = [];
                let header = null;
                let footer = null;
                let buttons = null;
                let btns = [];
                if (isHeader) {
                    switch (headerType) {
                        case 'text':
                            header = {
                                type: 'HEADER',
                                format: 'TEXT',
                                text: headerText,
                                ...(headerText.match(/{{\d+}}/g).length !== 0 && {
                                    example: { header_text: headerVarValue }
                                })
                            }
                            break;
                        case 'image':
                            header = {
                                type: "HEADER",
                                format: "IMAGE",
                                example: {
                                    header_handle: [
                                        fileId
                                    ]
                                }
                            };
                            break;
                        case 'video':
                            header = {
                                type: "HEADER",
                                format: "VIDEO",
                                example: {
                                    header_handle: [
                                        fileId
                                    ]
                                }
                            };
                            break;
                        case 'document':
                            header = {
                                type: "HEADER",
                                format: "DOCUMENT",
                                example: {
                                    header_handle: [
                                        fileId
                                    ]
                                }
                            };
                            break;
                        default:
                            break;
                    }
                }
                if (optInMessage) {
                    footer = {
                        type: "FOOTER",
                        text: `Reply STOP to Unsubscribe from ${storeDetail?.store_name}`
                    }
                }
                if (isButtons) {
                    sections.forEach((section) => {
                        if (section.id === 'weburl') {
                            buttonFields
                                .filter(field => field.type === 'weburl')
                                .forEach(field => {
                                    const weburl = {
                                        type: "URL",
                                        text: field.text,
                                        url: field.url
                                    };
                                    btns.push(weburl);
                                });
                        }
                        if (section.id === 'phone') {
                            buttonFields
                                .filter(field => field.type === 'phone')
                                .forEach(field => {
                                    const phone = {
                                        type: "PHONE_NUMBER",
                                        text: field.text,
                                        phone_number: field.phoneNumber
                                    }
                                    btns.push(phone);
                                });
                        }
                        if (section.id === 'quick') {
                            buttonFields
                                .filter(field => field.type === 'quick')
                                .forEach(field => {
                                    const quick = {
                                        type: "QUICK_REPLY",
                                        text: field.text
                                    }
                                    btns.push(quick);
                                });
                        }
                    });
                }
                if (btns !== null && btns.length > 0) {
                    buttons = {
                        type: "BUTTONS",
                        buttons: btns
                    }
                }

                const templateData = {
                    name: templateName,
                    language: selectedLanguage,
                    category: templateCategory,
                    components: [
                        ...(header !== null ? [header] : []),
                        {
                            type: 'Body',
                            text: whatsappText,
                            ...(whatsappText.match(/{{\d+}}/g) && whatsappText.match(/{{\d+}}/g).length !== 0 && (whatsappText.match(/{{\d+}}/g).length === 1 ? { example: { body_text: inputValues[0] } } : { example: { body_text: inputValues } }))
                        },
                        ...(footer !== null ? [footer] : []),
                        ...(buttons !== null ? [buttons] : [])
                    ],
                };
                console.log(templateData);
                formData.push(templateData);
                const whatsappAccessToken = userData?.whatsappAccessToken !== '' ? userData?.whatsappAccessToken : whatsappData?.whatsappAccessToken;
                const wabaid = userData?.wabaid !== '' ? userData?.wabaid : whatsappData?.wabaid;
                try {
                    setTemplateReview(true);
                    const response = await fetch(`${window.API_BASE_URL}/templates/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${sessionData?.token}`,
                        },
                        body: JSON.stringify({ formData: formData, fileName: fileName, accessToken: whatsappAccessToken, wabaid: wabaid }),
                    });
                    if (!response.ok) {
                        throw new Error(`Create Template failed with status ${response.status}`);
                    }
                    const data = await response.json();
                    setTemplateId(data?.responseData?.id);
                    setTemplateStatus(data?.responseData?.status);
                    if (data?.responseData?.status === 'APPROVED') {
                        setTemplateReview(false);
                    }
                    console.log('Create Template response:', data);
                    message.success('Template Created successfull.');
                } catch (error) {
                    setTemplateReview(false);
                    message.error(error?.message);
                    console.error('Error in Creating template:', error);
                } finally {
                    // setTemplateReview(false);
                }
            }
        });
    };

    const handleDateChange = (date) => {
        setExpireDate(date);
    };
    const disablePreviousDates = (current) => {
        return current && current < dayjs().startOf('day');
    };

    const handleSaveExit = () => {
        setCreateNewTemplateDrawer(false);
    };

    const handleActive = async () => {
        await insertUpdateCampaign('active', 'no');
        setCreateNewTemplateDrawer(false);
        message.success('campaign activate');
    };

    const handleChannelClick = (channel) => {
        setSelectedChannels(prevState => ({
            ...prevState,
            [channel]: !prevState[channel]
        }));
    };

    const handleChangeRewardType = (type) => {
        setRewardType(type);
    }

    const handleAddTerms = (terms) => {
        if (termsText.includes(terms)) {
            setTermsText(termsText.replace(terms, '').trim());
        } else {
            setTermsText(`${termsText}\n${terms}`.trim());
        }
    };

    const fetchTemplateStatus = async () => {
        setTemplateReview(false);
    };

    const handleCustomRangeChange = (dates) => {
        setCustomRange(dates);
    };

    return (
        <>
            {stepLoader && (
                <div className='loaderDiv'>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }
                    />
                </div>
            )}
            <Layout className='contentLayout'>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
                    <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Campaigns</b></Title>
                    <Button type='primary' onClick={() => { setCreateNewTemplateDrawer(true); navigate(`/campaigns/addnew`); }}>Start from Scratch <PiArrowArcRightDuotone /></Button>
                </div>
                <Tabs defaultActiveKey={!tabType ? 'template-library' : tabType} items={items} onChange={onChange} />
                <Drawer
                    placement='bottom'
                    closable={true}
                    onClose={() => setOpenDrawer(false)}
                    open={openDrawer}
                    className='templateDrawer'
                    height="100vh"
                >
                    <Row>
                        <Col span={12}>
                            <div className='drawerLeftSide'>
                                <div className='center-div'>
                                    <Segmented className='segmented' defaultValue="Creative" style={{ marginBottom: 8 }}
                                        onChange={(value) => setSegmentType(value)}
                                        options={['Creative', 'SMS', 'Email']}
                                    />
                                    {segmentType === 'Creative' ? (
                                        <div className='creativeSegment'>
                                            <div className='final-no-discount-camp-card'>
                                                <div className="card-header">
                                                    <img alt="banner_image" title="banner image" className="banner_image" src={foodOne} />
                                                </div>
                                                <div className="card-logo-div">
                                                    <p className="card-logo-name">{storeDetail?.store_name}</p>
                                                </div>
                                                <div className="card-content">
                                                    <h3 className="camptitle">Happy Ganesh Chaturthi!</h3>
                                                    <p className="desc">Celebrate this Ganesh Chaturthi with us &amp; enjoy our #GaneshaSpecials with your friends and family!</p>
                                                    <p className="foot">Call <a href="tel:9591674518"><u>9591674518</u></a> for more details </p>
                                                </div>
                                            </div>
                                            <img className="prev-arrow" src={arrowOne} alt="arrow" />
                                            <p className="prev-arrow-text">Your customers receive Campaign creative on SMS,  Whatsapp &amp; Email</p>
                                        </div>
                                    ) : segmentType === 'SMS' ? (
                                        <div className='creativeSegment'>
                                            <div className="final-sms-card">
                                                <img alt="phone" className="back-mobile" src={phonevector2} />
                                                <div className="preview-sms-card2">
                                                    <div className="header">
                                                        <div className="logo">Z</div>
                                                        <p>mZIPLER</p>
                                                    </div>
                                                    <div className="sms">
                                                        <div className="text">Celebrate Ganesh Chaturthi with your family &amp; delicious delicacies at {storeDetail?.store_name}. So, why wait? visit now! Click <a href="https://zipler.io/" rel="noreferrer" target="_blank">zipler.io</a><br /><br />via zipler
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : segmentType === 'Email' && (
                                        <div className='creativeSegment'>
                                            <div className="template-email-root">
                                                <img alt="phonevector" className="phonevector" src={phonevector} />
                                                <div className="scrollable-div">
                                                    <img alt="gmail" className="gmail" src={gmail} />
                                                    <h5 className="email-subject">Celebrations inside!</h5>
                                                    <img width="350px" className="email-img" src={emailBanner} alt="Edit campaign to generate email template" />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='drawerRightSide'>
                                <div className='left-div'>
                                    <Title level={3} style={{ margin: 0, fontSize: 16, lineHeight: '22px', color: '#0E1B1B', padding: '6px 10px', borderRadius: 40, backgroundColor: '#E9EDED', width: 'fit-content' }}>Ganesh Chaturthi (7th Sep)</Title>
                                    <Title level={2} style={{ marginTop: '0.2em' }}>Happy Ganesh Chaturthi!</Title>
                                    <Title level={5} style={{ display: 'flex', alignItems: 'center' }}><GrAnnounce size={16} />&nbsp;No Discount</Title>
                                    <Button type='primary'>Customise and Send <ArrowRightOutlined /></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Drawer>
                <Modal open={targatedGroupModal} title={<Flex justify='space-between' align='center'><Title level={4} style={{ margin: 0 }}>Choose from pre-packed groups</Title><Button shape='round' className='close-btn' onClick={() => setTargatedGroupModal(false)}><PiX /></Button></Flex>} centered footer={<Flex align='center' justify='space-between'><Button onClick={clearSelectAll}>Clear All</Button><Button onClick={() => { setTargatedGroupModal(false); message.success('Customers selected successfuly.') }} type='primary'>Select {isGroupSelected('new') ? customers?.length : 0} Customers</Button></Flex>} closable={false}>
                    <Paragraph style={{ color: '#697777' }}>These are some of the groups people use most often to send campaign.</Paragraph>
                    <hr />
                    <div className='modal-group-list' onClick={toggleSelectAll}>
                        <Flex justify='space-between' align='center' style={{ cursor: 'pointer' }}>
                            <Title level={5} style={{ margin: 0 }}>Select All</Title>
                            <Checkbox checked={areAllSelected} />
                        </Flex>
                    </div>
                    <div className='modal-groups-div'>
                        {groups.map((group) => (
                            <div key={group.key} className={`modal-group-list ${isGroupSelected(group.key) && 'active'}`} onClick={() => toggleGroup(group.key)}>
                                <Flex justify='space-between' align='center' style={{ cursor: 'pointer' }}>
                                    <div>
                                        <Title level={5} style={{ margin: 0 }}>{group.label} {group.key === 'new' ? customers?.length : 0}</Title>
                                        <Paragraph className='modal-group-list-subtitle'>{group.title}</Paragraph>
                                    </div>
                                    <Checkbox checked={isGroupSelected(group.key)} />
                                </Flex>
                            </div>
                        ))}
                    </div>
                </Modal>
                <Modal width={700} open={filterCustomerModal} title={<Flex justify='space-between' align='center'><Title level={4} style={{ margin: 0 }}>Filter Customers</Title><Button shape='round' className='close-btn' onClick={() => setFilterCustomerModal(false)}><PiX /></Button></Flex>} centered footer={<Flex align='center' justify='space-between'><Button onClick={clearSelectAll}>Clear All</Button><Button onClick={() => { setFilterCustomerModal(false); message.success('Customers selected successfuly.') }} type='primary'>Select {isSagmentSelected('new') ? customers?.length : 0} Customers</Button></Flex>} closable={false}>
                    <Paragraph style={{ color: '#697777' }}>These are some of the filters people use most often.</Paragraph>
                    <hr />
                    <div className='modal-groups-div'>
                        <Title level={4} style={{ margin: 0 }}>Customer visited in</Title>
                        <Flex gap={16} align='center' style={{ marginBottom: 16 }}>
                            <RangePicker
                                presets={rangePresets}
                                value={customRange}
                                onChange={handleCustomRangeChange}
                                style={{ marginTop: '10px', width: '50%' }}
                                placeholder={['Start date', 'End date']}
                                className='dateRangePicker'
                            />
                        </Flex>
                        <Title level={4} style={{ margin: 0 }}>Customer has not visited in</Title>
                        <Flex gap={16} align='center' style={{ marginBottom: 16 }}>
                            <RangePicker
                                presets={rangePresets}
                                value={customRange}
                                onChange={handleCustomRangeChange}
                                style={{ marginTop: '10px', width: '50%' }}
                                placeholder={['Start date', 'End date']}
                                className='dateRangePicker'
                            />
                        </Flex>
                        <hr />
                        <Title level={4} style={{ margin: 0 }}>Customer Segment</Title>
                        <Flex align='center' justify='flex-start' wrap>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('new')}>
                                <Checkbox checked={isSagmentSelected('new')} />
                                <Title level={5} style={{ margin: 0 }}>New Customers</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('needattention')}>
                                <Checkbox checked={isSagmentSelected('needattention')} />
                                <Title level={5} style={{ margin: 0 }}>Need Attention</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('promissing')}>
                                <Checkbox checked={isSagmentSelected('promissing')} />
                                <Title level={5} style={{ margin: 0 }}>Promising Customers</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('loyal')}>
                                <Checkbox checked={isSagmentSelected('loyal')} />
                                <Title level={5} style={{ margin: 0 }}>Loyal Customer</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('vip')}>
                                <Checkbox checked={isSagmentSelected('vip')} />
                                <Title level={5} style={{ margin: 0 }}>VIP Customer</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('risk')}>
                                <Checkbox checked={isSagmentSelected('risk')} />
                                <Title level={5} style={{ margin: 0 }}>At Risk Customer</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('lost')}>
                                <Checkbox checked={isSagmentSelected('lost')} />
                                <Title level={5} style={{ margin: 0 }}>Lost Customer</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('potential')}>
                                <Checkbox checked={isSagmentSelected('potential')} />
                                <Title level={5} style={{ margin: 0 }}>Potential Customer</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('cantloose')}>
                                <Checkbox checked={isSagmentSelected('cantloose')} />
                                <Title level={5} style={{ margin: 0 }}>Can't Loose Customer</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('sleep')}>
                                <Checkbox checked={isSagmentSelected('sleep')} />
                                <Title level={5} style={{ margin: 0 }}>About to Sleep Customer</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerSagment('potentialloyal')}>
                                <Checkbox checked={isSagmentSelected('potentialloyal')} />
                                <Title level={5} style={{ margin: 0 }}>Potential Loyal Customer</Title>
                            </Flex>
                        </Flex>
                        <hr />
                        <Title level={4} style={{ margin: 0 }}>Tags</Title>
                        <Select placeholder="Select tags" multiple showSearch style={{ width: '100%', marginTop: 8, marginBottom: 16 }}>

                        </Select>
                        <hr />
                        <Title level={4} style={{ margin: 0 }}>Imported customers</Title>
                        <Flex gap={8} justify='start' align='center' style={{ marginTop: 8, marginBottom: 16, cursor: 'pointer' }} onClick={() => setImportedCustomer(!importedCustomer)}>
                            <Checkbox checked={importedCustomer} /><Title level={5} style={{ margin: 0 }}>Includes customers who haven’t yet made a purchase at your store after being imported.</Title>
                        </Flex>
                        <hr />
                        <Title level={4} style={{ margin: 0 }}>Gender</Title>
                        <Flex align='center' justify='flex-start' wrap>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerGender('male')}>
                                <Checkbox checked={isgenderSelected('male')} />
                                <Title level={5} style={{ margin: 0 }}>Male</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerGender('female')}>
                                <Checkbox checked={isgenderSelected('female')} />
                                <Title level={5} style={{ margin: 0 }}>Female</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerGender('notspecified')}>
                                <Checkbox checked={isgenderSelected('notspecified')} />
                                <Title level={5} style={{ margin: 0 }}>Not Specified</Title>
                            </Flex>
                        </Flex>
                        <hr />
                        <Title level={4} style={{ margin: 0 }}>Average spend</Title>
                        <Flex align='center' justify='flex-start' wrap>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerAverageSpand('low')}>
                                <Checkbox checked={isAverageSpandSelected('low')} />
                                <Title level={5} style={{ margin: 0 }}>Low Spender</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerAverageSpand('medium')}>
                                <Checkbox checked={isAverageSpandSelected('medium')} />
                                <Title level={5} style={{ margin: 0 }}>Medium Spender</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerAverageSpand('high')}>
                                <Checkbox checked={isAverageSpandSelected('high')} />
                                <Title level={5} style={{ margin: 0 }}>High Spender</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerAverageSpand('veryhigh')}>
                                <Checkbox checked={isAverageSpandSelected('veryhigh')} />
                                <Title level={5} style={{ margin: 0 }}>Very High Spender</Title>
                            </Flex>
                        </Flex>
                        <hr />
                        <Flex justify='space-between' align='center'>
                            <div>
                                <Title level={5} style={{ margin: 0 }}>Total Spent</Title>
                                <Paragraph><b>{storeDetail?.currency_symbol} 1110 - {storeDetail?.currency_symbol} 1110</b></Paragraph>
                                <Paragraph>The avg spend of your business is {storeDetail?.currency_symbol} 1110</Paragraph>
                            </div>
                            <Switch onChange={() => setIsTotalSpend(!isTotalSpend)} checked={isTotalSpend} />
                        </Flex>
                        {isTotalSpend && (
                            <div>
                                <div className='rangeslider'>
                                    <Slider min={0} max={1110} value={totalSpentRangeValues} range defaultValue={[1110, 1110]} onChange={(values) => setTotalSpentRangeValues(values)} tooltip={{ formatter: (value) => `${storeDetail?.currency_symbol}  ${value}`, open: true }} />
                                </div>
                                <Flex justify='space-between' align='center' gap={16}>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Minimum Amount</Title>
                                        <Input type='number' min={0} max={1110} value={totalSpentRangeValues[0]} onChange={(e) => { e.target.value <= 1110 && setTotalSpentRangeValues([e.target.value, totalSpentRangeValues[1]]) }} />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Maximum Amount</Title>
                                        <Input type='number' min={0} max={1110} value={totalSpentRangeValues[1]} onChange={(e) => { e.target.value <= 1110 && setTotalSpentRangeValues([totalSpentRangeValues[0], e.target.value]) }} />
                                    </div>
                                </Flex>
                            </div>
                        )}
                        <hr />
                        <Flex justify='space-between' align='center'>
                            <div>
                                <Title level={5} style={{ margin: 0 }}>Average Order Value</Title>
                                <Paragraph><b>{storeDetail?.currency_symbol} 370 - {storeDetail?.currency_symbol} 370</b></Paragraph>
                                <Paragraph>The average order value at your business is {storeDetail?.currency_symbol} 370</Paragraph>
                            </div>
                            <Switch onChange={() => setIsAverageSpend(!isAverageSpend)} checked={isAverageSpend} />
                        </Flex>
                        {isAverageSpend && (
                            <div>
                                <div className='rangeslider'>
                                    <Slider min={0} max={370} value={averageSpentRangeValues} range defaultValue={[370, 370]} onChange={(values) => setAverageSpentRangeValues(values)} tooltip={{ formatter: (value) => `${storeDetail?.currency_symbol}  ${value}`, open: true }} />
                                </div>
                                <Flex justify='space-between' align='center' gap={16}>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Minimum Avg</Title>
                                        <Input type='number' min={0} max={370} value={averageSpentRangeValues[0]} onChange={(e) => { e.target.value <= 370 && setAverageSpentRangeValues([e.target.value, averageSpentRangeValues[1]]) }} />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Maximum Avg</Title>
                                        <Input type='number' min={0} max={370} value={averageSpentRangeValues[1]} onChange={(e) => { e.target.value <= 370 && setAverageSpentRangeValues([averageSpentRangeValues[0], e.target.value]) }} />
                                    </div>
                                </Flex>
                            </div>
                        )}
                        <hr />
                        <Flex justify='space-between' align='center'>
                            <div>
                                <Title level={5} style={{ margin: 0 }}>Total Visits</Title>
                                <Paragraph><b>3 - 3</b></Paragraph>
                                <Paragraph>The average visits at your business is 3</Paragraph>
                            </div>
                            <Switch onChange={() => setIsTotalVisits(!isTotalVisits)} checked={isTotalVisits} />
                        </Flex>
                        {isTotalVisits && (
                            <div>
                                <div className='rangeslider'>
                                    <Slider min={0} max={3} value={totalVisitsRangeValues} range defaultValue={[3, 3]} onChange={(values) => setTotalVisitsRangeValues(values)} tooltip={{ formatter: (value) => `${value}`, open: true }} />
                                </div>
                                <Flex justify='space-between' align='center' gap={16}>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Minimum Visits</Title>
                                        <Input type='number' min={0} max={3} value={totalVisitsRangeValues[0]} onChange={(e) => { e.target.value <= 3 && setTotalVisitsRangeValues([e.target.value, totalVisitsRangeValues[1]]) }} />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Maximum Visits</Title>
                                        <Input type='number' min={0} max={3} value={totalVisitsRangeValues[1]} onChange={(e) => { e.target.value <= 3 && setTotalVisitsRangeValues([totalVisitsRangeValues[0], e.target.value]) }} />
                                    </div>
                                </Flex>
                            </div>
                        )}
                        <hr />
                        <Flex justify='space-between' align='center'>
                            <div>
                                <Title level={5} style={{ margin: 0 }}>Loyalty Points Balance</Title>
                                <Paragraph><b>150 - 500</b></Paragraph>
                            </div>
                            <Switch onChange={() => setIsLoyaltyPointBalance(!isLoyaltyPointBalance)} checked={isLoyaltyPointBalance} />
                        </Flex>
                        {isLoyaltyPointBalance && (
                            <div>
                                <div className='rangeslider'>
                                    <Slider min={150} max={500} value={loyaltyPointBalanceRangeValues} range defaultValue={[150, 500]} onChange={(values) => setLoyaltyPointBalanceRangeValues(values)} tooltip={{ formatter: (value) => `${value}`, open: true }} />
                                </div>
                                <Flex justify='space-between' align='center' gap={16}>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Minimum points</Title>
                                        <Input type='number' min={150} max={500} value={loyaltyPointBalanceRangeValues[0]} onChange={(e) => { e.target.value <= 500 && setLoyaltyPointBalanceRangeValues([e.target.value, loyaltyPointBalanceRangeValues[1]]) }} />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Maximum points</Title>
                                        <Input type='number' min={150} max={500} value={loyaltyPointBalanceRangeValues[1]} onChange={(e) => { e.target.value <= 500 && setLoyaltyPointBalanceRangeValues([loyaltyPointBalanceRangeValues[0], e.target.value]) }} />
                                    </div>
                                </Flex>
                            </div>
                        )}
                        <hr />
                        <Flex justify='space-between' align='center'>
                            <div>
                                <Title level={5} style={{ margin: 0 }}>Loyalty Points Redeemed</Title>
                                <Paragraph><b>0 - 1</b></Paragraph>
                            </div>
                            <Switch onChange={() => setIsLoyaltyPointRedeem(!isLoyaltyPointRedeem)} checked={isLoyaltyPointRedeem} />
                        </Flex>
                        {isLoyaltyPointRedeem && (
                            <div>
                                <div className='rangeslider'>
                                    <Slider min={0} max={1} value={loyaltyPointRedeemRangeValues} range defaultValue={[0, 1]} onChange={(values) => setLoyaltyPointRedeemRangeValues(values)} tooltip={{ formatter: (value) => `${value}`, open: true }} />
                                </div>
                                <Flex justify='space-between' align='center' gap={16}>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Minimum points redeemed</Title>
                                        <Input type='number' min={0} max={1} value={loyaltyPointRedeemRangeValues[0]} onChange={(e) => { e.target.value <= 1 && setLoyaltyPointRedeemRangeValues([e.target.value, loyaltyPointRedeemRangeValues[1]]) }} />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Maximum points redeemed</Title>
                                        <Input type='number' min={0} max={1} value={loyaltyPointRedeemRangeValues[1]} onChange={(e) => { e.target.value <= 1 && setLoyaltyPointRedeemRangeValues([loyaltyPointRedeemRangeValues[0], e.target.value]) }} />
                                    </div>
                                </Flex>
                            </div>
                        )}
                        <hr />
                        <Flex justify='space-between' align='center'>
                            <div>
                                <Title level={5} style={{ margin: 0 }}>Number of times loyalty points redeemed</Title>
                                <Paragraph><b>0 - 1</b></Paragraph>
                            </div>
                            <Switch onChange={() => setNoOfTimeLoyaltyPointRedeem(!isNoOfTimeLoyaltyPointRedeem)} checked={isNoOfTimeLoyaltyPointRedeem} />
                        </Flex>
                        {isNoOfTimeLoyaltyPointRedeem && (
                            <div>
                                <div className='rangeslider'>
                                    <Slider min={0} max={1} value={noOfLoyaltyPointBalanceRangeValues} range defaultValue={[0, 1]} onChange={(values) => setNoOfLoyaltyPointRedeemRangeValues(values)} tooltip={{ formatter: (value) => `${value}`, open: true }} />
                                </div>
                                <Flex justify='space-between' align='center' gap={16}>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Minimum Times</Title>
                                        <Input type='number' min={0} max={1} value={noOfLoyaltyPointBalanceRangeValues[0]} onChange={(e) => { e.target.value <= 1 && setNoOfLoyaltyPointRedeemRangeValues([e.target.value, noOfLoyaltyPointBalanceRangeValues[1]]) }} />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <Title level={5} style={{ margin: 0 }}>Maximum Times</Title>
                                        <Input type='number' min={0} max={1} value={noOfLoyaltyPointBalanceRangeValues[1]} onChange={(e) => { e.target.value <= 1 && setNoOfLoyaltyPointRedeemRangeValues([noOfLoyaltyPointBalanceRangeValues[0], e.target.value]) }} />
                                    </div>
                                </Flex>
                            </div>
                        )}
                        <hr />
                        <Title level={4} style={{ margin: 0 }}>Day Preference</Title>
                        <Flex align='center' justify='flex-start' wrap>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerDayPreference('weekend')}>
                                <Checkbox checked={isDayPreferenceSelected('weekend')} />
                                <Title level={5} style={{ margin: 0 }}>Weekend</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerDayPreference('weekday')}>
                                <Checkbox checked={isDayPreferenceSelected('weekday')} />
                                <Title level={5} style={{ margin: 0 }}>Weekday</Title>
                            </Flex>
                        </Flex>
                        <hr />
                        <Title level={4} style={{ margin: 0 }}>Time Preference</Title>
                        <Flex align='center' justify='flex-start' wrap>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerTimePreference('morning')}>
                                <Checkbox checked={isTimePreferenceSelected('morning')} />
                                <Title level={5} style={{ margin: 0 }}>Morning (5 am - 11:59 am)</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerTimePreference('afternoon')}>
                                <Checkbox checked={isTimePreferenceSelected('afternoon')} />
                                <Title level={5} style={{ margin: 0 }}>Afternoon (12 pm - 3:59 pm)</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerTimePreference('evening')}>
                                <Checkbox checked={isTimePreferenceSelected('evening')} />
                                <Title level={5} style={{ margin: 0 }}>Evening (4 pm - 7:59 pm)</Title>
                            </Flex>
                            <Flex align='center' justify='start' gap={8} style={{ width: '50%', paddingTop: 8, paddingBottom: 8, cursor: 'pointer' }} onClick={() => toggleCustomerTimePreference('night')}>
                                <Checkbox checked={isTimePreferenceSelected('night')} />
                                <Title level={5} style={{ margin: 0 }}>Night (8 pm - 4:59 am)</Title>
                            </Flex>
                        </Flex>
                    </div>
                </Modal>
                <Modal open={templateReview} centered footer={false} closable={false}>
                    <div style={{ textAlign: 'center', }}>
                        <Title level={3}>Hang on!<br />Your template is Under Review <FcBinoculars size={24} style={{ position: 'relative', top: 5 }} /></Title>
                        <Paragraph>Meta is reviewing your template. Click 'Fetch Template Status' to check the process!</Paragraph>
                        <Image src={waitImage} preview={false} width={300} />
                        <div class="loader-line"></div>
                        <br />
                        <Button type='primary' style={{ width: '100%' }} onClick={fetchTemplateStatus}>Fetch Template Status</Button>
                    </div>
                </Modal>
                <Drawer
                    placement='bottom'
                    closable={false}
                    onClose={handleCloseAddNewPopup}
                    open={createNewTemplateDrawer}
                    className='templateDrawer'
                    height="100vh"
                    zIndex={999}
                >
                    <Row style={{ height: '10vh', overflow: 'hidden' }}>
                        <Col span={24}>
                            <Flex gap='middle' style={{ padding: '16px 24px 10px 24px' }} justify='space-between' align='center'>
                                <Flex gap='small' justify='center' align='center'>
                                    <Button shape='round' className='close-btn' onClick={handleCloseAddNewPopup}><PiArrowLeftDuotone /></Button>
                                    <PiMicrosoftPowerpointLogoDuotone size={20} /> <Title level={5} style={{ margin: 0 }}>Create New Template</Title>
                                </Flex>
                                {staps === 1 ? (
                                    <Flex gap='small' justify='center' align='center'>
                                        <Button type='primary' onClick={handleNextAddNewPopup} disabled={!selectedChannels.whatsapp && !selectedChannels.sms && !selectedChannels.email}>Next <PiArrowRightDuotone /></Button>
                                    </Flex>
                                ) : staps === 9 ? (
                                    <Flex gap='small' justify='center' align='center'>
                                        <Button onClick={handleSaveExit}><PiFloppyDiskDuotone /> Save & Exit</Button>
                                        <Button type='primary' onClick={handleActive}>Active Now <PiArrowRightDuotone /></Button>
                                    </Flex>
                                ) : (
                                    <Flex gap='small' justify='center' align='center'>
                                        <Button onClick={handleSaveExit} ><PiFloppyDiskDuotone /> Save & Exit</Button>
                                        <Button type='primary' onClick={handleNextAddNewPopup} disabled={!selectedChannels.whatsapp && !selectedChannels.sms && !selectedChannels.email}>Next <PiArrowRightDuotone /></Button>
                                    </Flex>
                                )}

                            </Flex>
                            <Progress showInfo={false} percent={staps * 10} strokeColor={twoColors} strokeLinecap="butt" />
                        </Col>
                    </Row>
                    <Row style={{ height: '90vh' }}>
                        <Col span={12}>
                            <div className='tempDrawerLeftSide'>
                                {staps === 1 ? (
                                    <Flex align='center' justify='center' style={{ height: '100%' }}>
                                        <Title level={3} style={{ margin: 0 }}>How do you want to send the Campaign?</Title>
                                    </Flex>
                                ) : (staps === 2 || staps === 3 || staps === 4 || staps === 8 || staps === 9) ? (
                                    <Flex justify='center' style={{ height: '100%' }}>
                                        <div className={`${staps === 2 ? 'campaign-details-left' : staps === 3 ? 'campaign-terms-left' : staps === 9 ? 'last-camp-card' : staps === 4 && staps === 8 && 'campaign-theme-left'}`}>
                                            <Title level={3} style={{ marginTop: 0, marginBlock: 24 }}>{staps === 2 ? ('What is the Campaign about?') : staps === 3 ? ('Add terms of your campaign') : staps === 4 ? 'Review Campaign Creative' : staps === 8 ? 'Preview Campaign Creative' : staps === 9 && 'Review Campaign Creative'}</Title>
                                            <div className='email-root'>
                                                <div ref={uploadContainerRef} className='edit-camp-card' style={{ color: textColor, backgroundColor: themeColor }}>
                                                    {(staps === 2 || staps === 4 || staps === 8 || staps === 9) && (
                                                        <div>
                                                            {staps === 9 && (
                                                                <Tooltip title={'Edit theme'}>
                                                                    <span className='editbtn' onClick={() => setStaps(4)}><PiPencilDuotone size={24} color='#000' /> </span>
                                                                </Tooltip>
                                                            )}
                                                            <div className='card-header'>
                                                                <Image preview={false} src={posterBannerImage ? posterBannerImage : fileName ? `${window.BASE_URL}uploads/template_media/${fileName}` :  defaultImage} />
                                                            </div>
                                                            <div class="card-logo-div">
                                                                <img className='card-logo-image' alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className='card-content'>
                                                        {staps === 9 && (
                                                            <Tooltip title={'Edit Details'}>
                                                                <span className='editbtn' onClick={() => setStaps(2)}><PiPencilDuotone size={24} color='#000' /> </span>
                                                            </Tooltip>
                                                        )}
                                                        <div className='title-desc' style={{ background: themeColor }}>
                                                            <Title level={3} style={{ color: textColor, marginTop: 0, fontWeight: 'bold' }}>{campaignTitle !== '' ? campaignTitle : 'Write campaign title'}</Title>
                                                            <Paragraph style={{ color: textColor }}>{campaignDesc !== '' ? campaignDesc : 'Write campaign subtitle'}</Paragraph>
                                                        </div>
                                                        <hr />
                                                        <div className={`${staps === 3 && 'parent-foot'}`} style={{ background: themeColor }}>
                                                            <Title style={{ color: textColor }} level={5}>Expires on {expireDate && expireDate.format('DD MMM YYYY')}</Title>
                                                            {staps === 9 && (
                                                                <Tooltip title={'Edit Terms'}>
                                                                    <span className='editbtn' onClick={() => setStaps(3)}><PiPencilDuotone size={24} color='#000' /> </span>
                                                                </Tooltip>
                                                            )}
                                                            <Paragraph style={{ color: textColor }} className='terms-points'>
                                                                {termsText}
                                                            </Paragraph>
                                                            <Paragraph style={{ color: textColor }} className="foot">{redeemTime === 'justonce' ? 'The campaign can be redeemed once.' : 'The campaign can be redeemed multiple times.'} </Paragraph>
                                                            {showContact && (<Paragraph style={{ color: textColor }} className="foot">Call <u>{contactNumber}</u> for more details </Paragraph>)}
                                                        </div>
                                                    </div>
                                                </div>
                                                {(staps === 2 || staps === 3) && (
                                                    <div className="gradient-mask"></div>
                                                )}
                                            </div>
                                        </div>
                                    </Flex>
                                ) : staps === 5 ? (
                                    <Flex justify='center' style={{ height: '100%' }}>
                                        <div className='campaign-whatsapp-left'>
                                            <Title level={3} style={{ marginTop: 0, marginBlock: 24 }}>Review WhatsApp Message</Title>
                                            <div className='whatsapp-preview-card'>
                                                <img src={whatsappMobileMokup} className='back-mobile' alt='phone' />
                                                <div className='preview-whatsapp-card'>
                                                    {/* <div className='whatsapp-header'>
                                                        <Flex align='center' gap={8}>
                                                            <Avatar src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                            <Title level={5} style={{ margin: 0, color: '#FFFFFF' }}>{storeDetail?.store_name}</Title>
                                                        </Flex>
                                                    </div> */}
                                                    <div className='whatsapp-message' style={{ marginTop: 80 }}>
                                                        {isHeader && (
                                                            headerType === 'image' ? (
                                                                <div className='whatsapp-banner-image'>
                                                                    <Image preview={false} src={whatsappImage ? whatsappImage : posterBannerImage ? posterBannerImage : fileName ? `${window.BASE_URL}uploads/template_media/${fileName}` : defaultImage} style={{ width: '100%' }} />
                                                                </div>
                                                            ) : headerType === 'video' ? (
                                                                <div className='whatsapp-banner-image'>
                                                                    {posterBannerVideo ? (
                                                                        <video controls autoPlay muted style={{ width: '100%' }}>
                                                                            <source src={posterBannerVideo} type="video/mp4" />
                                                                        </video>
                                                                    ) : (
                                                                        <PiVideoDuotone size={100} color='#A7A7A7' />
                                                                    )}
                                                                </div>
                                                            ) : headerType === 'document' ? (
                                                                <div className='whatsapp-banner-image'>
                                                                    {posterBannerDocument ? (
                                                                        <PiFileDocDuotone size={100} color='#A7A7A7' />
                                                                    ) : (
                                                                        <PiFileDocDuotone size={100} color='#A7A7A7' />
                                                                    )}
                                                                </div>
                                                            ) : headerType === 'text' && (
                                                                <div style={{ color: '#000000', textAlign: 'left' }}>
                                                                    <b>{headerText}</b>
                                                                </div>
                                                            )
                                                        )}
                                                        <div className='message'>
                                                            <div
                                                                style={{ whiteSpace: 'pre-wrap' }}
                                                                dangerouslySetInnerHTML={{ __html: formatTextForPreview(whatsappText) }}
                                                            />
                                                            {optInMessage && (<Paragraph style={{ margin: 0, fontSize: 12, color: '#777' }}>{`Reply STOP to Unsubscribe from ${storeDetail?.store_name}`}</Paragraph>)}
                                                        </div>
                                                        <div className="preview-section">
                                                            <div className="preview-buttons">
                                                                {sections.map((section, index) => (
                                                                    <>
                                                                        {section.id === 'weburl' && (
                                                                            buttonFields.filter(field => field.type === 'weburl').map((field) => (
                                                                                <Button key={field.id} style={{ margin: '4px' }} href={field.url} target="_blank">
                                                                                    <LinkOutlined /> {field.text}
                                                                                </Button>
                                                                            ))
                                                                        )}
                                                                        {section.id === 'phone' && (
                                                                            buttonFields.filter(field => field.type === 'phone').map((field) => (
                                                                                <Button key={field.id} style={{ margin: '4px' }}>
                                                                                    <PiPhoneCall /> {field.text}
                                                                                </Button>
                                                                            ))
                                                                        )}
                                                                        {section.id === 'quick' && (
                                                                            buttonFields.filter(field => field.type === 'quick').map((field) => (
                                                                                <Button key={field.id} style={{ margin: '4px' }}>
                                                                                    <PiArrowArcLeft /> {field.text}
                                                                                </Button>
                                                                            ))
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Flex>
                                ) : staps === 6 ? (
                                    <Flex justify='center' style={{ height: '100%' }}>
                                        <div className='campaign-sms-left'>
                                            <Title level={3} style={{ marginTop: 0, marginBlock: 24 }}>Review SMS</Title>
                                            <div className='sms-preview-card'>
                                                <img src={smsMobileMokup} className='back-mobile' alt='phone' />
                                                <div className='preview-sms-card'>
                                                    {/* <div className='header'>
                                                        <div className='logo'>Z</div>
                                                        <p>mZIPLER</p>
                                                    </div> */}
                                                    <div class="sms" style={{ marginTop: 80 }}><div class="text">Dear Customer,{`\n${smsText}\n`}-Zipler</div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Flex>
                                ) : staps === 7 && (
                                    <Flex justify='center' style={{ height: '100%' }}>
                                        <div className='campaign-sms-left'>
                                            <Title level={3} style={{ marginTop: 0, marginBlock: 24 }}>Review Email</Title>
                                            <div className='creativeSegment'>
                                                <div className="template-email-root">
                                                    <img alt="phonevector" className="phonevector" src={emailMobileMokup} />
                                                    <div className="scrollable-div" style={{ marginTop: 80 }}>
                                                        {/* <img alt="gmail" className="gmail" src={gmail} /> */}
                                                        <Title level={4}>{emailSubject === '' ? 'Write email subject' : emailSubject}</Title>
                                                        <div className='email-root'>
                                                            <div className='final-camp-card' style={{ color: textColor, backgroundColor: themeColor }}>
                                                                <div>
                                                                    <div className='card-header'>
                                                                        <Image preview={false} src={posterBannerImage ? posterBannerImage : fileName ? `${window.BASE_URL}uploads/template_media/${fileName}` : defaultImage} />
                                                                    </div>
                                                                    <div class="card-logo-div">
                                                                        <img className='card-logo-image' alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                                    </div>
                                                                </div>
                                                                <div className='card-content'>
                                                                    <div className='title-desc' style={{ background: themeColor }}>
                                                                        <Title level={3} style={{ color: textColor, marginTop: 0, fontWeight: 'bold' }}>{campaignTitle !== '' ? campaignTitle : 'Write campaign title'}</Title>
                                                                        <Paragraph style={{ color: textColor }}>{campaignDesc !== '' ? campaignDesc : 'Write campaign subtitle'}</Paragraph>
                                                                    </div>
                                                                    <hr />
                                                                    <div style={{ background: themeColor }}>
                                                                        <Title style={{ color: textColor }} level={5}>Expires on {expireDate && expireDate.format('DD MMM YYYY')}</Title>
                                                                        <Paragraph style={{ color: textColor }} className='terms-points'>
                                                                            {termsText}
                                                                        </Paragraph>
                                                                        <Paragraph style={{ color: textColor }} className="foot">{redeemTime === 'justonce' ? 'The campaign can be redeemed once.' : 'The campaign can be redeemed multiple times.'} </Paragraph>
                                                                        {showContact && (<Paragraph style={{ color: textColor }} className="foot">Call <u>{contactNumber}</u> for more details </Paragraph>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Flex>
                                )}
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='tempdrawerRightSide'>
                                {staps === 1 ? (
                                    <Flex justify='center' align='center'>
                                        <Card bordered={false} className='left-div'>
                                            <div>
                                                <Title level={4} style={{ margin: 0 }}>Select channels</Title>
                                                <div className={`template-channle-checkbox-div ${selectedChannels.whatsapp && 'active'}`} onClick={() => handleChannelClick('whatsapp')}>
                                                    <Flex justify='space-between' align='center'>
                                                        <Flex gap={8} align='center'>
                                                            <PiWhatsappLogo size={24} /><Typography><b>Whatsapp Marketing</b></Typography>
                                                        </Flex>
                                                        <Checkbox checked={selectedChannels.whatsapp} />
                                                    </Flex>
                                                </div>
                                                <div className={`template-channle-checkbox-div ${selectedChannels.sms && 'active'}`} onClick={() => handleChannelClick('sms')}>
                                                    <Flex justify='space-between' align='center'>
                                                        <Flex gap={8} align='center'>
                                                            <PiChatCenteredDotsDuotone size={24} /><Typography><b>SMS</b></Typography>
                                                        </Flex>
                                                        <Checkbox checked={selectedChannels.sms} />
                                                    </Flex>
                                                </div>
                                                <div className={`template-channle-checkbox-div ${selectedChannels.email && 'active'}`} onClick={() => handleChannelClick('email')}>
                                                    <Flex justify='space-between' align='center'>
                                                        <Flex gap={8} align='center'>
                                                            <PiEnvelopeDuotone size={24} /><Typography><b>Email</b></Typography>
                                                        </Flex>
                                                        <Checkbox checked={selectedChannels.email} />
                                                    </Flex>
                                                </div>
                                            </div>
                                        </Card>
                                    </Flex>
                                ) : staps === 2 ? (
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Card bordered={false} className='left-div'>
                                                <div>
                                                    <Title level={4} style={{ margin: 0 }}>Reward Type</Title>
                                                    <div className={`template-channle-checkbox-div ${rewardType === 'percentage' && 'active'}`} onClick={() => handleChangeRewardType('percentage')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Redeem % discount</b></Typography>
                                                            <Radio checked={rewardType === 'percentage'} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${rewardType === 'rupees' && 'active'}`} onClick={() => handleChangeRewardType('rupees')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Redeem {storeDetail?.currency_symbol}  discount</b></Typography>
                                                            <Radio checked={rewardType === 'rupees'} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${rewardType === 'freeitem' && 'active'}`} onClick={() => handleChangeRewardType('freeitem')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Redeem a free Item</b></Typography>
                                                            <Radio checked={rewardType === 'freeitem'} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${rewardType === 'nodiscount' && 'active'}`} onClick={() => handleChangeRewardType('nodiscount')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>No Discount</b></Typography>
                                                            <Radio checked={rewardType === 'nodiscount'} />
                                                        </Flex>
                                                    </div>
                                                    {rewardType === 'percentage' ? (
                                                        <div className='reward-type-settings'>
                                                            <Input name="offpercent" type='number' value={preDisc} onChange={(e) => { e.target.value <= 100 ? setPerDisc(e.target.value) : message.error('Percentage off should be greater than 0 and less than or equal to 100') }} placeholder='How many % off' step="0.01" max={100} min={0} className='how-many-input' />
                                                            <Flex gap={16} align='center' style={{ marginTop: 16 }}>
                                                                <Button style={{ width: '100%' }} type={rewardOn === 'entire' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('entire')}><PiShoppingCartDuotone size={20} /> Entire purchase</Button>
                                                                <Button style={{ width: '100%' }} type={rewardOn === 'specific' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('specific')}><PiPackageDuotone size={20} /> Specific purchase</Button>
                                                            </Flex>
                                                            {rewardOn === 'specific' && (
                                                                <Input name="item" placeholder='Add Item' value={specificItem} onChange={(e) => setSpecificItem(e.target.value)} className='add-item-input' style={{ marginTop: 16 }} />
                                                            )}
                                                        </div>
                                                    ) : rewardType === 'rupees' ? (
                                                        <div className='reward-type-settings'>
                                                            <Input name="offcash" type='number' value={cashDisc} onChange={(e) => setCashDisc(e.target.value)} placeholder={`How many ${storeDetail?.currency_symbol}  off`} className='how-many-input' />
                                                            <Flex gap={16} align='center' style={{ marginTop: 16 }}>
                                                                <Button style={{ width: '100%' }} type={rewardOn === 'entire' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('entire')}><PiShoppingCartDuotone size={20} /> Entire purchase</Button>
                                                                <Button style={{ width: '100%' }} type={rewardOn === 'specific' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('specific')}><PiPackageDuotone size={20} /> Specific purchase</Button>
                                                            </Flex>
                                                            {rewardOn === 'specific' && (
                                                                <Input style={{ marginTop: 16 }} name="item" value={specificItem} onChange={(e) => setSpecificItem(e.target.value)} placeholder='Add Item' className='add-item-input' />
                                                            )}
                                                            <div className='max-discount-card'>
                                                                <Flex className='top-row' justify='space-between' gap={16} align='center' onClick={() => setMinPurchace(minPurchace === 'checked' ? '' : 'checked')}>
                                                                    <Tooltip title="Set a minimum amount that your customers must spend to be able to redeem their reward.">
                                                                        <label>Minimum purchase required for redemption</label>
                                                                    </Tooltip>
                                                                    <Checkbox checked={minPurchace === 'checked' && true} />
                                                                </Flex>
                                                                {minPurchace === 'checked' && (
                                                                    <Input style={{ marginTop: 16 }} type='number' value={minPurchaseAmt} onChange={(e) => setMinPurchaceAmt(e.target.value)} name="min-amt" placeholder={`${storeDetail?.currency_symbol}  0`} className='add-item-input' />
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : rewardType === 'freeitem' && (
                                                        <div className='reward-type-settings'>
                                                            <Input name="freeitem" value={freeItem} onChange={(e) => setFreeItem(e.target.value)} placeholder='Add Item' className='add-item-input' />
                                                            <div className='max-discount-card'>
                                                                <Flex className='top-row' justify='space-between' gap={16} align='center' onClick={() => setMinPurchace(minPurchace === 'checked' ? '' : 'checked')}>
                                                                    <Tooltip title="Set a minimum amount that your customers must spend to be able to redeem their reward.">
                                                                        <label>Minimum purchase required for redemption</label>
                                                                    </Tooltip>
                                                                    <Checkbox checked={minPurchace === 'checked' && true} />
                                                                </Flex>
                                                                {minPurchace === 'checked' && (
                                                                    <Input style={{ marginTop: 16 }} type='number' value={minPurchaseAmt} onChange={(e) => setMinPurchaceAmt(e.target.value)} name="min-amt" placeholder={`${storeDetail?.currency_symbol}  0`} className='add-item-input' />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Campaign title</Title>
                                                <Input value={campaignTitle} onChange={(e) => { setCampaignTitle(e.target.value); setTemplateName(formatCampaignTitle(e.target.value)) }} maxLength={120} showCount placeholder='Eg. Events, Social Media, Zomato' />
                                                <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Campaign description / subtitle</Title>
                                                <TextArea value={campaignDesc} onChange={(e) => setCampaignDesc(e.target.value)} rows={4} maxLength={1024} showCount placeholder='Campaign Description / subtitle' />
                                            </Card>
                                        </div>
                                    </Flex>
                                ) : staps === 2 ? (
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Card bordered={false} className='left-div'>
                                                <div>
                                                    <Title level={4} style={{ margin: 0 }}>Reward Type</Title>
                                                    <div className={`template-channle-checkbox-div ${rewardType === 'percentage' && 'active'}`} onClick={() => handleChangeRewardType('percentage')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Redeem % discount</b></Typography>
                                                            <Radio checked={rewardType === 'percentage'} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${rewardType === 'rupees' && 'active'}`} onClick={() => handleChangeRewardType('rupees')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Redeem {storeDetail?.currency_symbol}  discount</b></Typography>
                                                            <Radio checked={rewardType === 'rupees'} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${rewardType === 'freeitem' && 'active'}`} onClick={() => handleChangeRewardType('freeitem')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Redeem a free Item</b></Typography>
                                                            <Radio checked={rewardType === 'freeitem'} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${rewardType === 'nodiscount' && 'active'}`} onClick={() => handleChangeRewardType('nodiscount')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>No Discount</b></Typography>
                                                            <Radio checked={rewardType === 'nodiscount'} />
                                                        </Flex>
                                                    </div>
                                                    {rewardType === 'percentage' ? (
                                                        <div className='reward-type-settings'>
                                                            <Input name="offpercent" type='number' value={preDisc} onChange={(e) => setPerDisc(e.target.value)} placeholder='How many % off' className='how-many-input' />
                                                            <Flex gap={16} align='center' style={{ marginTop: 16 }}>
                                                                <Button style={{ width: '100%' }} type={rewardOn === 'entire' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('entire')}><PiShoppingCartDuotone size={20} /> Entire purchase</Button>
                                                                <Button style={{ width: '100%' }} type={rewardOn === 'specific' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('specific')}><PiPackageDuotone size={20} /> Specific purchase</Button>
                                                            </Flex>
                                                            {rewardOn === 'specific' && (
                                                                <Input name="item" placeholder='Add Item' value={specificItem} onChange={(e) => setSpecificItem(e.target.value)} className='add-item-input' style={{ marginTop: 16 }} />
                                                            )}
                                                        </div>
                                                    ) : rewardType === 'rupees' ? (
                                                        <div className='reward-type-settings'>
                                                            <Input name="offcash" type='number' value={cashDisc} onChange={(e) => setCashDisc(e.target.value)} placeholder={`How many ${storeDetail?.currency_symbol}  off`} className='how-many-input' />
                                                            <Flex gap={16} align='center' style={{ marginTop: 16 }}>
                                                                <Button style={{ width: '100%' }} type={rewardOn === 'entire' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('entire')}><PiShoppingCartDuotone size={20} /> Entire purchase</Button>
                                                                <Button style={{ width: '100%' }} type={rewardOn === 'specific' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('specific')}><PiPackageDuotone size={20} /> Specific purchase</Button>
                                                            </Flex>
                                                            {rewardOn === 'specific' && (
                                                                <Input style={{ marginTop: 16 }} name="item" value={specificItem} onChange={(e) => setSpecificItem(e.target.value)} placeholder='Add Item' className='add-item-input' />
                                                            )}
                                                            <div className='max-discount-card'>
                                                                <Flex className='top-row' justify='space-between' gap={16} align='center' onClick={() => setMinPurchace(minPurchace === 'checked' ? '' : 'checked')}>
                                                                    <Tooltip title="Set a minimum amount that your customers must spend to be able to redeem their reward.">
                                                                        <label>Minimum purchase required for redemption</label>
                                                                    </Tooltip>
                                                                    <Checkbox checked={minPurchace === 'checked' && true} />
                                                                </Flex>
                                                                {minPurchace === 'checked' && (
                                                                    <Input style={{ marginTop: 16 }} type='number' value={minPurchaseAmt} onChange={(e) => setMinPurchaceAmt(e.target.value)} name="min-amt" placeholder={`${storeDetail?.currency_symbol}  0`} className='add-item-input' />
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : rewardType === 'freeitem' && (
                                                        <div className='reward-type-settings'>
                                                            <Input name="freeitem" value={freeItem} onChange={(e) => setFreeItem(e.target.value)} placeholder='Add Item' className='add-item-input' />
                                                            <div className='max-discount-card'>
                                                                <Flex className='top-row' justify='space-between' gap={16} align='center' onClick={() => setMinPurchace(minPurchace === 'checked' ? '' : 'checked')}>
                                                                    <Tooltip title="Set a minimum amount that your customers must spend to be able to redeem their reward.">
                                                                        <label>Minimum purchase required for redemption</label>
                                                                    </Tooltip>
                                                                    <Checkbox checked={minPurchace === 'checked' && true} />
                                                                </Flex>
                                                                {minPurchace === 'checked' && (
                                                                    <Input style={{ marginTop: 16 }} type='number' value={minPurchaseAmt} onChange={(e) => setMinPurchaceAmt(e.target.value)} name="min-amt" placeholder={`${storeDetail?.currency_symbol}  0`} className='add-item-input' />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Campaign title</Title>
                                                <Input value={campaignTitle} onChange={(e) => setCampaignTitle(e.target.value)} maxLength={120} showCount placeholder='Eg. Events, Social Media, Zomato' />
                                                <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Campaign description / subtitle</Title>
                                                <TextArea value={campaignDesc} onChange={(e) => setCampaignDesc(e.target.value)} rows={4} maxLength={1024} showCount placeholder='Campaign Description / subtitle' />
                                            </Card>
                                        </div>
                                    </Flex>
                                ) : staps === 3 ? (
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Card bordered={false} className='left-div'>
                                                <div>
                                                    <Title level={4} style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 8 }}>Campaign Expires on <Tooltip overlayClassName='cust-segment-tooltip' title={
                                                        <div>
                                                            <Title level={1} style={{ margin: 0 }}><PiLightbulbDuotone size={24} style={{ color: '#F9C23C' }} /></Title>
                                                            <Title level={5} style={{ margin: 0, color: '#FFF' }}>Select the date till which customers can redeem this campaign. It will expire on 11:59 PM of the selected date.</Title>
                                                        </div>
                                                    }><PiInfoDuotone size={20} style={{ position: 'relative', top: 2 }} /></Tooltip></Title>
                                                    <DatePicker style={{ marginTop: 16, width: '100%' }} value={expireDate} onChange={handleDateChange} disabledDate={disablePreviousDates} />
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <div>
                                                    <Title level={4} style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 8 }}>Redemption Per Customer <Tooltip overlayClassName='cust-segment-tooltip' title={
                                                        <div>
                                                            <Title level={1} style={{ margin: 0 }}><PiLightbulbDuotone size={24} style={{ color: '#F9C23C' }} /></Title>
                                                            <Title level={5} style={{ margin: 0, color: '#FFF' }}>Selecting only once will allow the customer to redeem the campaign once till the expiry date. Selecting multiple times will allow the customer to redeem the campaign multiple times till the expiry date.</Title>
                                                        </div>
                                                    }><PiInfoDuotone size={20} style={{ position: 'relative', top: 2 }} /></Tooltip></Title>
                                                    <Flex gap={16} align='center' style={{ marginTop: 16 }}>
                                                        <Button style={{ width: '100%' }} type={redeemTime === 'justonce' ? 'primary' : 'default'} shape='round' onClick={() => setRedeemTime('justonce')}>Only Once</Button>
                                                        <Button style={{ width: '100%' }} type={redeemTime === 'multipletime' ? 'primary' : 'default'} shape='round' onClick={() => setRedeemTime('multipletime')}>Multiple times</Button>
                                                    </Flex>
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <div>
                                                    <Title level={4} style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 8 }}>Terms & conditions</Title>
                                                    <TextArea style={{ marginTop: 16 }} value={termsText} onChange={(e) => setTermsText(e.target.value)} rows={4} placeholder='Enter terms & conditions' />
                                                    <div className={`template-channle-checkbox-div ${termsText.includes('Min purchase required.') && 'active'}`} onClick={() => handleAddTerms('Min purchase required.')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Min purchase required.</b></Typography>
                                                            <Checkbox checked={termsText.includes('Min purchase required.')} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${termsText.includes('2 offers cannot be clubbed.') && 'active'}`} onClick={() => handleAddTerms('2 offers cannot be clubbed.')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>2 offers cannot be clubbed.</b></Typography>
                                                            <Checkbox checked={termsText.includes('2 offers cannot be clubbed.')} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${termsText.includes('Reward cannot be exchanged for cash.') && 'active'}`} onClick={() => handleAddTerms('Reward cannot be exchanged for cash.')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Reward cannot be exchanged for cash.</b></Typography>
                                                            <Checkbox checked={termsText.includes('Reward cannot be exchanged for cash.')} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${termsText.includes('Reward redeemable in-store only.') && 'active'}`} onClick={() => handleAddTerms('Reward redeemable in-store only.')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Reward redeemable in-store only.</b></Typography>
                                                            <Checkbox checked={termsText.includes('Reward redeemable in-store only.')} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${termsText.includes('Merchant reserves the right to the final say in case of any disputes.') && 'active'}`} onClick={() => handleAddTerms('Merchant reserves the right to the final say in case of any disputes.')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Merchant reserves the right to the final say in case of any disputes.</b></Typography>
                                                            <Checkbox checked={termsText.includes('Merchant reserves the right to the final say in case of any disputes.')} />
                                                        </Flex>
                                                    </div>
                                                    <div className={`template-channle-checkbox-div ${termsText.includes('Additional T&C apply.') && 'active'}`} onClick={() => handleAddTerms('Additional T&C apply.')}>
                                                        <Flex justify='space-between' align='center'><Typography><b>Additional T&C apply.</b></Typography>
                                                            <Checkbox checked={termsText.includes('Additional T&C apply.')} />
                                                        </Flex>
                                                    </div>
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <Flex gap={16} justify='space-between' align='center'>
                                                    <Title level={4} style={{ margin: 0 }}>Contact Number</Title>
                                                    <Switch onChange={() => setShowContact(showContact ? false : true)} checked={showContact} />
                                                </Flex>
                                                <Paragraph style={{ fontSize: 12, marginTop: 6 }}>This will be included in your Campaign link, SMS, Whatsapp & Email</Paragraph>
                                                <Input type='number' value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} placeholder='Enter Contact number' className='how-many-input' />
                                            </Card>
                                        </div>
                                    </Flex>
                                ) : staps === 4 ? (
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Card bordered={false} className='left-div'>
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiImageDuotone size={24} />Logo</Title>
                                                <ImgCrop shape="round" aspect={1} quality={1}>
                                                    <Upload {...props} style={{ width: '100%' }} className='qr-upload-logo' accept="image/jpeg, image/png">
                                                        {file ? (
                                                            <Button style={{ width: '100%' }}>Upload another file</Button>
                                                        ) : (
                                                            <Button style={{ width: '100%' }} icon={<UploadOutlined />}>Upload your logo</Button>
                                                        )}

                                                    </Upload>
                                                </ImgCrop>
                                                <Paragraph style={{ color: '#697777', fontSize: 12, marginTop: 8 }}>Recommended size 512 x 512 px in JPG, GIF or PNG format up-to max size of 10MB</Paragraph>
                                            </Card>
                                            <Flex gap={16} style={{ marginTop: 24 }}>
                                                <div className='add-new-qr-input-div'>
                                                    <Flex gap={16} align='center'>
                                                        <ColorPicker value={themeColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setThemeColor(hexColor); }} />
                                                        <Title level={4} style={{ margin: 0 }}>Theme Color</Title>
                                                    </Flex>
                                                </div>
                                                <div className='add-new-qr-input-div'>
                                                    <Flex gap={16} align='center'>
                                                        <ColorPicker value={textColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setTextColor(hexColor); }} />
                                                        <Title level={4} style={{ margin: 0 }}>Text Color</Title>
                                                    </Flex>
                                                </div>
                                            </Flex>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiImageDuotone size={24} />Banner Image</Title>
                                                <Upload.Dragger
                                                    name="banner"
                                                    listType="picture"
                                                    beforeUpload={beforeUpload}
                                                    onChange={handleChangePosterBanner}
                                                    maxCount={1}
                                                    accept="image/jpeg, image/png"
                                                    customRequest={(fileInfo) => customRequest()(fileInfo)}
                                                    action={selectedChannels.whatsapp ? `${window.API_BASE_URL}/templates/upload-media` : `${window.API_BASE_URL}/templates/upload-banner`}
                                                    onRemove={handleRemovePosterBanner}
                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                    <p className="ant-upload-hint">Support for a single upload only.</p>
                                                </Upload.Dragger>
                                                <Paragraph style={{ color: '#697777', fontSize: 12, marginTop: 8 }}>Recommended size 512 x 512 px in JPG, GIF or PNG format up-to max size of 10MB</Paragraph>
                                            </Card>
                                        </div>
                                    </Flex>
                                ) : staps === 5 ? (
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Card bordered={false} className='left-div'>
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiWhatsappLogo size={24} />Template Name</Title>
                                                <Input type='text' value={templateName} placeholder='Enter text for button' onChange={handletemplateNameInputChange} showCount maxLength={512} />
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiWhatsappLogo size={24} />Category</Title>
                                                <Select placeholder="Choose Category" style={{ width: '100%' }} onChange={(value) => setTemplateCategory(value)} value={templateCategory} showSearch>
                                                    <Select.Option key="MARKETING" value="MARKETING">Marketing</Select.Option>
                                                    <Select.Option key="UTILITY" value="UTILITY">Utility</Select.Option>
                                                    <Select.Option key="AUTHENTICATION" value="AUTHENTICATION">Authentication</Select.Option>
                                                </Select>
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiWhatsappLogo size={24} />Language</Title>
                                                <Select placeholder="Select Language" style={{ width: '100%' }} onChange={(value) => setSelectedLanguage(value)} value={selectedLanguage} showSearch>
                                                    {languagesData.map(lang => (
                                                        <Select.Option key={lang.code} value={lang.code}> {lang.name} </Select.Option>
                                                    ))}
                                                </Select>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiWhatsappLogo size={24} />Edit Message</Title>
                                                <TextArea ref={textAreaRef} style={{ marginTop: 16 }} value={whatsappText} onChange={handleTextChange} rows={6} showCount maxLength={1024} allowClear />
                                                <Flex>
                                                    <Tooltip title='Emojis'><Button style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={() => setShowPicker(!showPicker)}><PiSmileyDuotone size={24} /></Button></Tooltip>
                                                    <Tooltip title='Bold'><Button style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={() => handleTextStyle('bold')}><FaBold size={18} /></Button></Tooltip>
                                                    <Tooltip title='Italic'><Button style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={() => handleTextStyle('italic')}><FaItalic size={18} /></Button></Tooltip>
                                                    <Tooltip title='Strikethrough'><Button style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={() => handleTextStyle('strike')}><FaStrikethrough size={18} /></Button></Tooltip>
                                                    <Tooltip title='Monospace'><Button style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={() => handleTextStyle('mono')}><GrMonospace size={24} /></Button></Tooltip>
                                                    <Tooltip title='Add Variable, These make the content of the message personalized for users'><Button style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={handleAddVariable}><Paragraph style={{ margin: 0, fontSize: 18, fontWeight: 'bold' }}>{`{{${(whatsappText.match(/{{\d+}}/g) || []).length || 1}}}`}</Paragraph></Button></Tooltip>
                                                </Flex>
                                                {showPicker && (
                                                    <div ref={pickerRef}>
                                                        <Picker data={data} onEmojiSelect={handleEmojiSelect} onClickOutside={() => setShowPicker(showPicker)} />
                                                    </div>
                                                )}

                                                {whatsappText.match(/{{\d+}}/g)?.map((match, index) => (
                                                    <Input key={index} value={inputValues[index] || ''} onChange={(e) => handleInputChange(index, e.target.value)} placeholder={`Enter variable text for ${match}`} style={{ marginTop: 16 }} />
                                                ))}
                                                <div className='opt-in-checkbox'>
                                                    <Flex justify='space-between' align='center'>
                                                        <Paragraph style={{ margin: 0 }}><b>Opt out message</b></Paragraph>
                                                        <Checkbox checked={optInMessage} />
                                                    </Flex>
                                                    <Paragraph style={{ marginTop: 0 }}>Reply STOP to Unsubscribe from {storeDetail?.store_name}</Paragraph>
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <Flex align='center' justify='space-between'>
                                                    <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}>{iconComponents[currentIconIndex]} Header</Title>
                                                    <Switch onClick={() => setIsHeader(!isHeader)} checked={isHeader} />
                                                </Flex>
                                                <Flex justify='space-evenly'>
                                                    <Flex align='centet' justify='center' className={`headerTypeBtn ${headerType === 'image' && 'active'}`} onClick={() => setHeaderType('image')}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <PiImagesDuotone size={30} />
                                                            <Paragraph style={{ marginBottom: 0 }}><b>Image</b></Paragraph>
                                                        </div>
                                                    </Flex>
                                                    <Flex align='centet' justify='center' className={`headerTypeBtn ${headerType === 'video' && 'active'}`} onClick={() => setHeaderType('video')}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <PiVideoDuotone size={30} />
                                                            <Paragraph style={{ marginBottom: 0 }}><b>Video</b></Paragraph>
                                                        </div>
                                                    </Flex>
                                                    <Flex align='centet' justify='center' className={`headerTypeBtn ${headerType === 'image' && 'document'}`} onClick={() => setHeaderType('document')}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <PiFileDocDuotone size={30} />
                                                            <Paragraph style={{ marginBottom: 0 }}><b>Document</b></Paragraph>
                                                        </div>
                                                    </Flex>
                                                    <Flex align='centet' justify='center' className={`headerTypeBtn ${headerType === 'text' && 'active'}`} onClick={() => setHeaderType('text')}>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <PiTextTDuotone size={30} />
                                                            <Paragraph style={{ marginBottom: 0 }}><b>Text</b></Paragraph>
                                                        </div>
                                                    </Flex>
                                                </Flex>
                                                {headerType === 'image' ? (
                                                    <div style={{ marginTop: 16 }}>
                                                        <Upload.Dragger
                                                            style={{ width: '100%' }}
                                                            name="banner"
                                                            listType="picture"
                                                            beforeUpload={beforeUpload}
                                                            onChange={handleChange}
                                                            maxCount={1}
                                                            accept="image/jpeg, image/png"
                                                            customRequest={(fileInfo) => customRequest()(fileInfo)}
                                                            action={`${window.API_BASE_URL}/templates/upload-media`}
                                                            onRemove={handleRemoveWhatsappBanner}
                                                        >
                                                            <p className="ant-upload-drag-icon">
                                                                <InboxOutlined />
                                                            </p>
                                                            <p className="ant-upload-text">{!whatsappImage ? 'Click or drag file to this area to upload' : 'Click or drag file to this area to upload'}</p>
                                                            <p className="ant-upload-hint">Support for a single upload only.</p>
                                                        </Upload.Dragger>
                                                    </div>
                                                ) : headerType === 'video' ? (
                                                    <div style={{ marginTop: 16 }}>
                                                        <Upload.Dragger
                                                            style={{ width: '100%' }}
                                                            name="banner"
                                                            listType="picture"
                                                            beforeUpload={beforeUploadVideo}
                                                            onChange={handleChange}
                                                            maxCount={1}
                                                            accept="video/mp4, video/3gp"
                                                            customRequest={(fileInfo) => customRequest()(fileInfo)}
                                                            action={`${window.API_BASE_URL}/templates/upload-media`}
                                                            onRemove={handleRemoveWhatsappVideo}
                                                        >
                                                            <p className="ant-upload-drag-icon">
                                                                <InboxOutlined />
                                                            </p>
                                                            <p className="ant-upload-text">{!whatsappImage ? 'Click or drag file to this area to upload' : 'Click or drag file to this area to upload'}</p>
                                                            <p className="ant-upload-hint">Support for a single upload only.</p>
                                                        </Upload.Dragger>
                                                    </div>
                                                ) : headerType === 'document' ? (
                                                    <div style={{ marginTop: 16 }}>
                                                        <Upload.Dragger
                                                            style={{ width: '100%' }}
                                                            name="banner"
                                                            listType="picture"
                                                            beforeUpload={beforeUploadDoc}
                                                            onChange={handleChange}
                                                            maxCount={1}
                                                            accept="text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                            customRequest={(fileInfo) => customRequest()(fileInfo)}
                                                            action={`${window.API_BASE_URL}/templates/upload-media`}
                                                            onRemove={handleRemoveWhatsappDocument}
                                                        >
                                                            <p className="ant-upload-drag-icon">
                                                                <InboxOutlined />
                                                            </p>
                                                            <p className="ant-upload-text">{!whatsappImage ? 'Click or drag file to this area to upload' : 'Click or drag file to this area to upload'}</p>
                                                            <p className="ant-upload-hint">Support for a single upload only.</p>
                                                        </Upload.Dragger>
                                                    </div>
                                                ) : headerType === 'text' && (
                                                    <div>
                                                        <TextArea ref={headerTextRef} value={headerText} onChange={(e) => setHeaderText(e.target.value)} rows={1} placeholder={`Enter text for header`} showCount maxLength={60} allowClear style={{ marginTop: 16 }} />
                                                        <Tooltip title='Add Variable, These make the content of the message personalized for users'><Button style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={handleAddVariableToHeader}><Paragraph style={{ margin: 0, fontSize: 18, fontWeight: 'bold' }}>{`{{${(headerText.match(/{{\d+}}/g) || []).length || 1}}}`}</Paragraph></Button></Tooltip>
                                                        {(headerText.match(/{{\d+}}/g) || []).length !== 0 && (
                                                            <Input value={headerVarValue} onChange={(e) => setHeaderVarValue(e.target.value)} placeholder={`Enter variable text for {{1}}`} style={{ marginTop: 16 }} />
                                                        )}
                                                    </div>
                                                )}
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <Flex align='center' justify='space-between'>
                                                    <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiHandPointingDuotone size={24} /> Buttons</Title>
                                                    <Switch onClick={() => setIsButtons(!isButtons)} checked={isButtons} />
                                                </Flex>
                                                <Flex justify='space-between' align='center' style={{ marginTop: 16, border: '1px solid #343434', borderStyle: 'dashed', borderRadius: 8, padding: 8, backgroundColor: '#ddd' }}>
                                                    <Paragraph style={{ margin: 0 }}>The total number of buttons cannot exceed 10.</Paragraph>
                                                    <Paragraph style={{ margin: 0 }}>{buttonFields.length} / 10</Paragraph>
                                                </Flex>
                                                <DragDropContext onDragEnd={onDragEnd}>
                                                    <Droppable droppableId="droppable-sections">
                                                        {(provided) => (
                                                            <div {...provided.droppableProps} ref={provided.innerRef} className="chackboxdiv">
                                                                {sections.map((section, index) => (
                                                                    <Draggable key={section.id} draggableId={section.id} index={index}>
                                                                        {(provided) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                className={`${section.id}btns`}
                                                                            >
                                                                                {section.id === 'weburl' && (
                                                                                    <div className='urlbtns'>
                                                                                        <HolderOutlined /> <Checkbox
                                                                                            onChange={() => handleChangeButtonCheckbox('weburl')}
                                                                                            checked={buttons.includes('weburl')}
                                                                                        >
                                                                                            Add website url
                                                                                        </Checkbox>
                                                                                        {buttons.includes('weburl') && (
                                                                                            <div className='btnbox'>
                                                                                                {buttonFields.filter(field => field.type === 'weburl').map((field) => (
                                                                                                    <span key={field.id}>
                                                                                                        <Row gutter={[8, 8]}>
                                                                                                            <Col span={6}>
                                                                                                                <Select value={field.urlType || 'static'} style={{ width: '100%' }} placeholder='Choose url Type' onChange={(value) => handleButtonInputChange('weburl', field.id, 'urlType', value)}>
                                                                                                                    <Select.Option value='static'>Static</Select.Option>
                                                                                                                    <Select.Option value='dynamic'>Dynamic</Select.Option>
                                                                                                                </Select>
                                                                                                            </Col>
                                                                                                            <Col span={18}>
                                                                                                                <Input type='text' value={field.text} placeholder='Enter text for button' onChange={(e) => handleButtonInputChange('weburl', field.id, 'text', e.target.value)} showCount maxLength={25} />
                                                                                                            </Col>
                                                                                                            <Col span={24}>
                                                                                                                <Input type='text' value={field.url} placeholder='Enter url' onChange={(e) => handleButtonInputChange('weburl', field.id, 'url', e.target.value)} showCount maxLength={200} />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Button style={{ marginTop: 8, marginBottom: 8 }} danger onClick={() => { buttonFields.filter(field => field.type === 'weburl').length === 1 ? handleChangeButtonCheckbox('weburl') : removeField(field.id) }}>
                                                                                                            <CloseOutlined /> Remove
                                                                                                        </Button>
                                                                                                    </span>
                                                                                                ))}
                                                                                                {buttonFields.length < 10 && buttonFields.filter(field => field.type === 'weburl').length < 3 && (
                                                                                                    <Button style={{ marginLeft: 8, marginTop: 8, marginBottom: 8 }} onClick={() => addField('weburl')}>
                                                                                                        <PlusOutlined /> Add more url buttons
                                                                                                    </Button>
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                {section.id === 'phone' && (
                                                                                    <div className='phonebtns'>
                                                                                        <HolderOutlined /> <Checkbox
                                                                                            onChange={() => handleChangeButtonCheckbox('phone')}
                                                                                            checked={buttons.includes('phone')}
                                                                                        >
                                                                                            Add phone number
                                                                                        </Checkbox>
                                                                                        {buttons.includes('phone') && (
                                                                                            <div className='btnbox'>
                                                                                                {buttonFields.filter(field => field.type === 'phone').map((field) => (
                                                                                                    <span key={field.id}>
                                                                                                        <Row gutter={[8, 8]}>
                                                                                                            <Col span={24}>
                                                                                                                <PhoneInput country={currencyToCountryMap[storeDetail?.currency_code] || 'in'} value={field.phoneNumber} onChange={(value) => handleButtonInputChange('phone', field.id, 'phoneNumber', value)} enableSearch={true} inputStyle={{ width: '100%' }} />
                                                                                                            </Col>
                                                                                                            <Col span={24}>
                                                                                                                <Input type='text' value={field.text} onChange={(e) => handleButtonInputChange('phone', field.id, 'text', e.target.value)} placeholder='Enter Text for Button' showCount maxLength={25} />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Button style={{ marginTop: 8, marginBottom: 8 }} danger onClick={() => { buttonFields.filter(field => field.type === 'phone').length === 1 ? handleChangeButtonCheckbox('phone') : removeField(field.id) }}>
                                                                                                            <CloseOutlined /> Remove
                                                                                                        </Button>
                                                                                                    </span>
                                                                                                ))}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                {section.id === 'quick' && (
                                                                                    <div className='quickreplybtn'>
                                                                                        <HolderOutlined /> <Checkbox
                                                                                            onChange={() => handleChangeButtonCheckbox('quick')}
                                                                                            checked={buttons.includes('quick')}
                                                                                        >
                                                                                            Add Quick Replies
                                                                                        </Checkbox>
                                                                                        {buttons.includes('quick') && (
                                                                                            <div className='btnbox'>
                                                                                                {buttonFields.filter(field => field.type === 'quick').map((field) => (
                                                                                                    <span key={field.id}>
                                                                                                        <Row gutter={[8, 8]}>
                                                                                                            <Col span={24}>
                                                                                                                <Input type='text' value={field.text} onChange={(e) => handleButtonInputChange('quick', field.id, 'text', e.target.value)} placeholder='Enter Text for QuickReply Button' showCount maxLength={25} />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Button style={{ marginTop: 8, marginBottom: 8 }} danger onClick={() => { buttonFields.filter(field => field.type === 'quick').length === 1 ? handleChangeButtonCheckbox('quick') : removeField(field.id) }}>
                                                                                                            <CloseOutlined /> Remove
                                                                                                        </Button>
                                                                                                    </span>
                                                                                                ))}
                                                                                                {buttonFields.length < 10 && buttonFields.filter(field => field.type === 'quick').length < 10 && (
                                                                                                    <Button style={{ marginLeft: 8, marginTop: 8, marginBottom: 8 }} onClick={() => addField('quick')}>
                                                                                                        <PlusOutlined /> Add more quickreply buttons
                                                                                                    </Button>
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </Card>
                                        </div>
                                    </Flex>
                                ) : staps === 6 ? (
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Card bordered={false} className='left-div'>
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiChatCenteredDotsDuotone size={24} />Edit SMS</Title>
                                                <Paragraph style={{ color: '#697777', fontSize: 12 }}>Character length: {smsText.length + 22} / {Math.ceil((smsText.length + 22) / 160)} credit</Paragraph>
                                                <TextArea style={{ marginTop: 16 }} value={smsText} onChange={(e) => setSmsText(e.target.value)} rows={4} />
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiLinkSimpleDuotone size={24} />Campaign link</Title>
                                            </Card>
                                        </div>
                                    </Flex>
                                ) : staps === 7 ? (
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Card bordered={false} className='left-div'>
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiChatCenteredDotsDuotone size={24} />Email Subject</Title>
                                                <Input value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} placeholder='Enter email subject' className='how-many-input' />
                                            </Card>
                                        </div>
                                    </Flex>
                                ) : staps === 8 ? (
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Card bordered={false} className='left-div'>
                                                <Flex gap={8} justify='start' align='center'>
                                                    <PiUsersDuotone size={24} /> <Title level={3} style={{ margin: 0 }}>Who do you want to send to?</Title>
                                                </Flex>
                                                <div className={`template-channle-checkbox-div ${customerType === 'all' && 'active'}`} onClick={() => setCustomerType('all')}>
                                                    <Flex justify='space-between' align='center'><Typography><b>All Customers - {customers?.length}</b></Typography>
                                                        <Radio checked={customerType === 'all'} />
                                                    </Flex>
                                                </div>
                                                <div className={`template-channle-checkbox-div ${customerType === 'targated' && 'active'}`} onClick={() => { setCustomerType('targated'); setTargatedGroupModal(true) }}>
                                                    <Flex justify='space-between' align='center'><Typography><b>Targated Group</b></Typography>
                                                        <Radio checked={customerType === 'targated'} />
                                                    </Flex>
                                                </div>
                                                <div className={`template-channle-checkbox-div ${customerType === 'advanced' && 'active'}`} onClick={() => { setCustomerType('advanced'); setFilterCustomerModal(true) }}>
                                                    <Flex justify='space-between' align='center'><Typography><b>Advanced filter</b></Typography>
                                                        <Radio checked={customerType === 'advanced'} />
                                                    </Flex>
                                                </div>
                                                <div className='campaign-info-div'>
                                                    <Flex>
                                                        <Flex justify='space-between' align='center'>
                                                            <Flex align='center' gap={8}><PiInfoDuotone size={48} color='#00445A' /><Paragraph style={{ margin: 0, color: '#00445A' }}>Don't send this campaign to customers who had inactive numbers.</Paragraph></Flex>
                                                            <Switch className='info-switch' checked={isSendToInactive === 'yes'} onChange={() => setIsSendToInactive(isSendToInactive === 'yes' ? 'no' : 'yes')} />
                                                        </Flex>
                                                    </Flex>
                                                </div>
                                                <div className='campaign-info-div'>
                                                    <Flex>
                                                        <Flex justify='space-between' align='center'>
                                                            <Flex align='center' gap={8}><PiInfoDuotone size={48} color='#00445A' /><Paragraph style={{ margin: 0, color: '#00445A' }}>Email messages will be sent to all customers including imported customers.</Paragraph></Flex>
                                                        </Flex>
                                                    </Flex>
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <Flex gap={8} justify='start' align='center'>
                                                    <PiClockAfternoonDuotone size={24} /> <Title level={3} style={{ margin: 0 }}>When do you want to send?</Title>
                                                </Flex>
                                                <div className={`template-channle-checkbox-div ${whenSend === 'now' && 'active'}`} onClick={() => setWhenSend('now')}>
                                                    <Flex justify='space-between' align='center'><Typography><b>Send Now</b></Typography>
                                                        <Radio checked={whenSend === 'now'} />
                                                    </Flex>
                                                </div>
                                                <div className={`template-channle-checkbox-div ${whenSend === 'later' && 'active'}`} onClick={() => { setWhenSend('later') }}>
                                                    <Flex justify='space-between' align='center'><Typography><b>Send Later</b></Typography>
                                                        <Radio checked={whenSend === 'later'} />
                                                    </Flex>
                                                </div>
                                                {whenSend === 'later' && (
                                                    <DatePicker value={scheduleTime} onChange={(date) => setScheduleTime(date)} style={{ width: '100%' }} showTime autoFocus defaultOpen defaultValue={dayjs()} disabledDate={(current) => current && current < dayjs().startOf('day')} disabledTime={(current) => current && current.isSame(dayjs(), 'day') ? { disabledHours: () => Array.from({ length: dayjs().hour() }, (_, i) => i), disabledMinutes: () => Array.from({ length: dayjs().minute() }, (_, i) => i), disabledSeconds: () => Array.from({ length: dayjs().second() }, (_, i) => i) } : {}} />
                                                )}
                                            </Card>
                                        </div>
                                    </Flex>
                                ) : staps === 9 && (
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Card bordered={false} className='left-div'>
                                                <Flex gap={8} justify='start' align='center'>
                                                    <PiLaptopDuotone size={24} /> <Title level={3} style={{ margin: 0 }}>Test Campaign</Title>
                                                </Flex>
                                                <div style={{ marginTop: 16, marginBottom: 16 }}>
                                                    <PhoneInput value={testPhone} onChange={(value) => setTestPhone(value)} enableSearch={true} inputStyle={{ width: '100%' }} />
                                                </div>
                                                <div style={{ marginTop: 16, marginBottom: 16 }}>
                                                    <Input type='email' value={testMail} onChange={(e) => setTestMail(e.target.value)} />
                                                </div>
                                                <div style={{ marginTop: 16, marginBottom: 16 }}>
                                                    <Button loading={loading} onClick={downloadPosterAsImage}>Test</Button>
                                                </div>
                                                <Paragraph style={{ margin: 0, fontSize: 10, color: '#aaa' }}>This is only for testing purposes. Data received from this link will not be saved</Paragraph>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <Flex gap={8} justify='space-between' align='start'>
                                                    <div>
                                                        <Paragraph style={{ margin: 0, color: '#182020' }}>Campaign Reward</Paragraph>
                                                        <Title level={3} style={{ margin: 0 }}>{rewardType === 'percentage' ? `${preDisc}% discount` : rewardType === 'rupees' ? `${storeDetail?.currency_symbol} ${cashDisc} discount` : rewardType === 'freeitem' ? `Free ${freeItem}` : 'No discount'}</Title>
                                                    </div>
                                                    <span className='editcampbtn' onClick={() => setStaps(2)}><PiPencilDuotone size={24} /></span>
                                                </Flex>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <Flex gap={8} justify='space-between' align='start'>
                                                    <div>
                                                        <Paragraph style={{ margin: 0, color: '#182020' }}>Campaign expiry</Paragraph>
                                                        <Title level={3} style={{ margin: 0 }}>{expireDate && expireDate.format('DD MMM YYYY')}</Title>
                                                        <br />
                                                        <Paragraph style={{ margin: 0, color: '#182020' }}>Redeemable</Paragraph>
                                                        <Title level={3} style={{ margin: 0 }}>{redeemTime === 'jsutonce' ? 'Only once' : 'Multiple time'}</Title>
                                                    </div>
                                                    <span className='editcampbtn' onClick={() => setStaps(2)}><PiPencilDuotone size={24} /></span>
                                                </Flex>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <Flex gap={8} justify='space-between' align='start'>
                                                    <div>
                                                        <Paragraph style={{ margin: 0, color: '#182020' }}>Audience</Paragraph>
                                                        <Title level={3} style={{ margin: 0 }}>{customerType === 'all' ? 'All customers' : customerType === 'targated' ? 'Targated Groups' : 'Advanced filter'}</Title>
                                                    </div>
                                                    <span className='editcampbtn' onClick={() => setStaps(2)}><PiPencilDuotone size={24} /></span>
                                                </Flex>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <Flex gap={8} justify='space-between' align='start'>
                                                    <div>
                                                        <Paragraph style={{ margin: 0, color: '#182020' }}>Channels</Paragraph>
                                                        <Title level={3} style={{ margin: 0 }}>{`${selectedChannels.sms ? 'SMS' : ''} ${selectedChannels.whatsapp ? 'Whatsapp' : ''} ${selectedChannels.email ? 'Email' : ''}`}</Title>
                                                    </div>
                                                    <span className='editcampbtn' onClick={() => setStaps(2)}><PiPencilDuotone size={24} /></span>
                                                </Flex>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <Flex gap={8} justify='space-between' align='start'>
                                                    <div>
                                                        <Paragraph style={{ margin: 0, color: '#182020' }}>Scheduled time</Paragraph>
                                                        <Title level={3} style={{ margin: 0 }}>{whenSend === 'now' ? 'Send Now' : scheduleTime && scheduleTime.format('DD MMM, YYYY h:mm:ss A')}</Title>
                                                    </div>
                                                    <span className='editcampbtn' onClick={() => setStaps(2)}><PiPencilDuotone size={24} /></span>
                                                </Flex>
                                            </Card>
                                        </div>
                                    </Flex>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Drawer>
            </Layout>
        </>
    );
};

export default CampaignContent;