import React, { useState, useEffect, useCallback } from "react";
import { SocketProvider, useSocket } from "./SocketContext";
import { isMobileOnly } from "react-device-detect";
import { messaging, getToken, onMessage } from "./firebase";
import {
  useLocation,
  useNavigate,
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {
  ConfigProvider,
  Layout,
  theme,
  message,
  Spin,
  notification,
  Modal,
  Typography,
  Input,
  DatePicker,
  Button,
  Form,
  Select,
  Flex,
} from "antd";
import FooterContent from "./include/Footer";
import HeaderContent from "./include/TopBar";
import SidebarContent from "./include/SideBar";
import Home from "./pages/Home";
import CampaignContent from "./pages/Campaign";
import CustomerContent from "./pages/Customers";
import AddCustomersContent from "./pages/AddCustomers";
import AutoCampaignContent from "./pages/AutoCampaign";
import FeedbackContent from "./pages/Feedback";
import LoyaltyContent from "./pages/Loyalty";
import QrCodeContent from "./pages/QrCodes";
import Account from "./pages/Account";
import Stores from "./pages/Stores";
import Plans from "./pages/Plans";
import Team from "./pages/Team";
import Channels from "./pages/Channels";
import ChannelSettings from "./pages/ChannelSettings";
import LoginForm from "./pages/Login";
import RegisterForm from "./pages/Register";
import PaymentStatus from "./pages/paymentStatus";
import RefillHistory from "./pages/RefillHistory";
import Onbording from "./pages/Onbording";
import Chats from "./pages/Chatbox";
import { LoadingOutlined } from "@ant-design/icons";
import WhatsAppSignup from "./pages/WhatsappSignup";
import PhonePage from "./pages/Phone";
import Automation from "./pages/Automation";
import WhatsappSettings from "./pages/WhatsappSettings";
import CustomerSegmentSettings from "./pages/SegmantationSetting";
import Billing from "./pages/Billing";
import dayjs from "dayjs";
import Orders from "./pages/Orders";
import { HiOutlineArrowRightOnRectangle } from "react-icons/hi2";
const { Title, Paragraph } = Typography;

const { Content } = Layout;

const formatDateFromTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

const App = () => {
  const location = useLocation();
  const isPaymentStatusPage = location.pathname.includes("/payment-status");
  const [expLoading, setExpLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const loginToken = localStorage.getItem("loginToken");
  const [storeId, setStoreId] = useState(localStorage.getItem("storeId"));
  const [userData, setUserData] = useState({});
  const [whatsappData, setWhatsappData] = useState({});
  const [sessionData, setSessionData] = useState({});
  const [loading, setLoading] = useState(true);
  const [storeData, setStoreData] = useState([]);
  const [settings, setSettings] = useState({});
  const [socketData, setSocketData] = useState(null);
  const [supportModal, setSupportModal] = useState(false);
  const navigate = useNavigate();
  const { socket } = useSocket();
  const [form] = Form.useForm();
  const [api, notificationContextHolder] = notification.useNotification();

  useEffect(() => {
    const registerServiceWorker = async () => {
      if ("serviceWorker" in navigator) {
        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration) {
          const newRegistration = await navigator.serviceWorker.register(
            "/firebase-messaging-sw.js"
          );
          console.log(
            "Service Worker registered with scope:",
            newRegistration.scope
          );
        } else {
          console.log("Service Worker already registered:", registration.scope);
        }
      }
    };

    registerServiceWorker();
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      message.success(`New message: ${payload.notification.title}`);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("message", (data) => {
        if (data?.message?.is_message === "from") {
          if (socketData?.phone_number_id === userData?.phonenumberid) {
            api.info({
              message: `${data?.message?.whatsappname} (+${data?.message?.wanumber})`,
              description: `${data?.message?.message}`,
              placement: "bottomRight",
            });
          }
        }
        setSocketData(data.message);
      });
    }
    return () => {
      if (socket) {
        socket.off("some-event");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const keyFromUrl =
      queryParams.get("key") || location.pathname.split("/")[1];
    if (keyFromUrl) {
      setSelectedKey(keyFromUrl);
    } else {
      setSelectedKey("home");
    }
  }, [location]);

  const fetchUserData = useCallback(async () => {
    const url = `${window.API_BASE_URL}/users`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginToken}`,
        },
      });
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        setUserData(data.user);
        form.setFieldsValue({
          name: data?.user?.user_name,
          email: data?.user?.user_email,
          phone: data?.user?.user_phone,
        });
        setWhatsappData(data.whatsappData);
        setSessionData(data.sessionData);
        setStoreData(data.storeData);
        setSettings(data.settings);
        const storeId = localStorage.getItem("storeId");
        console.log(storeId);
        if (!storeId) {
          if (data?.storeData.length > 0) {
            localStorage.setItem("storeId", data?.storeData?.[0]?.store_id);
            setStoreId(data?.storeData?.[0]?.store_id);
          }
        }
        setLoading(false);
      } else {
        if (data.message === "Invalid token") {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("deviceId");
          localStorage.removeItem("storeId");
        }
        console.error("Error fetching user data:", data.message);
      }
    } catch (error) {
      console.error("Error during fetching user data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginToken]);

  const requestNotificationPermission = useCallback(async () => {
    console.log("requestNotificationPermission");
    try {
      const token = await getToken(messaging, {
        vapidKey:
          "BMNF8-TUvH08jnu_vehrEwHAwarJ0ZE1ex28D0WR26TMtllspfod5uiEGiQBBZ_Z4yRISKaYBUAqdQIj7evARTA",
      });
      if (token) {
        console.log("FCM Token:", token);
        if (localStorage.getItem("token") !== token) {
          if (loginToken) {
            const updateNotificationToken = async () => {
              const response = await fetch(
                `${window.API_BASE_URL}/users/update-notification-token`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${loginToken}`,
                  },
                  body: JSON.stringify({ notificationToken: token }),
                }
              );

              if (response.ok) {
                localStorage.setItem("token", token);
                console.log("Notification token updated successfully.");
              } else {
                console.error("Error updating notification token.");
              }
            };

            updateNotificationToken();
          }
        }
      } else {
        console.log("No registration token available.");
      }
    } catch (error) {
      console.error("Error getting FCM token:", error);
    }
  }, [loginToken]);

  useEffect(() => {
    if (loginToken) {
      fetchUserData();
      requestNotificationPermission();
    } else {
      setLoading(false);
    }
  }, [fetchUserData, requestNotificationPermission, loginToken]);

  const handleMenuClick = ({ key }) => {
    navigate(key === "home" ? "/" : `/${key}`);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleSubmitSupport = async () => {
    setExpLoading(true);
    try {
      const values = await form.validateFields();
      const formattedTime = dayjs(values.timedate).format("DD-MM-YYYY hh:mm A");
      const response = await fetch(
        `${window.API_BASE_URL}/auth/send-support-mail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: values.name,
            email: values.email,
            phone: values.phone,
            time: formattedTime,
            reason: values.reason,
            information: values.additionalInfo || ''
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      console.log("Success:", result);
      setSupportModal(false);
      Modal.success({
        content: "Thank you, We will get back to you.",
        style: {
          top: "50%",
          transform: "translateY(-50%)",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setExpLoading(false);
    }
  };

  const handleSubmit = async () => {
    setExpLoading(true);
    try {
      const values = await form.validateFields();
      const formattedTime = dayjs(values.timedate).format("DD-MM-YYYY hh:mm A");
      const response = await fetch(
        `${window.API_BASE_URL}/auth/send-renew-mail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: values.name,
            email: values.email,
            phone: values.phone,
            time: formattedTime,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      console.log("Success:", result);
      Modal.success({
        content: "Thank you, We will get back to you.",
        style: {
          top: "50%",
          transform: "translateY(-50%)",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setExpLoading(false);
    }
  };
  const key = 'updatable';
  const handleLogout = async () => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Loading...',
    });
    const url = `${window.API_BASE_URL}/auth/logout`;
    const token = localStorage.getItem('loginToken');
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log(response);
      if (response.ok) {
        setTimeout(() => {
          messageApi.open({
            key,
            type: 'success',
            content: 'Logout Successful!',
            duration: 2,
          });
          setTimeout(() => {
            localStorage.removeItem('loginToken');
            localStorage.removeItem('deviceId');
            localStorage.removeItem('storeId');
            window.location.reload();
          }, 300);
        }, 1000);
      } else {
        const data = await response.json();
        console.error('Logout failed:', data.message);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <>
      {notificationContextHolder}
      {loading ? (
        <div className="main-loading-div">
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 48,
                }}
                spin
              />
            }
          />
        </div>
      ) : (
        <>
          {contextHolder}
          {!loginToken ? (
            <Routes>
              <Route path="/login" element={<LoginForm />} />
              <Route path="/register" element={<RegisterForm />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          ) : !isMobileOnly ? (
            !storeData.length > 0 ? (
              <Routes>
                <Route
                  path="/onboarding/:step?"
                  element={
                    <Onbording
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                      messageApi={messageApi}
                    />
                  }
                />
                <Route path="*" element={<Navigate to="/onboarding" />} />
              </Routes>
            ) : userData?.start_date === "" ? (
              <Layout>
                <SidebarContent
                  collapsed={collapsed}
                  selectedKey={selectedKey}
                  handleMenuClick={handleMenuClick}
                  userData={userData}
                  whatsappData={whatsappData}
                />
                <Layout
                  style={{
                    marginInlineStart: collapsed ? 80 : 200,
                    transition: "margin-inline-start 0.3s ease",
                  }}
                >
                  <HeaderContent
                    colorBgContainer={colorBgContainer}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    messageApi={messageApi}
                    userData={userData}
                    whatsappData={whatsappData}
                    storeData={storeData}
                    storeId={storeId}
                    setStoreId={setStoreId}
                    setSupportModal={setSupportModal}
                    supportModal={supportModal}
                  />
                  <Content
                    style={{
                      margin: "24px 18px",
                      padding: 12,
                      height: "calc( 100vh - 112px )",
                      overflow: "hidden",
                      overflowY: "auto",
                      background: colorBgContainer,
                      borderRadius: borderRadiusLG,
                      scrollbarWidth: "thin",
                    }}
                  >
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Home
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                    <FooterContent />
                  </Content>
                </Layout>
                <Modal
                  centered
                  open={true}
                  closable={false}
                  footer=""
                  className="newpopupexpire"
                >
                  <Title level={4} style={{ color: "#FFF", marginTop: 0 }}>
                    Plan Expired
                  </Title>
                  <Paragraph style={{ color: "#FFF", marginTop: 0 }}>
                    Your subscription plan has expired. Please renew to continue
                    using our services.
                  </Paragraph>
                  <Form form={form} layout="vertical" style={{ marginTop: 16 }}>
                    <Form.Item
                      label={<span style={{ color: "#FFF" }}>Name</span>}
                      name="name"
                      rules={[
                        { required: true, message: "Please enter your name!" },
                      ]}
                    >
                      <Input placeholder="Enter your name" />
                    </Form.Item>

                    <Form.Item
                      label={<span style={{ color: "#FFF" }}>Email</span>}
                      name="email"
                      rules={[
                        { required: true, message: "Please enter your email!" },
                        {
                          type: "email",
                          message: "Please enter a valid email!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter your email" />
                    </Form.Item>

                    <Form.Item
                      label={
                        <span style={{ color: "#FFF" }}>Phone Number</span>
                      }
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number!",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Please enter a valid phone number!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter your phone number" />
                    </Form.Item>

                    <Form.Item
                      label={
                        <span style={{ color: "#FFF" }}>
                          Preferred Contact Time
                        </span>
                      }
                      name="timedate"
                      rules={[
                        {
                          required: true,
                          message: "Please select a date and time!",
                        },
                      ]}
                    >
                      <DatePicker showTime style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item>
                      <Flex align="center" justify="space-between" gap={16}>
                      <Button
                        type="primary"
                        block
                        onClick={handleSubmit}
                        loading={expLoading}
                      >
                        Submit request
                      </Button>
                      <Button onClick={handleLogout} danger icon={<HiOutlineArrowRightOnRectangle />} block>Logout</Button>
                      </Flex>
                    </Form.Item>
                  </Form>
                </Modal>
              </Layout>
            ) : userData?.end_date <= Math.floor(Date.now() / 1000) ? (
              <Layout>
                <SidebarContent
                  collapsed={collapsed}
                  selectedKey={selectedKey}
                  handleMenuClick={handleMenuClick}
                  userData={userData}
                  whatsappData={whatsappData}
                />
                <Layout
                  style={{
                    marginInlineStart: collapsed ? 80 : 200,
                    transition: "margin-inline-start 0.3s ease",
                  }}
                >
                  <HeaderContent
                    colorBgContainer={colorBgContainer}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    messageApi={messageApi}
                    userData={userData}
                    whatsappData={whatsappData}
                    storeData={storeData}
                    storeId={storeId}
                    setStoreId={setStoreId}
                    setSupportModal={setSupportModal}
                    supportModal={supportModal}
                  />
                  <Content
                    style={{
                      margin: "24px 18px",
                      padding: 12,
                      height: "calc( 100vh - 112px )",
                      overflow: "hidden",
                      overflowY: "auto",
                      background: colorBgContainer,
                      borderRadius: borderRadiusLG,
                      scrollbarWidth: "thin",
                    }}
                  >
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Home
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                    <FooterContent />
                  </Content>
                </Layout>
                <Modal
                  centered
                  open={true}
                  closable={false}
                  footer=""
                  className="newpopupexpire"
                >
                  <Title level={4} style={{ color: "#FFF", marginTop: 0 }}>
                    Plan Expired
                  </Title>
                  <Paragraph style={{ color: "#FFF", marginTop: 0 }}>
                    Your subscription plan has expired. Please renew to continue
                    using our services.
                  </Paragraph>
                  <Form form={form} layout="vertical" style={{ marginTop: 16 }}>
                    <Form.Item
                      label={<span style={{ color: "#FFF" }}>Name</span>}
                      name="name"
                      rules={[
                        { required: true, message: "Please enter your name!" },
                      ]}
                    >
                      <Input placeholder="Enter your name" />
                    </Form.Item>

                    <Form.Item
                      label={<span style={{ color: "#FFF" }}>Email</span>}
                      name="email"
                      rules={[
                        { required: true, message: "Please enter your email!" },
                        {
                          type: "email",
                          message: "Please enter a valid email!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter your email" />
                    </Form.Item>

                    <Form.Item
                      label={
                        <span style={{ color: "#FFF" }}>Phone Number</span>
                      }
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number!",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Please enter a valid phone number!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter your phone number" />
                    </Form.Item>

                    <Form.Item
                      label={
                        <span style={{ color: "#FFF" }}>
                          Preferred Contact Time
                        </span>
                      }
                      name="timedate"
                      rules={[
                        {
                          required: true,
                          message: "Please select a date and time!",
                        },
                      ]}
                    >
                      <DatePicker showTime style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item>
                      <Flex align="center" justify="space-between" gap={16}>
                      <Button
                        type="primary"
                        block
                        onClick={handleSubmit}
                        loading={expLoading}
                      >
                        Submit request
                      </Button>
                      <Button onClick={handleLogout} danger icon={<HiOutlineArrowRightOnRectangle />} block>Logout</Button>
                      </Flex>
                    </Form.Item>
                  </Form>
                </Modal>
              </Layout>
            ) : !isPaymentStatusPage ? (
              <Layout>
                <SidebarContent
                  collapsed={collapsed}
                  selectedKey={selectedKey}
                  handleMenuClick={handleMenuClick}
                  userData={userData}
                  whatsappData={whatsappData}
                />
                <Layout
                  style={{
                    marginInlineStart: collapsed ? 80 : 200,
                    transition: "margin-inline-start 0.3s ease",
                  }}
                >
                  <HeaderContent
                    colorBgContainer={colorBgContainer}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    messageApi={messageApi}
                    userData={userData}
                    whatsappData={whatsappData}
                    storeData={storeData}
                    storeId={storeId}
                    setStoreId={setStoreId}
                    setSupportModal={setSupportModal}
                    supportModal={supportModal}
                  />
                  <Content
                    style={{
                      margin: "24px 18px",
                      padding: 12,
                      height: "calc( 100vh - 112px )",
                      overflow: "hidden",
                      overflowY: "auto",
                      background: colorBgContainer,
                      borderRadius: borderRadiusLG,
                      scrollbarWidth: "thin",
                    }}
                  >
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Home
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route
                        path="/campaigns/:tabType?"
                        element={
                          <CampaignContent
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                            socketData={socketData}
                          />
                        }
                      />
                      <Route
                        path="/whatsapp-settings"
                        element={
                          <WhatsappSettings
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                            socketData={socketData}
                          />
                        }
                      />
                      <Route
                        path="/customer-insights/:segmentType?"
                        element={
                          <CustomerContent
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route
                        path="/loyalty"
                        element={
                          <LoyaltyContent
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route
                        path="/auto-campaigns/:segmentType?"
                        element={
                          <AutoCampaignContent
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                            socketData={socketData}
                          />
                        }
                      />
                      <Route
                        path="/feedback/:tabType?"
                        element={
                          <FeedbackContent
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route
                        path="/whatsapp-signup"
                        element={
                          <WhatsAppSignup
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                            settings={settings}
                          />
                        }
                      />
                      <Route
                        path="/qr-code/:segmentType?"
                        element={
                          <QrCodeContent
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route
                        path="/add-customers/:contentType?"
                        element={
                          <AddCustomersContent
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route
                        path="/account"
                        element={
                          <Account
                            userData={userData}
                            whatsappData={whatsappData}
                            setUserData={setUserData}
                            sessionData={sessionData}
                          />
                        }
                      />
                      <Route
                        path="/stores"
                        element={
                          <Stores
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                          />
                        }
                      />
                      <Route
                        path="/plans"
                        element={
                          <Plans
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                          />
                        }
                      />
                      <Route
                        path="invite-team/"
                        element={
                          <Team
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                          />
                        }
                      />
                      <Route
                        path="/channels"
                        element={
                          <Channels
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route
                        path="/automation/:segmentType?/:subsegmentType?"
                        element={
                          <Automation
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route
                        path="/whatsapp/:chatId?"
                        element={
                          <Chats
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                            socketData={socketData}
                          />
                        }
                      />
                      <Route
                        path="/channel-settings/:channelType?"
                        element={
                          <ChannelSettings
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                          />
                        }
                      />
                      <Route
                        path="/orders/:customerId?"
                        element={
                          <Orders
                            userData={userData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route
                        path="/payment-status/:txnId?"
                        element={
                          <PaymentStatus
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                          />
                        }
                      />
                      <Route
                        path="/refill-history"
                        element={
                          <RefillHistory
                            userData={userData}
                            whatsappData={whatsappData}
                            sessionData={sessionData}
                          />
                        }
                      />
                      <Route
                        path="/billing"
                        element={
                          <Billing
                            userData={userData}
                            sessionData={sessionData}
                            storeData={storeData}
                          />
                        }
                      />
                      <Route
                        path="/segment-setting"
                        element={<CustomerSegmentSettings />}
                      />
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                    {!location.pathname.includes("/whatsapp") && (
                      <FooterContent />
                    )}
                  </Content>
                </Layout>
              </Layout>
            ) : (
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                      storeData={storeData}
                    />
                  }
                />
                <Route
                  path="/campaigns/:tabType?"
                  element={
                    <CampaignContent
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="/whatsapp-settings"
                  element={
                    <WhatsappSettings
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                      storeData={storeData}
                      socketData={socketData}
                    />
                  }
                />
                <Route
                  path="/customer-insights/:segmentType?"
                  element={
                    <CustomerContent
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="/loyalty"
                  element={
                    <LoyaltyContent
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                      storeData={storeData}
                    />
                  }
                />
                <Route
                  path="/auto-campaigns/:segmentType?"
                  element={
                    <AutoCampaignContent
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                      storeData={storeData}
                      socketData={socketData}
                    />
                  }
                />
                <Route
                  path="/feedback/:tabType?"
                  element={
                    <FeedbackContent
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                      storeData={storeData}
                    />
                  }
                />
                <Route
                  path="/qr-code"
                  element={
                    <QrCodeContent
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="/whatsapp-signup"
                  element={
                    <WhatsAppSignup
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                      storeData={storeData}
                      settings={settings}
                    />
                  }
                />
                <Route
                  path="/add-customers/:contentType?"
                  element={
                    <AddCustomersContent
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="/account"
                  element={
                    <Account
                      userData={userData}
                      whatsappData={whatsappData}
                      setUserData={setUserData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="/stores"
                  element={
                    <Stores
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="/plans"
                  element={
                    <Plans
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="invite-team"
                  element={
                    <Team
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="/channels"
                  element={
                    <Channels
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                      storeData={storeData}
                    />
                  }
                />
                <Route
                  path="/automation/:segmentType?/:subsegmentType?"
                  element={
                    <Automation
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                      storeData={storeData}
                    />
                  }
                />
                <Route
                  path="/whatsapp/:chatId?"
                  element={
                    <Chats
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                      socketData={socketData}
                    />
                  }
                />
                <Route
                  path="/orders/:customerId?"
                  element={
                    <Orders
                      userData={userData}
                      sessionData={sessionData}
                      storeData={storeData}
                    />
                  }
                />
                <Route
                  path="/channel-settings/:channelType?"
                  element={
                    <ChannelSettings
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="/payment-status/:txnId?"
                  element={
                    <PaymentStatus
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="/refill-history"
                  element={
                    <RefillHistory
                      userData={userData}
                      whatsappData={whatsappData}
                      sessionData={sessionData}
                    />
                  }
                />
                <Route
                  path="/billing"
                  element={
                    <Billing
                      userData={userData}
                      sessionData={sessionData}
                      storeData={storeData}
                    />
                  }
                />
                <Route
                  path="/segment-setting"
                  element={<CustomerSegmentSettings />}
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            )
          ) : (
            <Routes>
              <Route
                path="/phone"
                element={
                  <PhonePage
                    userData={userData}
                    whatsappData={whatsappData}
                    sessionData={sessionData}
                    messageApi={messageApi}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/phone" />} />
            </Routes>
          )}
        </>
      )}
      <Modal
        centered
        open={supportModal}
        closable={true}
        onClose={() => setSupportModal(false)}
        onCancel={() => setSupportModal(false)}
        footer=""
        className="newpopupexpire"
      >
        <Title level={4} style={{ color: "#FFF", marginTop: 0 }}>
          Contact Support
        </Title>
        <Paragraph style={{ color: "#FFF", marginTop: 0 }}>
          Reach out to us for guidance, troubleshooting, or any other assistance
          you need.
        </Paragraph>
        <Form form={form} layout="vertical" style={{ marginTop: 16 }}>
          <Form.Item
            label={<span style={{ color: "#FFF" }}>Name</span>}
            name="name"
            rules={[{ required: true, message: "Please enter your name!" }]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>

          <Form.Item
            label={<span style={{ color: "#FFF" }}>Email</span>}
            name="email"
            rules={[
              { required: true, message: "Please enter your email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>

          <Form.Item
            label={<span style={{ color: "#FFF" }}>Phone Number</span>}
            name="phone"
            rules={[
              { required: true, message: "Please enter your phone number!" },
              {
                pattern: /^\d+$/,
                message: "Please enter a valid phone number!",
              },
            ]}
          >
            <Input placeholder="Enter your phone number" />
          </Form.Item>

          <Form.Item
            label={
              <span style={{ color: "#FFF" }}>Preferred Contact Time</span>
            }
            name="timedate"
            rules={[
              { required: true, message: "Please select a date and time!" },
            ]}
          >
            <DatePicker showTime style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label={<span style={{ color: "#FFF" }}>Reason</span>}
            name="reason"
            rules={[{ required: true, message: "Please select a reason!" }]}
          >
            <Select placeholder="Select a reason">
              <Select.Option value="Onboarding and payment issues">
                Onboarding and payment issues
              </Select.Option>
              <Select.Option value="Technical support">
              Technical support
              </Select.Option>
              <Select.Option value="Billing and renewal support">
                Billing and renewal support
              </Select.Option>
              <Select.Option value="Custom integration request">
                Custom integration request
              </Select.Option>
              <Select.Option value="Others">Others</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <span style={{ color: "#FFF" }}>Additional Information</span>
            }
            name="additionalInfo"
          >
            <Input.TextArea
              placeholder="Enter any additional information (optional)"
              rows={4}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              block
              onClick={handleSubmitSupport}
              loading={expLoading}
            >
              Submit request
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

function Connectby() {
  return (
    <SocketProvider>
      <ConfigProvider
        direction="ltr"
        theme={{ token: { colorPrimary: "#3A1A55" } }}
      >
        <Router>
          <App />
        </Router>
      </ConfigProvider>
    </SocketProvider>
  );
}

export default Connectby;
