import React, { useState, useEffect, useCallback } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Flex,
  Button,
  Card,
  Drawer,
  Form,
  Input,
  Select,
  Upload,
  Switch,
  message,
  Image,
  Modal,
  Skeleton,
  Result,
  Tooltip,
} from "antd";
import {
  PiArrowArcRightDuotone,
  PiArrowLeftDuotone,
  PiCopyDuotone,
  PiLaptopDuotone,
  PiPencilLineDuotone,
  PiPlus,
  PiStorefrontDuotone,
  PiXCircleDuotone,
} from "react-icons/pi";
import ImgCrop from "antd-img-crop";
import { PlusOutlined } from "@ant-design/icons";
import { CountrySelect } from "react-country-state-city";
import CurrencyList from "currency-list";
// import { useNavigate } from 'react-router-dom';

const { Link, Paragraph, Title } = Typography;
const { Option } = Select;

const Stores = ({ userData, sessionData }) => {
  const [storeDetailDrawerVisible, setStoreDetailDrawerVisible] =
    useState(false);
  const currencyList = CurrencyList.getAll("en_US");
  const [storeAddDrawerVisible, setStoreAddDrawerVisible] = useState(false);
  const [storeEditDrawerVisible, setStoreEditDrawerVisible] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [editingStore, setEditingStore] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [pos, setPos] = useState("");
  const [industry, setIndustry] = useState("");
  const [category, setCategoty] = useState("");
  const [loading, setLoading] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [storeDetail, setStoreDetail] = useState({});
  const [activeStatus, setActiveStatus] = useState("active");
  const [posData, setPosData] = useState([]);
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const [country, setCountry] = useState(null);
  const [currency, setCurrency] = useState(null);

  // const navigate = useNavigate();

  const fetchStoreData = useCallback(async () => {
    const url = `${window.API_BASE_URL}/stores/`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionData?.token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setStoreData(data?.store);
      } else {
        if (data.message === "Invalid token") {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("deviceId");
          localStorage.removeItem("storeId");
          // navigate('/login');
        }
        console.error("Error fetching user data:", data.message);
      }
    } catch (error) {
      console.error("Error during fetching store data:", error);
    } finally {
      setDataLoading(false);
    }
  }, [sessionData?.token]);

  const fetchStoreDrops = useCallback(async () => {
    const url = `${window.API_BASE_URL}/users/storedrops`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionData?.token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setLoading(false);
        setIndustryData(data?.industries);
        setCategoryData(data?.categories);
        setPosData(data?.pos);
      } else {
        if (data.message === "Invalid token") {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("deviceId");
          localStorage.removeItem("storeId");
          // navigate('/login');
        }
        console.error("Error fetching user data:", data.message);
      }
    } catch (error) {
      console.error("Error during fetching store drops:", error);
    }
  }, [sessionData?.token]);

  useEffect(() => {
    const fetchData = async () => {
      if (sessionData) {
        await Promise.all([fetchStoreDrops(), fetchStoreData()]);
      } else {
        setDataLoading(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchStoreDrops, fetchStoreData, sessionData]);

  useEffect(() => {
    if (storeEditDrawerVisible && editingStore) {
      editform.setFieldsValue(editingStore);
    }
  }, [storeEditDrawerVisible, editingStore, editform]);

  const handlePreview = (file) => {
    setPreviewImage(file.thumbUrl || file.url);
    setPreviewVisible(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const showStoreDetailDrawer = (store) => {
    setStoreDetail(store);
    setStoreDetailDrawerVisible(true);
  };

  const closeStoreDetailDrawer = () => {
    setStoreDetailDrawerVisible(false);
  };

  const showStoreAddDrawer = () => {
    setStoreAddDrawerVisible(true);
  };

  const closeStoreAddDrawer = () => {
    form.resetFields();
    setActiveStatus("active");
    setFileList([]);
    setPreviewImage("");
    setPos("");
    setIndustry("");
    setCategoty("");
    setCountry(null);
    setCurrency(null);
    setStoreAddDrawerVisible(false);
  };

  const showStoreEditDrawer = (store) => {
    const updatedStore = { ...store };
    if (
      parseInt(updatedStore?.pos_id) === 0 ||
      updatedStore?.pos_id === "notusing" ||
      updatedStore?.pos_id === "other"
    ) {
      if (typeof updatedStore?.pos === "undefined") {
        updatedStore.pos_id = "notusing";
        updatedStore.pos = "";
      } else if (updatedStore?.pos === "undefined") {
        updatedStore.pos_id = "notusing";
        updatedStore.pos = "";
      } else {
        updatedStore.pos_id = "other";
        setPos("other");
      }
    } else {
      updatedStore.pos_id = parseInt(updatedStore?.pos_id);
    }
    if (
      parseInt(updatedStore?.industry_id) === 0 ||
      updatedStore?.industry_id === "other"
    ) {
      updatedStore.industry_id = "other";
      setIndustry("other");
    } else {
      updatedStore.industry_id = parseInt(updatedStore?.industry_id);
    }
    if (
      parseInt(updatedStore?.category_id) === 0 ||
      updatedStore?.category_id === "other"
    ) {
      updatedStore.category_id = "other";
      setCategoty("other");
    } else {
      updatedStore.category_id = parseInt(updatedStore?.category_id);
    }
    if (store?.store_logo) {
      if (store?.store_logo) {
        setPreviewImage(`${window.BASE_URL}${store?.store_logo}`);
        const storeLogoUrl = `${window.BASE_URL}${store?.store_logo}`;
        const fileName = storeLogoUrl
          ? new URL(storeLogoUrl).pathname.split("/").pop()
          : "";
        setFileList([
          {
            uid: "-1",
            name: fileName,
            status: "done",
            url: `${window.BASE_URL}${store?.store_logo}`,
          },
        ]);
      }
    }
    console.log(store?.country);
    setCountry(store?.country);
    setCurrency(store?.currency_code);
    editform.setFieldsValue({
        country: store?.country,
        currency: store?.currency_code,
    });
    
    setEditingStore(updatedStore);
    setActiveStatus(store?.status);
    setStoreDetailDrawerVisible(false);
    setStoreEditDrawerVisible(true);
  };

  const closeStoreEditDrawer = () => {
    setStoreEditDrawerVisible(false);
    setEditingStore(null);
    editform.resetFields();
    setActiveStatus("active");
    setFileList([]);
    setPreviewImage("");
    setPos("");
    setIndustry("");
    setCategoty("");
    setCountry(null);
    setCurrency(null);
  };

  const closeStoreEditDrawerBack = () => {
    setStoreDetailDrawerVisible(true);
    setStoreEditDrawerVisible(false);
    setEditingStore(null);
    editform.resetFields();
    setActiveStatus("active");
    setFileList([]);
    setPreviewImage("");
    setPos("");
    setIndustry("");
    setCategoty("");
    setCountry(null);
    setCurrency(null);
  };

  const handleAddStore = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const formData = new FormData();
      formData.append("status", activeStatus);

      if (fileList.length > 0) {
        formData.append("store_logo", fileList[0]?.originFileObj);
      }
      if (industry !== "other") {
        formData.append(
          "industry",
          industryData.find((item) => item.industry_id === industry)
            ?.industry_name
        );
      }
      if (category !== "other") {
        formData.append(
          "category",
          categoryData.find((item) => item.category_id === category)
            ?.category_name
        );
      }
      if (pos !== "other") {
        formData.append(
          "pos",
          posData.find((item) => item.pos_id === pos)?.pos_name
        );
      }

      Object.keys(values).forEach((key) => {
        if (key !== "store_logo" && key !== "country" && key !== "currency") {
          formData.append(key, values[key]);
        }
      });
      formData.append('country', country ? country?.name : '');
      formData.append('currency_code', currency !== '' ? currencyList[currency]?.code : '');
      formData.append('currency_symbol', currency !== '' ? currencyList[currency]?.symbol : '');

      const url = `${window.API_BASE_URL}/stores`;
      console.log(sessionData?.token);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionData?.token}`,
        },
        body: formData,
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        setStoreData((prevStoreData) => [...prevStoreData, data?.store]);
        message.success("Store added successfully");
        console.log("Store details added successfully");
      } else {
        message.error("Failed to add store");
        console.error("Failed to add store details");
      }
    } catch (error) {
      console.log("Error while adding store", error);
      message.error("Error while adding store");
    } finally {
      form.resetFields();
      setLoading(false);
      setActiveStatus("active");
      setFileList([]);
      setPreviewImage("");
      setPos("");
      setIndustry("");
      setCategoty("");
      setCountry(null);
      setCurrency(null);
      closeStoreAddDrawer();
    }
  };

  const handleEditStore = async () => {
    setLoading(true);
    try {
      const values = await editform.validateFields();
      const formData = new FormData();
      formData.append("status", activeStatus);
      if (fileList.length > 0) {
        formData.append("store_logo", fileList[0]?.originFileObj);
      }
      if (industry !== "") {
        if (industry !== "other") {
          formData.append(
            "industry",
            industryData.find((item) => item.industry_id === industry)
              ?.industry_name
          );
        }
      } else {
        if (values.industry_id !== "other") {
          formData.append(
            "industry",
            industryData.find((item) => item.industry_id === values.industry_id)
              ?.industry_name
          );
        }
      }
      if (category !== "") {
        if (category !== "other") {
          formData.append(
            "category",
            categoryData.find((item) => item.category_id === category)
              ?.category_name
          );
        }
      } else {
        if (values.category_id !== "other") {
          formData.append(
            "category",
            categoryData.find((item) => item.category_id === values.category_id)
              ?.category_name
          );
        }
      }
      if (pos !== "") {
        if (pos !== "other") {
          formData.append(
            "pos",
            posData.find((item) => item.pos_id === pos)?.pos_name
          );
        }
      } else {
        if (values.pos_id !== "other") {
          formData.append(
            "pos",
            posData.find((item) => item.pos_id === values.pos_id)?.pos_name
          );
        }
      }
      Object.keys(values).forEach((key) => {
        if (key !== "store_logo" && key !== "country" && key !== "currency") {
          formData.append(key, values[key]);
        }
      });

      formData.append('country', country ? typeof country === 'object' ? country?.name : country : '');
      formData.append('currency_code', currency !== '' ? currencyList[currency]?.code : '');
      formData.append('currency_symbol', currency !== '' ? currencyList[currency]?.symbol : '');

      const url = `${window.API_BASE_URL}/stores/${editingStore?.store_id}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${sessionData?.token}`,
        },
        body: formData,
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        message.success("Store updated successfully");
        console.log(data?.store);
        setStoreData((prevStoreData) =>
          prevStoreData.map((store) =>
            store.store_id === editingStore.store_id
              ? { ...store, ...data?.store }
              : store
          )
        );
        console.log("Store details updated successfully");
      } else {
        message.error("Failed to update store");
        console.error("Failed to update store details");
      }
    } catch (error) {
      console.log("Error while updating store", error);
      message.error("Error while updating store");
    } finally {
      editform.resetFields();
      setLoading(false);
      setActiveStatus("active");
      setFileList([]);
      setPreviewImage("");
      setPos("");
      setIndustry("");
      setCategoty("");
      setCountry(null);
      setCurrency(null);
      closeStoreEditDrawer();
    }
  };

  const handlePosOnChange = (value) => {
    setPos(value);
  };
  const handleIndustryOnChange = (value) => {
    setIndustry(value);
  };
  const handleCategoryOnChange = (value) => {
    setCategoty(value);
  };

  return (
    <Layout className="contentLayout">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 24,
        }}
      >
        <Title
          level={3}
          style={{ display: "flex", alignItems: "center", margin: 0 }}
        >
          <b>Stores</b>
        </Title>
      </div>
      <Row gutter={16}>
        <Col span={24}>
          <Flex gap="middle" justify="space-between" align="center">
            <div>
              <Title level={3} style={{ margin: 0 }}>
                My Store
              </Title>
              <Paragraph>
                You have <b>{storeData?.length} Action store</b>
              </Paragraph>
            </div>
            <Button type="primary" onClick={showStoreAddDrawer}>
              <PiPlus size={20} /> Add new store
            </Button>
          </Flex>
        </Col>
      </Row>
      {dataLoading ? (
        <Row gutter={16}>
          <Col span={24} className="custom-margin">
            <Card bordered={false} className="store-list-card">
              <Flex gap="middle" justify="space-between" align="center">
                <Flex gap="middle" align="center">
                  <Skeleton.Avatar active size={40} shape="circle" />
                  <Skeleton.Input active style={{ width: 200 }} size="small" />
                </Flex>
                <Skeleton.Button active size="small" />
              </Flex>
              <Card
                bordered={false}
                hoverable
                className="store-list-inner-card"
              >
                <Flex
                  gap="middle"
                  justify="space-between"
                  align="center"
                  style={{
                    paddingBottom: 16,
                    marginBottom: 16,
                    borderBottom: "1px solid #e9eded",
                  }}
                >
                  <Flex gap="small" align="center">
                    <PiStorefrontDuotone size={24} />
                    <Skeleton.Input
                      active
                      style={{ width: 100 }}
                      size="small"
                    />
                  </Flex>
                </Flex>
                <Flex gap="middle" align="center" justify="space-between">
                  {[...Array(5)].map((_, index) => (
                    <div key={index}>
                      <Paragraph style={{ marginBottom: 0, color: "#697777" }}>
                        <Skeleton.Input
                          active
                          style={{ width: 80 }}
                          size="small"
                        />
                      </Paragraph>
                      <Skeleton.Input
                        active
                        style={{ width: 100 }}
                        size="small"
                      />
                    </div>
                  ))}
                </Flex>
              </Card>
            </Card>
          </Col>
          <Col span={24} className="custom-margin">
            <Card bordered={false} className="store-list-card">
              <Flex gap="middle" justify="space-between" align="center">
                <Flex gap="middle" align="center">
                  <Skeleton.Avatar active size={40} shape="circle" />
                  <Skeleton.Input active style={{ width: 200 }} size="small" />
                </Flex>
                <Skeleton.Button active size="small" />
              </Flex>
              <Card
                bordered={false}
                hoverable
                className="store-list-inner-card"
              >
                <Flex
                  gap="middle"
                  justify="space-between"
                  align="center"
                  style={{
                    paddingBottom: 16,
                    marginBottom: 16,
                    borderBottom: "1px solid #e9eded",
                  }}
                >
                  <Flex gap="small" align="center">
                    <PiStorefrontDuotone size={24} />
                    <Skeleton.Input
                      active
                      style={{ width: 100 }}
                      size="small"
                    />
                  </Flex>
                </Flex>
                <Flex gap="middle" align="center" justify="space-between">
                  {[...Array(5)].map((_, index) => (
                    <div key={index}>
                      <Paragraph style={{ marginBottom: 0, color: "#697777" }}>
                        <Skeleton.Input
                          active
                          style={{ width: 80 }}
                          size="small"
                        />
                      </Paragraph>
                      <Skeleton.Input
                        active
                        style={{ width: 100 }}
                        size="small"
                      />
                    </div>
                  ))}
                </Flex>
              </Card>
            </Card>
          </Col>
          <Col span={24} className="custom-margin">
            <Card bordered={false} className="store-list-card">
              <Flex gap="middle" justify="space-between" align="center">
                <Flex gap="middle" align="center">
                  <Skeleton.Avatar active size={40} shape="circle" />
                  <Skeleton.Input active style={{ width: 200 }} size="small" />
                </Flex>
                <Skeleton.Button active size="small" />
              </Flex>
              <Card
                bordered={false}
                hoverable
                className="store-list-inner-card"
              >
                <Flex
                  gap="middle"
                  justify="space-between"
                  align="center"
                  style={{
                    paddingBottom: 16,
                    marginBottom: 16,
                    borderBottom: "1px solid #e9eded",
                  }}
                >
                  <Flex gap="small" align="center">
                    <PiStorefrontDuotone size={24} />
                    <Skeleton.Input
                      active
                      style={{ width: 100 }}
                      size="small"
                    />
                  </Flex>
                </Flex>
                <Flex gap="middle" align="center" justify="space-between">
                  {[...Array(5)].map((_, index) => (
                    <div key={index}>
                      <Paragraph style={{ marginBottom: 0, color: "#697777" }}>
                        <Skeleton.Input
                          active
                          style={{ width: 80 }}
                          size="small"
                        />
                      </Paragraph>
                      <Skeleton.Input
                        active
                        style={{ width: 100 }}
                        size="small"
                      />
                    </div>
                  ))}
                </Flex>
              </Card>
            </Card>
          </Col>
        </Row>
      ) : !storeData.length ? (
        <Row gutter={16}>
          <Col span={24}>
            <Result
              status="404"
              title="No inactive automated campaigns."
              subTitle=""
              extra={
                <Button type="primary" onClick={showStoreAddDrawer}>
                  <PiPlus size={20} /> Add new store
                </Button>
              }
            />
          </Col>
        </Row>
      ) : (
        storeData.map((store) => (
          <Row gutter={16}>
            <Col span={24} className="custom-margin">
              <Card bordered={false} className="store-list-card">
                <Flex gap="middle" justify="space-between" align="center">
                  <Flex gap="middle" align="center">
                    {store?.store_logo !== null ? (
                      <div className="card-image-logo-div">
                        <Image src={`${window.BASE_URL}${store?.store_logo}`} />
                      </div>
                    ) : (
                      <div className="card-logo-div">
                        <Paragraph className="card-logo-name">
                          {store?.store_name}
                        </Paragraph>
                      </div>
                    )}
                    <Title level={3} style={{ margin: 0 }}>
                      {store?.store_name}
                    </Title>
                  </Flex>
                  <Title
                    level={5}
                    style={{ margin: 0, cursor: "pointer" }}
                    onClick={() => showStoreDetailDrawer(store)}
                  >
                    View More Details <PiArrowArcRightDuotone size={14} />
                  </Title>
                </Flex>
                <Card
                  bordered={false}
                  hoverable
                  className="store-list-inner-card"
                >
                  <Flex
                    gap="middle"
                    justify="space-between"
                    align="center"
                    style={{
                      paddingBottom: 16,
                      marginBottom: 16,
                      borderBottom: "1px solid #e9eded",
                    }}
                  >
                    <Flex gap="small" align="center">
                      <PiStorefrontDuotone size={24} />
                      <Title level={3} style={{ margin: 0 }}>
                        Profile
                      </Title>
                    </Flex>
                  </Flex>
                  <Flex gap="middle" align="center" justify="space-between">
                    <div>
                      <Paragraph style={{ marginBottom: 0, color: "#697777" }}>
                        Industry
                      </Paragraph>
                      <Title
                        level={4}
                        style={{ marginTop: 0, color: "#0e1b1b" }}
                      >
                        {store?.industry}
                      </Title>
                    </div>
                    <div>
                      <Paragraph style={{ marginBottom: 0, color: "#697777" }}>
                        Category
                      </Paragraph>
                      <Title
                        level={4}
                        style={{ marginTop: 0, color: "#0e1b1b" }}
                      >
                        {store?.category}
                      </Title>
                    </div>
                    <div>
                      <Paragraph style={{ marginBottom: 0, color: "#697777" }}>
                        Address
                      </Paragraph>
                      <Tooltip
                        title={`${store?.store_address || ""}${
                          store?.city ? `, ${store.city}` : ""
                        }${store?.state ? `, ${store.state}` : ""}${
                          store?.postcode ? `, ${store.postcode}` : ""
                        }`}
                      >
                        <Title
                          level={4}
                          style={{ marginTop: 0, color: "#0e1b1b" }}
                        >
                          {`${store?.store_address || ""}${
                            store?.city ? `, ${store.city}` : ""
                          }${store?.state ? `, ${store.state}` : ""}${
                            store?.postcode ? `, ${store.postcode}` : ""
                          }`.length > 15
                            ? `${`${store?.store_address || ""}${
                                store?.city ? `, ${store.city}` : ""
                              }${store?.state ? `, ${store.state}` : ""}${
                                store?.postcode ? `, ${store.postcode}` : ""
                              }`.substring(0, 15)}...`
                            : `${store?.store_address || ""}${
                                store?.city ? `, ${store.city}` : ""
                              }${store?.state ? `, ${store.state}` : ""}${
                                store?.postcode ? `, ${store.postcode}` : ""
                              }`}
                        </Title>
                      </Tooltip>
                    </div>
                    <div>
                      <Paragraph style={{ marginBottom: 0, color: "#697777" }}>
                        POS Name
                      </Paragraph>
                      <Title
                        level={4}
                        style={{ marginTop: 0, color: "#0e1b1b" }}
                      >
                        {store?.pos === "undefined"
                          ? "Not integrated"
                          : store?.pos}
                      </Title>
                    </div>
                  </Flex>
                </Card>
              </Card>
            </Col>
          </Row>
        ))
      )}
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={3} className="popup-title">
              Store Details
            </Title>
            <Button
              shape="round"
              onClick={closeStoreDetailDrawer}
              className="popup-close-btn"
            >
              <PiXCircleDuotone size={40} />
            </Button>
          </div>
        }
        closable={false}
        className="right-side-popup"
        placement="right"
        onClose={closeStoreDetailDrawer}
        open={storeDetailDrawerVisible}
        width={"40%"}
      >
        <Card hoverable className="store-detail-popup-card">
          <div className="store-detail-card-header">
            <Flex gap="middle" justify="space-between" align="center">
              <Title
                level={4}
                style={{
                  margin: 0,
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <PiStorefrontDuotone size={24} style={{ color: "#3A1A55" }} />{" "}
                Profile
              </Title>
              <Button
                type="text"
                style={{ fontWeight: "bold" }}
                onClick={() => showStoreEditDrawer(storeDetail)}
              >
                <PiPencilLineDuotone size={18} style={{ color: "#3A1A55" }} />{" "}
                Edit
              </Button>
            </Flex>
          </div>
          <div className="store-detail-card-body">
            <Flex gap="middle" justify="space-between" align="center">
              <Flex gap="middle" align="center" style={{ marginBottom: 16 }}>
                {storeDetail?.store_logo !== null ? (
                  <div className="card-image-logo-div">
                    <Image
                      src={`${window.BASE_URL}${storeDetail?.store_logo}`}
                    />
                  </div>
                ) : (
                  <div className="card-logo-div">
                    <Paragraph className="card-logo-name">
                      {storeDetail?.store_name}
                    </Paragraph>
                  </div>
                )}
                <div>
                  <Title level={4} style={{ margin: 0 }}>
                    {storeDetail?.store_name}
                  </Title>
                  <Paragraph
                    style={{ color: "#576464", fontWeight: 400, margin: 0 }}
                  >
                    {storeDetail?.industry} • {storeDetail?.category}
                  </Paragraph>
                </div>
              </Flex>
            </Flex>
            <Flex gap="middle" justify="space-between" align="center">
              <div>
                <Paragraph
                  style={{ color: "#576464", fontWeight: 400, marginBottom: 0 }}
                >
                  Address
                </Paragraph>
                <Title level={4} style={{ marginTop: 0 }}>
                  {storeDetail?.store_address}
                </Title>
              </div>
            </Flex>
            <Flex gap="middle" justify="space-between" align="center">
              <div>
                <Paragraph
                  style={{ color: "#576464", fontWeight: 400, marginBottom: 0 }}
                >
                  Store Owner
                </Paragraph>
                <Title level={4} style={{ marginTop: 0 }}>
                  {userData?.user_name}
                </Title>
              </div>
            </Flex>
          </div>
        </Card>
        <Card hoverable className="cashier-portal-popup-card">
          <div className="cashier-portal-card-header">
            <Flex gap="middle" justify="space-between" align="center">
              <Title
                level={4}
                style={{
                  margin: 0,
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <PiLaptopDuotone size={24} style={{ color: "#e04f16" }} />{" "}
                Cashier Portal
              </Title>
              <Flex gap={2} align="center">
                <Button
                  type="text"
                  style={{
                    fontWeight: "bold",
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  <PiCopyDuotone size={18} style={{}} /> Copy
                </Button>
                <Button
                  type="text"
                  style={{
                    fontWeight: "bold",
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  <PiArrowArcRightDuotone size={18} style={{}} /> Open Portal
                </Button>
                <Button
                  type="text"
                  style={{
                    fontWeight: "bold",
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  <PiPencilLineDuotone size={18} style={{ color: "#3A1A55" }} />{" "}
                  Edit
                </Button>
              </Flex>
            </Flex>
          </div>
          <div className="cashier-portal-card-body">
            <Flex gap="middle" justify="space-between" align="center">
              <div>
                <Paragraph
                  style={{ color: "#576464", fontWeight: 400, marginBottom: 0 }}
                >
                  Link
                </Paragraph>
                <Link href="https://app.zipler.io/cashier/66d32373a4527b87d95870b3">
                  <Title level={4} style={{ marginTop: 0 }}>
                    https://app.zipler.io/cashier/66d32373a4527b87d95870b3
                  </Title>
                </Link>
              </div>
            </Flex>
            <Flex gap="middle" justify="space-between" align="center">
              <div>
                <Paragraph
                  style={{ color: "#576464", fontWeight: 400, marginBottom: 0 }}
                >
                  Login Username
                </Paragraph>
                <Title level={4} style={{ marginTop: 0 }}>
                  cashier.{storeDetail?.store_name}
                </Title>
              </div>
              <div>
                <Paragraph
                  style={{ color: "#576464", fontWeight: 400, marginBottom: 0 }}
                >
                  Password
                </Paragraph>
                <Title level={4} style={{ marginTop: 0 }}>
                  M2MC@eiPNSSEM2T
                </Title>
              </div>
            </Flex>
          </div>
        </Card>
      </Drawer>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={3} className="popup-title">
              Add Store Details
            </Title>
            <Button
              shape="round"
              onClick={closeStoreAddDrawer}
              className="popup-close-btn"
            >
              <PiXCircleDuotone size={40} />
            </Button>
          </div>
        }
        closable={false}
        className="right-side-popup"
        width={"40%"}
        onClose={closeStoreAddDrawer}
        open={storeAddDrawerVisible}
        footer={
          <Flex gap={16} justify="end" align="center">
            <Switch
              checked={activeStatus === "active" ? true : false}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={() =>
                setActiveStatus(
                  activeStatus === "active" ? "inactive" : "active"
                )
              }
            />
            <Button type="primary" onClick={handleAddStore} loading={loading}>
              Save Changes
            </Button>
          </Flex>
        }
      >
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="store_logo" style={{ textAlign: "center" }}>
                <ImgCrop
                  aspect={1}
                  cropShape="rect"
                  quality={1}
                  zoom={true}
                  rotate
                  showGrid
                  modalTitle="Store Logo"
                  modalOk="Crop"
                >
                  <Upload
                    listType="picture-circle"
                    beforeUpload={() => false}
                    fileList={fileList}
                    onChange={handleChange}
                    onPreview={handlePreview}
                    maxCount={1}
                    className="uploadBtn"
                  >
                    {fileList.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Store Logo</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
                <Paragraph>Upload Logo</Paragraph>
                <Modal
                  open={previewVisible}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="logo"
                    style={{ width: "100%", borderRadius: "0" }}
                    src={previewImage}
                  />
                </Modal>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="store_name"
                label="Store Name"
                rules={[{ required: true, message: "Please enter store name" }]}
              >
                <Input placeholder="Store Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="store_number"
                label="Store Contact Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter store contact number",
                  },
                ]}
              >
                <Input placeholder="Store Contact Number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="store_website"
                label="Store Website"
                rules={[
                  { required: false, message: "Please enter store website" },
                ]}
              >
                <Input placeholder="Store Website" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="industry_id"
                label="Industry"
                rules={[
                  { required: false, message: "Please select an industry" },
                ]}
              >
                <Select
                  placeholder="Select Industry"
                  showSearch
                  onChange={handleIndustryOnChange}
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {industryData.map((industry) => (
                    <Option
                      key={industry.industry_id}
                      value={industry.industry_id}
                    >
                      {industry.industry_name}
                    </Option>
                  ))}
                  <Option value="other">Others</Option>
                </Select>
              </Form.Item>
            </Col>
            {industry === "other" && (
              <Col span={12}>
                <Form.Item
                  name="industry"
                  label="Specify Industry Name"
                  rules={[
                    { required: false, message: "Please enter industry name" },
                  ]}
                >
                  <Input placeholder="Industry Name" />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                name="category_id"
                label="Category"
                rules={[
                  { required: false, message: "Please select an category" },
                ]}
              >
                <Select
                  placeholder="Select Category"
                  showSearch
                  onChange={handleCategoryOnChange}
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {categoryData.map((category) => (
                    <Option
                      key={category.category_id}
                      value={category.category_id}
                    >
                      {category.category_name}
                    </Option>
                  ))}
                  <Option value="other">Others</Option>
                </Select>
              </Form.Item>
            </Col>
            {category === "other" && (
              <Col span={12}>
                <Form.Item
                  name="category"
                  label="Specify Category Name"
                  rules={[
                    { required: false, message: "Please enter category name" },
                  ]}
                >
                  <Input placeholder="Category Name" />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                name="pos_id"
                label="POS"
                rules={[
                  { required: false, message: "Please enter POS details" },
                ]}
              >
                <Select
                  placeholder="Select POS"
                  showSearch
                  onChange={handlePosOnChange}
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {posData.map((pos) => (
                    <Option key={pos.pos_id} value={pos.pos_id}>
                      {pos.pos_name}
                    </Option>
                  ))}
                  <Option value="other">I'm using another one</Option>
                  <Option value="notusing">Do not use a POS</Option>
                </Select>
              </Form.Item>
            </Col>
            {pos === `other` && (
              <Col span={12}>
                <Form.Item
                  name="pos"
                  label="Specify POS Name"
                  rules={[
                    { required: false, message: "Please enter POS name" },
                  ]}
                >
                  <Input placeholder="POS Name" />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="store_address"
                label="Store Address"
                rules={[
                  { required: false, message: "Please enter store address" },
                ]}
              >
                <Input placeholder="Store Address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Please select country",
                  },
                ]}
              >
                <CountrySelect
                  onChange={(e) => {
                    console.log(e);
                    setCountry(e);
                    setCurrency(e.currency);
                    form.setFieldsValue({
                      currency: e.currency,
                    });
                  }}
                  // defaultValue={country && country}
                  placeHolder="Select Country"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[
                  {
                    required: true,
                    message: "Please select country",
                  },
                ]}
              >
                <Select
                  showSearch
                  onChange={(e) => setCurrency(e)}
                  placeholder="Select Currency"
                  style={{ width: "100%" }}
                  value={currency}
                >
                  {Object.keys(currencyList).map((currency) => (
                    <Option key={currency} value={currency}>
                      {currency} ({currencyList[currency].symbol})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="map_location"
                label="Store Location url"
                rules={[
                  {
                    required: false,
                    message: "Please enter store location url",
                  },
                ]}
              >
                <Input placeholder="Store Location url" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
      <Drawer
        title={
          <Flex gap="middel" justify="space-between" align="center">
            <Flex gap="middle" align="center">
              <Button
                shape="round"
                onClick={closeStoreEditDrawerBack}
                className="popup-close-btn"
              >
                <PiArrowLeftDuotone />
              </Button>
              <Title level={3} className="popup-title">
                Edit Store Details
              </Title>
            </Flex>
            <Button
              shape="round"
              onClick={closeStoreEditDrawer}
              className="popup-close-btn"
            >
              <PiXCircleDuotone size={40} />
            </Button>
          </Flex>
        }
        closable={false}
        className="right-side-popup"
        width={"40%"}
        onClose={closeStoreEditDrawer}
        open={storeEditDrawerVisible}
        footer={
          <Flex gap={16} justify="end" align="center">
            <Switch
              checked={activeStatus === "active" ? true : false}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={() =>
                setActiveStatus(
                  activeStatus === "active" ? "inactive" : "active"
                )
              }
            />
            <Button type="primary" onClick={handleEditStore}>
              Save Changes
            </Button>
          </Flex>
        }
      >
        {editingStore !== null && (
          <Form layout="vertical" initialValues={editingStore} form={editform}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item name="store_logo" style={{ textAlign: "center" }}>
                  <ImgCrop
                    aspect={1}
                    cropShape="rect"
                    quality={1}
                    zoom={true}
                    rotate
                    showGrid
                    modalTitle="Store Logo"
                    modalOk="Crop"
                  >
                    <Upload
                      listType="picture-circle"
                      beforeUpload={() => false}
                      fileList={fileList}
                      onChange={handleChange}
                      onPreview={handlePreview}
                      maxCount={1}
                      className="uploadBtn"
                    >
                      {fileList.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Store Logo</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                  <Paragraph>Upload Logo</Paragraph>
                  <Modal
                    open={previewVisible}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="logo"
                      style={{ width: "100%", borderRadius: "0" }}
                      src={previewImage}
                    />
                  </Modal>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="store_name"
                  label="Store Name"
                  rules={[
                    { required: true, message: "Please enter store name" },
                  ]}
                >
                  <Input placeholder="Store Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="store_number"
                  label="Store Contact Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter store contact number",
                    },
                  ]}
                >
                  <Input placeholder="Store Contact Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="store_website"
                  label="Store Website"
                  rules={[
                    { required: false, message: "Please enter store website" },
                  ]}
                >
                  <Input placeholder="Store Website" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="industry_id"
                  label="Industry"
                  rules={[
                    { required: true, message: "Please select an industry" },
                  ]}
                >
                  <Select
                    placeholder="Select Industry"
                    showSearch
                    onChange={handleIndustryOnChange}
                    filterOption={(inputValue, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    }
                  >
                    {industryData.map((industry) => (
                      <Option
                        key={industry.industry_id}
                        value={industry.industry_id}
                      >
                        {industry.industry_name}
                      </Option>
                    ))}
                    <Option value="other">Others</Option>
                  </Select>
                </Form.Item>
              </Col>
              {industry === "other" && (
                <Col span={12}>
                  <Form.Item
                    name="industry"
                    label="Specify Industry Name"
                    rules={[
                      { required: true, message: "Please enter industry name" },
                    ]}
                  >
                    <Input placeholder="Industry Name" />
                  </Form.Item>
                </Col>
              )}
              <Col span={12}>
                <Form.Item
                  name="category_id"
                  label="Category"
                  rules={[
                    { required: true, message: "Please select an category" },
                  ]}
                >
                  <Select
                    placeholder="Select Category"
                    showSearch
                    onChange={handleCategoryOnChange}
                    filterOption={(inputValue, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    }
                  >
                    {categoryData.map((category) => (
                      <Option
                        key={category.category_id}
                        value={category.category_id}
                      >
                        {category.category_name}
                      </Option>
                    ))}
                    <Option value="other">Others</Option>
                  </Select>
                </Form.Item>
              </Col>
              {category === "other" && (
                <Col span={12}>
                  <Form.Item
                    name="category"
                    label="Specify Category Name"
                    rules={[
                      { required: true, message: "Please enter category name" },
                    ]}
                  >
                    <Input placeholder="Category Name" />
                  </Form.Item>
                </Col>
              )}
              <Col span={12}>
                <Form.Item
                  name="pos_id"
                  label="POS"
                  rules={[
                    { required: true, message: "Please enter POS details" },
                  ]}
                >
                  <Select
                    placeholder="Select POS"
                    showSearch
                    onChange={handlePosOnChange}
                    filterOption={(inputValue, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    }
                  >
                    {posData.map((pos) => (
                      <Option key={pos.pos_id} value={pos.pos_id}>
                        {pos.pos_name}
                      </Option>
                    ))}
                    <Option value="other">I'm using another one</Option>
                    <Option value="notusing">Do not use a POS</Option>
                  </Select>
                </Form.Item>
              </Col>
              {pos === `other` && (
                <Col span={12}>
                  <Form.Item
                    name="pos"
                    label="Specify POS Name"
                    rules={[
                      { required: true, message: "Please enter POS name" },
                    ]}
                  >
                    <Input placeholder="POS Name" />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  name="store_address"
                  label="Store Address"
                  rules={[
                    { required: false, message: "Please enter store address" },
                  ]}
                >
                  <Input placeholder="Store Address" />
                </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Please select country",
                  },
                ]}
              >
                <CountrySelect
                  onChange={(e) => {
                    console.log(e);
                    setCountry(e);
                    setCurrency(e.currency);
                    editform.setFieldsValue({
                      currency: e.currency,
                    });
                  }}
                  value={country}
                  placeHolder={country ? typeof country === 'object' ? country.name : country : 'Select Country'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[
                  {
                    required: true,
                    message: "Please select country",
                  },
                ]}
              >
                <Select
                  showSearch
                  onChange={(e) => setCurrency(e)}
                  placeholder="Select Currency"
                  style={{ width: "100%" }}
                  value={currency}
                >
                  {Object.keys(currencyList).map((currency) => (
                    <Option key={currency} value={currency}>
                      {currency} ({currencyList[currency].symbol})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
              <Col span={12}>
                <Form.Item
                  name="map_location"
                  label="Store Location url"
                  rules={[
                    {
                      required: false,
                      message: "Please enter store location url",
                    },
                  ]}
                >
                  <Input placeholder="Store Location url" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Drawer>
    </Layout>
  );
};
export default Stores;
