import React, { useRef, useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

const Checkout = ({total, billingFormValue, finalRefillData, userData, sessionData, storeDetail}) => {
    const [{ isPending }] = usePayPalScriptReducer();
    const [transactionId, setTransactionId] = useState('');
    const transactionIdRef = useRef('');
    const navigate = useNavigate();


    const onCreateOrder = (data,actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: total,
                    },
                },
            ],
        });
    }

    const onInit = async (data, actions) => {
        try {
            const paymentData = {
                billing_id: billingFormValue?.detail_id,
                refill_data: finalRefillData,
                phone: userData?.user_phone,
                currency_code: storeDetail?.currency_code,
            };
    
            const url = `${window.API_BASE_URL}/pay`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify(paymentData),
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            console.log(data.payment_id);
            setTransactionId(data.payment_id);
            transactionIdRef.current = data.payment_id;
            console.log("Payment initiation successful:", response);
        } catch (error) {
            console.error("Error initiating payment data:", error);
        }
    };    

    const onApproveOrder = async (data, actions) => {
        try {
            const details = await actions.order.capture();
            const paymentData = {
                txnId: transactionIdRef.current,
                pay_status:'success',
                paydetail: details,
                paydata: data,
            };
    
            const url = `${window.API_BASE_URL}/pay/status`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify(paymentData),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            transactionIdRef.current = '';
            message.success(`Transaction success`);
            navigate('/refill-history');
        } catch (error) {
            console.error("Error proceeding payment data:", error);
            message.error("Oops! Something went wrong, Please try again");
        }
    };    

    const onCancleOrder = async (data, actions) => {
        try {
            const paymentData = {
                txnId: transactionId,
                pay_status: 'canceled',
                paydetail: null,
                paydata: data,
            };
            const url = `${window.API_BASE_URL}/pay/status`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify(paymentData),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            console.log("Payment cancellation data sent successfully:", result);
            message.error("Transaction was canceled");
            transactionIdRef.current = '';
            navigate('/refill-history');
        } catch (error) {
            console.error("Error sending canceled payment data:", error);
        }
    };    

    return (
        <div className="checkout">
            {isPending ? <p>LOADING...</p> : (
                <>
                    <PayPalButtons 
                        style={{ layout: "vertical" }}
                        fundingSource={undefined}
                        createOrder={(data, actions) => onCreateOrder(data, actions)}
                        onApprove={(data, actions) => onApproveOrder(data, actions)}
                        onCancel={(data, actions) => onCancleOrder(data, actions)}
                        onInit={(data, actions) => onInit(data, actions)}
                    />
                </>
            )}
        </div>
    );
}

export default Checkout;