import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Row, Col, Typography, Card, Flex, Select, Switch, Button, Dropdown, Space, message, Spin, Drawer, QRCode } from 'antd';
import { PiArrowArcRightDuotone, PiArrowRightDuotone, PiChatDotsDuotone, PiCoinsDuotone, PiCopyDuotone, PiCurrencyInrDuotone, PiDeviceMobileCameraDuotone, PiDownloadDuotone, PiEnvelopeDuotone, PiGiftDuotone, PiLightningDuotone, PiMonitorDuotone, PiPercentDuotone, PiShoppingBagDuotone, PiStorefrontDuotone, PiUserDuotone, PiXCircleDuotone } from 'react-icons/pi';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import bgImg from '../assets/dymmy.jpg';
import { useNavigate } from 'react-router-dom';
const { Paragraph, Title } = Typography;
const QrInsights = ({ userData, sessionData, storeDetail, setAddNewDrawer, handleExistingQr }) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [qrData, setqrData] = useState([]);
    const [newCount, setNewCount] = useState(0);
    const [existingCount, setExistingCount] = useState(0);
    const [totalVisitsCount, setTotalVisitsCount] = useState(0);
    const [aptoxRevenueCount, setAptoxRevenueCount] = useState(0);
    const [visitRateCount, setVisitRateCount] = useState(0);
    const [drawer, setDrawer] = useState(false);
    const [selectedQrData, setSeelctedQrData] = useState(null);
    const [previewType, setPreviewType] = useState('poster');
    const uploadContainerRef = useRef(null);
    const uploadContainerRefOne = useRef(null);
    const [loadingQrStatus, setLoadingQrStatus] = useState(false);
    const [customers, setCustomers] = useState([]);

    const fetchQrData = useCallback(async () => {
        const url = `${window.API_BASE_URL}/qrcodes/${localStorage.getItem('storeId')}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setqrData(data?.qr_codes);
                if (data?.qr_codes) {
                    const totalVisits = data.qr_codes.reduce((accumulator, qrcode) => {
                        return accumulator + (qrcode.total_visits || 0);
                    }, 0);
                    setTotalVisitsCount(totalVisits);
                    setCustomers(data?.customers)
                    const existingCount = data?.customers?.filter(customer => parseInt(customer.total_purchase) !== 0).length || 0;
                    const newCount = data?.customers?.filter(customer => parseInt(customer.total_purchase) === 0).length || 0;
                    setExistingCount(existingCount);
                    setNewCount(newCount);
                    const aptoxRevenueCount = data?.customers?.reduce((total, customer) => total + (parseFloat(customer.total_spent) || 0), 0) || 0;
                    setAptoxRevenueCount(aptoxRevenueCount.toFixed(2));
                    const visitRatePercentage = Math.min(100, Math.max(0, ((totalVisits / (existingCount + newCount)) * 100) || 0));
                    setVisitRateCount(visitRatePercentage);
                }
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                }
                console.error('Error fetching user data:', data.message);
            }
        } catch (error) {
            console.error('Error during fetching store data:', error);
        } finally {
            setDataLoading(false);
        }
    }, [sessionData?.token]);

    useEffect(() => {
        const fetchData = async () => {
            if (sessionData) {
                await fetchQrData();
            } else {
                setDataLoading(false);
            }
        };
        fetchData();
        setVisitRateCount(0);
    }, [fetchQrData, sessionData]);

    const totalCount = newCount + existingCount;

    const newBarHeight = totalCount > 0 ? (newCount / totalCount) * 50 : 0;
    const existingBarHeight = totalCount > 0 ? (existingCount / totalCount) * 50 : 0;

    const handleMenuClick = (e, qrcode) => {
        setLoading(true);
        setPreviewType(e.key);
        setSeelctedQrData(qrcode);
        setDrawer(true);
        downloadPosterAsImage(qrcode, e.key);
    };

    const handleQrModeChange = async (qrcode) => {
        setLoadingQrStatus(true);
        const url = `${window.API_BASE_URL}/qrcodes/modify-status/${qrcode?.qr_id}/${qrcode?.status === 'inactive' ? 'active' : 'inactive'}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            if (response.ok) {
                const newStatus = qrcode.status === 'active' ? 'inactive' : 'active';
                const updatedQrCode = { ...qrcode, status: newStatus };
                setqrData((prevQrCodes) =>
                    prevQrCodes.map((item) => (item.qr_id === qrcode.qr_id ? updatedQrCode : item))
                );
                message.success('Status updated successfully.');
            }
        } catch (error) {
            console.error('Error during fetching store data:', error);
        } finally {
            setLoadingQrStatus(false);
        }
    };

    const handleEditQr = (qrcode) => {
        setAddNewDrawer(true);
        handleExistingQr(qrcode);
        navigate(`/qr-code/addnew`);
    };

    const items = [
        {
            label: 'Poster',
            key: 'poster',
        },
        {
            label: 'Sticker',
            key: 'sticker',
        },
        {
            label: 'Social Post',
            key: 'social',
        },
        {
            label: 'Business Card',
            key: 'businesscard',
        },
        {
            label: 'QR Code Only',
            key: 'qrcode',
        }
    ];

    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link)
            .then(() => {
                console.log('Link copied to clipboard:', link);
                message.success('Link copied to clipboard!');
            })
            .catch((err) => {
                console.error('Error copying to clipboard: ', err);
            });
    };

    const downloadPosterAsImage = (qrcode, type) => {
        const downloadImages = (containerRef) => {
            if (!containerRef.current) {
                return Promise.resolve();
            }

            const images = containerRef.current.getElementsByTagName('img');
            const imageLoadPromises = Array.from(images).map((img) => {
                return new Promise((resolve) => {
                    img.crossOrigin = "anonymous";
                    if (img.complete) {
                        resolve();
                    } else {
                        img.onload = resolve;
                        img.onerror = resolve;
                    }
                });
            });

            const backgroundLoadPromise = new Promise((resolve) => {
                const bgImage = new Image();
                bgImage.crossOrigin = "anonymous";
                bgImage.src = selectedQrData?.poster_banner ? `${window.BASE_URL}${selectedQrData?.poster_banner}` : bgImg;
                bgImage.onload = resolve;
                bgImage.onerror = resolve;
            });

            return Promise.all([...imageLoadPromises, backgroundLoadPromise]);
        };

        const generateAndDownloadCanvas = (containerRef) => {
            html2canvas(containerRef.current, { allowTaint: true, useCORS: true, backgroundColor: null })
                .then((canvas) => {
                    const link = document.createElement('a');
                    link.href = canvas.toDataURL('image/png');
                    link.download = `${qrcode?.objective}-qr-poster.png`;
                    link.click();
                })
                .catch(err => {
                    console.error("Error generating canvas:", err);
                }).finally(() => {
                    setLoading(false);
                });
        };

        if (type === 'businesscard') {
            const uploadPromises = Promise.all([
                downloadImages(uploadContainerRef),
                downloadImages(uploadContainerRefOne)
            ]);
            uploadPromises.then(() => {
                generateAndDownloadCanvas(uploadContainerRef);
                generateAndDownloadCanvas(uploadContainerRefOne);
            }).catch(err => {
                console.error("Image loading error:", err);
            });
        } else {
            downloadImages(uploadContainerRef).then(() => {
                generateAndDownloadCanvas(uploadContainerRef);
            }).catch(err => {
                console.error("Image loading error:", err);
            });
        }
    };

    return (
        dataLoading ? (
            <div className='loaderDiv'>
                <Spin
                    indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: 48,
                            }}
                            spin
                        />
                    }
                />
            </div>
        ) : (
            <>
                {loading && (
                    <div className='loaderDiv'>
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 48,
                                    }}
                                    spin
                                />
                            }
                        />
                    </div>
                )}
                <Row gutter={16}>
                    <Col span={24}>
                        <div className='qr-page-divider' />
                        <Card bordered={false} className='qr-summary'>
                            <Flex gap={16} justify='space-between' align='center' className='main-title-flex'>
                                <Title level={3}>Overview</Title>
                            </Flex>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <div className='summary-card'>
                                        <Flex gap={16} justify='space-between' align='center'>
                                            <div className='total-data'>
                                                <Title level={2} className='main-title'>{totalCount}</Title>
                                                <Paragraph className='sub-title'><PiDeviceMobileCameraDuotone size={20} /> Customers Captured</Paragraph>
                                            </div>
                                            <Flex gap={12} justify='flex-end' align='flex-end' className="bar-chart">
                                                <div className="bar-item">
                                                    <Paragraph className='bar-count'>{newCount}</Paragraph>
                                                    <div className="bar new-bar" style={{ height: newBarHeight + 24 }}>
                                                        <Paragraph className='bar-label'>New</Paragraph>
                                                    </div>
                                                </div>
                                                <div className="bar-item">
                                                    <Paragraph className='bar-count'>{existingCount}</Paragraph>
                                                    <div className='bar existing-bar' style={{ height: existingBarHeight + 24 }}>
                                                        <Paragraph className='bar-label'>Existing</Paragraph>
                                                    </div>
                                                </div>
                                            </Flex>
                                        </Flex>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='summary-card'>
                                        <Flex gap={16} justify='space-between' align='center'>
                                            <div className='total-data'>
                                                <Title level={2} className='main-title'>{totalVisitsCount}</Title>
                                                <Paragraph className='sub-title'><PiLightningDuotone size={20} /> Total Visits</Paragraph>
                                            </div>
                                        </Flex>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='summary-card'>
                                        <Flex gap={16} justify='space-between' align='center'>
                                            <div className='total-data'>
                                                <Title level={2} className='main-title'>{storeDetail?.currency_symbol} {aptoxRevenueCount}</Title>
                                                <Paragraph className='sub-title'>{storeDetail?.currency_symbol} Approx Revenue</Paragraph>
                                            </div>
                                        </Flex>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='summary-card'>
                                        <Flex gap={16} justify='space-between' align='center'>
                                            <div className='total-data'>
                                                <Title level={2} className='main-title'>{visitRateCount} %</Title>
                                                <Paragraph className='sub-title'><PiPercentDuotone size={20} /> Visit Rate</Paragraph>
                                            </div>
                                        </Flex>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                            <Col span={24}>
                                <Flex gap={16} justify='space-between' align='center'>
                                    <div>
                                        <Title level={3} style={{ margin: 0 }}>Active QR Codes</Title>
                                        <Paragraph>Showing {qrData.length} of {qrData.length} QR Codes</Paragraph>
                                    </div>
                                    <Flex gap={16} align='center'>
                                        <Select showSearch defaultValue={'active'}>
                                            <Select.Option value='active'>Active</Select.Option>
                                            <Select.Option value='inactive'>In-active</Select.Option>
                                            <Select.Option value='draft'>Drafts</Select.Option>
                                            <Select.Option value='all'>All</Select.Option>
                                        </Select>
                                    </Flex>
                                </Flex>
                            </Col>
                            <Col span={24}>
                                <div className='qr-codes-list'>
                                    {qrData && qrData.map((qrcode) => (
                                        <Card bordered={false} className='qr-insight-card' key={qrcode?.qr_id}>
                                            <div className='template-type'><PiShoppingBagDuotone size={20} /> <span>{qrcode?.qr_template_type}</span></div>
                                            <div className='card-main-info'>
                                                <Title level={2}>{qrcode?.objective}</Title>
                                                <Flex gap={16} align='center'>
                                                    <div className='template-type'><PiGiftDuotone size={20} /> <span>{qrcode?.reward_type === 'predisc' ? `${qrcode?.pre_disc}% discount on entire purchase` : qrcode?.reward_type === 'cashdisc' ? `₹${qrcode?.cash_disc} discount on entire purchase` : qrcode?.reward_type === 'freeitem' && 'Free Items'}</span></div>
                                                    <div className='template-type'><PiMonitorDuotone size={20} /> <span>{qrcode?.reward_term_type === 'newandexisting' ? 'New + Existing customers' : 'New Customers Only'}</span></div>
                                                    <div className='template-type'><PiChatDotsDuotone size={20} /> <span>{qrcode?.communication_type === 'both' ? 'SMS, WhasApp' : qrcode?.communication_type === 'sms' ? 'SMS' : qrcode?.communication_type === 'whatsapp' && 'WhatsApp'}</span></div>
                                                    <div className='template-type'><PiStorefrontDuotone size={20} /> <span>{storeDetail?.store_name}</span></div>
                                                </Flex>
                                            </div>
                                            <Row gutter={[16, 61]}>
                                                <Col span={8}>
                                                    <div className='summary-card'>
                                                        <Flex gap={16} justify='space-between' align='center'>
                                                            <div className='total-data'>
                                                                <Title level={2} className='main-title'>{customers?.filter(customer => parseInt(customer.qr_code_id) === qrcode?.qr_id).length || 0}</Title>
                                                                <Paragraph className='sub-title'><PiUserDuotone size={20} /> Customers Captured</Paragraph>
                                                            </div>
                                                        </Flex>
                                                    </div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className='summary-card'>
                                                        <Flex gap={16} justify='space-between' align='center'>
                                                            <div className='total-data'>
                                                                <Title level={2} className='main-title'>{qrcode?.total_visits}</Title>
                                                                <Paragraph className='sub-title'><PiLightningDuotone size={20} /> Total Visits</Paragraph>
                                                            </div>
                                                        </Flex>
                                                    </div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className='summary-card'>
                                                        <Flex gap={16} justify='space-between' align='center'>
                                                            <div className='total-data'>
                                                                <Title level={2} className='main-title'>{(customers?.filter(customer => customer.qr_code_id === qrcode?.qr_id).reduce((total, customer) => total + (parseFloat(customer.total_spent) || 0), 0) || 0).toFixed(2)}</Title>
                                                                <Paragraph className='sub-title'><PiCoinsDuotone size={20} /> Revenue</Paragraph>
                                                            </div>
                                                        </Flex>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Flex gap={16} justify='space-between' align='center' style={{ marginTop: 16 }}>
                                                <Flex gap={8} align='center'>
                                                    <Switch disabled={qrcode?.status === 'draft'} checked={qrcode?.status === 'active'} onChange={() => handleQrModeChange(qrcode)} loading={loadingQrStatus} /> <span style={{ color: '#697777' }}>{qrcode?.status === 'active' ? 'Active since' : qrcode?.status === 'inactive' ? 'Inactive since' : 'In draft since'} {qrcode?.updated_at && moment(qrcode?.updated_at).format('MMM DD, YYYY')}</span>
                                                </Flex>
                                                <Flex gap={16} align='center'>
                                                    <Button onClick={() => copyToClipboard(`t.zipler.io/${qrcode?.enc_qr_id}`)} icon={<PiCopyDuotone />}>Copy Link</Button>
                                                    <Dropdown menu={{ items, onClick: (e) => handleMenuClick(e, qrcode) }} key={qrcode?.qr_id}>
                                                        <Button icon={<PiDownloadDuotone />}>
                                                            <Space>
                                                                Download
                                                                <DownOutlined />
                                                            </Space>
                                                        </Button>
                                                    </Dropdown>
                                                    <Button onClick={() => handleEditQr(qrcode)}>Edit QR Code</Button>
                                                    <Button type='primary' onClick={() => navigate(`/customer-insights/customer-list`)}>More Details <PiArrowArcRightDuotone /></Button>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Drawer
                    placement='bottom'
                    closable={false}
                    onClose={() => setDrawer(false)}
                    open={drawer}
                    height={'100vh'}
                    className='qr-popup'
                >
                    <div className='qr-close-btn'>
                        <Button onClick={() => setDrawer(false)} shape="round" className='popup-close-btn'><PiXCircleDuotone size={40} /></Button>
                    </div>
                    <Row style={{ height: '100vh' }}>
                        <Col span={24}>
                            <Flex gap={16} justify='center' align='center' style={{ height: '100vh' }}>
                                {selectedQrData && (
                                    previewType === 'poster' ? (
                                        <div className='qr-poster' ref={uploadContainerRef} style={{ backgroundColor: selectedQrData?.poster_theme_color }}>
                                            <div className='qr-bg-img-div' style={{ backgroundImage: `url(${selectedQrData?.poster_banner ? `${window.BASE_URL}${selectedQrData?.poster_banner}` : bgImg})` }}>
                                                <div className='backdrop'>
                                                    <div className='card-logo-div'>
                                                        <img className='card-logo-image' alt='store-logo' src={selectedQrData?.logo ? `${window.BASE_URL}${selectedQrData?.logo}` : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                    </div>
                                                    <Title level={2} style={{ color: selectedQrData?.poster_text_color }}>{selectedQrData?.poster_title}</Title>
                                                    <div className="instruction" style={{ color: selectedQrData?.poster_text_color }}><span>Scan</span> <PiArrowRightDuotone size={16} /> <span>Claim</span><PiArrowRightDuotone size={16} /><span>Redeem</span></div>
                                                    <Flex justify='center' style={{ marginTop: 28, marginBottom: 38 }}>
                                                        <div className='qrcode-image-div'>
                                                            <QRCode
                                                                size={220}
                                                                errorLevel="H"
                                                                value={`t.zipler.io/${selectedQrData?.enc_qr_id}`}
                                                                icon={selectedQrData?.logo ? `${window.BASE_URL}${selectedQrData?.logo}` : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                            />
                                                        </div>
                                                    </Flex>
                                                    <p className='powered-by' style={{ color: selectedQrData?.poster_text_color }}>Powered by <a href="https://zipler.io" alt="zipler" style={{ color: selectedQrData?.poster_text_color }}>zipler.io</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : previewType === 'sticker' ? (
                                        <div className='qr-code-sticker' ref={uploadContainerRef} style={{ backgroundColor: selectedQrData?.sticker_theme_color }}>
                                            <div className='sticker-head'>
                                                <div className='card-logo-div'>
                                                    <img className='card-logo-image' alt='store-logo' src={selectedQrData?.logo ? `${window.BASE_URL}${selectedQrData?.logo}` : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                </div>
                                                <Title level={3} style={{ color: selectedQrData?.sticker_text_color, marginTop: 16 }}>{selectedQrData?.sticker_title}</Title>
                                            </div>
                                            <div>
                                                <Flex justify='center' style={{ marginBottom: 38 }}>
                                                    <div className='qrcode-image-div'>
                                                        <QRCode
                                                            size={220}
                                                            errorLevel="H"
                                                            value={`t.zipler.io/${selectedQrData?.enc_qr_id}`}
                                                            icon={selectedQrData?.logo ? `${window.BASE_URL}${selectedQrData?.logo}` : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                        />
                                                    </div>
                                                </Flex>
                                                <p className='powered-by' style={{ color: selectedQrData?.sticker_text_color }}>Powered by <a href="https://zipler.io" alt="zipler" style={{ color: selectedQrData?.sticker_text_color }}>zipler.io</a></p>

                                            </div>
                                        </div>
                                    ) : previewType === 'social' ? (
                                        <div className='qr-social-media-container'>
                                            <div className='qr-social-media' ref={uploadContainerRef} style={{ backgroundColor: selectedQrData?.social_theme_color }}>
                                                <div className='qr-bg-img-div' style={{ backgroundImage: `url(${selectedQrData?.social_banner ? `${window.BASE_URL}${selectedQrData?.social_banner}` : bgImg})` }}>
                                                    <div className='backdrop'>
                                                        <div className='left-side'>
                                                            <Flex justify='center' style={{ marginTop: 28, marginBlock: 38 }}>
                                                                <div className='qrcode-image-div'>
                                                                    <QRCode
                                                                        size={220}
                                                                        errorLevel="H"
                                                                        value={`t.zipler.io/${selectedQrData?.enc_qr_id}`}
                                                                        icon={selectedQrData?.logo ? `${window.BASE_URL}${selectedQrData?.logo}` : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                                    />
                                                                </div>
                                                            </Flex>
                                                        </div>
                                                        <div className='right-side'>
                                                            <div className='card-logo-div'>
                                                                <img className='card-logo-image' alt='store-logo' src={selectedQrData?.logo ? `${window.BASE_URL}${selectedQrData?.logo}` : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                            </div>
                                                            <Title level={2} style={{ color: selectedQrData?.social_text_color }}>{selectedQrData?.social_title}</Title>
                                                            <div className="instruction" style={{ color: selectedQrData?.social_text_color }}><span>Scan</span> <PiArrowRightDuotone size={16} /> <span>Claim</span><PiArrowRightDuotone size={16} /><span>Redeem</span></div>
                                                            <p className='powered-by' style={{ color: selectedQrData?.social_text_color }}>Powered by <a href="https://zipler.io" alt="zipler" style={{ color: selectedQrData?.social_text_color }}>zipler.io</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : previewType === 'businesscard' ? (
                                        <div className='qr-business-card-container'>
                                            <div ref={uploadContainerRef} className='qr-business-card' style={{ backgroundColor: selectedQrData?.business_card_theme_color }}>
                                                <div className='business-card-front' style={{ backgroundImage: `url(${selectedQrData?.business_card_banner ? `${window.BASE_URL}${selectedQrData?.business_card_banner}` : bgImg})` }}>
                                                    <div>
                                                        <div className='card-logo-div'>
                                                            <img className='card-logo-image' alt='store-logo' src={selectedQrData?.logo ? `${window.BASE_URL}${selectedQrData?.logo}` : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                        </div>
                                                        <Title level={1} style={{ margin: 0, color: selectedQrData?.business_card_front_text_color }}>{selectedQrData?.business_name}</Title>
                                                    </div>
                                                </div>
                                            </div>
                                            <div ref={uploadContainerRefOne} className='qr-business-card' style={{ backgroundColor: selectedQrData?.business_card_theme_color }}>
                                                <div className='business-card-back'>
                                                    <div className='card-text-side'>
                                                        <div>
                                                            <Title level={1} style={{ margin: 0, color: selectedQrData?.business_card_text_color }}>{selectedQrData?.business_name}</Title>
                                                            <Paragraph style={{ margin: 0, fontSize: 16, color: selectedQrData?.business_card_text_color }}>{selectedQrData?.business_address}</Paragraph>
                                                        </div>
                                                        <div className="sources">
                                                            {selectedQrData?.business_phone !== '' && <div className="source" style={{ color: selectedQrData?.business_card_text_color }}><PiDeviceMobileCameraDuotone size={20} /> <Paragraph style={{ color: selectedQrData?.business_card_text_color, margin: 0, fontSize: 16 }}>{selectedQrData?.business_phone}</Paragraph></div>}
                                                            {selectedQrData?.business_email !== '' && <div className="source" style={{ color: selectedQrData?.business_card_text_color }}><PiEnvelopeDuotone size={20} /> <Paragraph style={{ color: selectedQrData?.business_card_text_color, margin: 0, fontSize: 16 }}>{selectedQrData?.business_email}</Paragraph></div>}
                                                        </div>
                                                    </div>
                                                    <div className='qr-code-side'>
                                                        <div className='qrcode-image-div'>
                                                            <QRCode
                                                                size={170}
                                                                errorLevel="H"
                                                                value={`t.zipler.io/${selectedQrData?.enc_qr_id}`}
                                                                icon={selectedQrData?.logo ? `${window.BASE_URL}${selectedQrData?.logo}` : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                            />
                                                        </div>
                                                        <Paragraph style={{ fontWeight: 500, fontSize: 18, marginBottom: 0, marginTop: 14, color: selectedQrData?.business_card_text_color }}><i>Scan for surprise ✨</i></Paragraph>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : previewType === 'qrcode' && (
                                        <div className='qr-code-only'>
                                            <Flex justify='center' style={{ marginTop: 28, marginBlock: 38 }}>
                                                <div className='qrcode-image-div' ref={uploadContainerRef}>
                                                    <QRCode
                                                        size={220}
                                                        errorLevel="H"
                                                        value={`t.zipler.io/${selectedQrData?.enc_qr_id}`}
                                                        icon={selectedQrData?.logo ? `${window.BASE_URL}${selectedQrData?.logo}` : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                    />
                                                </div>
                                            </Flex>
                                        </div>
                                    )
                                )}
                            </Flex>
                        </Col>
                    </Row>
                </Drawer>
            </>
        )
    );
};
export default QrInsights;