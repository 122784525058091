import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Typography, Flex, Button, Dropdown, Tooltip, Card, Table, Popconfirm, Select, Input } from 'antd';
import { DownOutlined, BulbFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import dayjs from 'dayjs';
import { PiSparkleDuotone, PiLightningDuotone, PiMapPinAreaDuotone, PiCalendarDotsDuotone, PiLightbulbDuotone, PiHandCoinsDuotone, PiEyeDuotone, PiPencilDuotone, PiTrashDuotone, PiDownloadSimpleDuotone, PiUsersDuotone, PiHandDuotone, PiHandDepositDuotone, PiShoppingBagDuotone, PiUploadSimpleDuotone, PiPlusDuotone } from "react-icons/pi";
import { GrAnnounce } from "react-icons/gr";
const { Search } = Input;
const { Option } = Select;
Chart.register(...registerables);

const { Paragraph, Title } = Typography;

const generateRandomData = () => {
    let data = [];
    for (let i = 0; i < 30; i++) {
        const date = dayjs().add(i, 'day').format('MMM D');
        data.push({
            name: date,
            uv: Math.floor(Math.random() * 61),
            pv: Math.floor(Math.random() * 61),
        });
    }
    return data;
};

const generateRandomWeekData = () => {
    return [
        { name: 'Mon-Fri', value: 55, color: '#F7B27A' },
        { name: 'Weekends', value: 26, color: '#60ACAC' },
        { name: 'Both', value: 19, color: '#F9A0B0' }
    ];
};

const generateRandomSpendWeekData = () => {
    return [
        { name: 'First time', value: 740, color: '#A2DFDF' },
        { name: 'Repeat', value: 705, color: '#047272' }
    ];
};

const dataSource = [
    {
        order_id: '1',
        name: 'No Name',
        phone_no: '9XXXXXXXX0',
        action: 'Purchase',
        amount: '1537',
        bill_number: '19235',
        points: '+1537',
        purchased_at: '20 Sep, 2024 | 02:54',
    },
    {
        order_id: '2',
        name: 'No Name',
        phone_no: '8XXXXXXXX5',
        action: 'Purchase',
        amount: '574',
        bill_number: '19234',
        points: '+574',
        purchased_at: '20 Sep, 2024 | 02:59',
    },
    {
        order_id: '3',
        name: 'No Name',
        phone_no: '9XXXXXXXX4',
        action: 'Purchase',
        amount: '439',
        bill_number: '19233',
        points: '+439',
        purchased_at: '20 Sep, 2024 | 02:48',
    },
    {
        order_id: '4',
        name: 'No Name',
        phone_no: '8XXXXXXXX2',
        action: 'Purchase',
        amount: '40',
        bill_number: '19232',
        points: '+40',
        purchased_at: '20 Sep, 2024 | 02:37',
    },
    {
        order_id: '5',
        name: 'No Name',
        phone_no: '8XXXXXXXX2',
        action: 'Loyalty Redemption',
        amount: '0',
        bill_number: '19231',
        points: '-800',
        purchased_at: '20 Sep, 2024 | 02:26',
    },
    {
        order_id: '6',
        name: 'No Name',
        phone_no: '8XXXXXXXX2',
        action: 'Purchase',
        amount: '93',
        bill_number: '19235',
        points: '+93',
        purchased_at: '20 Sep, 2024 | 02:15',
    },
    {
        order_id: '7',
        name: 'No Name',
        phone_no: '9XXXXXXXX6',
        action: 'Purchase',
        amount: '2262',
        bill_number: '19230',
        points: '+2262',
        purchased_at: '20 Sep, 2024 | 02:04',
    },
    {
        order_id: '8',
        name: 'No Name',
        phone_no: '9XXXXXXXX5',
        action: 'Purchase',
        amount: '486',
        bill_number: '19229',
        points: '+486',
        purchased_at: '20 Sep, 2024 | 01:53',
    },
    {
        order_id: '9',
        name: 'No Name',
        phone_no: '9XXXXXXXX7',
        action: 'Purchase',
        amount: '1271',
        bill_number: '19228',
        points: '+1271',
        purchased_at: '20 Sep, 2024 | 01:42',
    },
    {
        order_id: '10',
        name: 'No Name',
        phone_no: '9XXXXXXXX7',
        action: 'Campaign Redemption',
        amount: '0',
        bill_number: '19227',
        points: 'NA',
        purchased_at: '20 Sep, 2024 | 01:15',
    },
];

const assessCustomerTag = (customer) => {
    const currentDate = new Date();
    const lastPurchaseDate = new Date(customer.last_purchase);
    const daysSinceLastPurchase = customer.last_purchase ? Math.floor((currentDate - lastPurchaseDate) / (1000 * 60 * 60 * 24)) : null;
    const totalVisits = parseInt(customer.total_visits);
    const totalSpent = parseInt(customer.total_spent);
    if (customer.last_purchase === '' && totalVisits === 0) {
        return 'new';
    } else if (daysSinceLastPurchase !== null && daysSinceLastPurchase > 180) {
        return 'lost';
    } else if (daysSinceLastPurchase !== null && daysSinceLastPurchase > 90) {
        return 'at_risk';
    } else if (totalSpent >= 1000 && totalVisits > 10) {
        return 'vip';
    } else if (totalSpent > 500 && totalVisits > 3) {
        return 'promising';
    } else if (totalVisits === 1 && daysSinceLastPurchase !== null && daysSinceLastPurchase <= 30) {
        return 'new';
    } else if (totalSpent < 100 && daysSinceLastPurchase !== null && daysSinceLastPurchase > 60) {
        return 'need_attention';
    } else if (totalVisits >= 5 && daysSinceLastPurchase !== null && daysSinceLastPurchase <= 30) {
        return 'loyal';
    } else if (totalSpent < 200 && daysSinceLastPurchase !== null && daysSinceLastPurchase < 30) {
        return 'potential_loyal';
    } else if (daysSinceLastPurchase !== null && daysSinceLastPurchase > 30 && totalVisits > 5) {
        return 'about_to_sleep';
    } else if (totalSpent >= 500 && totalVisits >= 10) {
        return 'cant_loose';
    }
    return null;
};

const getCustomerDisplayName = (tag) => {
    const tagDisplayNames = {
        'new': 'New',
        'at_risk': 'At Risk',
        'lost': 'Lost',
        'vip': 'VIP',
        'promising': 'Promising',
        'need_attention': 'Need Attention',
        'loyal': 'Loyal',
        'potential_loyal': 'Potential Loyal',
        'about_to_sleep': 'About to Sleep',
        'cant_loose': "Can't Lose"
    };
    return tagDisplayNames[tag] || 'Unknown';
};

const datePickerItems = [
    { key: '1', label: 'Highlights for Today' },
    { key: '2', label: 'Last 7 Days' },
    { key: '3', label: 'Last 30 Days' },
    { key: '4', label: 'This Month' },
    { key: '5', label: 'Last Month' },
    { key: '6', label: 'Last 12 Month' },
    { key: '7', label: 'Custom range' }
];


const CustomerContent = ({ userData, sessionData, storeData }) => {
    const storeDetail = storeData?.filter(store => store?.store_id.toString() === localStorage.getItem('storeId'))?.[0];
    const [chartData, setChartData] = useState([]);
    const [weekChartData, setWeekChartData] = useState([]);
    const [weekSpendChartData, setWeekSpendChartData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [customerData, setCustomerData] = useState([]);
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [totalPurchase, setTotalPurchase] = useState(0);
    const [totalImported, setTotalImported] = useState(0);
    const [potentialCustomers, setPotentialCustomers] = useState(0);
    const [blocked, setBlocked] = useState(0);
    const [dataLoading, setDataLoading] = useState(true);
    const [pageSize, setPageSize] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedAction, setSelectedAction] = useState('all');
    const [newCustomrsCount, setNewCustomrsCount] = useState(0);
    const [promisingCustomrsCount, setPromisingCustomrsCount] = useState(0);
    const [lostCustomrsCount, setLostCustomrsCount] = useState(0);
    const [atRiskCustomrsCount, setAtRiskCustomrsCount] = useState(0);
    const [needAttentionCustomrsCount, setNeedAttentionCustomrsCount] = useState(0);
    const [vipCustomrCount, setVipCustomrCount] = useState(0);
    const [loyalCustomrCount, setLoyalCustomrCount] = useState(0);
    const [totalVisits, setTotalVisits] = useState(0);
    const [totalSpent, setTotalSpent] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalNewVisits, setTotalNewVisits] = useState(0);
    const [totalLostVisits, setTotalLostVisits] = useState(0);
    const [totalPromisingVisits, setTotalPromisingVisits] = useState(0);
    const [totalAtRiskVisits, setTotalAtRiskVisits] = useState(0);
    const [totalNeedAttentionVisits, setTotalNeedAttentionVisits] = useState(0);
    const [totalLoyalVisits, setTotalLoyalVisits] = useState(0);
    const [totalVipVisits, setTotalVipVisits] = useState(0);

    const [totalNewSpent, setTotalNewSpent] = useState(0);
    const [totalLostSpent, setTotalLostSpent] = useState(0);
    const [totalPromisingSpent, setTotalPromisingSpent] = useState(0);
    const [totalAtRiskSpent, setTotalAtRiskSpent] = useState(0);
    const [totalNeedAttentionSpent, setTotalNeedAttentionSpent] = useState(0);
    const [totalLoyalSpent, setTotalLoyalSpent] = useState(0);
    const [totalVipSpent, setTotalVipSpent] = useState(0);

    const [totalNewOrders, setTotalNewOrders] = useState(0);
    const [totalLostOrders, setTotalLostOrders] = useState(0);
    const [totalPromisingOrders, setTotalPromisingOrders] = useState(0);
    const [totalAtRiskOrders, setTotalAtRiskOrders] = useState(0);
    const [totalNeedAttentionOrders, setTotalNeedAttentionOrders] = useState(0);
    const [totalLoyalOrders, setTotalLoyalOrders] = useState(0);
    const [totalVipOrders, setTotalVipOrders] = useState(0);

    const { segmentType } = useParams();
    const navigate = useNavigate();

    const handleSearch = (value) => {
        setSearchTerm(value);
    };


    const fetchCustomerOverviewData = useCallback(async () => {
        const storeId = localStorage.getItem('storeId');
        const url = `${window.API_BASE_URL}/users/customer-overview?storeId=${storeId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                setTotalCustomers(data?.totalCustomers);
                setTotalVisits(data?.totalVisits);
                setTotalSpent(data?.totalSpent);
                setTotalOrders(data?.totalOrders);
                setTotalNewSpent(data?.totalNewSpent);
                setTotalNewVisits(data?.totalNewVisits);
                setTotalLostVisits(data?.totalLostVisits);
                setTotalPromisingVisits(data?.totalPromisingVisits);
                setTotalAtRiskVisits(data?.totalAtRiskVisits);
                setTotalNeedAttentionVisits(data?.totalNeedAttentionVisits);
                setTotalLoyalVisits(data?.totalLoyalVisits);
                setTotalVipVisits(data?.totalVipVisits);
                setTotalLostSpent(data?.totalLostSpent);
                setTotalPromisingSpent(data?.totalPromisingSpent);
                setTotalAtRiskSpent(data?.totalAtRiskSpent);
                setTotalNeedAttentionSpent(data?.totalNeedAttentionSpent);
                setTotalLoyalSpent(data?.totalLoyalSpent);
                setTotalVipSpent(data?.totalVipSpent);
                setTotalNewOrders(data?.totalNewOrders);
                setTotalLostOrders(data?.totalLostOrders);
                setTotalPromisingOrders(data?.totalPromisingOrders);
                setTotalAtRiskOrders(data?.totalAtRiskOrders);
                setTotalNeedAttentionOrders(data?.totalNeedAttentionOrders);
                setTotalLoyalOrders(data?.totalLoyalOrders);
                setTotalVipOrders(data?.totalVipOrders);
                setTotalPurchase(data?.totalWithPurchases);
                setTotalImported(data?.totalImported);
                setPotentialCustomers(data?.totalNewCustomers);
                setBlocked(data?.totalInactiveCustomers);
                setNewCustomrsCount(data?.totalNewCustomers);
                setLostCustomrsCount(data?.lostCustomers);
                setPromisingCustomrsCount(data?.promisingCustomers);
                setAtRiskCustomrsCount(data?.atRiskCustomers);
                setNeedAttentionCustomrsCount(data?.needAttentionCustomers);
                setLoyalCustomrCount(data?.loyalCustomers);
                setVipCustomrCount(data?.vipCustomers);
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                }
                console.error('Error fetching user data:', data.message);
            }
        } catch (error) {
            console.error('Error during fetching store data:', error);
        } finally {
            setDataLoading(false);
        }
    }, [sessionData?.token]);


    const fetchCustomerData = useCallback(async () => {
        const storeId = localStorage.getItem('storeId');
        const url = `${window.API_BASE_URL}/users/customer?storeId=${storeId}&page=${pageNumber}&search=${searchTerm}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data);
                setCustomerData(data?.users);
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                }
                console.error('Error fetching user data:', data.message);
            }
        } catch (error) {
            console.error('Error during fetching store data:', error);
        } finally {
            setDataLoading(false);
        }
    }, [sessionData?.token, pageNumber, searchTerm]);

    useEffect(() => {
        const fetchData = async () => {
            if (sessionData) {
                await fetchCustomerOverviewData();
                await fetchCustomerData();
            } else {
                setDataLoading(false);
            }
        };
        fetchData();
    }, [fetchCustomerOverviewData, fetchCustomerData, sessionData]);

    useEffect(() => {
        setChartData(generateRandomData());
        setWeekChartData(generateRandomWeekData());
        setWeekSpendChartData(generateRandomSpendWeekData())
    }, []);

    const handleEdit = () => {

    };

    const handleDelete = () => {

    };

    const handleChange = (value) => {
        setSelectedAction(value);
        console.log(selectedAction);
    };

    const columns = [
        {
            title: 'Bill Number',
            dataIndex: 'bill_number',
            key: 'bill_number',
            render: (_, record) => (
                <b>#{record.bill_number}</b>
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mobile',
            dataIndex: 'phone_no',
            key: 'phone_no',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'Bill Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, record) => (
                <span>₹{record.amount}</span>
            )
        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',
            render: (_, record) => (
                <span style={{ color: record.points.startsWith('+') ? 'green' : record.points.startsWith('-') ? 'red' : 'inherit' }}>{record.points}</span>
            )
        },
        {
            title: 'Date & Time',
            dataIndex: 'purchased_at',
            key: 'purchased_at',
        },
        {
            title: 'Edit',
            dataIndex: 'order_id',
            key: 'order_id',
            render: (_, record) => (
                <Flex gap="small" align="flex-end" vertical>
                    <Flex gap="small">
                        <Tooltip title="Edit Customer">
                            <PiPencilDuotone size={24} style={{ color: 'blue' }} onClick={() => handleEdit(record)} />
                        </Tooltip>
                        <Popconfirm
                            title="Delete the Contact"
                            description="Are you sure to delete this Contact?"
                            onConfirm={() => handleDelete(record.customer_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip title="Delete Customer"><PiTrashDuotone style={{ color: 'red' }} size={24} /></Tooltip>
                        </Popconfirm>
                    </Flex>
                </Flex>
            )
        }
    ];

    const CustomerListcolumns = [
        {
            title: 'Name',
            dataIndex: 'customer_name',
            key: 'name',
        },
        {
            title: 'Segment',
            dataIndex: 'segment',
            key: 'segment',
            render: (_, record) => (
                <span className={`segment ${assessCustomerTag(record)}`}>
                    <span style={{position:'relative'}}>{getCustomerDisplayName(assessCustomerTag(record))}</span>
                </span>
            )
        },
        {
            title: 'Phone #.',
            dataIndex: 'customer_number',
            key: 'customer_number',
        },
        {
            title: 'Total Spent',
            dataIndex: 'total_spent',
            key: 'total_spent',
        },
        {
            title: 'Total visits',
            dataIndex: 'total_visits',
            key: 'total_visits',
        },
        {
            title: 'Pts. Balance',
            dataIndex: 'customer_points',
            key: 'customer_points',
        },
        {
            title: 'Redemption',
            dataIndex: 'point_redemption',
            key: 'point_redemption'
        },
        {
            title: 'Tags',
            dataIndex: 'customer_id',
            key: 'customer_id',
            render: (_, record) => (
                <Flex gap="small" align="flex-end" vertical>
                    <Flex gap="small">
                        {record.customer_tags ? JSON.parse(record.customer_tags).length > 0 ? JSON.parse(record.customer_tags).join(', ') : <Tooltip title="Add Tag">
                            <Flex gap='small' style={{ color: 'blue' }}><PiPlusDuotone size={24} onClick={() => handleEdit(record)} />Add</Flex>
                        </Tooltip> : <Tooltip title="Add Tag">
                            <Flex gap='small' style={{ color: 'blue' }}><PiPlusDuotone size={24} onClick={() => handleEdit(record)} />Add</Flex>
                        </Tooltip>}
                    </Flex>
                </Flex>
            )
        }
    ];

    const thirtydata = useMemo(() => ({
        labels: chartData.map(item => item.name),
        datasets: [
            {
                label: 'New',
                data: chartData.map(item => item.pv),
                backgroundColor: '#7eb5e9',
                borderRadius: 20,
            },
            {
                label: 'Returning',
                data: chartData.map(item => item.uv),
                backgroundColor: '#d5dbe2',
                borderRadius: 20,
            }
        ]
    }), [chartData]);

    const thirtyoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        return `${label}: ${value}`;
                    }
                }
            },
            legend: {
                position: 'top',
                align: 'end',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
    };

    const weekdataoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        return `${label}: ${value}`;
                    }
                }
            },
            legend: {
                display: false,
                position: 'top',
                align: 'end',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                display: false,
            },
        },
    };
    const weekdata = useMemo(() => ({
        labels: weekChartData.map(item => item.name),
        datasets: [{
            label: 'Percentage',
            data: weekChartData.map(item => item.value),
            backgroundColor: weekChartData.map(item => item.color),
            borderRadius: 20,
        }]
    }), [weekChartData]);

    const weekspenddata = useMemo(() => ({
        labels: weekSpendChartData.map(item => item.name),
        datasets: [{
            label: ' ₹ Spend',
            data: weekSpendChartData.map(item => item.value),
            backgroundColor: weekSpendChartData.map(item => item.color),
            borderRadius: 20,
        }]
    }), [weekSpendChartData]);

    const handleSegmentClick = (segmentType) => {
        navigate(`/customer-insights/${segmentType}`);
    };

    return (
        <Layout className='contentLayout'>
            <div style={{ marginBottom: 24 }}>
                <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Customer Insights</b></Title>
                <Paragraph style={{ color: '#697777' }}>All your customer behavior data, segmentation details, and activity, easily accessible in one place.</Paragraph>
            </div>
            <Row gutter={16}>
                <Col span={24}>
                    <Flex gap='middle'>
                        {/* <Button type={!segmentType ? 'primary' : segmentType === 'overview' && 'primary'} onClick={() => handleSegmentClick('overview')} shape="round" size='large'>Overview</Button> */}
                        <Button type={!segmentType ? 'primary' : segmentType === 'segmentation' && 'primary'} onClick={() => handleSegmentClick('segmentation')} shape="round" size='large'>Segmentation</Button>
                        <Button type={segmentType === 'customer-list' && 'primary'} onClick={() => handleSegmentClick('customer-list')} shape="round" size='large'>Customer List</Button>
                        {/* <Button type={segmentType === 'activity' && 'primary'} onClick={() => handleSegmentClick('activity')} shape="round" size='large'>Activity</Button> */}
                    </Flex>
                </Col>
            </Row>
            {segmentType === 'overview' ? (
                <Row gutter={16}>
                    <Col span={24} style={{ marginBottom: 16 }}>
                        <Flex gap='middle' justify='space-between' align='center'>
                            <Title level={3}>Customer Snapshot</Title>
                            <Dropdown
                                menu={{
                                    items: datePickerItems,
                                }}
                                className='summarydroptitle'
                            ><Button >Last 12 Months 1,Sep 23 - 19, Sep 24 <DownOutlined /></Button>
                            </Dropdown>
                        </Flex>
                    </Col>
                    <Col span={8}>
                        <div className='cust-detail-card'>
                            <Title level={2}>9344</Title>
                            <Title level={5}>Total Unique Customers</Title>
                            <Tooltip title={
                                <div>
                                    <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                    <p>Total unique customers that visited your store during the selected period.</p>
                                </div>
                            }>
                                <InfoCircleOutlined style={{ fontSize: 16 }} />
                            </Tooltip>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className='cust-detail-card'>
                            <Title level={2}>9344</Title>
                            <Title level={5}>New Customer In Selected Period</Title>
                            <Tooltip title={
                                <div>
                                    <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                    <p>All the customers that visited your store and made their first-ever purchase at your store for the selected period.</p>
                                </div>
                            }>
                                <InfoCircleOutlined style={{ fontSize: 16 }} />
                            </Tooltip>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className='cust-detail-card'>
                            <Title level={2}>3073</Title>
                            <Title level={5}>Customer Who Visited More than Once in Selected Period</Title>
                            <Tooltip title={
                                <div>
                                    <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                    <p>All the customers that visited your store more than once for the selected period.</p>
                                </div>
                            }>
                                <InfoCircleOutlined style={{ fontSize: 16 }} />
                            </Tooltip>
                        </div>
                    </Col>
                    <Col span={24}>
                        <Card hoverable bordered={false} style={{ marginTop: 16 }}>
                            <Flex gap='middle' justify='space-between' align='center'>
                                <Title level={3} style={{ margin: 0 }}>New vs Returning <Tooltip title={
                                    <div>
                                        <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                        <p>This graph provides a quick overview of your new vs returning customers as well as customers that are getting lost for selected time period.</p>
                                    </div>
                                }>
                                    <InfoCircleOutlined style={{ fontSize: 16 }} />
                                </Tooltip></Title>
                                <Dropdown
                                    menu={{
                                        items: datePickerItems,
                                    }}
                                    className='summarydroptitle'
                                ><Button >Last 30 Days 22,Aug 24 - 21, Sep 24 <DownOutlined /></Button>
                                </Dropdown>
                            </Flex>
                            <div style={{ width: '100%', height: 500, marginTop: 16 }}>
                                <Bar data={thirtydata} options={thirtyoptions} />
                            </div>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Title level={3} style={{ marginBottom: 20 }}>Know your customers like never before!</Title>
                        <Row gutter={16}>
                            <Col span={12}>
                                <div className='customer-insight-know-customers-left'>
                                    <Title level={4} style={{ marginTop: 0 }}>Customers By Day</Title>
                                    <Title level={1} style={{ margin: 0, color: '#6938ef', fontWeight: 'bold' }}>55%</Title>
                                    <Title level={4} style={{ marginBottom: 0, marginTop: 6 }}>Customer make purchase only on weekdays</Title>
                                    <Title level={5} style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}><PiCalendarDotsDuotone size={16} />&nbsp;Weekend vs weekday</Title>
                                    <div style={{ width: '100%', height: 300, marginTop: 16 }}>
                                        <Bar data={weekdata} options={weekdataoptions} />
                                    </div>
                                    <div style={{ background: '#FFFFFF', padding: 16, marginTop: 16, borderRadius: 16 }}>
                                        <Title level={5} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', color: '#dd990a', marginTop: 0 }}><PiSparkleDuotone size={16} />&nbsp;WHAT IT MEANS?</Title>
                                        <Paragraph style={{ fontSize: 16 }}>Your customers' love the week but your business suffers over the weekend.</Paragraph>
                                    </div>
                                    <div style={{ background: '#FFFFFF', padding: 16, marginTop: 16, borderRadius: 16 }}>
                                        <Title level={5} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', color: '#3A1A55', marginTop: 0 }}><PiLightningDuotone size={16} />&nbsp;TAKE ACTION!</Title>
                                        <Paragraph style={{ fontSize: 16 }}><b>Promote weekend incentives to weekday customers</b><br />Time shifting will draw more business during slow days, and will get your customers to visit.</Paragraph>
                                        <Button type='primary' style={{ height: 40, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><GrAnnounce /> Send Campaign</Button>
                                    </div>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div className='customer-insight-know-customers-right'>
                                    <Title level={4} style={{ marginTop: 0 }}>Average spend by repeat customers</Title>
                                    <Title level={1} style={{ margin: 0, color: '#099250', fontWeight: 'bold' }}>5%</Title>
                                    <Title level={4} style={{ marginBottom: 0, marginTop: 6 }}>Less spend on every order by repeat customers.</Title>
                                    <Title level={5} style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}><PiMapPinAreaDuotone size={16} />&nbsp;Spend</Title>
                                    <div style={{ width: '100%', height: 300, marginTop: 16 }}>
                                        <Bar data={weekspenddata} options={weekdataoptions} />
                                    </div>
                                    <div style={{ background: '#FFFFFF', padding: 16, marginTop: 16, borderRadius: 16 }}>
                                        <Title level={5} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', color: '#dd990a', marginTop: 0 }}><PiSparkleDuotone size={16} />&nbsp;WHAT IT MEANS?</Title>
                                        <Paragraph style={{ fontSize: 16 }}>Your new customers have a higher order value than your repeat customers.</Paragraph>
                                    </div>
                                    <div style={{ background: '#FFFFFF', padding: 16, marginTop: 16, borderRadius: 16 }}>
                                        <Title level={5} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', color: '#3A1A55', marginTop: 0 }}><PiLightningDuotone size={16} />&nbsp;TAKE ACTION!</Title>
                                        <Paragraph style={{ fontSize: 16 }}><b>Incentivize repeat customers with offers</b><br />Engage with your repeat customers by sending campaigns that increase their ticket size and drive more value from them.</Paragraph>
                                        <Button type='primary' style={{ height: 40, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><GrAnnounce /> Send Campaign</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : !segmentType || segmentType === 'segmentation' ? (
                <Row gutter={16}>
                    <Col span={24} style={{ marginBottom: 16 }}>
                        <Title level={3}>{totalCustomers} Total customers <Tooltip title={
                            <div>
                                <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                <p>This is the total number of customers who have made a purchase at your business. This doesn’t include customes who have been imported.</p>
                            </div>
                        }>
                            <InfoCircleOutlined style={{ fontSize: 16 }} />
                        </Tooltip></Title>
                        <Paragraph style={{ color: '#697777' }}>Explore how recently, how often and how much money a customer has given your brand with RFM analysis. <Tooltip title={
                            <div>
                                <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                <p>RFM segmentation helps you identify your groups of customers for special treatment and improve your customer marketing. RFM stands for recency, frequency and monetary.</p>
                            </div>
                        }>
                            <InfoCircleOutlined style={{ fontSize: 16 }} />
                        </Tooltip></Paragraph>
                    </Col>
                    <Col span={24}>
                        <Row gutter={16} className='cust-segment-row'>
                            <Col span={6}>
                                <div className='cust-segment-card green'>
                                    <Tooltip color='#FFFFFF' overlayClassName='cust-segment-tooltip' title={
                                        <div>
                                            <Title level={4} className='tooltupTitle green'>New</Title>
                                            <Paragraph style={{ marginBottom: 0 }}>These customers have recently visited your business for the first time.</Paragraph>
                                            <Title level={1} style={{ margin: 0 }}><PiLightbulbDuotone size={24} style={{ color: '#F9C23C' }} /></Title>
                                            <Title level={5} style={{ margin: 0 }}>Pro-tip</Title>
                                            <Paragraph>Send a warm and welcoming message to make them feel extra special!</Paragraph>
                                            <Button onClick={() => navigate('/campaigns/addnew')} type='primary' style={{ height: 40, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><GrAnnounce /> Send Campaign</Button>
                                        </div>
                                    }>
                                        <div className='card-body'>
                                            <div className='content'>
                                                <Paragraph style={{ margin: 0 }}>New</Paragraph>
                                                <Title level={2} style={{ margin: 0 }}>{((newCustomrsCount && totalCustomers) ? (newCustomrsCount / totalCustomers) * 100 : 0).toFixed(0)}%</Title>
                                                <Paragraph style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 0 }}>{newCustomrsCount} customers</Paragraph>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className='cust-segment-card two orange'>
                                    <Tooltip color='#FFFFFF' overlayClassName='cust-segment-tooltip' title={
                                        <div>
                                            <Title level={4} className='tooltupTitle orange'>Promising</Title>
                                            <Paragraph style={{ marginBottom: 0 }}>These customers have visited your business recently, but they haven't spent much.</Paragraph>
                                            <Title level={1} style={{ margin: 0 }}><PiLightbulbDuotone size={24} style={{ color: '#F9C23C' }} /></Title>
                                            <Title level={5} style={{ margin: 0 }}>Pro-tip</Title>
                                            <Paragraph>Send tempting offers and give them a friendly brand recall to encourage their return!</Paragraph>
                                            <Button onClick={() => navigate('/campaigns/addnew')} type='primary' style={{ height: 40, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><GrAnnounce /> Send Campaign</Button>
                                        </div>
                                    }>
                                        <div className='card-body'>
                                            <div className='content'>
                                                <Paragraph style={{ margin: 0 }}>Promising</Paragraph>
                                                <Title level={2} style={{ margin: 0 }}>{((promisingCustomrsCount ? promisingCustomrsCount : 0 / totalCustomers ? totalCustomers : 0) * 100).toFixed(0)}%</Title>
                                                <Paragraph style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 0 }}>{promisingCustomrsCount} customers</Paragraph>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='cust-segment-card two pink'>
                                    <Tooltip color='#FFFFFF' overlayClassName='cust-segment-tooltip' title={
                                        <div>
                                            <Title level={4} className='tooltupTitle pink'>Lost</Title>
                                            <Paragraph style={{ marginBottom: 0 }}>These customers have not visited your business in a very long time or very often, and they have spent the least.</Paragraph>
                                            <Title level={1} style={{ margin: 0 }}><PiLightbulbDuotone size={24} style={{ color: '#F9C23C' }} /></Title>
                                            <Title level={5} style={{ margin: 0 }}>Pro-tip</Title>
                                            <Paragraph>Explore our win-back campaigns and win them over!</Paragraph>
                                            <Button onClick={() => navigate('/campaigns/addnew')} type='primary' style={{ height: 40, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><GrAnnounce /> Send Campaign</Button>
                                        </div>
                                    }>
                                        <div className='card-body'>
                                            <div className='content'>
                                                <Paragraph style={{ margin: 0 }}>Lost</Paragraph>
                                                <Title level={2} style={{ margin: 0 }}>{((lostCustomrsCount ? lostCustomrsCount : 0 / totalCustomers ? totalCustomers : 0) * 100).toFixed(0)}%</Title>
                                                <Paragraph style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 0 }}>{lostCustomrsCount} customers</Paragraph>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className='cust-segment-card two yellow'>
                                    <Tooltip color='#FFFFFF' overlayClassName='cust-segment-tooltip' title={
                                        <div>
                                            <Title level={4} className='tooltupTitle yellow'>At Risk</Title>
                                            <Paragraph style={{ marginBottom: 0 }}>These customers are average spenders, but they haven't visited your business recently.</Paragraph>
                                            <Title level={1} style={{ margin: 0 }}><PiLightbulbDuotone size={24} style={{ color: '#F9C23C' }} /></Title>
                                            <Title level={5} style={{ margin: 0 }}>Pro-tip</Title>
                                            <Paragraph>Act fast before they're gone, and offer something they can't resist!</Paragraph>
                                            <Button onClick={() => navigate('/campaigns/addnew')} type='primary' style={{ height: 40, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><GrAnnounce /> Send Campaign</Button>
                                        </div>
                                    }>
                                        <div className='card-body'>
                                            <div className='content'>
                                                <Paragraph style={{ margin: 0 }}>At Risk</Paragraph>
                                                <Title level={2} style={{ margin: 0 }}>{((atRiskCustomrsCount ? atRiskCustomrsCount : 0 / totalCustomers ? totalCustomers : 0) * 100).toFixed(0)}%</Title>
                                                <Paragraph style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 0 }}>{atRiskCustomrsCount} customers</Paragraph>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='cust-segment-card two purple'>
                                    <Tooltip color='#FFFFFF' overlayClassName='cust-segment-tooltip' title={
                                        <div>
                                            <Title level={4} className='tooltupTitle purple'>Need Attention</Title>
                                            <Paragraph style={{ marginBottom: 0 }}>These customers visit very occasionally, spend an average amount, and haven't made a purchase recently.</Paragraph>
                                            <Title level={1} style={{ margin: 0 }}><PiLightbulbDuotone size={24} style={{ color: '#F9C23C' }} /></Title>
                                            <Title level={5} style={{ margin: 0 }}>Pro-tip</Title>
                                            <Paragraph>Reconnect with a friendly touch, send them a personalised offer!</Paragraph>
                                            <Button onClick={() => navigate('/campaigns/addnew')} type='primary' style={{ height: 40, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><GrAnnounce /> Send Campaign</Button>
                                        </div>
                                    }>
                                        <div className='card-body'>
                                            <div className='content'>
                                                <Paragraph style={{ margin: 0 }}>Needs Attention</Paragraph>
                                                <Title level={2} style={{ margin: 0 }}>{((needAttentionCustomrsCount ? needAttentionCustomrsCount : 0 / totalCustomers ? totalCustomers : 0) * 100).toFixed(0)}%</Title>
                                                <Paragraph style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 0 }}>{needAttentionCustomrsCount} customers</Paragraph>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className='cust-segment-card two blue'>
                                    <Tooltip color='#FFFFFF' overlayClassName='cust-segment-tooltip' title={
                                        <div>
                                            <Title level={4} className='tooltupTitle blue'>VIP</Title>
                                            <Paragraph style={{ marginBottom: 0 }}>These customers are frequent visitors who have recently been to your business and spend the most.</Paragraph>
                                            <Title level={1} style={{ margin: 0 }}><PiLightbulbDuotone size={24} style={{ color: '#F9C23C' }} /></Title>
                                            <Title level={5} style={{ margin: 0 }}>Pro-tip</Title>
                                            <Paragraph>Invite them to exclusive events, store openings, or just pamper them with special offers!</Paragraph>
                                            <Button onClick={() => navigate('/campaigns/addnew')} type='primary' style={{ height: 40, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><GrAnnounce /> Send Campaign</Button>
                                        </div>
                                    }>
                                        <div className='card-body'>
                                            <div className='content'>
                                                <Paragraph style={{ margin: 0 }}>VIP</Paragraph>
                                                <Title level={2} style={{ margin: 0 }}>{((vipCustomrCount ? vipCustomrCount : 0 / totalCustomers ? totalCustomers : 0) * 100).toFixed(0)}%</Title>
                                                <Paragraph style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 0 }}>{vipCustomrCount} customers</Paragraph>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='cust-segment-card two greenblue'>
                                    <Tooltip color='#FFFFFF' overlayClassName='cust-segment-tooltip' title={
                                        <div>
                                            <Title level={4} className='tooltupTitle greenblue'>Loyal</Title>
                                            <Paragraph style={{ marginBottom: 0 }}>These are frequent visitors, and they spend the most, but they haven't visited in a while.</Paragraph>
                                            <Title level={1} style={{ margin: 0 }}><PiLightbulbDuotone size={24} style={{ color: '#F9C23C' }} /></Title>
                                            <Title level={5} style={{ margin: 0 }}>Pro-tip</Title>
                                            <Paragraph>Offer them related products to upsell or cross-sell. They're likely to make a purchase!</Paragraph>
                                            <Button onClick={() => navigate('/campaigns/addnew')} type='primary' style={{ height: 40, lineHeight: 0, paddingTop: 0, paddingBottom: 0 }}><GrAnnounce /> Send Campaign</Button>
                                        </div>
                                    }>
                                        <div className='card-body'>
                                            <div className='content'>
                                                <Paragraph style={{ margin: 0 }}>Loyal</Paragraph>
                                                <Title level={2} style={{ margin: 0 }}>{((loyalCustomrCount ? loyalCustomrCount : 0 / totalCustomers ? totalCustomers : 0) * 100).toFixed(0)}%</Title>
                                                <Paragraph style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 0 }}>{loyalCustomrCount} customers</Paragraph>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <div style={{ marginTop: 16, padding: 24, backgroundColor: '#E9EDED', borderRadius: 16 }}>
                            <Flex gap='middle' justify='space-between' align='center'>
                                <Paragraph style={{ margin: 0, display: 'flex', alignItems: 'center' }}><PiHandCoinsDuotone size={24} />&nbsp;Potential Customer</Paragraph>
                                <div style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                                    <Paragraph onClick={() => navigate('/customer-insights/customer-list')} style={{ fontWeight: 'bold', marginTop: 0, marginBottom: 0, display: 'flex', alignItems: 'center', marginRight: 16, cursor: 'pointer' }}>View Customers</Paragraph>
                                    <Paragraph onClick={() => navigate('/campaigns/addnew')} style={{ fontWeight: 'bold', margin: 0, display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#3A1A55' }}>Send Campaigns</Paragraph>
                                </div>
                            </Flex>
                        </div>
                    </Col>
                    <Col span={24}>
                        <Flex gap='middle' justify='space-between' align='center'>
                            <Title level={3}>Compare Segments</Title>
                        </Flex>
                        <table className='segmenttable'>
                            <thead>
                                <tr>
                                    <th>Segment</th>
                                    <th>Customers</th>
                                    <th>Visits</th>
                                    <th>Avg Visit / Customer</th>
                                    <th>AOV</th>
                                    <th>Total Sales</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {[
                                    {
                                        label: 'New',
                                        count: newCustomrsCount,
                                        color: 'green',
                                        totalVisits: totalNewVisits,
                                        totalOrders: totalNewOrders,
                                        totalSpent: totalNewSpent,
                                    },
                                    {
                                        label: 'Promising',
                                        count: promisingCustomrsCount,
                                        color: 'orange',
                                        totalVisits: totalPromisingVisits,
                                        totalOrders: totalPromisingOrders,
                                        totalSpent: totalPromisingSpent,
                                    },
                                    {
                                        label: 'Lost',
                                        count: lostCustomrsCount,
                                        color: 'pink',
                                        totalVisits: totalLostVisits,
                                        totalOrders: totalLostOrders,
                                        totalSpent: totalLostSpent,
                                    },
                                    {
                                        label: 'Needs Attention',
                                        count: needAttentionCustomrsCount,
                                        color: 'yellow',
                                        totalVisits: totalNeedAttentionVisits,
                                        totalOrders: totalNeedAttentionOrders,
                                        totalSpent: totalNeedAttentionSpent,
                                    },
                                    {
                                        label: 'At Risk',
                                        count: atRiskCustomrsCount,
                                        color: 'purple',
                                        totalVisits: totalAtRiskVisits,
                                        totalOrders: totalAtRiskOrders,
                                        totalSpent: totalAtRiskSpent,
                                    },
                                    {
                                        label: 'VIP',
                                        count: vipCustomrCount,
                                        color: 'blue',
                                        totalVisits: totalVipVisits,
                                        totalOrders: totalVipOrders,
                                        totalSpent: totalVipSpent,
                                    },
                                    {
                                        label: 'Loyal',
                                        count: loyalCustomrCount,
                                        color: 'greenblue',
                                        totalVisits: totalLoyalVisits,
                                        totalOrders: totalLoyalOrders,
                                        totalSpent: totalLoyalSpent,
                                    },
                                ].map((segment) => (
                                    <tr key={segment.label}>
                                        <td>
                                            <div className={segment.color}></div>
                                            {segment.label}
                                        </td>
                                        <td>{segment.count}</td>
                                        <td>{segment.totalVisits}</td>
                                        <td>{segment.count > 0 ? (segment.totalVisits / segment.count).toFixed(2) : 0}</td>
                                        <td>{storeDetail?.currency_symbol} {segment.totalOrders > 0 ? (segment.totalSpent / segment.totalOrders).toFixed(2) : 0}</td>
                                        <td>{storeDetail?.currency_symbol} {segment.totalSpent}</td>
                                        <td>
                                            <Button type="primary" onClick={() => navigate('/customer-insights/customer-list')} className='previewBtnOne'><PiEyeDuotone /></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            ) : segmentType === 'customer-list' ? (
                <Row gutter={16}>
                    <Col span={24} style={{ marginBottom: 16 }}>
                        <Card bordered={false} className='customers-list-card-one'>
                            <Title level={3} style={{ marginTop: 0 }}>Quick Glance</Title>
                            <Flex gap='middle' justify='space-evenly'>
                                <div className='customers-list-card-inner-div'>
                                    <Title level={2} style={{ marginTop: 0 }}>{totalCustomers}</Title>
                                    <Tooltip title='This represents the total number of customers in your zipler.io account.'>
                                        <Flex gap='small' align='center'><PiUsersDuotone size={30} style={{ color: '#3A1A55' }} /><Paragraph className='revtitle'>Total Customers</Paragraph></Flex>
                                    </Tooltip>
                                </div>
                                <div className='customers-list-card-inner-div'>
                                    <Title level={2} style={{ marginTop: 0 }}>{totalPurchase}</Title>
                                    <Tooltip title='These are the total number of customers who have made a purchase from your company.'>
                                        <Flex gap='small' align='center'><PiShoppingBagDuotone size={30} style={{ color: '#3A1A55' }} /><Paragraph className='revtitle'>Purchased</Paragraph></Flex>
                                    </Tooltip>
                                </div>
                                <div className='customers-list-card-inner-div'>
                                    <Title level={2} style={{ marginTop: 0 }}>{totalImported}</Title>
                                    <Tooltip title='These are all of the customers who have been imported but have yet to make a purchase.'>
                                        <Flex gap='small' align='center'><PiHandDepositDuotone size={30} style={{ color: '#3A1A55' }} /><Paragraph className='revtitle'>Imported</Paragraph></Flex>
                                    </Tooltip>
                                </div>
                                <div className='customers-list-card-inner-div'>
                                    <Title level={2} style={{ marginTop: 0 }}>{potentialCustomers}</Title>
                                    <Tooltip title={`This is the total number of customers who have been captured but have not yet made a purchase (via QR Code, Referral Program, or Cashier Portal's unsettled bill).`}>
                                        <Flex gap='small' align='center'><PiHandCoinsDuotone size={30} style={{ color: '#3A1A55' }} /><Paragraph className='revtitle'>Potential</Paragraph></Flex>
                                    </Tooltip>
                                </div>
                                <div className='customers-list-card-inner-div'>
                                    <Title level={2} style={{ marginTop: 0 }}>{blocked}</Title>
                                    <Tooltip title='These are all of the customers who have been blocked.'>
                                        <Flex gap='small' align='center'><PiHandDuotone size={30} style={{ color: '#3A1A55' }} /><Paragraph className='revtitle'>Blocked</Paragraph></Flex>
                                    </Tooltip>
                                </div>
                            </Flex>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Flex gap={'middle'}>
                            <Search placeholder="Search customers" onSearch={(e) => handleSearch(e)} style={{ marginBottom: '16px' }} />
                            <Button onClick={() => navigate(`/add-customers/import-customers`)}><PiUploadSimpleDuotone /> Import</Button>
                            <Button><PiDownloadSimpleDuotone /> Export</Button>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Table
                            className='customerTable'
                            dataSource={customerData}
                            columns={CustomerListcolumns}
                            loading={dataLoading}
                            pagination={{
                                total: totalCustomers,
                                pageSize: pageSize,
                                pageNumber: pageNumber,
                                pageSizeOptions: ['10', '20', '50', '100', '200', '500', '1000'],
                                onChange: (page, pageSize) => {
                                    setPageNumber(page);
                                    setPageSize(pageSize);
                                },
                                showTotal: (total, range) => (
                                    <span className="totaldata">Showing {range[0]} to {range[1]} of {total} entries</span>
                                )
                            }}
                            onRow={(record) => ({
                                onClick: () => navigate(`/orders/${record.customer_id}`),
                            })} />
                    </Col>
                </Row>
            ) : segmentType === 'activity' && (
                <Row gutter={16} style={{ marginTop: 16 }}>
                    <Col span={24}>
                        <Flex gap={'middle'}>
                            <Search placeholder="Search customers" onChange={(e) => handleSearch(e.target.value)} style={{ marginBottom: '16px', maxWidth: 300 }} />
                            <Select
                                placeholder="Select Action"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={handleChange}
                                style={{ width: 200 }}
                                defaultValue={'all'}
                            >
                                <Option key='all' value='all' >All</Option>
                                <Option key='purchase' value='purchase'>Purchase</Option>
                                <Option key='qrbonus'>QR Bonus</Option>
                                <Option key='referal_bonus'>Referal Bonus</Option>
                                <Option key='feedback_bonus'>Feedback Bonus</Option>
                                <Option key='profile_complete_bonus'>Profile Complete Bonus</Option>
                                <Option key='import_bonus'>Import Bonus</Option>
                                <Option key='loyalty_redemption'>Loyalty Redemption</Option>
                                <Option key='qr_redemption'>QR Redemption</Option>
                                <Option key='campaign_redemption'>Campaign Redemption</Option>
                                <Option key='referral_redemption'>Referral Redemption</Option>
                                <Option key='points_expired'>Points Expired</Option>
                                <Option key='fraudulent_activity'>Fraudulent Activity</Option>
                            </Select>
                            <Select
                                placeholder="Select Action"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={handleChange}
                                style={{ width: 200 }}
                                defaultValue={'last_30'}
                            >
                                <Option key='today' value='today'>Today</Option>
                                <Option key='last_7' value='last_7'>Last 7 Days</Option>
                                <Option key='last_30' value='last_30'>Last 30 Days 22,Aug 24 - 21, Sep 24</Option>
                                <Option key='today' value='today'>This Month</Option>
                                <Option key='today' value='today'>Last Month</Option>
                                <Option key='today' value='today'>Last 6 Month</Option>
                                <Option key='today' value='today'>Last 12 Month</Option>
                                <Option key='custom' value='custom'>Custom Range</Option>
                            </Select>
                            <Button><PiDownloadSimpleDuotone /> Export Data</Button>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        <Table
                            className='orderTable'
                            dataSource={dataSource}
                            columns={columns}
                            pagination={{
                                total: 43003,
                                pageSize: pageSize,
                                pageNumber: pageNumber,
                                pageSizeOptions: ['10', '20', '50', '100', '200', '500', '1000'],
                                onChange: (page, pageSize) => {
                                    setPageNumber(page);
                                    setPageSize(pageSize);
                                },
                                showTotal: (total, range) => (
                                    <span className="totaldata">Showing {range[0]} to {range[1]} of {total} entries</span>
                                )
                            }} />
                    </Col>
                </Row>
            )}
        </Layout>
    );
};
export default CustomerContent;