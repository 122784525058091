import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Layout, Row, Col, Typography, Table, message } from "antd";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const Orders = ({ userData, sessionData, storeData }) => {
  const storeDetail = storeData?.find(store => store?.store_id.toString() === localStorage.getItem("storeId"));
  const { customerId } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  const fetchOrderData = useCallback(async () => {
    const url = `${window.API_BASE_URL}/users/orders?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionData?.token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setOrderData(data?.order);
      } else {
        if (data.message === "Invalid token") {
          localStorage.clear();
        }
        message.error(data.message || "Failed to fetch order data.");
      }
    } catch (error) {
      message.error("An error occurred while fetching order data.");
      console.error("Fetch error:", error);
    } finally {
      setDataLoading(false);
    }
  }, [sessionData?.token, customerId]);

  useEffect(() => {
    if (sessionData && customerId) {
      fetchOrderData();
    } else {
      setDataLoading(false);
    }
  }, [fetchOrderData, sessionData, customerId]);

  const orderListcolumns = useMemo(() => [
    {
      title: "Order ID",
      dataIndex: "order_number",
      key: "order_number",
      render: order_number => `#${order_number}`,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: amount => `${storeDetail?.currency_symbol} ${amount}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: status => (status === "1" ? "Active" : status === "2" ? "Deactive" : "Unknown"),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
  ], [storeDetail]);

  return (
    <Layout className="contentLayout">
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <Title level={3} style={{ margin: 0 }}>
          <b>Orders</b>
        </Title>
      </div>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            className="customerTableOne"
            dataSource={orderData}
            columns={orderListcolumns}
            loading={dataLoading}
            rowKey="order_id"
            pagination={{ pageSize: 10 }}
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default Orders;