import React, { useState, useCallback, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Button,
  Flex,
  Image,
  Modal,
  Switch,
  Space,
  Radio,
  Input,
  Spin,
  Form,
  Select,
  Checkbox,
  Drawer,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  PiArrowBendDoubleUpRightDuotone,
  PiArrowClockwiseDuotone,
  PiArrowLeftDuotone,
  PiChatDotsDuotone,
  PiEnvelopeOpenDuotone,
  PiGearFineDuotone,
  PiShoppingCartDuotone,
  PiWhatsappLogoDuotone,
  PiXCircleDuotone,
} from "react-icons/pi";
import customHeaderCard from "../assets/customeheader.webp";
import { useNavigate } from "react-router-dom";
import refilSvg from "../assets/refillCredits.svg";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Checkout from "./Checkout";
const { Paragraph, Title } = Typography;
const { Option } = Select;

const extractNames = (userName) => {
  if (!userName) return { firstName: "", lastName: "" };
  const names = userName.split(" ");
  const firstName = names[0] || "";
  const lastName = names.slice(1).join(" ") || "";
  return { firstName, lastName };
};

const initialOptions = {
  "client-id":
    "AdvSOWuCe32cmqKYWxjHla_tZM0dhu8cKotH_RMUlv2eKEx6ezAKPdS0xSscL6fRxeVi6hpgXbnV9Vab",
  currency: "USD",
  intent: "capture",
};

const Channels = ({ userData, whatsappData, sessionData, storeData }) => {
  const storeDetail = storeData?.filter(
    (store) => store?.store_id.toString() === localStorage.getItem("storeId")
  )?.[0];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBillingModalOpen, setBillingModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [refillData, setRefillData] = useState([]);
  const [refillCards, setRefillCards] = useState({});
  const [refillValues, setRefillValues] = useState({});
  const [customValues, setCustomValues] = useState({});
  const [refillAmt, setRefillAmt] = useState(0.0);
  const [billingFormValue, setBillingFormValue] = useState({});
  const [confirmPaymentDrawer, setConfirmPaymentDrawer] = useState(false);
  const [finalRefillData, setFinalRefillData] = useState([]);
  const navigate = useNavigate();

  const [isGstRegistered, setIsGstRegistered] = useState(false);

  const handleGstChange = (e) => {
    setIsGstRegistered(e.target.checked);
  };

  const fetchReginPriceData = useCallback(async () => {
    const url = `${window.API_BASE_URL}/users/region_pricing`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionData?.token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data);
        setLoading(false);
        const refillData = data?.refills.filter(
          (item) => item.currency_code === storeDetail?.currency_code
        );
        if (!refillData || refillData.length === 0) {
          refillData.push(
            ...(data?.refills.filter(
              (item) => item.currency_code === "OTHER"
            ) || [])
          );
        }
        const newRefillData = [
          {
            refill_id: 1,
            name: "SMS",
            priceprcredit: parseFloat(
              parseFloat(refillData?.[0]?.sms) +
                parseFloat(refillData?.[0]?.sms) *
                  (parseFloat(refillData?.[0]?.margin) / 100)
            ).toFixed(4),
            status: "active",
          },
          {
            refill_id: 2,
            name: "Email",
            priceprcredit: parseFloat(
              parseFloat(refillData?.[0]?.email) +
                parseFloat(refillData?.[0]?.email) *
                  (parseFloat(refillData?.[0]?.margin) / 100)
            ).toFixed(4),
            status: "active",
          },
          {
            refill_id: 3,
            name: "Whatsapp Utility",
            priceprcredit: parseFloat(
              parseFloat(refillData?.[0]?.utility) +
                parseFloat(refillData?.[0]?.utility) *
                  (parseFloat(refillData?.[0]?.margin) / 100)
            ).toFixed(4),
            status: "active",
          },
          {
            refill_id: 4,
            name: "Whatsapp Marketing",
            priceprcredit: parseFloat(
              parseFloat(refillData?.[0]?.marketing) +
                parseFloat(refillData?.[0]?.marketing) *
                  (refillData?.[0]?.margin / 100)
            ).toFixed(4),
            status: "active",
          },
        ];
        setRefillData(newRefillData);
        const initialSwitchStates = newRefillData.reduce((acc, refill) => {
          acc[refill.refill_id] = true;
          return acc;
        }, {});
        setRefillCards(initialSwitchStates);
      } else {
        if (data.message === "Invalid token") {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("deviceId");
          localStorage.removeItem("storeId");
          // navigate('/login');
        }
        console.error("Error fetching user data:", data.message);
      }
    } catch (error) {
      console.error("Error during fetching store drops:", error);
    }
  }, [sessionData?.token, storeDetail?.currency_code]);

  // const fetchRefillData = useCallback(async () => {
  //     const url = `${window.API_BASE_URL}/users/refills`;
  //     try {
  //         const response = await fetch(url, {
  //             method: 'GET',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 'Authorization': `Bearer ${sessionData?.token}`,
  //             },
  //         });
  //         const data = await response.json();
  //         if (response.ok) {
  //             console.log(data);
  //             setLoading(false);
  //             setRefillData(data?.refills);
  //             const initialSwitchStates = data?.refills.reduce((acc, refill) => {
  //                 acc[refill.refill_id] = true;
  //                 return acc;
  //             }, {});
  //             setRefillCards(initialSwitchStates);
  //         } else {
  //             if (data.message === 'Invalid token') {
  //                 localStorage.removeItem('loginToken');
  //                 localStorage.removeItem('deviceId');
  //                 localStorage.removeItem('storeId');
  //                 // navigate('/login');
  //             }
  //             console.error('Error fetching user data:', data.message);
  //         }
  //     } catch (error) {
  //         console.error('Error during fetching store drops:', error);
  //     }
  // }, [sessionData?.token]);

  const fetchBillingData = useCallback(async () => {
    const url = `${window.API_BASE_URL}/payment/billing`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionData?.token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setBillingFormValue(data?.billingdetails);
        if (data?.billingdetails?.gstin) {
          setIsGstRegistered(true);
        }
      } else {
        const { firstName, lastName } = extractNames(userData?.user_name);
        setBillingFormValue({
          first_name: firstName,
          last_name: lastName,
        });
      }
    } catch (error) {
      console.error("Error during fetching store drops:", error);
    }
  }, [sessionData?.token, userData?.user_name]);

  useEffect(() => {
    const fetchData = async () => {
      if (sessionData) {
        // await fetchRefillData();
        await fetchReginPriceData();
        await fetchBillingData();
      } else {
        setLoading(false);
      }
    };
    fetchData();
  }, [fetchBillingData, fetchReginPriceData, sessionData]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCheckout = () => {
    setBillingModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelBillingModal = () => {
    setBillingModalOpen(false);
  };

  const handleRefillRadioChange = (e, refill) => {
    const newRefillValues = {
      ...refillValues,
      [refill?.refill_id]: e.target.value,
    };
    setRefillValues(newRefillValues);
    calculateTotalAmount(newRefillValues, customValues, refillCards);
  };

  const handleCustomInputChange = (e, refill) => {
    const customCreditValue = parseInt(e.target.value || 0, 10);
    const newCustomValues = {
      ...customValues,
      [refill?.refill_id]: customCreditValue,
    };
    setCustomValues(newCustomValues);
    calculateTotalAmount(refillValues, newCustomValues, refillCards);
  };

  const calculateTotalAmount = (refillValues, customValues, refillCards) => {
    let totalAmount = 0.0;
    Object.keys(refillValues).forEach((refill_id) => {
      if (refillCards[refill_id] !== false) {
        const selectedValue = refillValues[refill_id];
        const currentRefill = refillData.find(
          (r) => parseInt(r.refill_id) === parseInt(refill_id)
        );
        if (selectedValue === "custom") {
          const customCredit = customValues[refill_id] || 0;
          totalAmount += parseFloat(currentRefill.priceprcredit) * customCredit;
        } else if (selectedValue !== "custom") {
          totalAmount +=
            parseFloat(currentRefill.priceprcredit) * selectedValue;
        }
      }
    });
    setRefillAmt(totalAmount);
  };

  const handleRefillCard = (refill) => {
    const updatedSwitchStates = {
      ...refillCards,
      [refill.refill_id]: !refillCards[refill.refill_id],
    };
    setRefillCards(updatedSwitchStates);
    calculateTotalAmount(refillValues, customValues, updatedSwitchStates);
  };

  const baseUrl = window.location.origin;

  const checkOutRefillOne = async () => {
    setFormLoading(true);
    const data = {
      billing_id: billingFormValue?.detail_id,
      refill_data: finalRefillData,
      phone: userData?.user_phone,
      redirectUrl: `${baseUrl}/payment-status`,
      currency_code: storeDetail?.currency_code,
    };
    try {
      const url = `${window.API_BASE_URL}/pay`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionData?.token}`,
        },
        body: JSON.stringify(data),
      });
      console.log(response);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success === true) {
        window.location.href = responseData.redirectUrl;
      }
    } catch (error) {
      console.error("Error proceeding payment data:", error);
    } finally {
      setFormLoading(false);
    }
  };

  const checkOutRefill = async () => {
    setFormLoading(true);
    const data = {
      billing_id: billingFormValue?.detail_id,
      refill_data: finalRefillData,
      phone: userData?.user_phone,
      redirectUrl: `${baseUrl}/payment-status`,
    };
    try {
      const url = `${window.API_BASE_URL}/payment`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionData?.token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      if (responseData.success === true) {
        window.location.href = responseData.redirectUrl;
      }
    } catch (error) {
      console.error("Error proceeding payment data:", error);
    } finally {
      setFormLoading(false);
    }
  };

  const onFinish = async (values) => {
    const refilldata = Object.keys(refillCards)
      .map((key) => {
        if (refillCards[key] && refillValues[parseInt(key)]) {
          return {
            refill_id: parseInt(key),
            refill_name: refillData.find(
              (r) => parseInt(r.refill_id) === parseInt(key)
            )?.name,
            credits:
              refillValues[parseInt(key)] === "custom"
                ? customValues[parseInt(key)]
                : refillValues[parseInt(key)],
          };
        }
        return null;
      })
      .filter(Boolean);
    console.log(refilldata);
    setFinalRefillData(refilldata);
    const data = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      registered_address: values?.registered_address,
      country: values?.country,
      state: values?.state,
      city: values?.city,
      postcode: values?.postcode,
      gstin: values?.gstin,
      gst_number: values?.gstin ? values?.gst_number : "",
      refill_data: refilldata,
    };

    setFormLoading(true);
    try {
      const url = `${window.API_BASE_URL}/payment/billing`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionData?.token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log(responseData);
    } catch (error) {
      console.error("Error submitting billing data:", error);
    } finally {
      setFormLoading(false);
      setConfirmPaymentDrawer(true);
    }
  };

  return loading ? (
    <Layout className="contentLayout">
      <div className="loaderDiv">
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 48,
              }}
              spin
            />
          }
        />
      </div>
    </Layout>
  ) : (
    <Layout className="contentLayout">
      <Flex gap="middle" justify="space-between" style={{ marginBottom: 24 }}>
        <div>
          <Title
            level={3}
            style={{ display: "flex", alignItems: "center", margin: 0 }}
          >
            <b>Channels</b>
          </Title>
          <Paragraph>
            Click on the channel to see their details and reports
          </Paragraph>
        </div>
        <Flex gap="middle" align="center">
          <Button type="primary" onClick={() => navigate(`/refill-history`)}>
            <PiArrowClockwiseDuotone size={20} /> Refill History
          </Button>
          <Button
            type="primary"
            onClick={() => navigate(`/channel-settings/sms`)}
          >
            <PiGearFineDuotone size={20} /> Channel Setting
          </Button>
          <Button type="primary" onClick={showModal}>
            <PiShoppingCartDuotone size={20} /> Refill Credits
          </Button>
        </Flex>
      </Flex>
      <Row gutter={16}>
        <Col span={6}>
          <div className="channel-custom-card lightblue">
            <PiChatDotsDuotone size={40} style={{ color: "#2970ff" }} />
            <Title level={4} style={{ marginTop: 10 }}>
              SMS
            </Title>
            <div className="right-arrow">
              <PiArrowBendDoubleUpRightDuotone size={20} />
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className="channel-custom-card lightorange">
            <PiEnvelopeOpenDuotone size={40} style={{ color: "#ea4335" }} />
            <Title level={4} style={{ marginTop: 10 }}>
              EMAIL
            </Title>
            <div className="right-arrow">
              <PiArrowBendDoubleUpRightDuotone size={20} />
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className="channel-custom-card lightgreen">
            <PiWhatsappLogoDuotone size={40} style={{ color: "#4fce5d" }} />
            <Title level={4} style={{ marginTop: 10 }}>
              Whatsapp Utility
            </Title>
            <div className="right-arrow">
              <PiArrowBendDoubleUpRightDuotone size={20} />
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className="channel-custom-card lightred">
            <PiWhatsappLogoDuotone size={40} style={{ color: "#dd2590" }} />
            <Title level={4} style={{ marginTop: 10 }}>
              Whatsapp Marketing
            </Title>
            <div className="right-arrow">
              <PiArrowBendDoubleUpRightDuotone size={20} />
            </div>
          </div>
        </Col>
        <Col span={24} className="custom-margin">
          <div className="channel-custom-card-one lightgrey">
            <Flex gap="middle" justify="space-between" align="center">
              <div className="left-side">
                <Title
                  level={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 0,
                    marginBottom: 5,
                  }}
                >
                  <b>Setup your Custom Header for communication channels</b>
                </Title>
                <Paragraph style={{ marginBottom: 24 }}>
                  Reach your customers through your custom header for SMS and
                  Business Account for WhatsApp.
                </Paragraph>
                <Flex gap="middle">
                  <Button
                    type="primary"
                    onClick={() => navigate(`/channel-settings/whatsapp`)}
                  >
                    <PiWhatsappLogoDuotone size={20} /> WhatsApp
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => navigate(`/channel-settings/sms`)}
                  >
                    <PiChatDotsDuotone size={20} /> SMS
                  </Button>
                </Flex>
              </div>
              <div className="right-side">
                <Image
                  src={customHeaderCard}
                  preview={false}
                  style={{ width: 228, height: 160 }}
                />
              </div>
            </Flex>
          </div>
        </Col>
      </Row>
      <Modal
        title={
          <Flex gap="middle" justify="space-between" align="center">
            <Flex gap="small" align="center">
              <PiShoppingCartDuotone size={24} />
              <Title level={4} style={{ margin: 0 }}>
                Refill your credits to reach your customers.
              </Title>
            </Flex>
            <Button
              shape="round"
              onClick={handleCancel}
              className="popup-close-btn"
            >
              <PiXCircleDuotone size={40} />
            </Button>
          </Flex>
        }
        open={isModalOpen}
        closable={false}
        footer={false}
        width={"70%"}
      >
        <Row gutter={16}>
          {refillData.map((refill) => (
            <Col span={6} key={refill?.refill_id}>
              <div
                className={`refill-card ${
                  refillCards[refill.refill_id] !== false ? "" : "inactive"
                }`}
              >
                <Flex
                  className="refill-card-header"
                  gap="middle"
                  justify="space-between"
                  align="center"
                >
                  <Paragraph
                    style={{ margin: 0, fontWeight: 500, textWrap: "nowrap" }}
                  >
                    {refill?.name}
                  </Paragraph>{" "}
                  <Switch
                    defaultChecked={refillCards[refill.refill_id] !== false}
                    onChange={() => handleRefillCard(refill)}
                    checked={refillCards[refill.refill_id] !== false}
                  />{" "}
                </Flex>
                <Space className="refill-card-body" direction="vertical">
                  <Radio.Group
                    onChange={(e) => handleRefillRadioChange(e, refill)}
                    value={refillValues[refill?.refill_id]}
                    disabled={refillCards[refill.refill_id] === false}
                  >
                    <Radio value={25000}>
                      <Flex gap="middle" justify="space-between" align="center">
                        <Paragraph style={{ margin: 0 }}>25K</Paragraph>
                        <Paragraph style={{ margin: 0, color: "#3A1A55" }}>
                          <b>
                            {storeDetail?.currency_code === "INR" ? "₹" : "US$"}
                            {parseFloat(
                              parseFloat(refill?.priceprcredit) * 25000
                            ).toFixed(4)}
                          </b>
                        </Paragraph>
                      </Flex>
                    </Radio>
                    <Radio value={50000}>
                      <Flex gap="middle" justify="space-between" align="center">
                        <Paragraph style={{ margin: 0 }}>50K</Paragraph>
                        <Paragraph style={{ margin: 0, color: "#3A1A55" }}>
                          <b>
                            {storeDetail?.currency_code === "INR" ? "₹" : "US$"}
                            {parseFloat(
                              parseFloat(refill?.priceprcredit) * 50000
                            ).toFixed(4)}
                          </b>
                        </Paragraph>
                      </Flex>
                    </Radio>
                    <Radio value={100000}>
                      <Flex gap="middle" justify="space-between" align="center">
                        <Paragraph style={{ margin: 0 }}>100K</Paragraph>
                        <Paragraph style={{ margin: 0, color: "#3A1A55" }}>
                          <b>
                            {storeDetail?.currency_code === "INR" ? "₹" : "US$"}
                            {parseFloat(
                              parseFloat(refill?.priceprcredit) * 100000
                            ).toFixed(4)}
                          </b>
                        </Paragraph>
                      </Flex>
                    </Radio>
                    <Radio value={"custom"}>
                      <Flex gap="middle" justify="space-between" align="center">
                        <Paragraph style={{ margin: 0 }}>Custom</Paragraph>
                        <Paragraph style={{ margin: 0, color: "#3A1A55" }}>
                          <b>
                            {storeDetail?.currency_code === "INR" ? "₹" : "US$"}
                            {parseFloat(
                              parseFloat(refill?.priceprcredit) *
                                (customValues[refill?.refill_id] || 0.0)
                            ).toFixed(4)}
                          </b>
                        </Paragraph>
                      </Flex>
                      {refillValues[refill?.refill_id] === "custom" && (
                        <div
                          style={{
                            position: "absolute",
                            width: "calc(100% - 32px)",
                            left: 16,
                            bottom: 16,
                          }}
                        >
                          <Input
                            disabled={refillCards[refill.refill_id] === false}
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter number of credits"
                            onChange={(e) => handleCustomInputChange(e, refill)}
                            value={customValues[refill?.refill_id] || ""}
                          />
                        </div>
                      )}
                    </Radio>
                  </Radio.Group>
                </Space>
              </div>
            </Col>
          ))}
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Flex gap="middle" justify="space-between" align="center">
              <div>
                <Title level={4} style={{ marginTop: 16, marginBottom: 0 }}>
                  Total{" "}
                  <span style={{ color: "#3A1A55" }}>
                    {storeDetail?.currency_code === "INR" ? "₹" : "US$"}{" "}
                    {parseFloat(refillAmt).toFixed(4)}
                  </span>
                </Title>
                <Paragraph
                  style={{ margin: 0, fontSize: 10, color: "#aeb5b5" }}
                >
                  Price excludes GST
                </Paragraph>
              </div>
              <Button
                type="primary"
                disabled={refillAmt === 0 ? true : false}
                onClick={handleCheckout}
              >
                <PiShoppingCartDuotone size={20} /> Proceed to checkout
              </Button>
            </Flex>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={
          <Flex gap="middle" justify="space-between" align="center">
            <Flex gap="small" align="center">
              <PiShoppingCartDuotone size={24} />
              <Title level={4} style={{ margin: 0 }}>
                You’re one step away from refilling your credits
              </Title>
            </Flex>
            <Button
              shape="round"
              onClick={handleCancelBillingModal}
              className="popup-close-btn"
            >
              <PiXCircleDuotone size={40} />
            </Button>
          </Flex>
        }
        open={isBillingModalOpen}
        closable={false}
        footer={false}
        width={"70%"}
      >
        <Row gutter={16}>
          <Col span={12}>
            <div className="refill-credit-card">
              <img
                src={refilSvg}
                preview={false}
                alt="refill card svg"
                style={{ width: "100%" }}
              />
              <Flex
                gap="small"
                wrap="wrap"
                align="stretch"
                style={{ marginTop: 16 }}
              >
                {refillData.map((refill) => (
                  <div style={{ flexGrow: 1 }}>
                    <Title level={5} style={{ margin: 0 }}>
                      {refill?.name}
                    </Title>
                    {refillValues[refill?.refill_id] === "custom" ? (
                      <Paragraph>
                        {storeDetail?.currency_code === "INR" ? "₹" : "US$"}
                        {(
                          parseFloat(refill?.priceprcredit) *
                          parseFloat(customValues[refill?.refill_id] || 0)
                        ).toFixed(2)}
                      </Paragraph>
                    ) : (
                      <Paragraph>
                        {storeDetail?.currency_code === "INR" ? "₹" : "US$"}
                        {(
                          parseFloat(refill?.priceprcredit) *
                          parseFloat(refillValues[refill?.refill_id] || 0)
                        ).toFixed(2)}
                      </Paragraph>
                    )}
                  </div>
                ))}
              </Flex>
              <Flex gap="small" wrap="wrap" align="stretch">
                <div style={{ flexGrow: 1 }}>
                  <Title level={5} style={{ marginTop: 0 }}>
                    Total
                  </Title>
                  <Paragraph>
                    {storeDetail?.currency_code === "INR" ? "₹" : "US$"}
                    {parseFloat(refillAmt).toFixed(2)}
                  </Paragraph>
                </div>
              </Flex>
            </div>
          </Col>
          <Col span={12}>
            <Title level={4} style={{ color: "#a8b4b4" }}>
              Billing Details
            </Title>
            {billingFormValue && (
              <Form
                layout="vertical"
                style={{ width: "100%" }}
                initialValues={billingFormValue}
                onFinish={onFinish}
              >
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="First Name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your first name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter first name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Last Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your last name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter last name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="Registered Address"
                  name="registered_address"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your registered address",
                    },
                  ]}
                >
                  <Input placeholder="Enter registered address" />
                </Form.Item>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="Country"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Please select your country",
                        },
                      ]}
                    >
                      <Select placeholder="Select country" showSearch>
                        <Option value="india">India</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        { required: true, message: "Please select your state" },
                      ]}
                    >
                      <Select placeholder="Select state" showSearch>
                        {[
                          "Andhra Pradesh",
                          "Arunachal Pradesh",
                          "Assam",
                          "Bihar",
                          "Chhattisgarh",
                          "Goa",
                          "Gujarat",
                          "Haryana",
                          "Himachal Pradesh",
                          "Jharkhand",
                          "Karnataka",
                          "Kerala",
                          "Madhya Pradesh",
                          "Maharashtra",
                          "Manipur",
                          "Meghalaya",
                          "Mizoram",
                          "Nagaland",
                          "Odisha",
                          "Punjab",
                          "Rajasthan",
                          "Sikkim",
                          "Tamil Nadu",
                          "Telangana",
                          "Tripura",
                          "Uttar Pradesh",
                          "Uttarakhand",
                          "West Bengal",
                        ].map((state) => (
                          <Option key={state} value={state}>
                            {state}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[
                        { required: true, message: "Please enter your city" },
                      ]}
                    >
                      <Input placeholder="Enter city" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Postcode"
                      name="postcode"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your postcode",
                        },
                      ]}
                    >
                      <Input placeholder="Enter postcode" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="gstin" valuePropName="checked">
                  <Checkbox onChange={handleGstChange}>
                    I am registered with GSTIN
                  </Checkbox>
                </Form.Item>
                {isGstRegistered && (
                  <Form.Item
                    label="GSTIN Number"
                    name="gst_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your GSTIN number",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter GSTIN number"
                      disabled={!isGstRegistered}
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={formLoading}
                    className="checkout-button"
                  >
                    <PiShoppingCartDuotone size={24} />{" "}
                    <span>Proceed to checkout</span>
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
      </Modal>
      <Drawer
        title={
          <Row gutter={16}>
            <Col span={8} offset={8}>
              <Flex
                gap="small"
                align="center"
                onClick={() => setConfirmPaymentDrawer(false)}
              >
                <Button shape="round" className="close-btn">
                  <PiArrowLeftDuotone />
                </Button>
                <Paragraph style={{ margin: 0 }}>Back</Paragraph>
              </Flex>
            </Col>
          </Row>
        }
        footer={
          <Row gutter={16}>
            {storeDetail?.currency_code === "INR" ? (
              <Col span={8} offset={8}>
                <Flex
                  gap="small"
                  align="center"
                  justify="space-between"
                  style={{ paddingTop: 16, paddingBottom: 16 }}
                >
                  <Paragraph className="purchase-details-item-amount">
                  </Paragraph>
                  <Button
                    type="primary"
                    onClick={
                      storeDetail?.currency_code === "INR"
                        ? checkOutRefill
                        : checkOutRefillOne
                    }
                    loading={formLoading}
                  >
                    <PiShoppingCartDuotone size={24} />{" "}
                    <span>Proceed to checkout</span>
                  </Button>
                </Flex>
              </Col>
            ) : (
                <Col span={8} offset={8}>
              <PayPalScriptProvider options={initialOptions}>
                <Checkout
                  total={parseFloat(parseFloat(refillAmt) * 1.18).toFixed(2)}
                  billingFormValue={billingFormValue}
                  finalRefillData={finalRefillData}
                  userData={userData}
                  sessionData={sessionData}
                  storeDetail={storeDetail}
                />
              </PayPalScriptProvider>
              </Col>
            )}
          </Row>
        }
        placement="bottom"
        closable={false}
        onClose={() => setConfirmPaymentDrawer(false)}
        open={confirmPaymentDrawer}
        height={"100vh"}
      >
        <Row gutter={16}>
          <Col span={8} offset={8}>
            <Title level={4} style={{ marginTop: 0 }}>
              You're one step away from refilling your credits
            </Title>
            <Title level={5} style={{ marginTop: 0 }}>
              Purchase Details
            </Title>
            <div className="purchase-details">
              {finalRefillData.map((refilldata) => (
                <div className="purchase-details-item">
                  <Flex gap="middle" justify="space-between" align="center">
                    <Paragraph className="purchase-details-item-label">
                      {refilldata?.refill_name} * {refilldata?.credits}
                    </Paragraph>
                    <Paragraph className="purchase-details-item-amount">
                      {storeDetail?.currency_code === "INR" ? "₹" : "US$"}{" "}
                      {parseFloat(
                        parseFloat(
                          refillData.find(
                            (r) =>
                              parseInt(r.refill_id) ===
                              parseFloat(refilldata?.refill_id)
                          )?.priceprcredit
                        ) * parseFloat(refilldata?.credits)
                      ).toFixed(2)}{" "}
                    </Paragraph>
                  </Flex>
                </div>
              ))}
            </div>
            <div className="product-tax-div">
              <Title level={5} style={{ marginTop: 16 }}>
                Subtotal & Taxes
              </Title>
              <div className="purchase-details">
                <div className="purchase-details-item">
                  <Flex gap="middle" justify="space-between" align="center">
                    <Paragraph className="purchase-details-item-label">
                      Subtotal
                    </Paragraph>
                    <Paragraph className="purchase-details-item-amount">
                      {storeDetail?.currency_code === "INR" ? "₹" : "US$"}{" "}
                      {parseFloat(refillAmt).toFixed(2)}
                    </Paragraph>
                  </Flex>
                </div>
                <div className="purchase-details-item">
                  <Flex gap="middle" justify="space-between" align="center">
                    <Paragraph className="purchase-details-item-label">
                      IGST @ 18%
                    </Paragraph>
                    <Paragraph className="purchase-details-item-amount">
                      {storeDetail?.currency_code === "INR" ? "₹" : "US$"}{" "}
                      {parseFloat(parseFloat(refillAmt) * 0.18).toFixed(2)}
                    </Paragraph>
                  </Flex>
                </div>
                <div className="purchase-details-item total">
                  <Flex gap="middle" justify="space-between" align="center">
                    <Paragraph className="purchase-details-item-label">
                      Total
                    </Paragraph>
                    <Paragraph className="purchase-details-item-amount">
                      {storeDetail?.currency_code === "INR" ? "₹" : "US$"}{" "}
                      {parseFloat(parseFloat(refillAmt) * 1.18).toFixed(2)}
                    </Paragraph>
                  </Flex>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Drawer>
    </Layout>
  );
};
export default Channels;
