import React, { useState } from 'react';
import { Layout, Row, Col, Typography, Flex, Dropdown, Button, Tooltip, Switch, Image, Drawer, Progress, message, Card, Slider, Input, Checkbox, Radio, Select, Upload, ColorPicker } from 'antd';
import { DownOutlined, InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import googleSvg from '../assets/google.svg';
import emoji from '../assets/emojis/emoji_0.svg';
import emojiOne from '../assets/emojis/emoji_1.svg';
import emojiTwo from '../assets/emojis/emoji_2.svg';
import emojiThree from '../assets/emojis/emoji_3.svg';
import emojiFour from '../assets/emojis/emoji_4.svg';
import emojiFive from '../assets/emojis/emoji_5.svg';
import { useNavigate, useParams } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import whatsappMobileMokup from '../assets/zipler-mobile-mock-up.png';
import smsMobileMokup from '../assets/sms.png';
import emailMobileMokup from '../assets/email.png';
import { PiArrowLeftDuotone, PiArrowRight, PiArrowRightDuotone, PiBellDuotone, PiChatCenteredDotsDuotone, PiCheck, PiEnvelopeDuotone, PiFloppyDiskDuotone, PiHeartFill, PiImageDuotone, PiMicrosoftPowerpointLogoDuotone, PiNumberSquareFiveDuotone, PiNumberSquareFiveFill, PiNumberSquareFourDuotone, PiNumberSquareFourFill, PiNumberSquareOneDuotone, PiNumberSquareOneFill, PiNumberSquareThreeDuotone, PiNumberSquareThreeFill, PiNumberSquareTwoDuotone, PiNumberSquareTwoFill, PiPlusDuotone, PiShootingStarFill, PiStarFill, PiWhatsappLogo } from 'react-icons/pi';
import { FcGoogle } from 'react-icons/fc';
import { GiSwapBag, GiTakeMyMoney } from 'react-icons/gi';
import { FaBoxOpen, FaStarOfLife } from 'react-icons/fa';
import ImgCrop from 'antd-img-crop';
import emailFeedbackImage from '../assets/feedback.svg';
import blogo from '../assets/Zipler.svg';
const { Paragraph, Title } = Typography;
const { TextArea } = Input;

const datePickerItems = [
    { key: '1', label: 'Highlights for Today' },
    { key: '2', label: 'Last 7 Days' },
    { key: '3', label: 'Last 30 Days' },
    { key: '4', label: 'This Month' },
    { key: '5', label: 'Last Month' },
    { key: '6', label: 'Last 12 Month' },
    { key: '7', label: 'Custom range' }
];

const twoColors = {
    '0%': '#108ee9',
    '50%': '#3A1A55',
    '100%': '#3A1A55',
};

const rgbToHex = (r, g, b) => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
};

const FeedbackContent = ({ userData, sessionData, storeData }) => {
    const { tabType } = useParams();
    const navigate = useNavigate();
    const storeDetail = storeData?.filter(store => store?.store_id.toString() === localStorage.getItem('storeId'))?.[0];
    const [feedbackDrawer, setFeedbackDrawer] = useState(tabType === 'addnew' ? true : false);
    const [steps, setSteps] = useState(1);
    const [selectedChannels, setSelectedChannels] = useState({ whatsapp: true, sms: true, email: true });
    const [negativeFeedBackAlertChannels, setNegativeFeedBackAlertChannels] = useState({ whatsapp: true, sms: true, email: true });
    // const [rewardType, setRewardType] = useState('');
    const [themeColor, setThemeColor] = useState('#3A1A55');
    const [textColor, setTextColor] = useState('#FFFFFF');
    const [file, setFile] = useState(null);
    const [selectRating, setSelectRating] = useState(0);
    const [selectRange, setSelectRange] = useState(0);
    const [hoveredIndex, setHoveredIndex] = useState(0);
    const [ratingIconType, setRatingIconType] = useState('emoji');
    const [durationValue, setDurationValue] = useState(1);
    const [durationType, setDurationType] = useState('hours');
    const [categories, setCategories] = useState({
        'Food': true,
        'Service': true,
        'Ambience': true,
        'Cleanliness': true,
        'Value for Money': false
    });

    const [numbers, setNumbers] = useState({
        [storeDetail?.store_number]: true,
    });
    const [emails, setEmails] = useState({
        [storeDetail?.store_email ? storeDetail?.store_email : userData?.user_email]: true,
    });

    const [newNumber, setNewNumber] = useState('');
    const [newEmail, setNewEmail] = useState('');

    const [selectedLikeUnlikeItem, setSelectedLikeUnlikeItem] = useState({});
    const [newCategory, setNewCategory] = useState('');
    const [newHereChannel, setNewHereChannel] = useState('');
    const [reviewSteps, setReviewSteps] = useState(1);
    const [howHearChannels, setHowHearChannels] = useState({ 'Zomato / Swiggy': true, 'Social media': true, 'Friends & Family': true, 'Walking by': true, 'Newspaper': true });
    const [selectHowHereChannel, setSelectHowHereChannel] = useState('');
    const [feedBackHeaderText, setFeedBackHeaderText] = useState('your feedback is important to us!');
    const [feedBackQurstionText, setFeedBackQurstionText] = useState('How was your experience?');
    const [feedBackPositiveText, setFeedBackPositiveText] = useState('Glad to hear that. What did you like?');
    const [feedBacknegitiveText, setFeedBacknegitiveText] = useState('Sorry to hear. Tell us what went wrong?');
    const [showAddMoreInput, setShowAddMoreInput] = useState(false);
    const [howDidYouHereText, setHowDidYouHereText] = useState('How did you hear about us?');
    const [isFeedBackReward, setIsFeedBackReward] = useState(false);
    const [feedBackReward, setFeedBackReward] = useState(100);
    const [feedBackSendTime, setFeedBackSendTime] = useState('immediate');
    const [feedBackCollectThrough, setFeedBackCollectThrough] = useState('external');
    const [sendIfFailsWhatsapp, setSendIfFailsWhatsapp] = useState(false);
    const [addWhatsappCustomText, setAddWhatsappCustomText] = useState(false);
    const [whatsappCustomText, setWhatsappCustomText] = useState('');
    const [isNegativeFeedbackAlert, setIsNegativeFeedbackAlert] = useState(false);
    const [isReviewLingSend, setIsReviewLingSend] = useState(false);
    const [sendReviewlingWhen, setSendReviewlingWhen] = useState(5);
    const [reviewLink, setReviewLink] = useState('');
    const [previewType, setPreviewType] = useState('whatsapp');

    const handleChannelClick = (channel) => {
        setSelectedChannels(prevState => ({
            ...prevState,
            [channel]: !prevState[channel]
        }));
    };

    const handleNegativeChannelClick = (channel) => {
        setNegativeFeedBackAlertChannels(prevState => ({
            ...prevState,
            [channel]: !prevState[channel]
        }));
    };

    const props = {
        name: 'file',
        multiple: false,
        beforeUpload: (file) => {
            return false;
        },
        onChange(info) {
            const { status } = info.file;

            if (status !== 'uploading') {
                console.log('Selected File:', info.file);
                const file = info.file.originFileObj || info.file;
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const base64String = reader.result;
                    setFile(base64String);
                };
                reader.onerror = (error) => {
                    console.error('Error converting file to Base64:', error);
                };
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        showUploadList: false,
    };

    const handleCloseAddNewPopup = () => {
        switch (steps) {
            case 1:
                navigate(`/feedback`);
                setFeedbackDrawer(false);
                break;
            case 2:
                setSteps(steps - 1);
                break;
            case 3:
                setSteps(steps - 1);
                break;
            case 4:
                setSteps(steps - 1);
                break;
            case 5:
                setSteps(steps - 1);
                break;
            case 6:
                if (!selectedChannels.whatsapp) {
                    setSteps(steps - 2);
                } else {
                    setSteps(steps - 1);
                }
                break;
            case 7:
                let sevstap = 1;
                if (!selectedChannels.sms && selectedChannels.whatsapp) {
                    sevstap += 1;
                }
                if (!selectedChannels.sms && !selectedChannels.whatsapp) {
                    sevstap += 2;
                }
                setSteps(steps - sevstap);
                break;
            case 8:
                let eitstap = 1;
                if (selectedChannels.email) {
                    eitstap = 1;
                }
                if (!selectedChannels.email && selectedChannels.sms) {
                    eitstap = 2;
                }
                if (!selectedChannels.email && !selectedChannels.sms && selectedChannels.whatsapp) {
                    eitstap = 3;
                }
                setSteps(steps - eitstap);
                break;
            case 9:
                setSteps(steps - 1);
                break;
            case 10:
                setSteps(steps - 1);
                break;
            default:
                break;
        }
    };

    const handleNextAddNewPopup = async () => {
        switch (steps) {
            case 1:
                setSteps(steps + 1);
                break;
            case 2:

                setSteps(steps + 1);
                break;
            case 3:

                setSteps(steps + 1);
                break;
            case 4:
                if (selectedChannels.whatsapp) {
                    setSteps(steps + 1);
                }
                if (!selectedChannels.whatsapp && selectedChannels.sms) {
                    setSteps(steps + 2);
                }
                if (!selectedChannels.whatsapp && !selectedChannels.sms && selectedChannels.email) {
                    setSteps(steps + 3);
                }
                break;
            case 5:
                setSteps(steps + 1);
                break;
            case 6:
                if (selectedChannels.email) {
                    setSteps(steps + 1);
                } else {
                    setSteps(steps + 2);
                }
                break;
            case 7:
                setSteps(steps + 1);
                break;
            case 8:
                setSteps(steps + 1);
                break;
            case 9:
                setSteps(steps + 1);
                break;
            case 10:
                setSteps(steps);
                break;
            default:
                break;
        }
    };

    const handleSaveExit = () => {
        setFeedbackDrawer(false);
        navigate('/feedback');
    };

    const handleActive = () => {
        setFeedbackDrawer(false);
        navigate('/feedback');
    };

    const handleLikeUnlikeClick = (channel) => {
        setSelectedLikeUnlikeItem(prevState => ({
            ...prevState,
            [channel]: !prevState[channel]
        }));
    };

    const handleAddCategory = () => {
        const trimmedCategory = newCategory.trim();
        if (trimmedCategory && !(trimmedCategory in categories)) {
            setCategories(prevState => ({
                ...prevState,
                [trimmedCategory]: true
            }));
            setNewCategory('');
            setShowAddMoreInput(false);
        }
    };

    const handleAddNumber = () => {
        const trimedNumber = newNumber.trim();
        if (trimedNumber && !(newNumber in numbers)) {
            setNumbers(prevState => ({
                ...prevState,
                [trimedNumber]: true
            }));
            setNewNumber('');
        }
    };

    const handleAddEmail = () => {
        const trimmedEmail = newEmail.trim();
        if (trimmedEmail && !(trimmedEmail in emails)) {
            setEmails(prevState => ({
                ...prevState,
                [trimmedEmail]: true
            }));
            setNewEmail('');
        }
    };

    const handleAddHereChannel = () => {
        const trimmedHowhereChannel = newHereChannel.trim();
        if (trimmedHowhereChannel && !(trimmedHowhereChannel in howHearChannels)) {
            setHowHearChannels(prevState => ({
                ...prevState,
                [trimmedHowhereChannel]: true
            }));
            setNewHereChannel('');
            setShowAddMoreInput(false);
        }
    };

    const handleHereCheckboxChange = (hereChannel) => {
        setHowHearChannels(prevState => ({
            ...prevState,
            [hereChannel]: !prevState[hereChannel]
        }));
    };

    const handleSelectReviewStap = (value) => {
        setSelectHowHereChannel(value);
        setReviewSteps(3);
        setTimeout(() => {
            setReviewSteps(1);
            setSelectHowHereChannel('');
            setSelectedLikeUnlikeItem({});
            setSelectRating(0);
            setSelectRange(0);
        }, 3000);
    };

    const handleCheckboxChange = (category) => {
        setCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    const handleNumberCheckboxChange = (number) => {
        setNumbers(prevState => ({
            ...prevState,
            [number]: !prevState[number]
        }));
    };

    const handleEmailCheckboxChange = (email) => {
        setEmails(prevState => ({
            ...prevState,
            [email]: !prevState[email]
        }));
    };



    return (
        <Layout className='contentLayout'>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
                <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Feedback Insights</b></Title>
            </div>
            <Row gutter={16}>
                <Col span={24}>
                    <Flex gap='middle' justify='space-between' align='center'>
                        <Paragraph style={{ fontSize: 16, color: '#354545' }}><b>Track your customer feedback with real-time analytics</b></Paragraph>
                        <Dropdown
                            menu={{
                                items: datePickerItems,
                            }}
                            className='summarydroptitle'
                        ><Button >Last 12 Months 1,Sep 23 - 19, Sep 24 <DownOutlined /></Button>
                        </Dropdown>
                    </Flex>
                </Col>
                <Col span={24} className='custom-margin'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <div className='feedback-custom-box lightblue'>
                                        <Title level={2} style={{ margin: 0, fontSize: 48, fontWeight: 'bold' }}>0</Title>
                                        <Title level={5}>Total Feedback</Title>
                                        <div className='info-circle'>
                                            <Tooltip title={
                                                <div>
                                                    <p>Shows the total number of feedbacks you received in the selected time period.</p>
                                                </div>
                                            }>
                                                <InfoCircleOutlined style={{ fontSize: 16 }} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='feedback-custom-box lightgreen'>
                                        <Title level={2} style={{ margin: 0, fontSize: 48, fontWeight: 'bold' }}>0.00</Title>
                                        <Title level={5}>Zipler.io assisted reviews & ratings</Title>
                                        <div className='info-circle'>
                                            <Tooltip title={
                                                <div>
                                                    <p>The average of all the customer ratings that you received in the selected time period.</p>
                                                </div>
                                            }>
                                                <InfoCircleOutlined style={{ fontSize: 16 }} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <div className='feedback-custom-box lightgrey'>
                                <div style={{ width: '100%' }}>
                                    <Flex gap='middle' justify='space-between' align='center'>
                                        <Title level={4} style={{ marginTop: 0 }}>{feedBackQurstionText}</Title>
                                        <Flex gap='middle' align='center'>
                                            <Switch defaultChecked /> <Paragraph style={{ margin: 0 }}><b>Active</b></Paragraph>
                                        </Flex>
                                    </Flex>
                                    <div style={{ width: '100%' }}>
                                        <Paragraph style={{ fontSize: 14, color: '#374040' }}></Paragraph>
                                        <Paragraph style={{ fontSize: 14, color: '#374040' }}><b>Channel</b></Paragraph>
                                        <Flex gap='middle' justify='space-between' align='center'>
                                            <Paragraph style={{ backgroundColor: '#FFFFFF', padding: 8, fontSize: 10, fontWeight: 'bold', borderRadius: 28, width: 'fit-content' }}>Whatsapp Utility</Paragraph>
                                            <Button type='primary' onClick={() => setFeedbackDrawer(true)}>Edit Feedback Settings</Button>
                                        </Flex>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16} className='custom-margin'>
                        <Col span={6}>
                            <div className='feedback-custom-box lightblue'>
                                <Title level={2} style={{ margin: 0, fontSize: 48, fontWeight: 'bold' }}>0</Title>
                                <Flex gap='middle' justify='space-between' align='center'>
                                    <Title level={5}>Zipler.io assisted reviews & ratings</Title>
                                    <Image src={googleSvg} preview={false} />
                                </Flex>
                                <div className='info-circle'>
                                    <Tooltip title={
                                        <div>
                                            <p>The number of customers who have clicked to review your services online.</p>
                                        </div>
                                    }>
                                        <InfoCircleOutlined style={{ fontSize: 16 }} />
                                    </Tooltip>
                                </div>
                            </div>
                        </Col>
                        <Col span={18}>
                            <div className='feedback-custom-box lightgreen'>
                                <Paragraph style={{ fontSize: 16, color: '#354545' }}><b>Feedback Report</b></Paragraph>
                                <div style={{ width: '100%' }}>
                                    <Row gutter={16}>
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <Flex gap='small' align='center' justify='center'>
                                                <Image src={emojiFour} preview={false} style={{ width: 44, height: 44, position: 'relative', top: 4 }} />
                                                <Title level={2} style={{ margin: 0, fontSize: 48, fontWeight: 'bold' }}>100%</Title>
                                            </Flex>
                                            <Title level={5}>0 Positive Feedback</Title>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <Flex gap='small' align='center' justify='center'>
                                                <Image src={emojiTwo} preview={false} style={{ width: 44, height: 44, position: 'relative', top: 4 }} />
                                                <Title level={2} style={{ margin: 0, fontSize: 48, fontWeight: 'bold' }}>0%</Title>
                                            </Flex>
                                            <Title level={5}>0 Negative Feedback</Title>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <Flex gap='small' align='center' justify='center'>
                                                <Image src={emojiThree} preview={false} style={{ width: 44, height: 44, position: 'relative', top: 4 }} />
                                                <Title level={2} style={{ margin: 0, fontSize: 48, fontWeight: 'bold' }}>0%</Title>
                                            </Flex>
                                            <Title level={5}>0 Neutral Feedback</Title>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='info-circle'>
                                    <Tooltip title={
                                        <div>
                                            <p>Total percentage of different ratings received over the period of time.</p>
                                        </div>
                                    }>
                                        <InfoCircleOutlined style={{ fontSize: 16 }} />
                                    </Tooltip>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Drawer
                placement='bottom'
                closable={false}
                onClose={handleCloseAddNewPopup}
                open={feedbackDrawer}
                className='templateDrawer'
                height="100vh"
                zIndex={999}
            >
                <Row style={{ height: '10vh', overflow: 'hidden' }}>
                    <Col span={24}>
                        <Flex gap='middle' style={{ padding: '16px 24px 10px 24px' }} justify='space-between' align='center'>
                            <Flex gap='small' justify='center' align='center'>
                                <Button shape='round' className='close-btn' onClick={handleCloseAddNewPopup}><PiArrowLeftDuotone /></Button>
                                <PiMicrosoftPowerpointLogoDuotone size={20} /> <Title level={5} style={{ margin: 0 }}>Customize your feedback</Title>
                            </Flex>
                            {steps === 1 ? (
                                <Flex gap='small' justify='center' align='center'>
                                    <Button type='primary' onClick={handleNextAddNewPopup} disabled={!selectedChannels.whatsapp && !selectedChannels.sms && !selectedChannels.email}>Next <PiArrowRightDuotone /></Button>
                                </Flex>
                            ) : steps === 9 ? (
                                <Flex gap='small' justify='center' align='center'>
                                    <Button onClick={handleSaveExit}><PiFloppyDiskDuotone /> Save & Exit</Button>
                                    <Button type='primary' onClick={handleActive}>Active Now <PiArrowRightDuotone /></Button>
                                </Flex>
                            ) : (
                                <Flex gap='small' justify='center' align='center'>
                                    <Button onClick={handleSaveExit} ><PiFloppyDiskDuotone /> Save & Exit</Button>
                                    <Button type='primary' onClick={handleNextAddNewPopup} disabled={!selectedChannels.whatsapp && !selectedChannels.sms && !selectedChannels.email}>Next <PiArrowRightDuotone /></Button>
                                </Flex>
                            )}
                        </Flex>
                        <Progress showInfo={false} percent={steps * 10} strokeColor={twoColors} strokeLinecap="butt" />
                    </Col>
                </Row>
                <Row style={{ height: '90vh' }}>
                    {steps === 1 || steps === 2 || steps === 3 || steps === 4 || steps === 5 || steps === 6 ? (
                        <Col span={15}>
                            <div className='tempDrawerLeftSide fb-drawer-left'>
                                <div className='edit-feedback-preview-root'>
                                    <div className='edit-feedback-preview'>
                                        <div className='main-preview'>
                                            {(reviewSteps === 1 || reviewSteps === 2) && (
                                                <>
                                                    <div className='feedback-info' style={{ backgroundColor: themeColor, color: textColor }}>
                                                        <div className='fb-head'>
                                                            <div className='card-logo-container'>
                                                                <div className='card-logo-div' style={{ backgroundColor: textColor }}>
                                                                    {storeDetail?.store_logo || file ? (
                                                                        <img alt='card-logo' className='card-logo-image' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                                    ) : (
                                                                        <Paragraph className='card-logo-name'>{storeDetail?.store_name}</Paragraph>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <Title level={4} style={{ color: textColor }}>Hi Jhon, {feedBackHeaderText}</Title>
                                                        </div>
                                                        <div className='fb-divider' />
                                                        <div className='fb-details'>
                                                            <Title level={5} style={{ color: textColor }}>Spent <br /> <span>{storeDetail?.currency_symbol} 3400</span></Title>
                                                            <Title level={5} style={{ color: textColor }}>Date <br /> <span>02 July 2023</span></Title>
                                                            <Title level={5} style={{ color: textColor }}>Time <br /> <span>12:40 PM</span></Title>
                                                        </div>
                                                        {isFeedBackReward && (
                                                            <Flex className="bonus-card" justify='center' align='center' gap={8}>
                                                                <div>
                                                                    <GiSwapBag size={24} color='green' />
                                                                </div>
                                                                <div>
                                                                    <Title level={3} style={{ margin: 0 }}>Earn <span>{feedBackReward} Bonus cash.</span> on giving feedback</Title>
                                                                </div>
                                                            </Flex>
                                                        )}
                                                    </div>
                                                    {reviewSteps === 1 && (
                                                        <div className='fb-rating'>
                                                            <Paragraph>How was your experience ?</Paragraph>
                                                            {ratingIconType === 'emoji' ? (
                                                                <Flex className='rating-row' justify='space-between' align='center'>
                                                                    <Image preview={false} src={emojiOne} alt='emoji-1' className={`emojis ${selectRating !== 0 ? selectRating === 1 ? 'selected-rating' : 'unselected-rating' : ''}`} onClick={() => setSelectRating(1)} />
                                                                    <Image preview={false} src={emojiTwo} alt='emoji-2' className={`emojis ${selectRating !== 0 ? selectRating === 2 ? 'selected-rating' : 'unselected-rating' : ''}`} onClick={() => setSelectRating(2)} />
                                                                    <Image preview={false} src={emojiThree} alt='emoji-3' className={`emojis ${selectRating !== 0 ? selectRating === 3 ? 'selected-rating' : 'unselected-rating' : ''}`} onClick={() => setSelectRating(3)} />
                                                                    <Image preview={false} src={emojiFour} alt='emoji-4' className={`emojis ${selectRating !== 0 ? selectRating === 4 ? 'selected-rating' : 'unselected-rating' : ''}`} onClick={() => setSelectRating(4)} />
                                                                    <Image preview={false} src={emojiFive} alt='emoji-5' className={`emojis ${selectRating !== 0 ? selectRating === 5 ? 'selected-rating' : 'unselected-rating' : ''}`} onClick={() => setSelectRating(5)} />
                                                                </Flex>
                                                            ) : ratingIconType === 'star' ? (
                                                                <Flex className='rating-row' justify='space-between' align='center'>
                                                                    {[1, 2, 3, 4, 5].map((index) => (
                                                                        <PiStarFill size={24} onMouseOver={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(0)} className={`stars ${index <= (hoveredIndex || selectRating) ? 'selected' : 'unselected'}`} onClick={() => setSelectRating(index)} />
                                                                    ))}
                                                                </Flex>
                                                            ) : ratingIconType === 'heart' ? (
                                                                <Flex className='rating-row' justify='space-between' align='center'>
                                                                    {[1, 2, 3, 4, 5].map((index) => (
                                                                        <PiHeartFill size={24} onMouseOver={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(0)} className={`hearts ${index <= (hoveredIndex || selectRating) ? 'selected' : 'unselected'}`} onClick={() => setSelectRating(index)} />
                                                                    ))}
                                                                </Flex>
                                                            ) : ratingIconType === 'digit' ? (
                                                                <Flex className='rating-row' justify='space-between' align='center'>
                                                                    {[1, 2, 3, 4, 5].map((value) => {
                                                                        const isFilled = value <= (hoveredIndex || selectRating);
                                                                        const Icon = (() => {
                                                                            switch (value) {
                                                                                case 1: return isFilled ? PiNumberSquareOneFill : PiNumberSquareOneDuotone;
                                                                                case 2: return isFilled ? PiNumberSquareTwoFill : PiNumberSquareTwoDuotone;
                                                                                case 3: return isFilled ? PiNumberSquareThreeFill : PiNumberSquareThreeDuotone;
                                                                                case 4: return isFilled ? PiNumberSquareFourFill : PiNumberSquareFourDuotone;
                                                                                case 5: return isFilled ? PiNumberSquareFiveFill : PiNumberSquareFiveDuotone;
                                                                                default: return PiNumberSquareOneDuotone;
                                                                            }
                                                                        })();

                                                                        return (
                                                                            <Icon
                                                                                key={value}
                                                                                size={24}
                                                                                onMouseOver={() => setHoveredIndex(value)}
                                                                                onMouseLeave={() => setHoveredIndex(0)}
                                                                                className={`digits ${value <= (hoveredIndex || selectRating) ? 'selected' : 'unselected'}`}
                                                                                onClick={() => setSelectRating(value)}
                                                                            />
                                                                        );
                                                                    })}
                                                                </Flex>
                                                            ) : ratingIconType === 'emojione' && (
                                                                <div style={{ textAlign: 'center', marginTop: 16 }}>
                                                                    {selectRating === 0 ? (
                                                                        <Image preview={false} src={emoji} style={{ width: 100 }} />
                                                                    ) : selectRating === 1 ? (
                                                                        <Image preview={false} src={emojiOne} style={{ width: 100 }} />
                                                                    ) : selectRating === 2 ? (
                                                                        <Image preview={false} src={emojiTwo} style={{ width: 100 }} />
                                                                    ) : selectRating === 3 ? (
                                                                        <Image preview={false} src={emojiThree} style={{ width: 100 }} />
                                                                    ) : selectRating === 4 ? (
                                                                        <Image preview={false} src={emojiFour} style={{ width: 100 }} />
                                                                    ) : selectRating === 5 && (
                                                                        <Image preview={false} src={emojiFive} style={{ width: 100 }} />
                                                                    )}

                                                                    <Slider
                                                                        min={1}
                                                                        max={5}
                                                                        tooltip={{
                                                                            formatter: null
                                                                        }}
                                                                        onChange={(value) => { setSelectRange(value); setSelectRating(Math.round(value)); }}
                                                                        value={typeof selectRange === 'number' ? selectRange : 0}
                                                                        step={0.01}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {reviewSteps === 1 && selectRating !== 0 ? (
                                                        <div className='questions-card'>
                                                            <Title level={3}>{selectRating > 3 ? feedBackPositiveText : feedBacknegitiveText}</Title>
                                                            {Object.keys(categories).map((category) => (
                                                                categories[category] && (
                                                                    <div key={category} className={`category ${selectedLikeUnlikeItem[category.toLowerCase().replace(/\s/g, '')] ? 'selectedCategory' : ''}`} onClick={() => handleLikeUnlikeClick(category.toLowerCase().replace(/\s/g, ''))}>
                                                                        <Paragraph style={{ margin: 0, fontSize: 16, fontWeight: 500 }}>{category}</Paragraph>
                                                                        {selectedLikeUnlikeItem[category.toLowerCase().replace(/\s/g, '')] && (
                                                                            <PiCheck size={24} />
                                                                        )}
                                                                    </div>
                                                                )
                                                            ))}
                                                            <TextArea placeholder='Tell us more (optional)' maxLength={1024} rows={4} style={{ marginTop: 16, marginBottom: 16 }} />
                                                            <Button type='primary' style={{ width: '100%', color: textColor, backgroundColor: themeColor }} onClick={() => setReviewSteps(2)}>Next</Button>
                                                        </div>
                                                    ) : reviewSteps === 2 && (
                                                        <div className='questions-card'>
                                                            <Title level={3}>{howDidYouHereText}</Title>
                                                            {Object.keys(howHearChannels).map((hereChannel) => (
                                                                howHearChannels[hereChannel] && (
                                                                    <div className={`category`} onClick={() => handleSelectReviewStap(hereChannel)}>
                                                                        <Paragraph>{hereChannel}</Paragraph>
                                                                    </div>
                                                                )
                                                            ))}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {reviewSteps === 3 && (
                                                <div className="fb-ask-review-card">
                                                    <Flex justify='center'>
                                                        <ConfettiExplosion zIndex={999999} force={0.8} duration={3000} particleCount={250} width={450} />
                                                    </Flex>
                                                    <Flex justify='start' align='center' gap={8} className="thanks-msg">
                                                        <PiShootingStarFill size={36} color='gold' />
                                                        <Paragraph>Thanks for your valuable feedback.</Paragraph>
                                                    </Flex>
                                                    <div className="ask-review">
                                                        <Paragraph>Do us a favour and <span> share your <br /> rating on Google too?</span> It takes 2 sec.</Paragraph>
                                                        <Button type="primary" className="review-btn"><FcGoogle size={24} /> <span className="button-text">Leave Online Review</span><PiArrowRight size={24} /></Button></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ) : steps === 7 && (
                        <Col span={15}>
                            <div className='tempDrawerLeftSide fb-drawer-left'>
                                <Paragraph style={{ fontWeight: 500, fontSize: 16 }}>
                                    {feedBackSendTime === 'immediate' ? 'This will be sent to customers immediately with purchase along with loyalty communication.' : `This will be sent to customers ${durationValue} ${durationType} after their purchase.`}
                                </Paragraph>
                                <Flex justify='center' gap={16} align='center'>
                                    <Button type={previewType === 'whatsapp' ? 'primary' : 'default'} shape='round' onClick={() => setPreviewType('whatsapp')}>WhatsApp</Button>
                                    <Button type={previewType === 'sms' ? 'primary' : 'default'} shape='round' onClick={() => setPreviewType('sms')}>SMS</Button>
                                    <Button type={previewType === 'email' ? 'primary' : 'default'} shape='round' onClick={() => setPreviewType('email')}>Email</Button>
                                </Flex>
                                {previewType === 'whatsapp' ? (
                                    <div className='add-loyalty-left-popup'>
                                        <div className='add-loyalty-popup-left-div' style={{ overflow: 'hidden' }}></div>
                                        <div className='preview-content'>
                                            <div className='parent-container'>
                                                <div className='mobile-view'>
                                                    <Image src={whatsappMobileMokup} className='back-mobile' preview={false} />
                                                    <div className='preview-whatsapp-card' style={{height:'100%'}}>
                                                        <div className='whatsapp'>
                                                            <Paragraph className='text'><b>{storeDetail?.store_name}</b>{"\n\n"}Dear customer,{"\n\n"}We hope you enjoyed your visit😊{"\n\n"}We would greatly appreciate 10 seconds of your time to provide us with your <b>feedback</b>, so we can serve you even better next time! 🙌{"\n\n"}Thank you for helping us improve.{"\n\n"}We look forward to hearing from you! ✨{storeDetail?.store_name}.</Paragraph>
                                                        </div>
                                                        <div className='whatsapp' style={{marginTop:8, padding:10}}>
                                                            <Paragraph className='text' style={{ textAlign: 'center', margin:0, color:'#0d9298' }}>Share Feedback</Paragraph>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : previewType === 'sms' ? (
                                    <div className='creativeSegment'>
                                        <div className="final-sms-card">
                                            <img alt="phone" className="back-mobile" src={smsMobileMokup} />
                                            <div className="preview-sms-card2">
                                                {/* <div className="header">
                                                    <div className="logo">Z</div>
                                                    <p>mZIPLER</p>
                                                </div> */}
                                                <div className="sms" style={{marginTop:80}}>
                                                    <div className="text">Hi, hope you enjoyed your visit at {storeDetail?.store_name}, &amp; Share your valuable feedback and help us serve you better! Click: <a href="https://zipler.io/" rel="noreferrer" target="_blank">zipler.io</a><br /><br />via zipler
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : previewType === 'email' && (
                                    <div className='add-loyalty-left-popup'>
                                        <div className='add-loyalty-popup-left-div' style={{ overflow: 'hidden' }}></div>
                                        <div className='preview-content'>
                                            <div className='parent-container'>
                                                <div className='mobile-view'>
                                                    <Image src={emailMobileMokup} className='back-mobile' preview={false} />
                                                    <div className="loyalty-email-card">
                                                        <div className="logo">
                                                            <Paragraph className="logo-name">{storeDetail?.store_name}</Paragraph>
                                                        </div>
                                                        <Image preview={false} src={emailFeedbackImage} class="mid-image" alt="mid_image" />
                                                        <h1>We value your feedback!</h1>
                                                        <h3>We would love to hear about your recent experience with {storeDetail?.store_name}. Got 10 seconds?</h3>
                                                        <Button style={{margin:'30px auto 10px auto'}}>Share feedback <PiArrowRight size={24} /> </Button>
                                                        <h3>Thank you from team {storeDetail?.store_name}. 🙏</h3>
                                                        <br/>
                                                        <p class="sentBy">Sent by <a href="https://zipler.io" target="_blank" rel="noreferrer">zipler.io</a> on behalf of <span>{storeDetail?.store_name}</span></p>
                                                        <p class="unsub">To unsubscribe from these rewarding emails, you may opt-out<span>here</span></p>
                                                        <Image src={blogo} width="58px" alt="zipler.io" style={{ margin: '20px auto', display: 'block' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Col>
                    )}
                    {steps === 1 ? (
                        <Col span={9}>
                            <div className='feedbackRightSide'>
                                <div style={{ width: '100%' }}>
                                    <Title level={3} >Let's go over the essentials of your feedback.</Title>
                                    <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                        <div>
                                            <Title level={4} style={{ marginTop: 0 }}>Feedback header</Title>
                                            <Input value={feedBackHeaderText} onChange={(e) => setFeedBackHeaderText(e.target.value)} />
                                        </div>
                                        <div>
                                            <Title level={4}>Feedback question?</Title>
                                            <Input value={feedBackQurstionText} onChange={(e) => setFeedBackQurstionText(e.target.value)} />
                                        </div>
                                    </Card>
                                    <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                        <div>
                                            <Title level={4} style={{ margin: 0 }}>Select ratting Type</Title>
                                            <div className='rating-styles'>
                                                <Flex justify='space-between' align='center' className={`rating-style-option ${ratingIconType === 'emoji' ? 'active' : ''}`} onClick={() => setRatingIconType('emoji')}>
                                                    <Image preview={false} src={emojiOne} alt='emoji-1' className={`emojis selected-rating`} />
                                                    <Image preview={false} src={emojiTwo} alt='emoji-2' className={`emojis selected-rating`} />
                                                    <Image preview={false} src={emojiThree} alt='emoji-3' className={`emojis selected-rating`} />
                                                    <Image preview={false} src={emojiFour} alt='emoji-4' className={`emojis selected-rating`} />
                                                    <Image preview={false} src={emojiFive} alt='emoji-5' className={`emojis selected-rating`} />
                                                </Flex>
                                                <Flex justify='space-between' align='center' className={`rating-style-option ${ratingIconType === 'star' ? 'active' : ''}`} onClick={() => setRatingIconType('star')}>
                                                    {[1, 2, 3, 4, 5].map((index) => (
                                                        <PiStarFill size={24} className={`stars selected`} />
                                                    ))}
                                                </Flex>
                                                <Flex justify='space-between' align='center' className={`rating-style-option ${ratingIconType === 'heart' ? 'active' : ''}`} onClick={() => setRatingIconType('heart')}>
                                                    {[1, 2, 3, 4, 5].map((index) => (
                                                        <PiHeartFill size={24} className={`hearts selected`} />
                                                    ))}
                                                </Flex>
                                                <Flex justify='space-between' align='center' className={`rating-style-option ${ratingIconType === 'digit' ? 'active' : ''}`} onClick={() => setRatingIconType('digit')}>
                                                    <PiNumberSquareOneFill size={24} className={`digits selected`} />
                                                    <PiNumberSquareTwoFill size={24} className={`digits selected`} />
                                                    <PiNumberSquareThreeFill size={24} className={`digits selected`} />
                                                    <PiNumberSquareFourFill size={24} className={`digits selected`} />
                                                    <PiNumberSquareFiveFill size={24} className={`digits selected`} />
                                                </Flex>
                                                <Flex justify='center' align='center' className={`rating-style-option ${ratingIconType === 'emojione' ? 'active' : ''}`} onClick={() => setRatingIconType('emojione')}>
                                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                                        <Image preview={false} src={emojiFive} style={{ width: 100 }} />
                                                        <Slider
                                                            min={1}
                                                            max={5}
                                                            value={5}
                                                        />
                                                    </div>
                                                </Flex>
                                            </div>
                                        </div>
                                    </Card>
                                    <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                        <div>
                                            <Title level={4} style={{ marginTop: 0 }}>Positive rating follow-up?</Title>
                                            <Input value={feedBackPositiveText} onChange={(e) => setFeedBackPositiveText(e.target.value)} />
                                        </div>
                                        <div>
                                            <Title level={4}>Negative & Neutral rating follow-up?</Title>
                                            <Input value={feedBacknegitiveText} onChange={(e) => setFeedBacknegitiveText(e.target.value)} />
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    ) : steps === 2 ? (
                        <Col span={9}>
                            <div className='feedbackRightSide'>
                                <div style={{ width: '100%' }}>
                                    <Title level={3} >Choose the categories for feedback rating</Title>
                                    <Paragraph style={{ color: '#374040' }}>Once your customer provides their rating, they will be prompted to select a reason from the following categories.</Paragraph>
                                    <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                        {Object.keys(categories).map((category) => (
                                            <Flex justify='space-between' align='center' className={`fb-reasons ${categories[category] ? 'active' : ''}`} key={category} onClick={() => handleCheckboxChange(category)} >
                                                <Paragraph style={{ margin: 0, fontSize: 16, fontWeight: 500 }}>{category}</Paragraph>
                                                <Checkbox checked={categories[category]} />
                                            </Flex>
                                        ))}
                                        {showAddMoreInput && (
                                            <div className='add-more-fb-category'>
                                                <Input
                                                    value={newCategory}
                                                    onChange={(e) => setNewCategory(e.target.value)}
                                                    placeholder="Add a new category"
                                                    style={{ marginBottom: 8 }}
                                                />
                                                {newCategory !== '' && (
                                                    <Button type='link' style={{ color: '#3a1a55', position: 'absolute', right: 0 }} onClick={handleAddCategory}><PiPlusDuotone />Add</Button>
                                                )}
                                            </div>
                                        )}
                                        {!showAddMoreInput && (
                                            <Button style={{ width: '100%' }} onClick={() => setShowAddMoreInput(true)}>
                                                <PiPlusDuotone size={24} /> Add More
                                            </Button>
                                        )}
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    ) : steps === 3 ? (
                        <Col span={9}>
                            <div className='feedbackRightSide'>
                                <div style={{ width: '100%' }}>
                                    <Title level={3} >You could ask your customers:</Title>
                                    <Title level={4} style={{ marginTop: 0 }}>Type Quetion?</Title>
                                    <Input value={howDidYouHereText} onChange={(e) => setHowDidYouHereText(e.target.value)} />
                                    <Paragraph style={{ color: '#374040' }}>Select all the options that you want to show your customers for the question "{howDidYouHereText}".</Paragraph>
                                    <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                        {Object.keys(howHearChannels).map((hereChannel) => (
                                            <Flex justify='space-between' align='center' className={`fb-reasons ${howHearChannels[hereChannel] ? 'active' : ''}`} key={hereChannel} onClick={() => handleHereCheckboxChange(hereChannel)} >
                                                <Paragraph style={{ margin: 0, fontSize: 16, fontWeight: 500 }}>{hereChannel}</Paragraph>
                                                <Checkbox checked={howHearChannels[hereChannel]} />
                                            </Flex>
                                        ))}
                                        {showAddMoreInput && (
                                            <div className='add-more-fb-category'>
                                                <Input
                                                    value={newHereChannel}
                                                    onChange={(e) => setNewHereChannel(e.target.value)}
                                                    placeholder="Add option here"
                                                    style={{ marginBottom: 8 }}
                                                />
                                                {newHereChannel !== '' && (
                                                    <Button type='link' style={{ color: '#3a1a55', position: 'absolute', right: 0 }} onClick={handleAddHereChannel}><PiPlusDuotone />Add</Button>
                                                )}
                                            </div>
                                        )}
                                        {!showAddMoreInput && (
                                            <Button style={{ width: '100%' }} onClick={() => setShowAddMoreInput(true)}>
                                                <PiPlusDuotone size={24} /> Add More
                                            </Button>
                                        )}
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    ) : steps === 4 ? (
                        <Col span={9}>
                            <div className='feedbackRightSide'>
                                <div style={{ width: '100%' }}>
                                    <Title level={3} >Now, let's give your customers a reward for sharing feedback</Title>
                                    <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                        <Flex justify='space-between' align='center'>
                                            <Title level={4} style={{ marginTop: 0 }}>Feedback Reward</Title>
                                            <Switch checked={isFeedBackReward} onChange={() => setIsFeedBackReward(!isFeedBackReward)} />
                                        </Flex>
                                        <Paragraph style={{ color: '#374040' }}>Reward to your customers when they share feedback with you</Paragraph>
                                        <Input
                                            type="number"
                                            max={9999}
                                            value={feedBackReward}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value.length <= 4) {
                                                    setFeedBackReward(value);
                                                }
                                            }}
                                            disabled={!isFeedBackReward}
                                        />
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    ) : steps === 5 ? (
                        <Col span={9}>
                            <div className='feedbackRightSide'>
                                <div style={{ width: '100%' }}>
                                    <Title level={3} >Decide when you want to ask for feedback</Title>
                                    <Paragraph style={{ color: '#374040' }}>Customers will only be able to provide feedback for up to 5 days after the feedback communication.</Paragraph>
                                    <div className={`feedback-send-time ${feedBackSendTime === 'immediate' ? 'active' : ''}`}>
                                        <div className='' onClick={() => setFeedBackSendTime('immediate')}>
                                            <Flex justify='space-between' align='center'>
                                                <Title level={4} style={{ marginTop: 0 }}>Immediately with purchase</Title>
                                                <Radio checked={feedBackSendTime === 'immediate'} />
                                            </Flex>
                                            <Flex className='info-div' gap={8} align='center'>
                                                <FaBoxOpen size={36} />
                                                <Paragraph style={{ color: '#374040', margin: 0, fontSize: 16 }}>Select this if your customers has experienced your product / service by the time they pay for the bill.</Paragraph>
                                            </Flex>
                                        </div>
                                    </div>
                                    <div className={`feedback-send-time ${feedBackSendTime === 'schedule' ? 'active' : ''}`}>
                                        <div className='' onClick={() => setFeedBackSendTime('schedule')}>
                                            <Flex justify='space-between' align='center'>
                                                <Title level={4} style={{ marginTop: 0 }}>After a delay</Title>
                                                <Radio checked={feedBackSendTime === 'schedule'} />
                                            </Flex>
                                            <Flex className='info-div' gap={8} align='center'>
                                                <GiTakeMyMoney size={36} />
                                                <Paragraph style={{ color: '#374040', margin: 0, fontSize: 16 }}>Select this if your customers pays first and then experience your product and service.</Paragraph>
                                            </Flex>
                                            {feedBackSendTime === 'schedule' && (
                                                <div className='duration-delay-card'>
                                                    <Title level={5} style={{ marginTop: 0 }}>Delay duration</Title>
                                                    <Flex gap={16}>
                                                        <Select showSearch value={durationValue} onChange={(value) => setDurationValue(value)} style={{ width: '100%' }}>
                                                            {Array.from({ length: 60 }, (_, i) => (
                                                                <Select.Option key={i + 1} value={i + 1}>
                                                                    {i + 1}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                        <Select showSearch value={durationType} onChange={(value) => setDurationType(value)} style={{ width: '100%' }}>
                                                            <Select.Option value={'hours'}>Hours after purchase</Select.Option>
                                                            <Select.Option value={'minuts'}>Minuts after purchase</Select.Option>
                                                        </Select>
                                                    </Flex>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ) : steps === 6 ? (
                        <Col span={9}>
                            <div className='feedbackRightSide'>
                                <div style={{ width: '100%' }}>
                                    <Title level={3} >Design your feedback page</Title>
                                    <Paragraph style={{ color: '#374040' }}>You can change this anytime</Paragraph>
                                    <Card bordered={false} className='left-div'>
                                        <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiImageDuotone size={24} />Logo</Title>
                                        <ImgCrop shape="round" aspect={1} quality={1}>
                                            <Upload {...props} style={{ width: '100%' }} className='qr-upload-logo' accept="image/jpeg, image/png">
                                                {file ? (
                                                    <Button style={{ width: '100%' }}>Upload another file</Button>
                                                ) : (
                                                    <Button style={{ width: '100%' }} icon={<UploadOutlined />}>Upload your logo</Button>
                                                )}

                                            </Upload>
                                        </ImgCrop>
                                        <Paragraph style={{ color: '#697777', fontSize: 12, marginTop: 8 }}>Recommended size 512 x 512 px in JPG, GIF or PNG format up-to max size of 10MB</Paragraph>
                                    </Card>
                                    <Flex gap={16} style={{ marginTop: 24 }}>
                                        <div className='add-new-qr-input-div'>
                                            <Flex gap={16} align='center'>
                                                <ColorPicker value={themeColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setThemeColor(hexColor); }} />
                                                <Title level={4} style={{ margin: 0 }}>Theme Color</Title>
                                            </Flex>
                                        </div>
                                        <div className='add-new-qr-input-div'>
                                            <Flex gap={16} align='center'>
                                                <ColorPicker value={textColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setTextColor(hexColor); }} />
                                                <Title level={4} style={{ margin: 0 }}>Text Color</Title>
                                            </Flex>
                                        </div>
                                    </Flex>
                                </div>
                            </div>
                        </Col>
                    ) : steps === 7 ? (
                        <Col span={9}>
                            <div className='feedbackRightSide'>
                                <div style={{ width: '100%' }}>
                                    <Title level={3} >Next, let's decide how to collect feedback</Title>
                                    <Flex className='info-div' gap={8} align='center'>
                                        <FaStarOfLife size={36} />
                                        <Paragraph style={{ color: '#374040', margin: 0, fontSize: 16 }}>No credits will be used for feedback as it will be a part of the loyalty message that is sent immediately with purchase.</Paragraph>
                                    </Flex>
                                    <div className={`feedback-send-time ${feedBackCollectThrough === 'external' ? 'active' : ''}`}>
                                        <div className='' onClick={() => setFeedBackCollectThrough('external')}>
                                            <Flex justify='space-between' align='center'>
                                                <Title level={4} style={{ marginTop: 0 }}>External Link</Title>
                                                <Radio checked={feedBackCollectThrough === 'external'} />
                                            </Flex>
                                            <Paragraph style={{ color: '#374040', margin: 0, fontSize: 16 }}>Capture feedback through a link sent on Whatsapp, SMS or Email</Paragraph>
                                        </div>
                                        {feedBackCollectThrough === 'external' && (
                                            <div className='channels'>
                                                <Title level={4} style={{ margin: 0 }}>Select channels</Title>
                                                <div className={`template-channle-checkbox-div ${selectedChannels.whatsapp && 'active'}`}>
                                                    <Flex justify='space-between' align='center' onClick={() => handleChannelClick('whatsapp')}>
                                                        <Flex gap={8} align='center'>
                                                            <PiWhatsappLogo size={24} /><Typography><b>Whatsapp Marketing</b></Typography>
                                                        </Flex>
                                                        <Checkbox checked={selectedChannels.whatsapp} />
                                                    </Flex>
                                                    {selectedChannels.whatsapp && (
                                                        <>
                                                            <Flex gap={16} justify='space-between' align='center' style={{ marginTop: 16 }}>
                                                                <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}>Send via SMS if WhatsApp fails to deliver</Title>
                                                                <Switch checked={sendIfFailsWhatsapp} onChange={() => setSendIfFailsWhatsapp(!sendIfFailsWhatsapp)} />
                                                            </Flex>
                                                            <Flex gap={16} justify='space-between' align='center' style={{ marginTop: 16 }}>
                                                                <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}>Add custom text</Title>
                                                                <Switch checked={addWhatsappCustomText} onChange={() => setAddWhatsappCustomText(!addWhatsappCustomText)} />
                                                            </Flex>
                                                        </>
                                                    )}
                                                    {selectedChannels.whatsapp && addWhatsappCustomText && (
                                                        <>
                                                            <Title level={5}>Use this to drive engagement with your brand</Title>
                                                            <Input showCount maxLength={149} placeholder='Write somethign awsome...' value={whatsappCustomText} onChange={(e) => setWhatsappCustomText(e.target.value)} />
                                                        </>
                                                    )}
                                                </div>
                                                <div className={`template-channle-checkbox-div ${selectedChannels.sms && 'active'}`} onClick={() => handleChannelClick('sms')}>
                                                    <Flex justify='space-between' align='center'>
                                                        <Flex gap={8} align='center'>
                                                            <PiChatCenteredDotsDuotone size={24} /><Typography><b>SMS</b></Typography>
                                                        </Flex>
                                                        <Checkbox checked={selectedChannels.sms} />
                                                    </Flex>
                                                </div>
                                                <div className={`template-channle-checkbox-div ${selectedChannels.email && 'active'}`} onClick={() => handleChannelClick('email')}>
                                                    <Flex justify='space-between' align='center'>
                                                        <Flex gap={8} align='center'>
                                                            <PiEnvelopeDuotone size={24} /><Typography><b>Email</b></Typography>
                                                        </Flex>
                                                        <Checkbox checked={selectedChannels.email} />
                                                    </Flex>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`feedback-send-time ${feedBackCollectThrough === 'atstore' ? 'active' : ''}`}>
                                        <div className='' onClick={() => setFeedBackCollectThrough('atstore')}>
                                            <Flex justify='space-between' align='center'>
                                                <Title level={4} style={{ marginTop: 0 }}>Tablet (in-store)</Title>
                                                <Radio checked={feedBackCollectThrough === 'atstore'} />
                                            </Flex>
                                            <Paragraph style={{ color: '#374040', margin: 0, fontSize: 16 }}>Capture feedback on a tablet while the customer is in-store</Paragraph>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ) : steps === 8 ? (
                        <Col span={9}>
                            <div className='feedbackRightSide'>
                                <div style={{ width: '100%' }}>
                                    <Title level={3}>Choose who all should get alerts for negative feedback</Title>
                                    <Flex className='info-div' gap={8} align='center'>
                                        <div>
                                            <PiBellDuotone size={36} />
                                        </div>
                                        <div>
                                            <Paragraph style={{ color: '#374040', margin: 0, fontSize: 16 }}>Resolving feedbacks in real-time improves customer experience & prevents the customer to share negative review online</Paragraph>
                                        </div>
                                    </Flex>
                                    <div className={`feedback-send-time`}>
                                        <div className=''>
                                            <Flex justify='space-between' align='center'>
                                                <Title level={4} style={{ marginTop: 0 }}>Negative Feedback Alert</Title>
                                                <Switch checked={isNegativeFeedbackAlert} onChange={() => setIsNegativeFeedbackAlert(!isNegativeFeedbackAlert)} />
                                            </Flex>
                                            <Paragraph style={{ color: '#374040', margin: 0, fontSize: 16 }}>Select channels to receive alerts. Keep sufficient credits to not miss any alerts!</Paragraph>
                                        </div>
                                        <div className='channels'>
                                            <Title level={4} style={{ margin: 0 }}>Select channels</Title>
                                            <div className={`template-channle-checkbox-div ${negativeFeedBackAlertChannels.whatsapp && 'active'}`}>
                                                <Flex justify='space-between' align='center' onClick={() => handleNegativeChannelClick('whatsapp')}>
                                                    <Flex gap={8} align='center'>
                                                        <PiWhatsappLogo size={24} /><Typography><b>Whatsapp Marketing</b></Typography>
                                                    </Flex>
                                                    <Checkbox checked={negativeFeedBackAlertChannels.whatsapp} />
                                                </Flex>
                                            </div>
                                            <div className={`template-channle-checkbox-div ${negativeFeedBackAlertChannels.sms && 'active'}`} onClick={() => handleNegativeChannelClick('sms')}>
                                                <Flex justify='space-between' align='center'>
                                                    <Flex gap={8} align='center'>
                                                        <PiChatCenteredDotsDuotone size={24} /><Typography><b>SMS</b></Typography>
                                                    </Flex>
                                                    <Checkbox checked={negativeFeedBackAlertChannels.sms} />
                                                </Flex>
                                            </div>
                                            {(negativeFeedBackAlertChannels.whatsapp || negativeFeedBackAlertChannels.sms) && (
                                                <div className='negativealertNumbers'>
                                                    <Title level={4} style={{ margin: 0 }}>Mobile numbers to receive alerts</Title>
                                                    <Paragraph style={{ color: '#374040', margin: 0, fontSize: 16 }}>Same number will be used for both channels</Paragraph>
                                                    {Object.keys(numbers).map((number) => (
                                                        <Flex justify='space-between' align='center' className={`fb-reasons ${numbers[number] ? 'active' : ''}`} key={number} onClick={() => handleNumberCheckboxChange(number)} >
                                                            <Paragraph style={{ margin: 0, fontSize: 16, fontWeight: 500 }}>{number}</Paragraph>
                                                            <Checkbox checked={numbers[number]} />
                                                        </Flex>
                                                    ))}
                                                    <div className='add-more-fb-category'>
                                                        <Input
                                                            value={newNumber}
                                                            onChange={(e) => setNewNumber(e.target.value)}
                                                            placeholder="Add a new phone no."
                                                            style={{ marginBottom: 8 }}
                                                        />
                                                        {newNumber !== '' && (
                                                            <Button type='link' style={{ color: '#3a1a55', position: 'absolute', right: 0 }} onClick={handleAddNumber}><PiPlusDuotone />Add</Button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <div className={`template-channle-checkbox-div ${negativeFeedBackAlertChannels.email && 'active'}`}>
                                                <Flex justify='space-between' align='center' onClick={() => handleNegativeChannelClick('email')}>
                                                    <Flex gap={8} align='center'>
                                                        <PiEnvelopeDuotone size={24} /><Typography><b>Email</b></Typography>
                                                    </Flex>
                                                    <Checkbox checked={negativeFeedBackAlertChannels.email} />
                                                </Flex>
                                                {negativeFeedBackAlertChannels.email && (
                                                    <div className='negativealertNumbers'>
                                                        {Object.keys(emails).map((email) => (
                                                            <Flex justify='space-between' align='center' className={`fb-reasons ${emails[email] ? 'active' : ''}`} key={email} onClick={() => handleEmailCheckboxChange(email)} >
                                                                <Paragraph style={{ margin: 0, fontSize: 16, fontWeight: 500 }}>{email}</Paragraph>
                                                                <Checkbox checked={emails[email]} />
                                                            </Flex>
                                                        ))}
                                                        <div className='add-more-fb-category'>
                                                            <Input
                                                                value={newEmail}
                                                                onChange={(e) => setNewEmail(e.target.value)}
                                                                placeholder="Add a new email"
                                                                style={{ marginBottom: 8 }}
                                                            />
                                                            {newEmail !== '' && (
                                                                <Button type='link' style={{ color: '#3a1a55', position: 'absolute', right: 0 }} onClick={handleAddEmail}><PiPlusDuotone />Add</Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ) : steps === 9 && (
                        <Col span={9}>
                            <div className='feedbackRightSide'>
                                <div style={{ width: '100%' }}>
                                    <Title level={3} >Now, let's increase your online reviews automatically</Title>
                                    <div className='info-div'>
                                        <Flex gap={8} align='center'>
                                            <Image preview={false} src={emojiFive} />
                                            <Title level={5}>More reviews. More business for you.</Title>
                                        </Flex>
                                        <Paragraph style={{ color: '#374040', margin: 0, fontSize: 16 }}>When a customer gives 4 or 5 rating, we ask them to leave a positive review online.</Paragraph>
                                    </div>
                                    <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                        <Flex justify='space-between' align='center'>
                                            <Title level={4} style={{ marginTop: 0 }}>Ask For Online Reviews</Title>
                                            <Switch checked={isReviewLingSend} onChange={() => setIsReviewLingSend(!isReviewLingSend)} />
                                        </Flex>
                                        <Paragraph style={{ color: '#374040' }}>Where do you want to increase your online reputation?</Paragraph>
                                        <Input
                                            placeholder='Enter number of rettings'
                                            type="number"
                                            min={1}
                                            max={5}
                                            value={sendReviewlingWhen}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value <= 5) {
                                                    setSendReviewlingWhen(value);
                                                }
                                            }}
                                            disabled={!isReviewLingSend}
                                        />
                                        <Input
                                            placeholder='Enter review link'
                                            value={reviewLink}
                                            onChange={(e) => setReviewLink(e.target.value)}
                                            disabled={!isReviewLingSend}
                                            style={{ marginTop: 16 }}
                                        />
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </Drawer>
        </Layout>
    );
};
export default FeedbackContent;