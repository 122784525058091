import React, { useEffect, useState, useRef } from 'react';
import { Layout, Row, Col, Typography, Flex, Button, Drawer, Collapse, Modal, Progress, Input, Form, Radio, Tooltip, Checkbox, message, Space, Select, Switch, Upload, ColorPicker, QRCode, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PiArrowArcRightDuotone, PiArrowLeftDuotone, PiArrowRightDuotone, PiBellRingingDuotone, PiCalendarCheckDuotone, PiCaretRight, PiChatCenteredDotsDuotone, PiClockCountdownDuotone, PiCoinsDuotone, PiCurrencyInrDuotone, PiDeviceMobileCameraDuotone, PiDeviceMobileSpeakerFill, PiEnvelopeDuotone, PiFloppyDiskDuotone, PiGearFineDuotone, PiGiftDuotone, PiGoogleChromeLogoDuotone, PiImageDuotone, PiInfoDuotone, PiLightbulbDuotone, PiLightningDuotone, PiLinkDuotone, PiMapPinAreaDuotone, PiPackageDuotone, PiPencilLineDuotone, PiPercentDuotone, PiPhoneCallDuotone, PiPlayDuotone, PiPlusDuotone, PiQuestionDuotone, PiReceiptDuotone, PiSealCheckDuotone, PiSelectionPlusDuotone, PiShoppingCartDuotone, PiStorefrontDuotone, PiTextTDuotone, PiUserDuotone, PiWhatsappLogoDuotone, PiXCircleDuotone } from 'react-icons/pi';
// import qrImageOne from '../assets/qrpage/1.webp';
import qrImageTwo from '../assets/qrpage/qrpage.webp';
import mobilePng from '../assets/qrpage/mobile.png';
import arrowimage from '../assets/arrow2.svg';
import mobileImage from '../assets/mobile-phone.png';
import whatsappImage from '../assets/zipler-mobile-mock-up.png';
import skeletonUiMockup from '../assets/skeleton_ui.png';
import bgImg from '../assets/dymmy.jpg';
import { BsSearchHeart, BsStars } from 'react-icons/bs';
import { InboxOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import QrInsights from './QrInsights';

const { Paragraph, Title } = Typography;
const { TextArea } = Input;
const { confirm } = Modal;

const rgbToHex = (r, g, b) => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
};

const twoColors = {
    '0%': '#108ee9',
    '50%': '#3A1A55',
    '100%': '#3A1A55',
};

const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };
    return `${day}${daySuffix(day)} ${month} ${year}`;
};

const initialTermsList = [
    { text: 'Redeemable once every 24 hours', defaultChecked: false },
    { text: 'Minimum purchase required', defaultChecked: false },
    { text: '2 offers cannot be clubbed', defaultChecked: false },
    { text: 'Not redeemable on public holidays', defaultChecked: false },
    { text: 'Reward redeemable in-store only', defaultChecked: false },
    { text: 'Merchant reserves the right to final say', defaultChecked: false },
];

const options = [
    {
        value: 'days',
        label: 'Days',
    },
    {
        value: 'weeks',
        label: 'Weeks',
    },
    {
        value: 'months',
        label: 'Months',
    },
];

const QrCodeContent = ({ userData, sessionData, storeData }) => {
    const storeDetail = storeData?.filter(store => store?.store_id.toString() === localStorage.getItem('storeId'))?.[0];
    const { segmentType } = useParams();
    const navigate = useNavigate();
    // const [qrPopup, setQrPopup] = useState(false);
    const [qrScratchPopup, setQrScratchPopup] = useState(false);
    const [addNewDrawer, setAddNewDrawer] = useState(segmentType === 'addnew' ? true : false);
    const [staps, setStaps] = useState(1);
    const [secondStapPageType, setSecondStapPageType] = useState('landing');
    const [rewardType, setRewardType] = useState('');
    const [rewardTermType, setRewardTermType] = useState('newandexisting');
    const [rewardOn, setRewardOn] = useState('entire');
    const [minPurchace, setMinPurchace] = useState('');
    const [preDisc, setPerDisc] = useState('');
    const [cashDisc, setCashDisc] = useState('');
    const [freeItem, setFreeItem] = useState('');
    const [specificItem, setSpecificItem] = useState('');
    const [minPurchaseAmt, setMinPurchaceAmt] = useState('');
    const [loyalty, setLoyalty] = useState('');
    const [externalLink, setExternalLink] = useState('');
    const [landingPageTitle, setLandingPageTitle] = useState('Add a title for your landing page.');
    const [posterTitle, setPosterTitle] = useState('Add a title for your landing page.');
    const [stickerTitle, setStickerTitle] = useState('Add a title for your landing page.');
    const [socialTitle, setSocialTitle] = useState('Add a title for your landing page.');
    const [formOne, step2Form] = Form.useForm();
    const [redeemTime, setRedeemTime] = useState('justonce');
    const [terms, setTerms] = useState({});
    const [customTerms, setCustomTerms] = useState([]);
    const [addNewTerm, setAddNewTerm] = useState(false);
    const [addNewTermInput, setAddNewTermInput] = useState('');
    const landingtitleinputRef = useRef(null);
    const [rewardMessage, setRewardMessage] = useState('claimed');
    const [expirtime, setExpirtime] = useState('30');
    const [expirtimeNotification, setExpirtimeNotification] = useState('3');
    const [expirtimeNotificationType, setExpirtimeNotificationType] = useState('days');
    const [isExpirtimeNotification, setIsExpirtimeNotification] = useState(true);
    const [expirtimeType, setExpirtimeType] = useState('days');
    const [comunicationTypes, setComunicatinTypes] = useState({ 'Whatsapp Utility': true, SMS: true, });
    const [themeColor, setThemeColor] = useState('#4c2e61');
    const [textColor, setTextColor] = useState('#FFFFFF');
    const [posterThemeColor, setPosterThemeColor] = useState('#4c2e61');
    const [posterTextColor, setPosterTextColor] = useState('#FFFFFF');
    const [stickerThemeColor, setStickerThemeColor] = useState('#4c2e61');
    const [stickerTextColor, setStickerTextColor] = useState('#FFFFFF');
    const [socialPostThemeColor, setsocialPostThemeColor] = useState('#4c2e61');
    const [socialPostTextColor, setsocialPostTextColor] = useState('#FFFFFF');
    const [businessCardThemeColor, setBusinessCardThemeColor] = useState('#4c2e61');
    const [businessCardTextColor, setBusinessCardTextColor] = useState('#FFFFFF');
    const [businessName, setBusinessName] = useState(storeDetail?.store_name);
    const [businessAddress, setBusinessAddress] = useState(`${storeDetail?.store_address}${storeDetail?.city && `, ${storeDetail?.city}`}${storeDetail?.state && `, ${storeDetail?.state}`}${storeDetail?.postcode && ` - ${storeDetail?.postcode}`}`);
    const [businessCardTextColorFront, setBusinessCardTextColorFront] = useState('#FFFFFF');
    const [creativeType, setCreativeType] = useState('poster');
    const [businessContactInfoPhone, setBusinessContactInfoPhone] = useState(storeDetail?.store_number);
    const [businessContactInfoEmail, setBusinessContactInfoEmail] = useState(storeDetail?.store_email ? storeDetail?.store_email : '');
    const [file, setFile] = useState(null);
    const [businessCardSide, setBusinessCardSide] = useState('front');
    const [posterBannerImage, setPosterBannerImage] = useState(null);
    const [socialBannerImage, setSocialBannerImage] = useState(null);
    const [businessCardBannerImage, setbusinessCardBannerImage] = useState(null);
    const [qrId, setQrId] = useState(0);
    const [stepLoader, setStepLoader] = useState(false);
    const [encId, setEncId] = useState('');

    const beforeUpload = (file) => {
        const isJPGOrPNG = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJPGOrPNG) {
            message.error('You can only upload JPG/PNG files!');
            return Upload.LIST_IGNORE;
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('Image must be smaller than 5MB!');
            return Upload.LIST_IGNORE;
        }
        return isJPGOrPNG && isLt5M;
    };

    const handleChangePosterBanner = (info) => {
        if (info.file.status === 'done') {
            const reader = new FileReader();
            reader.onload = () => setPosterBannerImage(reader.result);
            reader.readAsDataURL(info.file.originFileObj);
        }
    };

    const handleRemovePosterBanner = () => {
        setPosterBannerImage(null);
    };

    const handleChangeSocialBanner = (info) => {
        if (info.file.status === 'done') {
            const reader = new FileReader();
            reader.onload = () => setSocialBannerImage(reader.result);
            reader.readAsDataURL(info.file.originFileObj);
        }
    };

    const handleRemoveSocialBanner = () => {
        setSocialBannerImage(null);
    };

    const handleExistingQr = (qrcode) => {
        setQrId(qrcode?.qr_id);
        setEncId(qrcode?.enc_qr_id);
        setRewardType(qrcode?.reward_type);
        setRewardOn(qrcode?.reward_on);
        setMinPurchace(qrcode?.min_purchase);
        setPerDisc(qrcode?.pre_disc);
        setCashDisc(qrcode?.cash_disc);
        setFreeItem(qrcode?.free_item);
        setSpecificItem(qrcode?.specific_item);
        setMinPurchaceAmt(qrcode?.min_purchase_amt);
        setExternalLink(qrcode?.external_link);
        setRewardTermType(qrcode?.reward_term_type);
        setRedeemTime(qrcode?.redeem_time);
        setTerms(JSON.parse(qrcode?.terms));
        setExpirtime(qrcode?.expire_time);
        setExpirtimeType(qrcode?.expire_time_type);
        setExpirtimeNotification(qrcode?.expiring_notification_time);
        setExpirtimeNotificationType(qrcode?.expiring_notification_time_type);
        setIsExpirtimeNotification(qrcode?.is_expiring_notification_enable);
        setComunicatinTypes(qrcode?.communication_type);
        setFile(`${window.BASE_URL}/${qrcode?.logo}`);
        setThemeColor(qrcode?.theme_color);
        setTextColor(qrcode?.text_color);
        setPosterThemeColor(qrcode?.poster_theme_color);
        setPosterTextColor(qrcode?.poster_text_color);
        setStickerThemeColor(qrcode?.sticker_theme_color);
        setStickerTextColor(qrcode?.sticker_text_color);
        setsocialPostThemeColor(qrcode?.social_theme_color);
        setsocialPostTextColor(qrcode?.social_text_color);
        setBusinessCardThemeColor(qrcode?.business_card_theme_color);
        setBusinessCardTextColor(qrcode?.business_card_text_color);
        setBusinessCardTextColorFront(qrcode?.business_card_front_text_color);
        setBusinessName(qrcode?.business_name);
        setBusinessAddress(qrcode?.business_address);
        setCreativeType(qrcode?.business_phone);
        setBusinessContactInfoPhone(qrcode?.business_email);
        setBusinessContactInfoEmail(qrcode?.business_whatsapp);
        setLandingPageTitle(qrcode?.landing_page_title);
        setPosterTitle(qrcode?.poster_title);
        setStickerTitle(qrcode?.sticker_title);
        setSocialTitle(qrcode?.social_title);
        setPosterBannerImage(`${window.BASE_URL}/${qrcode?.poster_banner}`);
        setSocialBannerImage(`${window.BASE_URL}/${qrcode?.social_banner}`);
        setbusinessCardBannerImage(`${window.BASE_URL}/${qrcode?.business_card_banner}`);
        formOne.setFieldsValue({
            qrname: qrcode?.name,
            qrObjective: qrcode?.objective
        });
    };

    const handleChangeBusinessCardBanner = (info) => {
        if (info.file.status === 'done') {
            const reader = new FileReader();
            reader.onload = () => setbusinessCardBannerImage(reader.result);
            reader.readAsDataURL(info.file.originFileObj);
        }
    };

    const handleRemoveBusinessCardBanner = () => {
        setbusinessCardBannerImage(null);
    };

    const props = {
        name: 'file',
        multiple: false,
        beforeUpload: (file) => {
            return false;
        },
        onChange(info) {
            const { status } = info.file;

            if (status !== 'uploading') {
                console.log('Selected File:', info.file);
                const file = info.file.originFileObj || info.file;
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const base64String = reader.result;
                    setFile(base64String);
                };
                reader.onerror = (error) => {
                    console.error('Error converting file to Base64:', error);
                };
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        showUploadList: false,
    };

    const handleSegmentClick = (segmentType) => {
        navigate(`/qr-code/${segmentType}`);
    };

    const toggleTerm = (term) => {
        setTerms((prev) => ({
            ...prev,
            [term]: !prev[term],
        }));
    };

    const toggleComunication = (type) => {
        setComunicatinTypes((prev) => ({
            ...prev,
            [type]: !prev[type],
        }));
    };

    const handleSetCustomTerm = () => {
        setAddNewTerm(true);
    };

    const handleAddCustomTerm = () => {
        setAddNewTerm(false);
        if (addNewTermInput) {
            setCustomTerms((prev) => [...prev, addNewTermInput]);
            setTerms((prev) => ({
                ...prev,
                [addNewTermInput]: true,
            }));
        }
        setAddNewTermInput('');
    };

    const allTerms = [...initialTermsList.map(term => term.text), ...customTerms];

    // const items = [
    //     {
    //         key: '1',
    //         label: <Title level={4} style={{ margin: 0 }}>How it works?</Title>,
    //         children: <div className='qr-drawer-ol'>
    //             <Paragraph className='qr-drawer-li'><span>1</span><div>Create your QR Code.</div></Paragraph>
    //             <Paragraph className='qr-drawer-li'><span>2</span><div>Print it and paste it on each order.</div></Paragraph>
    //             <Paragraph className='qr-drawer-li'><span>3</span><div>When the user scans it, they will be prompted to share their information in order to claim the offer.</div></Paragraph>
    //             <Paragraph className='qr-drawer-li'><span>4</span><div>You have their information and are ready to retarget them.</div></Paragraph>
    //         </div>,
    //     }
    // ];

    const items2 = [
        {
            key: '1',
            label: <Title level={4} style={{ margin: 0 }}>How it works?</Title>,
            children: <div className='qr-drawer-ol'>
                <Paragraph className='qr-drawer-li'><span>1</span><div>Create your QR Code.</div></Paragraph>
                <Paragraph className='qr-drawer-li'><span>2</span><div>Use it to market wherever you want by downloading or printing it.</div></Paragraph>
                <Paragraph className='qr-drawer-li'><span>3</span><div>When the user scans it, they will be prompted to share their information in order to claim the offer.</div></Paragraph>
                <Paragraph className='qr-drawer-li'><span>4</span><div>You have their information and are ready to retarget them.</div></Paragraph>
            </div>,
        }
    ];

    const resetAll = () => {
        setAddNewDrawer(false);
        setRewardType('');
        setRewardOn('entire');
        setMinPurchace('');
        setPerDisc('');
        setCashDisc('');
        setFreeItem('');
        setSpecificItem('');
        setMinPurchaceAmt('');
        setExternalLink('');
        setRewardTermType('newandexisting');
        setSecondStapPageType('landing');
        setRedeemTime('justonce');
        setTerms({});
        setCustomTerms([]);
        setExpirtime('30');
        setExpirtimeType('days');
        setExpirtimeNotification('3');
        setExpirtimeNotificationType('days');
        setIsExpirtimeNotification(true);
        setComunicatinTypes({ 'Whatsapp Utility': true, SMS: true });
        setRewardMessage('claimed');
        setFile(null);
        setThemeColor('#4c2e61');
        setTextColor('#FFFFFF');
        setPosterThemeColor('#4c2e61');
        setPosterTextColor('#FFFFFF');
        setStickerThemeColor('#4c2e61');
        setStickerTextColor('#FFFFFF');
        setsocialPostThemeColor('#4c2e61');
        setsocialPostTextColor('#FFFFFF');
        setBusinessCardThemeColor('#4c2e61');
        setBusinessCardTextColor('#FFFFFF');
        setBusinessCardTextColorFront('#FFFFFF');
        setBusinessName(storeDetail?.store_name);
        setBusinessAddress(`${storeDetail?.store_address}${storeDetail?.city && `, ${storeDetail?.city}`}${storeDetail?.state && `, ${storeDetail?.state}`}${storeDetail?.postcode && ` - ${storeDetail?.postcode}`}`);
        setCreativeType('poster');
        setBusinessContactInfoPhone(storeDetail?.store_number);
        setBusinessContactInfoEmail(storeDetail?.store_email ? storeDetail?.store_email : '');
        setBusinessCardSide('front');
        setPosterBannerImage(null);
        setSocialBannerImage(null);
        setbusinessCardBannerImage(null);
        formOne.resetFields();
        navigate(`/qr-code`);
    };

    const insertUpdateQr = async (status) => {
        setStepLoader(true);
        try {
            const values = await formOne.validateFields();
            const formData = new FormData();
            formData.append('store_id', storeDetail?.store_id);
            if (values.qrname) {
                formData.append('name', values.qrname);
            }
            if (values.qrname) {
                formData.append('objective', values.qrObjective);
            }
            formData.append('reward_type', rewardType);
            formData.append('qr_template_type', 'Create from scratch');
            formData.append('reward_on', rewardOn);
            formData.append('min_purchase', minPurchace);
            formData.append('pre_disc', preDisc);
            formData.append('cash_disc', cashDisc);
            formData.append('free_item', freeItem);
            formData.append('specific_item', specificItem);
            formData.append('min_purchase_amt', minPurchaseAmt);
            formData.append('external_link', externalLink);
            formData.append('reward_term_type', rewardTermType);
            formData.append('redeem_time', redeemTime);
            formData.append('terms', JSON.stringify(terms));
            formData.append('expire_time', expirtime);
            formData.append('expire_time_type', expirtimeType);
            formData.append('expiring_notification_time', expirtimeNotification);
            formData.append('expiring_notification_time_type', expirtimeNotificationType);
            formData.append('is_expiring_notification_enable', isExpirtimeNotification ? 'enable' : 'disable');
            formData.append('communication_type', comunicationTypes['Whatsapp Utility'] && comunicationTypes['SMS'] ? 'both' : comunicationTypes['Whatsapp Utility'] ? 'whatsapp' : comunicationTypes['SMS'] && 'sms');
            formData.append('theme_color', themeColor);
            formData.append('text_color', textColor);
            formData.append('poster_theme_color', posterThemeColor);
            formData.append('poster_text_color', posterTextColor);
            formData.append('landing_page_title', landingPageTitle);
            formData.append('phone_no', storeDetail?.store_number || userData?.user_phone);
            formData.append('website', storeDetail?.store_website);
            formData.append('map_location', storeDetail?.map_location);
            formData.append('poster_title', posterTitle);
            formData.append('sticker_theme_color', stickerThemeColor);
            formData.append('sticker_text_color', stickerTextColor);
            formData.append('sticker_title', stickerTitle);
            formData.append('social_theme_color', socialPostThemeColor);
            formData.append('social_text_color', socialPostTextColor);
            formData.append('social_title', socialTitle);
            formData.append('business_card_theme_color', businessCardThemeColor);
            formData.append('business_card_front_text_color', businessCardTextColorFront);
            formData.append('business_card_text_color', businessCardTextColor);
            formData.append('business_name', businessName);
            formData.append('business_address', businessAddress);
            formData.append('business_phone', businessContactInfoPhone);
            formData.append('business_email', businessContactInfoEmail);
            if (file !== null) {
                formData.append('logo', file);
            }
            if (socialBannerImage !== null) {
                formData.append('social_banner', socialBannerImage);
            }
            if (businessCardBannerImage !== null) {
                formData.append('business_card_banner', businessCardBannerImage);
            }
            if (posterBannerImage !== null) {
                formData.append('poster_banner', posterBannerImage);
            }
            formData.append('status', status);
            if (qrId === 0) {
                const url = `${window.API_BASE_URL}/qrcodes/`;
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                    body: formData,
                });

                const result = await response.json();
                if (response.ok) {
                    console.log('QR Inserted Successfully', result);
                    setQrId(result?.qr_code?.qr_id);
                    setEncId(result?.qr_code?.enc_qr_id);
                    return result;
                } else {
                    console.error('API Error:', result.message);
                    throw new Error(result.message);
                }
            } else {
                const url = `${window.API_BASE_URL}/qrcodes/${qrId}`;
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                    body: formData,
                });

                const result = await response.json();
                console.log(response);
                if (response.ok) {
                    console.log('QR Inserted Successfully', result);
                    return result;
                } else {
                    console.error('API Error:', result.message);
                    throw new Error(result.message);
                }
            }
        } catch (error) {
            console.error('Validation or Fetch Error:', error);
            throw error;
        } finally {
            setStepLoader(false);
        }
    };



    const handleCloseAddNewPopup = () => {
        switch (staps) {
            case 1:
                confirm({
                    className: 'confirm-popup',
                    title: 'Do you want to save this QR code as a draft?',
                    content: 'Saving the QR code as draft will allow you to edit the QR code later and activate it once you\'re ready.',
                    icon: null,
                    closable: true,
                    centered: true,
                    footer() {
                        return <Flex gap={4} justify='center' align='center'><Button className='dont-save-btn' danger onClick={dontSave}>Don't Save</Button> <Button type='primary' className='confirm-primary-btn' onClick={handleSaveDraft}>Save as draft & exit</Button></Flex>
                    },
                    onCancel() {
                        console.log('QR code not saved');
                    },
                    okButtonProps: { className: 'confirm-primary-btn' },
                    cancelButtonProps: { danger: true },
                });
                break;
            case 2:
                setRewardType('');
                setRewardOn('entire');
                setMinPurchace('');
                setPerDisc('');
                setCashDisc('');
                setFreeItem('');
                setSpecificItem('');
                setMinPurchaceAmt('');
                setExternalLink('');
                setStaps(staps - 1);
                break;
            case 3:
                setRewardTermType('newandexisting');
                setSecondStapPageType('landing');
                setRedeemTime('justonce');
                setTerms({});
                setCustomTerms([]);
                setStaps(staps - 1);
                break;
            case 4:
                setExpirtime('30');
                setExpirtimeType('days');
                setExpirtimeNotification('3');
                setExpirtimeNotificationType('days');
                setIsExpirtimeNotification(true);
                setComunicatinTypes({ 'Whatsapp Utility': true, SMS: true });
                setRewardMessage('claimed');
                setStaps(staps - 1);
                break;
            case 5:
                setFile(null);
                setThemeColor('#4c2e61');
                setTextColor('#FFFFFF');
                setPosterThemeColor('#4c2e61');
                setPosterTextColor('#FFFFFF');
                setStickerThemeColor('#4c2e61');
                setStickerTextColor('#FFFFFF');
                setsocialPostThemeColor('#4c2e61');
                setsocialPostTextColor('#FFFFFF');
                setBusinessCardThemeColor('#4c2e61');
                setBusinessCardTextColor('#FFFFFF');
                setBusinessCardTextColorFront('#FFFFFF');
                setStaps(staps - 1);
                break;
            case 6:
                setBusinessName(storeDetail?.store_name);
                setBusinessAddress(`${storeDetail?.store_address}${storeDetail?.city && `, ${storeDetail?.city}`}${storeDetail?.state && `, ${storeDetail?.state}`}${storeDetail?.postcode && ` - ${storeDetail?.postcode}`}`);
                setCreativeType('poster');
                setBusinessContactInfoPhone(storeDetail?.store_number);
                setBusinessContactInfoEmail(storeDetail?.store_email ? storeDetail?.store_email : '');
                setBusinessCardSide('front');
                setPosterBannerImage(null);
                setSocialBannerImage(null);
                setbusinessCardBannerImage(null);
                setPosterTitle(landingPageTitle);
                setSocialTitle(landingPageTitle);
                setStickerTitle(landingPageTitle);
                setStaps(staps - 1);
                break;
            case 7:
                setStaps(staps - 1);
                break;
            default:
                break;
        }
    };

    const handleNextAddNewPopup = async () => {
        try {
            switch (staps) {
                case 1:
                    await insertUpdateQr('draft');
                    setStaps(staps + 1);
                    break;
                case 2:
                    if (landingPageTitle === '') {
                        message.error('Please enter title for your landing page to proceed');
                        if (landingtitleinputRef.current) {
                            landingtitleinputRef.current.focus();
                        }
                    } else if (landingPageTitle === 'Add a title for your landing page.') {
                        message.error('Please enter title for your landing page to proceed');
                        if (landingtitleinputRef.current) {
                            landingtitleinputRef.current.focus();
                        }
                    } else {
                        await insertUpdateQr('draft');
                        setStaps(staps + 1);
                    }
                    break;
                case 3:
                    await insertUpdateQr('draft');
                    setStaps(staps + 1);
                    break;
                case 4:
                    await insertUpdateQr('draft');
                    setStaps(staps + 1);
                    break;
                case 5:
                    await insertUpdateQr('draft');
                    setStaps(staps + 1);
                    break;
                case 6:
                    await insertUpdateQr('draft');
                    setStaps(staps + 1);
                    break;
                default:
            }
        } catch (error) {
            console.log(error)
        } finally {

        }
    };

    const dontSave = async () => {
        if (qrId === 0) {
            Modal.destroyAll();
            resetAll();
        } else {
            setStepLoader(true);
            try {
                const url = `${window.API_BASE_URL}/qrcodes/${qrId}`;

                const response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    Modal.destroyAll();
                    resetAll();
                    message.success(data.message);
                } else {
                    message.error(data.message);
                }
            } catch (error) {
                console.error("Error deleting QR code:", error);
                message.error("Error deleting QR code");
            } finally {
                setStepLoader(false);
            }
        }
    };

    const handleActive = async () => {
        confirm({
            className: 'confirm-popup',
            title: 'Make sure you tested the QR Code before activating it.',
            content: <div className='expiry-reminder-info-card' style={{ marginBottom: 16 }}>
                <div className='reminder-info-content'>
                    <Flex gap={16} justify='space-between' align='center'>
                        <Title level={4} style={{ marginTop: 0 }}>Scan the QR code and test it before activating it.</Title>
                        <div style={{ backgroundColor: '#FFF', borderRadius: 16 }}>
                            <QRCode
                                size={120}
                                errorLevel="H"
                                value={`https://t.zipler.io/${encId}`}
                                icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                            />
                        </div>
                    </Flex>
                </div>
            </div>,
            icon: null,
            closable: true,
            centered: true,
            footer() {
                return <Flex gap={4} justify='center' align='center'><Button type='primary' className='confirm-primary-btn' onClick={handleActiveNow}>Activate Now <PiArrowArcRightDuotone /></Button></Flex>
            },
            onCancel() {
                console.log('QR code not saved');
            },
            okButtonProps: { className: 'confirm-primary-btn' },
            cancelButtonProps: { danger: true },
        });
    };

    const handleActiveNow = async () => {
        await insertUpdateQr('active');
        Modal.destroyAll();
        resetAll();
    };

    const handleSaveDraft = async () => {
        await insertUpdateQr('draft');
        Modal.destroyAll();
        resetAll();
    };

    const handleSaveExit = async () => {
        confirm({
            className: 'confirm-popup',
            title: 'Do you want to save this QR code as a draft?',
            content: 'Saving the QR code as draft will allow you to edit the QR code later and activate it once you\'re ready.',
            icon: null,
            closable: true,
            centered: true,
            footer() {
                return <Flex gap={4} justify='center' align='center'><Button className='dont-save-btn' danger onClick={dontSave}>Don't Save</Button> <Button type='primary' className='confirm-primary-btn' onClick={handleSaveDraft}>Save as draft & exit</Button></Flex>
            },
            onCancel() {
                console.log('QR code not saved');
            },
            okButtonProps: { className: 'confirm-primary-btn' },
            cancelButtonProps: { danger: true },
        });
    };

    const startFromScratch = () => {
        setQrScratchPopup(false);
        setAddNewDrawer(true);
        navigate(`/qr-code/addnew`);
    };

    const handleRewardTypeChange = (rewardType) => {
        setRewardType(rewardType);
        setRewardOn('entire');
        setMinPurchace('');
        setPerDisc('');
        setCashDisc('');
        setFreeItem('');
        setSpecificItem('');
        setMinPurchaceAmt('');
        setExternalLink('');
    };

    useEffect(() => {
        switch (rewardType) {
            case 'predisc':
                setLandingPageTitle(`Get ${preDisc === '' ? 0 : preDisc}% off on your next visit at ${storeDetail?.store_name}`);
                setPosterTitle(`Get ${preDisc === '' ? 0 : preDisc}% off on your next visit at ${storeDetail?.store_name}`);
                setSocialTitle(`Get ${preDisc === '' ? 0 : preDisc}% off on your next visit at ${storeDetail?.store_name}`);
                setStickerTitle(`Get ${preDisc === '' ? 0 : preDisc}% off on your next visit at ${storeDetail?.store_name}`);
                break;
            case 'cashdisc':
                setLandingPageTitle(`Get ${storeDetail?.currency_symbol} ${cashDisc === '' ? 0 : cashDisc} off on your next visit at ${storeDetail?.store_name}`);
                setPosterTitle(`Get ${storeDetail?.currency_symbol} ${cashDisc === '' ? 0 : cashDisc} off on your next visit at ${storeDetail?.store_name}`);
                setSocialTitle(`Get ${storeDetail?.currency_symbol} ${cashDisc === '' ? 0 : cashDisc} off on your next visit at ${storeDetail?.store_name}`);
                setStickerTitle(`Get ${storeDetail?.currency_symbol} ${cashDisc === '' ? 0 : cashDisc} off on your next visit at ${storeDetail?.store_name}`);
                break;
            case 'freeitem':
                setLandingPageTitle(`Get free ${freeItem} on your next visit at ${storeDetail?.store_name}`);
                setPosterTitle(`Get free ${freeItem} on your next visit at ${storeDetail?.store_name}`);
                setSocialTitle(`Get free ${freeItem} on your next visit at ${storeDetail?.store_name}`);
                setStickerTitle(`Get free ${freeItem} on your next visit at ${storeDetail?.store_name}`);
                break;
            case 'loyaltybonus':
                setLandingPageTitle(`Get ${storeDetail?.currency_symbol} ${loyalty === '' ? 0 : loyalty} cashback on your next visit at ${storeDetail?.store_name}`);
                setPosterTitle(`Get ${storeDetail?.currency_symbol} ${loyalty === '' ? 0 : loyalty} cashback on your next visit at ${storeDetail?.store_name}`);
                setSocialTitle(`Get ${storeDetail?.currency_symbol} ${loyalty === '' ? 0 : loyalty} cashback on your next visit at ${storeDetail?.store_name}`);
                setStickerTitle(`Get ${storeDetail?.currency_symbol} ${loyalty === '' ? 0 : loyalty} cashback on your next visit at ${storeDetail?.store_name}`);
                break;
            default:
                setLandingPageTitle('Add a title for your landing page.');
                setPosterTitle('Add a title for your landing page.');
                setSocialTitle('Add a title for your landing page.');
                setStickerTitle('Add a title for your landing page.');
        }
    }, [cashDisc, freeItem, loyalty, preDisc, rewardType, storeDetail]);

    return (
        <>
        {stepLoader && (
                <div className='loaderDiv'>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }
                    />
                </div>
            )}
        
        <Layout className='contentLayout'>
            <div style={{ marginBottom: 24 }}>
                <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Hello, Boost your business with smart QR codes!</b></Title>
                <Paragraph style={{ color: '#697777' }}>Attract new customers effortlessly. Create unlimited QR codes for smarter engagement.</Paragraph>
            </div>
            <Row gutter={16} style={{ marginBottom: 24 }}>
                <Col span={24}>
                    <Flex gap='middle' justify='space-between' align='center'>
                        <Flex gap='middle'>
                            {/* <Button type={!segmentType && 'primary'} onClick={() => handleSegmentClick('')} shape="round" size='large'>QR Objectives</Button> */}
                            <Button type={!segmentType ? 'primary' : segmentType === 'insights' && 'primary'} onClick={() => handleSegmentClick('insights')} shape="round" size='large'>QR Insights</Button>
                            {/* <Button type={segmentType === 'drafts' && 'primary'} onClick={() => handleSegmentClick('drafts')} shape="round" size='large'>Drafts</Button> */}
                        </Flex>
                        <Flex gap='middle'>
                            <Button type='link' style={{ fontSize: 14, fontWeight: 500, color: '#0e1b1b' }}>See how QR works <PiQuestionDuotone /></Button>
                            <Button onClick={() => setQrScratchPopup(true)}>Create from Scratch <PiArrowArcRightDuotone /></Button>
                        </Flex>
                    </Flex>
                </Col>
            </Row>
            {/* ? (
                <>
                    <Row gutter={[16, 16]}>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} onClick={() => setQrPopup(true)}>
                            <div className='qr-page-grid-boxs'>
                                <Title level={4} style={{ marginTop: 0 }}>Capture customer data from Zomato & Swiggy orders</Title>
                                <img src={qrImageOne} alt="qr iamges" style={{ width: '100%' }} />
                                <div className='qr-page-grid-boxs-detail'>
                                    <div className='qr-page-grid-boxs-info'>
                                        <Title level={5} style={{ color: '#fff' }}>Place this QR on your Zomato / Swiggy packages that encourages your customers to share their details in exchange of a reward.</Title>
                                        <Button type='primary' style={{ backgroundColor: '#fff', color: '#3A1A55' }}><PiArrowArcRightDuotone /></Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Drawer
                        placement='bottom'
                        closable={false}
                        onClose={() => setQrPopup(false)}
                        open={qrPopup}
                        height={'100vh'}
                        className='qr-popup'
                    >
                        <div className='qr-close-btn'>
                            <Button onClick={() => setQrPopup(false)} shape="round" className='popup-close-btn'><PiXCircleDuotone size={40} /></Button>
                        </div>
                        <Row style={{ height: '100vh' }}>
                            <Col span={12}>
                                <div className='qr-popup-left-div'>
                                    <div className='single-template-preview'>
                                        <Title level={2} style={{ marginTop: 0 }} className='single-template-preview-title'>See examples of how <span>other brands use this QR</span></Title>
                                        <div className='qr-popup-left-div-images'>
                                            <img src={qrImageTwo} className='preview-image' alt="qr-images" />
                                            <img src={qrImageThree} className='preview-image' alt="qr-images" />
                                            <img src={qrImageFour} className='preview-image' alt="qr-images" />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='qr-popup-right-div'>
                                    <Title level={2}>Capture customer data from Zomato & Swiggy orders</Title>
                                    <Flex gap='middle'>
                                        <Button><PiPlayDuotone /> Learn more</Button>
                                        <Button type='primary'>Create QR Like This <PiArrowArcRightDuotone /></Button>
                                    </Flex>
                                    <div style={{ marginTop: 24, width: '100%' }}>
                                        <Collapse className='qrcollapse' bordered={false} items={items} defaultActiveKey={['1']} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Drawer>
                </>
            ) :  */}
            {!segmentType || segmentType === 'insights' ? (
                <QrInsights userData={userData} sessionData={sessionData} storeDetail={storeDetail} setAddNewDrawer={setAddNewDrawer} handleExistingQr={handleExistingQr} />
            ) : segmentType === 'drafts' && (
                <Row gutter={16}>
                    <Col span={12}>
                        <Paragraph>Drafts</Paragraph>
                    </Col>
                </Row>
            )}
            <Drawer
                placement='bottom'
                closable={false}
                onClose={() => setQrScratchPopup(false)}
                open={qrScratchPopup}
                height={'100vh'}
                className='qr-popup'
            >
                <div className='qr-close-btn'>
                    <Button onClick={() => setQrScratchPopup(false)} shape="round" className='popup-close-btn'><PiXCircleDuotone size={40} /></Button>
                </div>
                <Row style={{ height: '100vh' }}>
                    <Col span={12}>
                        <div className='qr-popup-left-div'>
                            <div className='single-template-preview'>
                                <div className='title-with-bg'>
                                    <img src={mobilePng} alt='title-phone' />
                                    <Title level={3} style={{ marginTop: 0 }} className='single-template-preview-title-one'>We already have lots of pre-made templates of QR Code for you to start from.</Title>
                                </div>
                                <div className='qr-popup-left-div-images'>
                                    <img src={qrImageTwo} className='preview-image' alt="qr-images" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='qr-popup-right-div'>
                            <Title level={2}>Create new QR Code from Scratch</Title>
                            <Flex gap='middle'>
                                <Button><PiPlayDuotone /> Learn more</Button>
                                <Button onClick={startFromScratch} type='primary'>Start from Scratch <PiArrowArcRightDuotone /></Button>
                            </Flex>
                            <div style={{ marginTop: 24, width: '100%' }}>
                                <Collapse className='qrcollapse' bordered={false} items={items2} defaultActiveKey={['1']} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Drawer>
            <Drawer
                placement='bottom'
                closable={false}
                onClose={handleCloseAddNewPopup}
                open={addNewDrawer}
                height={'100vh'}
                className='qr-popup'
            >
                <Row style={{ height: '100vh', overflow: 'hidden' }}>
                    <Col span={24} style={{ height: '10vh', overflow: 'hidden' }}>
                        <Flex gap='middle' style={{ padding: '16px 24px 10px 24px' }} justify='space-between' align='center'>
                            <Flex gap='small' justify='center' align='center'>
                                <Button shape='round' className='close-btn' onClick={handleCloseAddNewPopup}><PiArrowLeftDuotone /></Button>
                                <PiStorefrontDuotone size={20} /> <Title level={5} style={{ margin: 0 }}>{storeDetail?.store_name}</Title>
                            </Flex>
                            {staps === 1 ? (
                                <Flex gap='small' justify='center' align='center'>
                                    <Button type='primary' onClick={handleNextAddNewPopup}>Next <PiArrowRightDuotone /></Button>
                                </Flex>
                            ) : staps === 7 ? (
                                <Flex gap='small' justify='center' align='center'>
                                    <Button onClick={handleSaveExit}><PiFloppyDiskDuotone /> Save & Exit</Button>
                                    <Button type='primary' onClick={handleActive}>Active Now <PiArrowRightDuotone /></Button>
                                </Flex>
                            ) : (
                                <Flex gap='small' justify='center' align='center'>
                                    <Button onClick={handleSaveExit} ><PiFloppyDiskDuotone /> Save & Exit</Button>
                                    <Button type='primary' onClick={handleNextAddNewPopup}>Next <PiArrowRightDuotone /></Button>
                                </Flex>
                            )}

                        </Flex>
                        <Progress showInfo={false} percent={staps * 12.5} strokeColor={twoColors} strokeLinecap="butt" />
                    </Col>
                    {staps === 1 ? (
                        <>
                            <Col span={14} className='add-qr-popup-left-div-column'>
                                <div className='add-qr-popup-left-div'>
                                    <div className='add-new-qr-div'>
                                        <Title level={3}>How name of the QR works?</Title>
                                        <Flex gap='middle' align='center' style={{ marginBottom: 16 }}><BsStars size={24} style={{ color: '#F9C23C' }} /> <Paragraph style={{ fontSize: 18, margin: 0 }}>Every customer who scan this QR code will be tagged automatically with the Qr name</Paragraph></Flex>
                                        <Flex gap='middle' align='center' style={{ marginBottom: 16 }}><BsSearchHeart size={24} style={{ color: '#767676' }} /> <Paragraph style={{ fontSize: 18, margin: 0 }}>Every customer who scan this QR code will be tagged automatically with the Qr name</Paragraph></Flex>
                                        <Flex gap='middle' align='center' style={{ marginBottom: 16 }}><BsStars size={24} style={{ color: '#F9C23C' }} /> <Paragraph style={{ fontSize: 18, margin: 0 }}>It can also help you identify QR easily you’re seeing QR insights</Paragraph></Flex>
                                        <div className="how-it-works-video"></div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={10}>
                                <Form layout="vertical" form={formOne}>
                                    <div className='add-qr-popup-right-div'>
                                        <Paragraph style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 8, color: '#697777', fontSize: 16 }}><PiSelectionPlusDuotone size={24} /> Create from scratch</Paragraph>
                                        <Title level={3}>What do you want to name this QR code?</Title>
                                        <div className='add-new-qr-input-div'>
                                            <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Name</Title>
                                            <Form.Item
                                                name='qrname'
                                                rules={[{ required: true, message: 'Please enter the QR code name!' }]}
                                            >
                                                <Input maxLength={120} showCount placeholder='Eg. Events, Social Media, Zomato' />
                                            </Form.Item>
                                            <div className='add-qr-input-info-div'>
                                                <Flex gap={16} justify='left' align='center' style={{ marginBlock: 16 }}><PiInfoDuotone color='#dd990a' size={24} /> <Paragraph style={{ margin: 0, fontSize: 16, fontWeight: 400 }}>Name of QR code can't be changed once QR is activated.</Paragraph></Flex>
                                                <Paragraph>The name here is for your internal reporting and tagging only. Your customers won't see this.</Paragraph>
                                            </div>
                                        </div>
                                        <div className='add-new-qr-input-div'>
                                            <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiLightningDuotone size={24} />Objective of this QR Code</Title>
                                            <Form.Item
                                                name='qrObjective'
                                                rules={[{ required: true, message: 'Please enter the objective!' }]}
                                            >
                                                <Input placeholder='Eg. IPL, Party Night' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </Col>
                        </>
                    ) : staps === 2 ? (
                        <>
                            <Col span={14} className='add-qr-popup-left-div-column'>
                                <div className='add-qr-popup-left-div'>
                                    <div style={{ padding: 16 }}>
                                        {rewardType !== 'externallink' ? (
                                            <Flex align='center' justify='center' gap={16} style={{ height: 30 }}>
                                                <Button type={secondStapPageType === 'landing' ? 'primary' : 'default'} shape='round' onClick={() => setSecondStapPageType('landing')}>Landing Page</Button>
                                                <Button type={secondStapPageType === 'success' ? 'primary' : 'default'} shape='round' onClick={() => setSecondStapPageType('success')}>Success Page</Button>
                                            </Flex>
                                        ) : (
                                            <div style={{ height: 30, width: '100%' }}></div>
                                        )}
                                        <div style={{ margin: '16px auto 24px', position: 'relative', maxWidth: 'fit-content' }}>
                                            <Paragraph style={{ marginBottom: 0, fontSize: 15, color: '#697777' }}>Customer sees this page after scanning the QR code</Paragraph>
                                            <img alt="arrowimage" src={arrowimage} style={{ right: '-8%', position: 'absolute' }} />
                                        </div>
                                        <div className={`qr-mobile-mockup ${rewardType === 'externallink' && 'external-link-mobile-mockup'}`}>
                                            <img alt="mobile-phone" className="mobile-phone" src={mobileImage} />
                                            {rewardType === 'externallink' ? (
                                                <div className='external-link'>
                                                    <img src={skeletonUiMockup} alt='skeleton mokup' />
                                                </div>
                                            ) : (
                                                <div className='mobile-screen'>
                                                    <div className='qr-landing-page'>
                                                        <div className='main-header edit-landing-page-header' style={{ color: '#FFFFFF', backgroundColor: '#4c2e61', minHeight: 300 }}>
                                                            <div className='notch' />
                                                            <div className='background-icons' />
                                                            {secondStapPageType === 'landing' ? (
                                                                <>
                                                                    <Title level={1} style={{ color: '#ffffff', marginTop: 0 }}>{landingPageTitle}</Title>
                                                                    <div className='store-details'>
                                                                        <div className='store-logo' style={{ backgroundColor: '#FFFFFF' }}>
                                                                            <img alt='store-logo' src={`${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                                        </div>
                                                                        <div className='store-profile'>
                                                                            <Paragraph style={{ fontSize: 16, fontWeight: 500, color: '#FFFFFF', margin: 0 }}>{storeDetail?.store_name}</Paragraph>
                                                                            <div className='social-links'>
                                                                                {(storeDetail?.store_number || userData?.user_phone) && (
                                                                                    <a
                                                                                        href={`tel:${storeDetail?.store_number || userData?.user_phone}`}
                                                                                        target='_blank'
                                                                                        rel="noreferrer"
                                                                                        className='link-logo'
                                                                                    >
                                                                                        <PiPhoneCallDuotone size={20} />
                                                                                    </a>
                                                                                )}
                                                                                {storeDetail?.store_website && (
                                                                                    <a
                                                                                        href={
                                                                                            storeDetail.store_website.startsWith('http://') || storeDetail.store_website.startsWith('https://')
                                                                                                ? storeDetail.store_website
                                                                                                : `http://${storeDetail.store_website}`
                                                                                        }
                                                                                        target='_blank'
                                                                                        rel="noreferrer"
                                                                                        className='link-logo'
                                                                                    >
                                                                                        <PiGoogleChromeLogoDuotone color="red" size={20} />
                                                                                    </a>
                                                                                )}
                                                                                {storeDetail?.map_location && <a href={storeDetail?.map_location} target='_blank' rel="noreferrer" className='link-logo'><PiMapPinAreaDuotone color="green" size={20} /></a>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : secondStapPageType === 'success' && (
                                                                <>
                                                                    <PiSealCheckDuotone size={50} />
                                                                    <Title level={2} style={{ color: '#ffffff', marginTop: 0 }}>Awesome! You've claimed your reward</Title>
                                                                    <Paragraph style={{ color: '#ffffff', fontSize: 16 }}>Redeem it before it expires!</Paragraph>
                                                                    <Flex gap={6} align='center'>
                                                                        <div className='deadline-logo'>
                                                                            <PiCalendarCheckDuotone size={20} />
                                                                        </div>
                                                                        <Paragraph style={{ color: '#ffffff', fontSize: 16, margin: 0 }}>Expires on <b>{formatDate(new Date())}</b></Paragraph>
                                                                    </Flex>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className='user-form'>
                                                            {secondStapPageType === 'landing' ? (
                                                                <div className='enter-details-body'>
                                                                    <Title level={3}>Enter your details & claim it now!</Title>
                                                                    <div className='inputs'>
                                                                        <Input name='name' placeholder='Your Name' />
                                                                    </div>
                                                                    <div className='inputs'>
                                                                        <Input name='phone_no' placeholder='Your Number' />
                                                                    </div>
                                                                    <div className='inputs'>
                                                                        <Button type='primary' style={{ width: '100%', height: 40, fontSize: 16, fontWeight: 500 }} onClick={() => setSecondStapPageType('success')}>Claim your reward <PiArrowArcRightDuotone size={20} /></Button>
                                                                    </div>
                                                                </div>
                                                            ) : secondStapPageType === 'success' && (
                                                                <div className='confirmation-body'>
                                                                    <div className='store-details'>
                                                                        <div className='store-logo' style={{ backgroundColor: '#FFFFFF' }}>
                                                                            <img alt='store-logo' src={`${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                                        </div>
                                                                        <div className='store-profile'>
                                                                            <Paragraph style={{ fontSize: 16, fontWeight: 500, margin: 0 }}>{storeDetail?.store_name}</Paragraph>
                                                                            <div className='social-links'>
                                                                                {(storeDetail?.store_number || userData?.user_phone) && (
                                                                                    <a
                                                                                        href={`tel:${storeDetail?.store_number || userData?.user_phone}`}
                                                                                        target='_blank'
                                                                                        rel="noreferrer"
                                                                                        className='link-logo'
                                                                                    >
                                                                                        <PiPhoneCallDuotone size={20} />
                                                                                    </a>
                                                                                )}
                                                                                {storeDetail?.store_website && (
                                                                                    <a
                                                                                        href={
                                                                                            storeDetail.store_website.startsWith('http://') || storeDetail.store_website.startsWith('https://')
                                                                                                ? storeDetail.store_website
                                                                                                : `http://${storeDetail.store_website}`
                                                                                        }
                                                                                        target='_blank'
                                                                                        rel="noreferrer"
                                                                                        className='link-logo'
                                                                                    >
                                                                                        <PiGoogleChromeLogoDuotone color="red" size={20} />
                                                                                    </a>
                                                                                )}
                                                                                {storeDetail?.map_location && <a href={storeDetail?.map_location} target='_blank' rel="noreferrer" className='link-logo'><PiMapPinAreaDuotone color="green" size={20} /></a>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='redeem-dets'>
                                                                        <div className="left">
                                                                            <Paragraph className='how-to-redeem'>How to redeem?</Paragraph>
                                                                            <Paragraph>Give your mobile number to the staff on your next purchase and ask them to redeem.</Paragraph>
                                                                        </div>
                                                                        <PiDeviceMobileSpeakerFill size={85} color='#d9d6fe' />
                                                                    </div>
                                                                    <div className='terms-and-conditions'>
                                                                        <Paragraph style={{ margin: 0, fontSize: 14, fontWeight: 500 }}>Terms and Conditions</Paragraph>
                                                                        <ul>
                                                                            <li>1 redemption per day is allowed</li>
                                                                            <li>Minimum purchase required.</li>
                                                                            <li>2 offers cannot be clubbed.</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={10} style={{ height: '90vh' }}>
                                <Form layout="vertical" form={step2Form} className='add-qr-popup-right-div-form' style={{ overflowY: 'auto', height: '100%' }}>
                                    <div className='add-qr-popup-right-div'>
                                        <Paragraph style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 8, color: '#697777', fontSize: 16 }}><PiSelectionPlusDuotone size={24} /> Create from scratch</Paragraph>
                                        <Title level={3}>Decide the reward customer gets for scanning this QR</Title>
                                        <div className='add-new-qr-input-div'>
                                            <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiGiftDuotone size={24} />Reward Type</Title>
                                            <div className={`reward-type-checkbox ${rewardType === 'predisc' && 'active-reward-type'}`}>
                                                <Flex className='radio-flex' gap={16} justify='space-between' align='center' onClick={() => handleRewardTypeChange('predisc')}>
                                                    <Flex gap={6} align='center'>
                                                        <PiPercentDuotone size={20} /> <Title level={5} style={{ margin: 0 }}>Percentage Discount</Title>
                                                    </Flex>
                                                    <Radio checked={rewardType === 'predisc' && true} />
                                                </Flex>
                                                {rewardType === 'predisc' && (
                                                    <div className='reward-type-settings'>
                                                        <Input name="offpercent" type='number' value={preDisc} onChange={(e) => setPerDisc(e.target.value)} placeholder='How many % off' className='how-many-input' />
                                                        <Flex gap={16} align='center'>
                                                            <Button type={rewardOn === 'entire' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('entire')}><PiShoppingCartDuotone size={20} /> Entire purchase</Button>
                                                            <Button type={rewardOn === 'specific' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('specific')}><PiPackageDuotone size={20} /> Specific purchase</Button>
                                                        </Flex>
                                                        {rewardOn === 'specific' && (
                                                            <Input name="item" placeholder='Add Item' value={specificItem} onChange={(e) => setSpecificItem(e.target.value)} className='add-item-input' />
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <div className={`reward-type-checkbox ${rewardType === 'cashdisc' && 'active-reward-type'}`}>
                                                <Flex className='radio-flex' gap={16} justify='space-between' align='center' onClick={() => handleRewardTypeChange('cashdisc')}>
                                                    <Flex gap={6} align='center'>
                                                        <PiCurrencyInrDuotone size={20} /> <Title level={5} style={{ margin: 0 }}>Cash Discount</Title>
                                                    </Flex>
                                                    <Radio checked={rewardType === 'cashdisc' && true} />
                                                </Flex>
                                                {rewardType === 'cashdisc' && (
                                                    <div className='reward-type-settings'>
                                                        <Input name="offcash" type='number' value={cashDisc} onChange={(e) => setCashDisc(e.target.value)} placeholder={`How many ${storeDetail?.currency_symbol}  off`} className='how-many-input' />
                                                        <Flex gap={16} align='center'>
                                                            <Button type={rewardOn === 'entire' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('entire')}><PiShoppingCartDuotone size={20} /> Entire purchase</Button>
                                                            <Button type={rewardOn === 'specific' ? 'primary' : 'default'} shape='round' onClick={() => setRewardOn('specific')}><PiPackageDuotone size={20} /> Specific purchase</Button>
                                                        </Flex>
                                                        {rewardOn === 'specific' && (
                                                            <Input name="item" value={specificItem} onChange={(e) => setSpecificItem(e.target.value)} placeholder='Add Item' className='add-item-input' />
                                                        )}
                                                        <div className='max-discount-card'>
                                                            <Flex className='top-row' justify='space-between' gap={16} align='center' onClick={() => setMinPurchace(minPurchace === 'checked' ? '' : 'checked')}>
                                                                <Tooltip title="Set a minimum amount that your customers must spend to be able to redeem their reward.">
                                                                    <label>Minimum purchase required for redemption</label>
                                                                </Tooltip>
                                                                <Checkbox checked={minPurchace === 'checked' && true} />
                                                            </Flex>
                                                            {minPurchace === 'checked' && (
                                                                <Input type='number' value={minPurchaseAmt} onChange={(e) => setMinPurchaceAmt(e.target.value)} name="min-amt" placeholder={`${storeDetail?.currency_symbol}  0`} className='add-item-input' />
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className={`reward-type-checkbox ${rewardType === 'freeitem' && 'active-reward-type'}`}>
                                                <Flex className='radio-flex' gap={16} justify='space-between' align='center' onClick={() => handleRewardTypeChange('freeitem')}>
                                                    <Flex gap={6} align='center'>
                                                        <PiGiftDuotone size={20} /> <Title level={5} style={{ margin: 0 }}>Free Item</Title>
                                                    </Flex>
                                                    <Radio checked={rewardType === 'freeitem' && true} />
                                                </Flex>
                                                {rewardType === 'freeitem' && (
                                                    <div className='reward-type-settings'>
                                                        <Input name="freeitem" value={freeItem} onChange={(e) => setFreeItem(e.target.value)} placeholder='Add Item' className='add-item-input' />
                                                        <div className='max-discount-card'>
                                                            <Flex className='top-row' justify='space-between' gap={16} align='center' onClick={() => setMinPurchace(minPurchace === 'checked' ? '' : 'checked')}>
                                                                <Tooltip title="Set a minimum amount that your customers must spend to be able to redeem their reward.">
                                                                    <label>Minimum purchase required for redemption</label>
                                                                </Tooltip>
                                                                <Checkbox checked={minPurchace === 'checked' && true} />
                                                            </Flex>
                                                            {minPurchace === 'checked' && (
                                                                <Input type='number' value={minPurchaseAmt} onChange={(e) => setMinPurchaceAmt(e.target.value)} name="min-amt" placeholder={`${storeDetail?.currency_symbol}  0`} className='add-item-input' />
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className={`reward-type-checkbox ${rewardType === 'loyaltybonus' && 'active-reward-type'}`}>
                                                <Flex className='radio-flex' gap={16} justify='space-between' align='center' onClick={() => handleRewardTypeChange('loyaltybonus')}>
                                                    <Flex gap={6} align='center'>
                                                        <PiCoinsDuotone size={20} /> <Title level={5} style={{ margin: 0 }}>Loyalty Bonus</Title>
                                                    </Flex>
                                                    <Radio checked={rewardType === 'loyaltybonus' && true} />
                                                </Flex>
                                                {rewardType === 'loyaltybonus' && (
                                                    <div className='reward-type-settings'>
                                                        <Input name="loyalty bonus" type='number' value={loyalty} onChange={(e) => setLoyalty(e.target.value)} placeholder='Add Loyalty Bonus' className='how-many-input' />
                                                    </div>
                                                )}
                                            </div>

                                            <div className={`reward-type-checkbox ${rewardType === 'externallink' && 'active-reward-type'}`}>
                                                <Flex className='radio-flex' gap={16} justify='space-between' align='center' onClick={() => handleRewardTypeChange('externallink')}>
                                                    <Flex gap={6} align='center'>
                                                        <PiLinkDuotone size={20} /> <Title level={5} style={{ margin: 0 }}>External Link <br /><span style={{ fontSize: 12, color: '#616d6d' }}>Link to your video, website link, social media...</span></Title>
                                                    </Flex>
                                                    <Radio checked={rewardType === 'externallink' && true} />
                                                </Flex>
                                                {rewardType === 'externallink' && (
                                                    <div className='reward-type-settings'>
                                                        <Input name="external-link" value={externalLink} onChange={(e) => setExternalLink(e.target.value)} placeholder='www.example.com' className='add-item-input' />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {rewardType !== 'externallink' && (
                                            <div className='add-new-qr-input-div'>
                                                <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}>
                                                    <PiTextTDuotone size={24} />
                                                    Landing page title
                                                </Title>
                                                <Form.Item
                                                    rules={[{ required: true, message: 'Please enter landing page title!' }]}
                                                    validateStatus={landingPageTitle === '' ? 'error' : landingPageTitle === 'Add a title for your landing page.' && 'error'}
                                                    help='Please enter landing page title!'
                                                >
                                                    <Input
                                                        ref={landingtitleinputRef}
                                                        autoComplete="off"
                                                        value={landingPageTitle === 'Add a title for your landing page.' ? '' : landingPageTitle}
                                                        onChange={(e) => { setLandingPageTitle(e.target.value); setPosterTitle(e.target.value); setSocialTitle(e.target.value); setStickerTitle(e.target.value); }}
                                                        maxLength={120}
                                                        showCount
                                                        placeholder='Add a title to be shown on customer page'
                                                    />
                                                </Form.Item>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            </Col>
                        </>
                    ) : staps === 3 ? (
                        <>
                            <Col span={14} className='add-qr-popup-left-div-column'>
                                <div className='qr-code-terms-left'>
                                    <div style={{ padding: 16 }}>
                                        <div className={`qr-mobile-mockup`}>
                                            <img alt="mobile-phone" className="mobile-phone" src={mobileImage} />
                                            <div className='mobile-screen'>
                                                <div className='qr-landing-page'>
                                                    <div className='main-header edit-landing-page-header' style={{ color: '#FFFFFF', backgroundColor: '#4c2e61', minHeight: 300 }}>
                                                        <div className='notch' />
                                                        <div className='background-icons' />
                                                        <PiSealCheckDuotone size={50} />
                                                        <Title level={2} style={{ color: '#ffffff', marginTop: 0 }}>Awesome! You've claimed your reward</Title>
                                                        <Paragraph style={{ color: '#ffffff', fontSize: 16 }}>Redeem it before it expires!</Paragraph>
                                                        <Flex gap={6} align='center'>
                                                            <div className='deadline-logo'>
                                                                <PiCalendarCheckDuotone size={20} />
                                                            </div>
                                                            <Paragraph style={{ color: '#ffffff', fontSize: 16, margin: 0 }}>Expires on <b>{formatDate(new Date())}</b></Paragraph>
                                                        </Flex>
                                                    </div>
                                                    <div className='user-form'>

                                                        <div className='confirmation-body'>
                                                            <div className='store-details'>
                                                                <div className='store-logo' style={{ backgroundColor: '#FFFFFF' }}>
                                                                    <img alt='store-logo' src={`${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                                </div>
                                                                <div className='store-profile'>
                                                                    <Paragraph style={{ fontSize: 16, fontWeight: 500, margin: 0 }}>{storeDetail?.store_name}</Paragraph>
                                                                    <div className='social-links'>
                                                                        {(storeDetail?.store_number || userData?.user_phone) && (
                                                                            <a
                                                                                href={`tel:${storeDetail?.store_number || userData?.user_phone}`}
                                                                                target='_blank'
                                                                                rel="noreferrer"
                                                                                className='link-logo'
                                                                            >
                                                                                <PiPhoneCallDuotone size={20} />
                                                                            </a>
                                                                        )}
                                                                        {storeDetail?.store_website && (
                                                                            <a
                                                                                href={
                                                                                    storeDetail.store_website.startsWith('http://') || storeDetail.store_website.startsWith('https://')
                                                                                        ? storeDetail.store_website
                                                                                        : `http://${storeDetail.store_website}`
                                                                                }
                                                                                target='_blank'
                                                                                rel="noreferrer"
                                                                                className='link-logo'
                                                                            >
                                                                                <PiGoogleChromeLogoDuotone color="red" size={20} />
                                                                            </a>
                                                                        )}
                                                                        {storeDetail?.map_location && <a href={storeDetail?.map_location} target='_blank' rel="noreferrer" className='link-logo'><PiMapPinAreaDuotone color="green" size={20} /></a>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='redeem-dets'>
                                                                <div className="left">
                                                                    <Paragraph className='how-to-redeem'>How to redeem?</Paragraph>
                                                                    <Paragraph>Give your mobile number to the staff on your next purchase and ask them to redeem.</Paragraph>
                                                                </div>
                                                                <PiDeviceMobileSpeakerFill size={85} color='#d9d6fe' />
                                                            </div>
                                                            <div className='terms-and-conditions'>
                                                                <Paragraph style={{ margin: 0, fontSize: 14, fontWeight: 500 }}>Terms and Conditions</Paragraph>
                                                                {Object.values(terms).some((isChecked) => isChecked) ? (
                                                                    <ul>
                                                                        {Object.entries(terms).map(([term, isChecked]) => (
                                                                            isChecked && <li>{term}</li>
                                                                        ))}
                                                                    </ul>
                                                                ) : (
                                                                    <ul>
                                                                        <li>1 redemption per day is allowed</li>
                                                                        <li>Minimum purchase required.</li>
                                                                        <li>2 offers cannot be clubbed.</li>
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={10} style={{ height: '90vh' }}>
                                <Form layout="vertical" form={step2Form} className='add-qr-popup-right-div-form' style={{ overflowY: 'auto', height: '100%' }}>
                                    <div className='add-qr-popup-right-div qr-code-terms-right'>
                                        <Paragraph style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 8, color: '#697777', fontSize: 16 }}><PiSelectionPlusDuotone size={24} /> Create from scratch</Paragraph>
                                        <Title level={3}>Let’s review the terms & conditions</Title>
                                        <div className='info-div'><PiLightbulbDuotone size={24} color='#6938ef' /><Paragraph style={{ fontSize: 14, margin: 0 }}>QR Code doesn't expire unless you disable it later.</Paragraph></div>
                                        <div className='add-new-qr-input-div'>
                                            <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiUserDuotone size={24} />Who can redeem this reward?</Title>
                                            <div className={`reward-type-checkbox ${rewardTermType === 'newonly' && 'active-reward-type'}`}>
                                                <Flex className='radio-flex' gap={16} justify='space-between' align='center' onClick={() => setRewardTermType('newonly')}>
                                                    <Flex gap={6} align='center'><Title level={5} style={{ margin: 0 }}>New Customers Only<br /><span style={{ fontSize: 12, color: '#616d6d' }}>This reward will be claimed and redeemed by new customers</span></Title></Flex>
                                                    <Radio checked={rewardTermType === 'newonly' && true} />
                                                </Flex>
                                            </div>
                                            <div className={`reward-type-checkbox ${rewardTermType === 'newandexisting' && 'active-reward-type'}`}>
                                                <Flex className='radio-flex' gap={16} justify='space-between' align='center' onClick={() => setRewardTermType('newandexisting')}>
                                                    <Flex gap={6} align='center'> <Title level={5} style={{ margin: 0 }}>New + Existing Customers<br /><span style={{ fontSize: 12, color: '#616d6d' }}>Reward will be claimed and redeemed by both old and new customers</span></Title>
                                                    </Flex>
                                                    <Radio checked={rewardTermType === 'newandexisting' && true} />
                                                </Flex>
                                            </div>
                                        </div>
                                        <div className='add-new-qr-input-div'>
                                            <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiReceiptDuotone size={24} />How many times can a customer redeem?</Title>
                                            <Flex gap={16} align='center'>
                                                <Button type={redeemTime === 'justonce' ? 'primary' : 'default'} shape='round' onClick={() => { setRedeemTime('justonce'); redeemTime !== 'justonce' && toggleTerm('Redeemable once every 24 hours'); }}>Just Once</Button>
                                                <Button type={redeemTime === 'multipletime' ? 'primary' : 'default'} shape='round' onClick={() => { setRedeemTime('multipletime'); redeemTime !== 'multipletime' && toggleTerm('Redeemable once every 24 hours'); }}>Multiple times</Button>
                                            </Flex>
                                            {redeemTime === 'multipletime' && (
                                                <div className='info-div' style={{ marginTop: 16 }}><PiInfoDuotone size={24} color='#6938ef' /> <Paragraph style={{ fontSize: 14, margin: 0 }}>Redeemable once every 24 hours</Paragraph></div>
                                            )}
                                        </div>
                                        <div className='add-new-qr-input-div'>
                                            <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiGearFineDuotone size={24} />Terms and conditions</Title>
                                            {allTerms.map((term, index) => (
                                                term === 'Redeemable once every 24 hours' ? (
                                                    redeemTime === 'multipletime' && (
                                                        <Flex
                                                            className='terms-check-list'
                                                            justify='space-between'
                                                            gap={16}
                                                            align='center'
                                                        >
                                                            <Title level={5} style={{ margin: 0 }}>{term}</Title>
                                                            <Checkbox readOnly checked={terms[term] || false} />
                                                        </Flex>
                                                    )
                                                ) : (
                                                    <Flex
                                                        key={index}
                                                        className='terms-check-list'
                                                        justify='space-between'
                                                        gap={16}
                                                        align='center'
                                                        onClick={() => toggleTerm(term)}
                                                    >
                                                        <Title level={5} style={{ margin: 0 }}>{term}</Title>
                                                        <Checkbox
                                                            checked={terms[term] || false}
                                                        />
                                                    </Flex>
                                                )
                                            ))}

                                            {addNewTerm ? (
                                                <>
                                                    <Input type='text' value={addNewTermInput} onChange={(e) => setAddNewTermInput(e.target.value)} placeholder='Add a new term condition' style={{ marginBottom: 16 }} />
                                                    <Button style={{ width: '100%' }} onClick={handleAddCustomTerm}><PiPlusDuotone size={20} /> Add above term</Button>
                                                </>
                                            ) : (
                                                <Button style={{ width: '100%' }} onClick={handleSetCustomTerm}><PiPlusDuotone size={20} /> Add your own</Button>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </Col>
                        </>
                    ) : staps === 4 ? (
                        <>
                            <Col span={14} className='add-qr-popup-left-div-column'>
                                <div className='add-qr-popup-left-div'>
                                    <div style={{ padding: 16 }}>
                                        <Flex align='center' justify='center' gap={16} style={{ height: 30 }}>
                                            <Button style={{ height: 40 }} type={rewardMessage === 'claimed' ? 'primary' : 'default'} shape='round' onClick={() => setRewardMessage('claimed')}><span className={`btntag one ${rewardMessage === 'claimed' && 'active'}`}>1st</span>Reward claimed</Button>
                                            <Button style={{ height: 40 }} type={rewardMessage === 'reminder' ? 'primary' : 'default'} shape='round' onClick={() => setRewardMessage('reminder')}><span className={`btntag two ${rewardMessage === 'reminder' && 'active'}`}>2nd</span>QR reminder</Button>
                                            <Button style={{ height: 40 }} type={rewardMessage === 'expiring' ? 'primary' : 'default'} shape='round' onClick={() => setRewardMessage('expiring')}><span className={`btntag three ${rewardMessage === 'expiring' && 'active'}`}>3rd</span>QR expiring today</Button>
                                        </Flex>
                                        <div style={{ margin: '16px auto 24px', position: 'relative', maxWidth: 'fit-content' }}>
                                            <Paragraph style={{ marginBottom: 0, fontSize: 15, color: '#697777', textAlign: 'center' }}>This will be sent to customer immediately after they give their<br />name & number and are eligible to claim the reward.</Paragraph>
                                        </div>
                                        <div className={`qr-mobile-mockup`}>
                                            <img alt="mobile-phone" className="mobile-phone" src={whatsappImage} />
                                            {rewardMessage === 'claimed' ? (
                                                <Paragraph className='qr-comm-text'>
                                                    <b>{storeDetail?.store_name}</b><br /><br />Dear customer,<br /><br />Congrats! 🎉 You've successfully claimed a reward expiring on 21 April<br /><br />Visit us soon to redeem 🎁<br /><br />See you soon!✨
                                                </Paragraph>
                                            ) : rewardMessage === 'reminder' ? (
                                                <Paragraph className='qr-comm-text'>
                                                    <b>{storeDetail?.store_name}</b><br /><br />Dear customer,<br /><br />Alert! You've got <b>{rewardType === 'predisc' ? `${preDisc}% discount on entire purchase` : rewardType === 'cashdisc' ? `${storeDetail?.currency_symbol} ${cashDisc} discount on entire purchase` : rewardType === 'freeitem' && 'Free Items'}</b> expiring on 21 April just waiting to be redeemed. 🎁💫<br /><br />Click below to check the rewards available for redemption on your next order.🎉<br /><br />See you soon!✨
                                                </Paragraph>
                                            ) : rewardMessage === 'expiring' && (
                                                <Paragraph className='qr-comm-text'>
                                                    <b>{storeDetail?.store_name}</b><br /><br />Dear customer,<br /><br />Reminder! Your <b>{rewardType === 'predisc' ? `${preDisc}% discount on entire purchase` : rewardType === 'cashdisc' ? `${storeDetail?.currency_symbol} ${cashDisc} discount on entire purchase` : rewardType === 'freeitem' && 'Free Items'}</b> are expiring <b>today</b><br /><br />See you soon!✨
                                                </Paragraph>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={10} style={{ height: '90vh' }}>
                                <Form layout="vertical" form={step2Form} className='add-qr-popup-right-div-form' style={{ overflowY: 'auto', height: '100%' }}>
                                    <div className='add-qr-popup-right-div'>
                                        <Paragraph style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 8, color: '#697777', fontSize: 16 }}><PiSelectionPlusDuotone size={24} /> Create from scratch</Paragraph>
                                        <Title level={3}>Now, choose when the reward expires and the channel for communication</Title>
                                        <div className='add-new-qr-input-div'>
                                            <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiClockCountdownDuotone size={24} />Reward Expires In</Title>
                                            <Flex className='radio-flex' gap={16} justify='space-between' align='center'>
                                                <Space.Compact>
                                                    <Input value={expirtime} onChange={(e) => setExpirtime(e.target.value)} />
                                                    <Select value={expirtimeType} onChange={(e) => setExpirtimeType(e.target.value)} options={options} />
                                                </Space.Compact>
                                                <Paragraph style={{ margin: 0, fontSize: 16, color: '#697777' }}>After the customer claims the reward</Paragraph>
                                            </Flex>
                                        </div>
                                        <div className='add-new-qr-input-div'>
                                            <Flex className='radio-flex' gap={16} justify='space-between' align='center'>
                                                <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiBellRingingDuotone size={24} />Send reward expiry communication</Title>
                                                <Switch checked={isExpirtimeNotification} onChange={() => setIsExpirtimeNotification(isExpirtimeNotification ? false : true)} />
                                            </Flex>
                                            {isExpirtimeNotification && (
                                                <>
                                                    <Flex className='radio-flex' gap={16} justify='space-between' align='center'>
                                                        <Space.Compact>
                                                            <Input value={expirtimeNotification} onChange={(e) => setExpirtimeNotification(e.target.value)} />
                                                            <Select value={expirtimeNotificationType} onChange={(e) => setExpirtimeNotificationType(e.target.value)} options={options} />
                                                        </Space.Compact>
                                                        <Paragraph style={{ margin: 0, fontSize: 16, color: '#697777' }}>before the reward expires</Paragraph>
                                                    </Flex>
                                                    <div className='expiry-reminder-info-card'>
                                                        <PiLightbulbDuotone size={30} color='#6938ef' />
                                                        <div className='reminder-info-content'>
                                                            <Paragraph style={{ fontSize: 14 }}>Customer gets a reminder message <b>on the last day of reward expiring.</b></Paragraph>
                                                            <Paragraph style={{ fontSize: 14 }}>You can <span style={{ color: '#6938ef', fontWeight: 500 }}>set 1 more reminder</span> a <b>few days before the expiry</b> to increase the chances of redemption.</Paragraph>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className='add-new-qr-input-div'>
                                            <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiDeviceMobileCameraDuotone size={24} />Select Communication Channel</Title>

                                            <Flex
                                                className='terms-check-list'
                                                justify='space-between'
                                                gap={16}
                                                align='center'
                                                onClick={() => toggleComunication('Whatsapp Utility')}
                                            >
                                                <Title level={5} style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiWhatsappLogoDuotone size={24} /> Whatsapp Utility</Title>
                                                <Checkbox
                                                    checked={comunicationTypes['Whatsapp Utility']}
                                                />
                                            </Flex>
                                            <Flex
                                                className='terms-check-list'
                                                justify='space-between'
                                                gap={16}
                                                align='center'
                                                onClick={() => toggleComunication('SMS')}
                                            >
                                                <Title level={5} style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiChatCenteredDotsDuotone size={24} /> SMS</Title>
                                                <Checkbox
                                                    checked={comunicationTypes['SMS']}
                                                />
                                            </Flex>
                                        </div>
                                    </div>
                                </Form>
                            </Col>
                        </>
                    ) : staps === 5 ? (
                        <>
                            <Col span={14} className='add-qr-popup-left-div-column'>
                                <div className='add-qr-popup-left-div'>
                                    <div style={{ padding: 16 }}>

                                        <div style={{ height: 30, width: '100%' }}></div>
                                        <div className={`qr-mobile-mockup`}>
                                            <img alt="mobile-phone" className="mobile-phone" src={mobileImage} />
                                            <div className='mobile-screen'>
                                                <div className='qr-landing-page'>
                                                    <div className='main-header edit-landing-page-header' style={{ color: textColor, backgroundColor: themeColor, minHeight: 300 }}>
                                                        <div className='notch' />
                                                        <div className='background-icons' />

                                                        <Title level={1} style={{ color: textColor, marginTop: 0 }}>{landingPageTitle}</Title>
                                                        <div className='store-details'>
                                                            <div className='store-logo' style={{ backgroundColor: textColor }}>
                                                                <img alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                            </div>
                                                            <div className='store-profile'>
                                                                <Paragraph style={{ fontSize: 16, fontWeight: 500, color: textColor, margin: 0 }}>{storeDetail?.store_name}</Paragraph>
                                                                <div className='social-links'>
                                                                    {(storeDetail?.store_number || userData?.user_phone) && (
                                                                        <a
                                                                            href={`tel:${storeDetail?.store_number || userData?.user_phone}`}
                                                                            target='_blank'
                                                                            rel="noreferrer"
                                                                            className='link-logo'
                                                                        >
                                                                            <PiPhoneCallDuotone size={20} />
                                                                        </a>
                                                                    )}
                                                                    {storeDetail?.store_website && (
                                                                        <a
                                                                            href={
                                                                                storeDetail.store_website.startsWith('http://') || storeDetail.store_website.startsWith('https://')
                                                                                    ? storeDetail.store_website
                                                                                    : `http://${storeDetail.store_website}`
                                                                            }
                                                                            target='_blank'
                                                                            rel="noreferrer"
                                                                            className='link-logo'
                                                                        >
                                                                            <PiGoogleChromeLogoDuotone color="red" size={20} />
                                                                        </a>
                                                                    )}
                                                                    {storeDetail?.map_location && <a href={storeDetail?.map_location} target='_blank' rel="noreferrer" className='link-logo'><PiMapPinAreaDuotone color="green" size={20} /></a>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='user-form'>
                                                        <div className='enter-details-body'>
                                                            <Title level={3}>Enter your details & claim it now!</Title>
                                                            <div className='inputs'>
                                                                <Input name='name' placeholder='Your Name' />
                                                            </div>
                                                            <div className='inputs'>
                                                                <Input name='phone_no' placeholder='Your Number' />
                                                            </div>
                                                            <div className='inputs'>
                                                                <Button type='primary' style={{ backgroundColor: themeColor, color: textColor, width: '100%', height: 40, fontSize: 16, fontWeight: 500 }}>Claim your reward <PiArrowArcRightDuotone size={20} /></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={10} style={{ height: '90vh' }}>
                                <Form layout="vertical" form={step2Form} className='add-qr-popup-right-div-form' style={{ overflowY: 'auto', height: '100%' }}>
                                    <div className='add-qr-popup-right-div'>
                                        <Paragraph style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 8, color: '#697777', fontSize: 16 }}><PiSelectionPlusDuotone size={24} /> Create from scratch</Paragraph>
                                        <Title level={3}>Customize your QR code landing page</Title>
                                        <div className='add-new-qr-input-div'>
                                            <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiImageDuotone size={24} />Logo</Title>
                                            <Paragraph style={{ color: '#697777', fontSize: 14 }}>Recommended size 512 x 512 px in JPG, GIF or PNG format up-to max size of 10MB</Paragraph>
                                            <ImgCrop shape="round" aspect={1} quality={1}>
                                                <Upload {...props} style={{ width: '100%' }} className='qr-upload-logo' accept="image/jpeg, image/png">
                                                    {file ? (
                                                        <Button style={{ width: '100%' }}>Upload another file</Button>
                                                    ) : (
                                                        <Button style={{ width: '100%' }} icon={<UploadOutlined />}>Upload your logo</Button>
                                                    )}

                                                </Upload>
                                            </ImgCrop>
                                        </div>
                                        <Flex gap={16}>
                                            <div className='add-new-qr-input-div'>
                                                <Flex gap={16} align='center'>
                                                    <ColorPicker value={themeColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setThemeColor(hexColor); setPosterThemeColor(hexColor); setStickerThemeColor(hexColor); setsocialPostThemeColor(hexColor); setBusinessCardThemeColor(hexColor); }} />
                                                    <Title level={4} style={{ margin: 0 }}>Theme Color</Title>
                                                </Flex>
                                            </div>
                                            <div className='add-new-qr-input-div'>
                                                <Flex gap={16} align='center'>
                                                    <ColorPicker value={textColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setTextColor(hexColor); setPosterTextColor(hexColor); setStickerTextColor(hexColor); setsocialPostTextColor(hexColor); setBusinessCardTextColorFront(hexColor); setBusinessCardTextColor(hexColor) }} />
                                                    <Title level={4} style={{ margin: 0 }}>Text Color</Title>
                                                </Flex>
                                            </div>
                                        </Flex>
                                        <div className='add-new-qr-input-div'>
                                            <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiQuestionDuotone size={24} />How can customers know more about you?</Title>

                                        </div>
                                    </div>
                                </Form>
                            </Col>
                        </>
                    ) : staps === 6 ? (
                        <>
                            <Col span={14} className='add-qr-popup-left-div-column'>
                                <div className='add-qr-popup-left-div'>
                                    <div style={{ padding: 16 }}>
                                        <Flex align='center' justify='center' gap={16} style={{ height: 30 }}>
                                            <Button style={{ height: 40 }} type={creativeType === 'poster' ? 'primary' : 'default'} shape='round' onClick={() => setCreativeType('poster')}>Poster</Button>
                                            <Button style={{ height: 40 }} type={creativeType === 'sticker' ? 'primary' : 'default'} shape='round' onClick={() => setCreativeType('sticker')}>Sticker</Button>
                                            <Button style={{ height: 40 }} type={creativeType === 'social' ? 'primary' : 'default'} shape='round' onClick={() => setCreativeType('social')}>Social Post</Button>
                                            <Button style={{ height: 40 }} type={creativeType === 'businesscard' ? 'primary' : 'default'} shape='round' onClick={() => setCreativeType('businesscard')}>Business Card</Button>
                                            <Button style={{ height: 40 }} type={creativeType === 'qrcode' ? 'primary' : 'default'} shape='round' onClick={() => setCreativeType('qrcode')}>QR Code Only</Button>
                                        </Flex>
                                    </div>
                                    {creativeType === 'poster' ? (
                                        <div className='qr-poster' style={{ backgroundColor: posterThemeColor }}>
                                            <div className='qr-bg-img-div' style={{ backgroundImage: `url(${posterBannerImage ? posterBannerImage : bgImg})` }}>
                                                <div className='backdrop'>
                                                    <div className='card-logo-div'>
                                                        <img className='card-logo-image' alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                    </div>
                                                    <Title level={2} style={{ color: posterTextColor }}>{posterTitle}</Title>
                                                    <div className="instruction" style={{ color: posterTextColor }}><span>Scan</span> <PiArrowRightDuotone size={16} /> <span>Claim</span><PiArrowRightDuotone size={16} /><span>Redeem</span></div>
                                                    <Flex justify='center' style={{ marginTop: 28, marginBottom: 38 }}>
                                                        <div className='qrcode-image-div'>
                                                            <QRCode
                                                                size={220}
                                                                errorLevel="H"
                                                                value={`https://t.zipler.io/${encId}`}
                                                                icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                            />
                                                        </div>
                                                    </Flex>
                                                    <p className='powered-by' style={{ color: posterTextColor }}>Powered by <a href="https://zipler.io" alt="zipler" style={{ color: posterTextColor }}>zipler.io</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : creativeType === 'sticker' ? (
                                        <div className='qr-code-sticker' style={{ backgroundColor: stickerThemeColor }}>
                                            <div className='sticker-head'>
                                                <div className='card-logo-div'>
                                                    <img className='card-logo-image' alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                </div>
                                                <Title level={3} style={{ color: stickerTextColor, marginTop: 16 }}>{stickerTitle}</Title>
                                            </div>
                                            <div>
                                                <Flex justify='center' style={{ marginBottom: 38 }}>
                                                    <div className='qrcode-image-div'>
                                                        <QRCode
                                                            size={220}
                                                            errorLevel="H"
                                                            value={`https://t.zipler.io/${encId}`}
                                                            icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                        />
                                                    </div>
                                                </Flex>
                                                <p className='powered-by' style={{ color: stickerTextColor }}>Powered by <a href="https://zipler.io" alt="zipler" style={{ color: stickerTextColor }}>zipler.io</a></p>

                                            </div>
                                        </div>
                                    ) : creativeType === 'social' ? (
                                        <div className='qr-social-media-container'>
                                            <div className='qr-social-media' style={{ backgroundColor: socialPostThemeColor }}>
                                                <div className='qr-bg-img-div' style={{ backgroundImage: `url(${socialBannerImage ? socialBannerImage : bgImg})` }}>
                                                    <div className='backdrop'>
                                                        <div className='left-side'>
                                                            <Flex justify='center' style={{ marginTop: 28, marginBlock: 38 }}>
                                                                <div className='qrcode-image-div'>
                                                                    <QRCode
                                                                        size={220}
                                                                        errorLevel="H"
                                                                        value={`https://t.zipler.io/${encId}`}
                                                                        icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                                    />
                                                                </div>
                                                            </Flex>
                                                        </div>
                                                        <div className='right-side'>
                                                            <div className='card-logo-div'>
                                                                <img className='card-logo-image' alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                            </div>
                                                            <Title level={2} style={{ color: socialPostTextColor }}>{socialTitle}</Title>
                                                            <div className="instruction" style={{ color: socialPostTextColor }}><span>Scan</span> <PiArrowRightDuotone size={16} /> <span>Claim</span><PiArrowRightDuotone size={16} /><span>Redeem</span></div>
                                                            <p className='powered-by' style={{ color: socialPostTextColor }}>Powered by <a href="https://zipler.io" alt="zipler" style={{ color: socialPostTextColor }}>zipler.io</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="social-claim-footer"><p>Click here to claim</p> <PiCaretRight size={20} color='#FFFFFF' /> </div>
                                        </div>
                                    ) : creativeType === 'businesscard' ? (
                                        <div className='qr-business-card-container'>
                                            <div className='qr-business-card' style={{ backgroundColor: businessCardThemeColor }}>
                                                {businessCardSide === 'front' ? (
                                                    <div className='business-card-front' style={{ backgroundImage: `url(${businessCardBannerImage ? businessCardBannerImage : bgImg})` }}>
                                                        <div>
                                                            <div className='card-logo-div'>
                                                                <img className='card-logo-image' alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                            </div>
                                                            <Title level={1} style={{ margin: 0, color: businessCardTextColorFront }}>{businessName}</Title>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='business-card-back'>
                                                        <div className='card-text-side'>
                                                            <div>
                                                                <Title level={1} style={{ margin: 0, color: businessCardTextColor }}>{businessName}</Title>
                                                                <Paragraph style={{ margin: 0, fontSize: 16, color: businessCardTextColor }}>{businessAddress}</Paragraph>
                                                            </div>
                                                            <div className="sources">
                                                                {businessContactInfoPhone !== '' && <div className="source" style={{ color: businessCardTextColor }}><PiDeviceMobileCameraDuotone size={20} /> <Paragraph style={{ color: businessCardTextColor, margin: 0, fontSize: 16 }}>{businessContactInfoPhone}</Paragraph></div>}
                                                                {businessContactInfoEmail !== '' && <div className="source" style={{ color: businessCardTextColor }}><PiEnvelopeDuotone size={20} /> <Paragraph style={{ color: businessCardTextColor, margin: 0, fontSize: 16 }}>{businessContactInfoEmail}</Paragraph></div>}
                                                            </div>
                                                        </div>
                                                        <div className='qr-code-side'>
                                                            <div className='qrcode-image-div'>
                                                                <QRCode
                                                                    size={170}
                                                                    errorLevel="H"
                                                                    value={`https://t.zipler.io/${encId}`}
                                                                    icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                                />
                                                            </div>
                                                            <Paragraph style={{ fontWeight: 500, fontSize: 18, marginBottom: 0, marginTop: 14, color: businessCardTextColor }}><i>Scan for surprise ✨</i></Paragraph>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='business-card-buttons'>
                                                <Flex gap='middle' justify='center'>
                                                    <Button type={businessCardSide === 'front' && 'primary'} onClick={() => setBusinessCardSide('front')} shape="round" size='large'>Front Side</Button>
                                                    <Button type={businessCardSide === 'back' && 'primary'} onClick={() => setBusinessCardSide('back')} shape="round" size='large'>Back Side</Button>
                                                </Flex>
                                            </div>
                                        </div>
                                    ) : creativeType === 'qrcode' && (
                                        <div className='qr-code-only'>
                                            <Flex justify='center' style={{ marginTop: 28, marginBlock: 38 }}>
                                                <div className='qrcode-image-div'>
                                                    <QRCode
                                                        size={220}
                                                        errorLevel="H"
                                                        value={`https://t.zipler.io/${encId}`}
                                                        icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                    />
                                                </div>
                                            </Flex>
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col span={10} style={{ height: '90vh' }}>
                                <Form layout="vertical" form={step2Form} className='add-qr-popup-right-div-form' style={{ overflowY: 'auto', height: '100%' }}>
                                    <div className='add-qr-popup-right-div'>
                                        <Paragraph style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 8, color: '#697777', fontSize: 16 }}><PiSelectionPlusDuotone size={24} /> Create from scratch</Paragraph>
                                        <Title level={3}>Awesome! Now let's design creatives to promote your QR code</Title>
                                        {creativeType === 'poster' ? (
                                            <>
                                                <div className='add-new-qr-input-div'>
                                                    <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Poster Title</Title>
                                                    <Form.Item
                                                        rules={[{ required: true, message: 'Please enter the poster title' }]}
                                                    >
                                                        <Input autoComplete="off" value={posterTitle === 'Add a title for your landing page.' ? '' : posterTitle} onChange={(e) => setPosterTitle(e.target.value)} maxLength={120} showCount placeholder='Add the title for QR Creative' />
                                                    </Form.Item>
                                                </div>
                                                <Flex gap={16}>
                                                    <div className='add-new-qr-input-div'>
                                                        <Flex gap={16} align='center'>
                                                            <ColorPicker value={posterThemeColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setPosterThemeColor(hexColor) }} />
                                                            <Title level={4} style={{ margin: 0 }}>Theme Color</Title>
                                                        </Flex>
                                                    </div>
                                                    <div className='add-new-qr-input-div'>
                                                        <Flex gap={16} align='center'>
                                                            <ColorPicker value={posterTextColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setPosterTextColor(hexColor) }} />
                                                            <Title level={4} style={{ margin: 0 }}>Text Color</Title>
                                                        </Flex>
                                                    </div>
                                                </Flex>
                                                <div className='add-new-qr-input-div'>
                                                    <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiImageDuotone size={24} />Banner Image</Title>
                                                    <Paragraph style={{ color: '#697777', fontSize: 14 }}>Recommended size 512 x 512 px in JPG, GIF or PNG format up-to max size of 5MB.</Paragraph>
                                                    <Upload.Dragger
                                                        name="banner"
                                                        listType="picture"
                                                        beforeUpload={beforeUpload}
                                                        onChange={handleChangePosterBanner}
                                                        maxCount={1}
                                                        accept="image/jpeg, image/png"
                                                        customRequest={({ file, onSuccess }) => {
                                                            setTimeout(() => onSuccess(file), 0);
                                                        }}
                                                        onRemove={handleRemovePosterBanner}
                                                    >
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                        <p className="ant-upload-hint">Support for a single upload only.</p>
                                                    </Upload.Dragger>
                                                </div>
                                            </>
                                        ) : creativeType === 'sticker' ? (
                                            <>
                                                <div className='add-new-qr-input-div'>
                                                    <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Sticker Title</Title>
                                                    <Form.Item
                                                        rules={[{ required: true, message: 'Please enter the sticker title' }]}
                                                    >
                                                        <Input autoComplete="off" value={stickerTitle === 'Add a title for your landing page.' ? '' : stickerTitle} onChange={(e) => setStickerTitle(e.target.value)} maxLength={120} showCount placeholder='Add the title for QR Creative' />
                                                    </Form.Item>
                                                </div>
                                                <Flex gap={16}>
                                                    <div className='add-new-qr-input-div'>
                                                        <Flex gap={16} align='center'>
                                                            <ColorPicker value={stickerThemeColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setStickerThemeColor(hexColor) }} />
                                                            <Title level={4} style={{ margin: 0 }}>Theme Color</Title>
                                                        </Flex>
                                                    </div>
                                                    <div className='add-new-qr-input-div'>
                                                        <Flex gap={16} align='center'>
                                                            <ColorPicker value={stickerTextColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setStickerTextColor(hexColor) }} />
                                                            <Title level={4} style={{ margin: 0 }}>Text Color</Title>
                                                        </Flex>
                                                    </div>
                                                </Flex>
                                            </>
                                        ) : creativeType === 'social' ? (
                                            <>
                                                <div className='add-new-qr-input-div'>
                                                    <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Social Post Title</Title>
                                                    <Form.Item
                                                        rules={[{ required: true, message: 'Please enter the social post title' }]}
                                                    >
                                                        <Input autoComplete="off" value={socialTitle === 'Add a title for your landing page.' ? '' : socialTitle} onChange={(e) => setSocialTitle(e.target.value)} maxLength={120} showCount placeholder='Add the title for QR Creative' />
                                                    </Form.Item>
                                                </div>
                                                <Flex gap={16}>
                                                    <div className='add-new-qr-input-div'>
                                                        <Flex gap={16} align='center'>
                                                            <ColorPicker value={socialPostThemeColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setsocialPostThemeColor(hexColor) }} />
                                                            <Title level={4} style={{ margin: 0 }}>Theme Color</Title>
                                                        </Flex>
                                                    </div>
                                                    <div className='add-new-qr-input-div'>
                                                        <Flex gap={16} align='center'>
                                                            <ColorPicker value={socialPostTextColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setsocialPostTextColor(hexColor) }} />
                                                            <Title level={4} style={{ margin: 0 }}>Text Color</Title>
                                                        </Flex>
                                                    </div>
                                                </Flex>
                                                <div className='add-new-qr-input-div'>
                                                    <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiImageDuotone size={24} />Banner Image</Title>
                                                    <Paragraph style={{ color: '#697777', fontSize: 14 }}>Recommended size 512 x 512 px in JPG, GIF or PNG format up-to max size of 5MB.</Paragraph>
                                                    <Upload.Dragger
                                                        name="socialbanner"
                                                        listType="picture"
                                                        beforeUpload={beforeUpload}
                                                        onChange={handleChangeSocialBanner}
                                                        maxCount={1}
                                                        accept="image/jpeg, image/png"
                                                        customRequest={({ file, onSuccess }) => {
                                                            setTimeout(() => onSuccess(file), 0);
                                                        }}
                                                        onRemove={handleRemoveSocialBanner}
                                                    >
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                        <p className="ant-upload-hint">Support for a single upload only.</p>
                                                    </Upload.Dragger>
                                                </div>
                                            </>
                                        ) : creativeType === 'businesscard' && (
                                            businessCardSide === 'front' ? (
                                                <>
                                                    <div className='add-new-qr-input-div'>
                                                        <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiImageDuotone size={24} />Banner Image</Title>
                                                        <Paragraph style={{ color: '#697777', fontSize: 14 }}>Recommended size 512 x 512 px in JPG, GIF or PNG format up-to max size of 5MB.</Paragraph>
                                                        <Upload.Dragger
                                                            name="banner"
                                                            listType="picture"
                                                            beforeUpload={beforeUpload}
                                                            onChange={handleChangeBusinessCardBanner}
                                                            maxCount={1}
                                                            accept="image/jpeg, image/png"
                                                            customRequest={({ file, onSuccess }) => {
                                                                setTimeout(() => onSuccess(file), 0);
                                                            }}
                                                            onRemove={handleRemoveBusinessCardBanner}
                                                        >
                                                            <p className="ant-upload-drag-icon">
                                                                <InboxOutlined />
                                                            </p>
                                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                            <p className="ant-upload-hint">Support for a single upload only.</p>
                                                        </Upload.Dragger>
                                                    </div>
                                                    <div className='add-new-qr-input-div'>
                                                        <Flex gap={16} align='center'>
                                                            <ColorPicker value={businessCardTextColorFront} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setBusinessCardTextColorFront(hexColor) }} />
                                                            <Title level={4} style={{ margin: 0 }}>Front Text Color</Title>
                                                        </Flex>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className='add-new-qr-input-div'>
                                                        <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Business Name</Title>
                                                        <Form.Item
                                                            rules={[{ required: true, message: 'Please enter business name' }]}
                                                        >
                                                            <Input autoComplete="off" value={businessName} onChange={(e) => setBusinessName(e.target.value)} placeholder='Add your business name' />
                                                        </Form.Item>
                                                    </div>
                                                    <div className='add-new-qr-input-div'>
                                                        <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiMapPinAreaDuotone size={24} />Address</Title>
                                                        <Form.Item
                                                            rules={[{ required: true, message: 'Please enter address' }]}
                                                        >
                                                            <TextArea
                                                                autoComplete="off"
                                                                value={businessAddress}
                                                                onChange={(e) => setBusinessAddress(e.target.value)}
                                                                maxLength={250}
                                                                showCount
                                                                placeholder='Add your business address'
                                                                rows={4}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <Flex gap={16}>
                                                        <div className='add-new-qr-input-div'>
                                                            <Flex gap={16} align='center'>
                                                                <ColorPicker value={businessCardThemeColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setBusinessCardThemeColor(hexColor) }} />
                                                                <Title level={4} style={{ margin: 0 }}>Theme Color</Title>
                                                            </Flex>
                                                        </div>
                                                        <div className='add-new-qr-input-div'>
                                                            <Flex gap={16} align='center'>
                                                                <ColorPicker value={businessCardTextColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setBusinessCardTextColor(hexColor) }} />
                                                                <Title level={4} style={{ margin: 0 }}>Text Color</Title>
                                                            </Flex>
                                                        </div>
                                                    </Flex>
                                                    <div className='add-new-qr-input-div'>
                                                        <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiImageDuotone size={24} />Contact information</Title>
                                                        <Form.Item
                                                            rules={[{ required: false }]}
                                                        >
                                                            <Input prefix={<PiDeviceMobileCameraDuotone />} autoComplete="off" value={businessContactInfoPhone} onChange={(e) => setBusinessContactInfoPhone(e.target.value)} placeholder='Phone No.' />
                                                        </Form.Item>
                                                        <Form.Item
                                                            rules={[{ required: false, }]}
                                                        >
                                                            <Input prefix={<PiEnvelopeDuotone />} autoComplete="off" value={businessContactInfoEmail} onChange={(e) => setBusinessContactInfoEmail(e.target.value)} placeholder='Email' />
                                                        </Form.Item>
                                                    </div>
                                                </>
                                            )
                                        )}
                                    </div>
                                </Form>
                            </Col>
                        </>
                    ) : staps === 7 && (
                        <>
                            <Col span={14} className='add-qr-popup-left-div-column'>
                                <div className='add-qr-popup-left-div'>
                                    <div style={{ padding: 16 }}>
                                        <Flex align='center' justify='center' gap={16} style={{ height: 30 }}>
                                            <Button style={{ height: 40 }} type={creativeType === 'poster' ? 'primary' : 'default'} shape='round' onClick={() => setCreativeType('poster')}>Poster</Button>
                                            <Button style={{ height: 40 }} type={creativeType === 'sticker' ? 'primary' : 'default'} shape='round' onClick={() => setCreativeType('sticker')}>Sticker</Button>
                                            <Button style={{ height: 40 }} type={creativeType === 'social' ? 'primary' : 'default'} shape='round' onClick={() => setCreativeType('social')}>Social Post</Button>
                                            <Button style={{ height: 40 }} type={creativeType === 'businesscard' ? 'primary' : 'default'} shape='round' onClick={() => setCreativeType('businesscard')}>Business Card</Button>
                                            <Button style={{ height: 40 }} type={creativeType === 'qrcode' ? 'primary' : 'default'} shape='round' onClick={() => setCreativeType('qrcode')}>QR Code Only</Button>
                                        </Flex>
                                    </div>
                                    {creativeType === 'poster' ? (
                                        <div className='qr-poster' style={{ backgroundColor: posterThemeColor }}>
                                            <div className='qr-bg-img-div' style={{ backgroundImage: `url(${posterBannerImage ? posterBannerImage : bgImg})` }}>
                                                <div className='backdrop'>
                                                    <div className='card-logo-div'>
                                                        <img className='card-logo-image' alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                    </div>
                                                    <Title level={2} style={{ color: posterTextColor }}>{posterTitle}</Title>
                                                    <div className="instruction" style={{ color: posterTextColor }}><span>Scan</span> <PiArrowRightDuotone size={16} /> <span>Claim</span><PiArrowRightDuotone size={16} /><span>Redeem</span></div>
                                                    <Flex justify='center' style={{ marginTop: 28, marginBottom: 38 }}>
                                                        <div className='qrcode-image-div'>
                                                            <QRCode
                                                                size={220}
                                                                errorLevel="H"
                                                                value={`https://t.zipler.io/${encId}`}
                                                                icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                            />
                                                        </div>
                                                    </Flex>
                                                    <p className='powered-by' style={{ color: posterTextColor }}>Powered by <a href="https://zipler.io" alt="zipler" style={{ color: posterTextColor }}>zipler.io</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : creativeType === 'sticker' ? (
                                        <div className='qr-code-sticker' style={{ backgroundColor: stickerThemeColor }}>
                                            <div className='sticker-head'>
                                                <div className='card-logo-div'>
                                                    <img className='card-logo-image' alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                </div>
                                                <Title level={3} style={{ color: stickerTextColor, marginTop: 16 }}>{stickerTitle}</Title>
                                            </div>
                                            <div>
                                                <Flex justify='center' style={{ marginBottom: 38 }}>
                                                    <div className='qrcode-image-div'>
                                                        <QRCode
                                                            size={220}
                                                            errorLevel="H"
                                                            value={`https://t.zipler.io/${encId}`}
                                                            icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                        />
                                                    </div>
                                                </Flex>
                                                <p className='powered-by' style={{ color: stickerTextColor }}>Powered by <a href="https://zipler.io" alt="zipler" style={{ color: stickerTextColor }}>zipler.io</a></p>

                                            </div>
                                        </div>
                                    ) : creativeType === 'social' ? (
                                        <div className='qr-social-media-container'>
                                            <div className='qr-social-media' style={{ backgroundColor: socialPostThemeColor }}>
                                                <div className='qr-bg-img-div' style={{ backgroundImage: `url(${socialBannerImage ? socialBannerImage : bgImg})` }}>
                                                    <div className='backdrop'>
                                                        <div className='left-side'>
                                                            <Flex justify='center' style={{ marginTop: 28, marginBlock: 38 }}>
                                                                <div className='qrcode-image-div'>
                                                                    <QRCode
                                                                        size={220}
                                                                        errorLevel="H"
                                                                        value={`https://t.zipler.io/${encId}`}
                                                                        icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                                    />
                                                                </div>
                                                            </Flex>
                                                        </div>
                                                        <div className='right-side'>
                                                            <div className='card-logo-div'>
                                                                <img className='card-logo-image' alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                            </div>
                                                            <Title level={2} style={{ color: socialPostTextColor }}>{socialTitle}</Title>
                                                            <div className="instruction" style={{ color: socialPostTextColor }}><span>Scan</span> <PiArrowRightDuotone size={16} /> <span>Claim</span><PiArrowRightDuotone size={16} /><span>Redeem</span></div>
                                                            <p className='powered-by' style={{ color: socialPostTextColor }}>Powered by <a href="https://zipler.io" alt="zipler" style={{ color: socialPostTextColor }}>zipler.io</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="social-claim-footer"><p>Click here to claim</p> <PiCaretRight size={20} color='#FFFFFF' /> </div>
                                        </div>
                                    ) : creativeType === 'businesscard' ? (
                                        <div className='qr-business-card-container'>
                                            <div className='qr-business-card' style={{ backgroundColor: businessCardThemeColor }}>
                                                {businessCardSide === 'front' ? (
                                                    <div className='business-card-front' style={{ backgroundImage: `url(${businessCardBannerImage ? businessCardBannerImage : bgImg})` }}>
                                                        <div>
                                                            <div className='card-logo-div'>
                                                                <img className='card-logo-image' alt='store-logo' src={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                            </div>
                                                            <Title level={1} style={{ margin: 0, color: businessCardTextColorFront }}>{storeDetail?.store_name}</Title>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='business-card-back'>
                                                        <div className='card-text-side'>
                                                            <div>
                                                                <Title level={1} style={{ margin: 0, color: businessCardTextColor }}>{storeDetail?.store_name}</Title>
                                                                <Paragraph style={{ margin: 0, fontSize: 16, color: businessCardTextColor }}>{businessAddress}</Paragraph>
                                                            </div>
                                                            <div className="sources">
                                                                {businessContactInfoPhone !== '' && <div className="source" style={{ color: businessCardTextColor }}><PiDeviceMobileCameraDuotone size={20} /> <Paragraph style={{ color: businessCardTextColor, margin: 0, fontSize: 16 }}>{businessContactInfoPhone}</Paragraph></div>}
                                                                {businessContactInfoEmail !== '' && <div className="source" style={{ color: businessCardTextColor }}><PiEnvelopeDuotone size={20} /> <Paragraph style={{ color: businessCardTextColor, margin: 0, fontSize: 16 }}>{businessContactInfoEmail}</Paragraph></div>}
                                                            </div>
                                                        </div>
                                                        <div className='qr-code-side'>
                                                            <div className='qrcode-image-div'>
                                                                <QRCode
                                                                    size={170}
                                                                    errorLevel="H"
                                                                    value={`https://t.zipler.io/${encId}`}
                                                                    icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                                />
                                                            </div>
                                                            <Paragraph style={{ fontWeight: 500, fontSize: 18, marginBottom: 0, marginTop: 14, color: businessCardTextColor }}><i>Scan for surprise ✨</i></Paragraph>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='business-card-buttons'>
                                                <Flex gap='middle' justify='center'>
                                                    <Button type={businessCardSide === 'front' && 'primary'} onClick={() => setBusinessCardSide('front')} shape="round" size='large'>Front Side</Button>
                                                    <Button type={businessCardSide === 'back' && 'primary'} onClick={() => setBusinessCardSide('back')} shape="round" size='large'>Back Side</Button>
                                                </Flex>
                                            </div>
                                        </div>
                                    ) : creativeType === 'qrcode' && (
                                        <div className='qr-code-only'>
                                            <Flex justify='center' style={{ marginTop: 28, marginBlock: 38 }}>
                                                <div className='qrcode-image-div'>
                                                    <QRCode
                                                        size={220}
                                                        errorLevel="H"
                                                        value={`https://t.zipler.io/${encId}`}
                                                        icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                    />
                                                </div>
                                            </Flex>
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col span={10} style={{ height: '90vh' }}>
                                <Form layout="vertical" form={step2Form} className='add-qr-popup-right-div-form' style={{ overflowY: 'auto', height: '100%' }}>
                                    <div className='add-qr-popup-right-div'>
                                        <Paragraph style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 8, color: '#697777', fontSize: 16 }}><PiSelectionPlusDuotone size={24} /> Create from scratch</Paragraph>
                                        <Title level={3}>Review QR Code</Title>
                                        <div className='expiry-reminder-info-card' style={{ marginBottom: 16 }}>
                                            <div className='reminder-info-content'>
                                                <Flex gap={16} justify='space-between' align='center'>
                                                    <Title level={4} style={{ marginTop: 0 }}>Scan the QR code and test it before activating it.</Title>
                                                    <div style={{ backgroundColor: '#FFF', borderRadius: 16 }}>
                                                        <QRCode
                                                            size={120}
                                                            errorLevel="H"
                                                            value={`https://t.zipler.io/${encId}`}
                                                            icon={file ? file : `${window.BASE_URL}${storeDetail?.store_logo}`}
                                                        />
                                                    </div>
                                                </Flex>
                                            </div>
                                        </div>
                                        <div className='add-new-qr-input-div'>
                                            <Flex gap={16} justify='space-between' align='center'>
                                                <div>
                                                    <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4, color: '#697777' }}><PiGiftDuotone size={24} />Reward Details</Title>
                                                    <Paragraph><b>{rewardType === 'predisc' ? `${preDisc}% discount on entire purchase` : rewardType === 'cashdisc' ? `${storeDetail?.currency_symbol} ${cashDisc} discount on entire purchase` : rewardType === 'freeitem' && 'Free Items'}</b></Paragraph>
                                                </div>
                                                <div className='edit-icon' onClick={() => setStaps(2)}>
                                                    <PiPencilLineDuotone size={24} />
                                                </div>
                                            </Flex>
                                        </div>
                                        <div className='add-new-qr-input-div'>
                                            <Flex gap={16} justify='space-between' align='center'>
                                                <div>
                                                    <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4, color: '#697777' }}><PiUserDuotone size={24} />Who can redeem this reward?</Title>
                                                    <Paragraph><b>{rewardTermType === 'newandexisting' ? 'New + Existing Customers' : 'New Customers Only'}</b></Paragraph>
                                                    <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4, color: '#697777' }}><PiReceiptDuotone size={24} />How many times can they redeem?</Title>
                                                    <Paragraph><b>{redeemTime === 'justonce' ? 'Only Once' : 'Multiple Times'}</b></Paragraph>
                                                </div>
                                                <div className='edit-icon' onClick={() => setStaps(3)}>
                                                    <PiPencilLineDuotone size={24} />
                                                </div>
                                            </Flex>
                                        </div>
                                        {isExpirtimeNotification && (
                                            <div className='add-new-qr-input-div'>
                                                <Flex gap={16} justify='space-between' align='center'>
                                                    <div>
                                                        <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4, color: '#697777' }}><PiGiftDuotone size={24} />When does the reward expire?</Title>
                                                        <Paragraph><b>{`${expirtime} ${expirtimeType} after claiming`}</b></Paragraph>
                                                        <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4, color: '#697777' }}><PiGiftDuotone size={24} />Customer gets reminder message</Title>
                                                        <Paragraph style={{ marginBottom: 0 }}><b>{`${expirtimeNotification} ${expirtimeNotificationType} before reward expiring`}</b></Paragraph>
                                                        <Paragraph><b>On the last day of reward expiring</b></Paragraph>
                                                        <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4, color: '#697777' }}><PiGiftDuotone size={24} />Reminder communication sent on</Title>
                                                        <Paragraph><b>{comunicationTypes['Whatsapp Utility'] && 'Whatsapp'}{comunicationTypes['SMS'] && ', SMS'}</b></Paragraph>
                                                    </div>
                                                    <div className='edit-icon' onClick={() => setStaps(4)}>
                                                        <PiPencilLineDuotone size={24} />
                                                    </div>
                                                </Flex>
                                            </div>
                                        )}
                                        <div className='add-new-qr-input-div'>
                                            <Flex gap={16} justify='space-between' align='center'>
                                                <div>
                                                    <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4, color: '#697777' }}><PiStorefrontDuotone size={24} />Reward redeemable at</Title>
                                                    <Paragraph><b>{storeDetail?.store_name}</b></Paragraph>
                                                </div>
                                            </Flex>
                                        </div>
                                    </div>
                                </Form>
                            </Col>
                        </>
                    )}
                </Row>
            </Drawer >
        </Layout >
        </>
    );
};
export default QrCodeContent;